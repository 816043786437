import { useManageFetchOFMasters } from '../Concept/lib/manageFetchMasters'
import { useEffectOnce } from 'react-use'
import { createContext, useContext, useEffect, useState } from 'react'
import { FetchState } from '../../types/utilTypes'
import { Annum } from '../../../domain/annum/annum'
import { noop } from '../../../utils/noop'
import { useLogoutAfter401Error } from '../../auth/useLogout'
import { AnnumCompareService } from '../../../domain/annum/annumCompareService'
import { buildHashParams, useHashParams } from '../../hooks/useHashParams'

type AnnumsFetchingState = FetchState & {
  data: {
    annums: Annum[]
  }
}

type State = AnnumsFetchingState & {
  selectedAnnumId: number | null
  changeSelectedAnnumsId: (annumId: number) => void
}

export const ANNUM_YEAR_KEY_FOR_HASH_PARAMS = 'annum_year'

export const AnnumsAndSelectedIdContext = createContext<State>({
  fetching: true,
  fetched: false,
  data: { annums: [] },
  selectedAnnumId: null,
  changeSelectedAnnumsId: noop,
})

export function useAnnumsAndSelectedIdContext(): State {
  return useContext(AnnumsAndSelectedIdContext)
}

export function useAnnumsAndSelectedId(): State {
  const { fetchMasters, listPromisesWithoutInactive } = useManageFetchOFMasters()
  const logout = useLogoutAfter401Error()

  const [annumsFetchingState, setAnnumsFetchingState] = useState<AnnumsFetchingState>({
    fetched: false,
    fetching: true,
    data: { annums: [] },
  })
  const [selectedAnnumId, setSelectedAnnumId] = useState<number | null>(null)

  const [hashParams, updateHashParam] = useHashParams()
  const annumYearFromParams: number = parseInt(hashParams.get(ANNUM_YEAR_KEY_FOR_HASH_PARAMS) ?? 'null', 10)

  // hashParamsの変化を検知してidを更新する
  // 初回の正しいhashParamsからの選択以外は URL変化(updateHashParam()) > useEffectの処理順でstateのidを更新する
  useEffect(() => {
    const annum = annumsFetchingState.data.annums.find((annum) => annum.year === annumYearFromParams)
    annum && setSelectedAnnumId(annum.id)
  }, [annumYearFromParams])

  // 初回はすでに正しいhashParamsが存在するとして、stateのidのみを更新し、URLへは変更を加えない
  useEffectOnce(() => {
    ;(async () => {
      fetchMasters(['annums'], logout)

      const annums = await listPromisesWithoutInactive.annums
      const sortedAnnums = [...annums].sort(AnnumCompareService.compareAnnumYear())

      setAnnumsFetchingState({
        fetching: false,
        fetched: true,
        data: {
          annums: sortedAnnums,
        },
      })

      // 初期選択年産を決定する
      // # 優先度1
      //  query parameterにある年
      // # 優先度2
      //  翌年
      // # 優先度3
      //  存在する中の最新を採用する
      const selectedAnnumP1 = sortedAnnums.find((a) => a.year === annumYearFromParams)
      if (selectedAnnumP1) {
        setSelectedAnnumId(selectedAnnumP1.id)
        return
      }
      const defaultFirstChoiceYear = new Date().getFullYear() + 1
      const selectedAnnumP2 = sortedAnnums.find((a) => a.year === defaultFirstChoiceYear)
      if (selectedAnnumP2) {
        setSelectedAnnumId(selectedAnnumP2.id)
        return
      }
      const selectedAnnumP3 = sortedAnnums[sortedAnnums.length - 1]
      setSelectedAnnumId(selectedAnnumP3.id)
    })()
  })

  function changeSelectedAnnumsId(annumsId: number) {
    const annum = annumsFetchingState.data.annums.find((annum) => annum.id === annumsId)
    annum && updateHashParam(ANNUM_YEAR_KEY_FOR_HASH_PARAMS, annum.year)
  }

  return {
    ...annumsFetchingState,
    selectedAnnumId,
    changeSelectedAnnumsId,
  }
}

/**
 * 現在のannum.yearを画面マウント時にURLに仕込む
 * 全画面で必要
 */
export function useSetCurrentYearToHashWhenMount() {
  const [, updateHashParam] = useHashParams()

  const { data, selectedAnnumId } = useAnnumsAndSelectedIdContext()

  useEffect(() => {
    if (selectedAnnumId) {
      const annum = data.annums.find((a) => a.id === selectedAnnumId)
      annum && updateHashParam('annum_year', annum.year)
    }
  }, [])
}

export function appendAnnumQueryParamsTo(url: string, paramsKeyValue: { [key: string]: string } = {}): string {
  const hashParamsKeys = [ANNUM_YEAR_KEY_FOR_HASH_PARAMS]
  const query = buildHashParams(hashParamsKeys, paramsKeyValue)

  return query ? `${url}?${query}` : url
}
