/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * ディール
 * @export
 * @interface Deal
 */
export interface Deal {
  /**
   * 納品物の包装形態ID
   * @type {number}
   * @memberof Deal
   */
  packagingFormId: number
  /**
   * 取引口数
   * @type {number}
   * @memberof Deal
   */
  tradingUnits: number
  /**
   * 増額される金額（円）
   * @type {number}
   * @memberof Deal
   */
  unitPriceAdjustmentInYen: number
  /**
   * 検査後保管可能期間ID
   * @type {number}
   * @memberof Deal
   */
  storagePeriodId: number
  /**
   * 収穫予定時期（画面では「x月y旬」として扱われる、上旬: 日付が1、中旬: 日付が11、下旬: 日付が21、nullは「未定・不明」）
   * @type {Date}
   * @memberof Deal
   */
  expectedHarvestTimeframe: Date | null
  /**
   * 検査実施主体（"not_needed": 検査不要、"self": 自身で検査する／検査手配する、"request": 外部検査機関の手配を依頼する）
   * @type {string}
   * @memberof Deal
   */
  inspectionResponsibility: DealInspectionResponsibilityEnum
}

/**
 * @export
 * @enum {string}
 */
export enum DealInspectionResponsibilityEnum {
  NotNeeded = 'not_needed',
  Self = 'self',
  Request = 'request',
}

export function DealFromJSON(json: any): Deal {
  return DealFromJSONTyped(json, false)
}

export function DealFromJSONTyped(json: any, ignoreDiscriminator: boolean): Deal {
  if (json === undefined || json === null) {
    return json
  }
  return {
    packagingFormId: json['packaging_form_id'],
    tradingUnits: json['trading_units'],
    unitPriceAdjustmentInYen: json['unit_price_adjustment_in_yen'],
    storagePeriodId: json['storage_period_id'],
    expectedHarvestTimeframe:
      json['expected_harvest_timeframe'] === null ? null : new Date(json['expected_harvest_timeframe']),
    inspectionResponsibility: json['inspection_responsibility'],
  }
}

export function DealToJSON(value?: Deal | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    packaging_form_id: value.packagingFormId,
    trading_units: value.tradingUnits,
    unit_price_adjustment_in_yen: value.unitPriceAdjustmentInYen,
    storage_period_id: value.storagePeriodId,
    expected_harvest_timeframe:
      value.expectedHarvestTimeframe === null ? null : value.expectedHarvestTimeframe.toISOString().substr(0, 10),
    inspection_responsibility: value.inspectionResponsibility,
  }
}
