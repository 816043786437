/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  AttachmentCreate,
  AttachmentCreateFromJSON,
  AttachmentCreateToJSON,
  InlineResponse201,
  InlineResponse201FromJSON,
  InlineResponse201ToJSON,
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
  ResponseSuccessBody,
  ResponseSuccessBodyFromJSON,
  ResponseSuccessBodyToJSON,
} from '../models'

export interface GetAttachmentsRequest {
  uuid: string
}

export interface GetAttachmentsUploadDoneRequest {
  uuid: string
}

export interface PostAttachmentsRequest {
  attachmentCreate?: AttachmentCreate
}

/**
 *
 */
export class AttachmentsApi extends runtime.BaseAPI {
  /**
   * 指定UUIDに該当する添付ファイルのダウンロードURLにリダイレクトする
   */
  async getAttachmentsRaw(requestParameters: GetAttachmentsRequest): Promise<runtime.ApiResponse<ResponseSuccessBody>> {
    if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
      throw new runtime.RequiredError(
        'uuid',
        'Required parameter requestParameters.uuid was null or undefined when calling getAttachments.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.uuid !== undefined) {
      queryParameters['uuid'] = requestParameters.uuid
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/attachments`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessBodyFromJSON(jsonValue))
  }

  /**
   * 指定UUIDに該当する添付ファイルのダウンロードURLにリダイレクトする
   */
  async getAttachments(requestParameters: GetAttachmentsRequest): Promise<ResponseSuccessBody> {
    const response = await this.getAttachmentsRaw(requestParameters)
    return await response.value()
  }

  /**
   * 添付ファイルAWS S3アップロード完了後に呼び出されるコールバック（AWS S3アップロードリクエストのレスポンスによりここにリダイレクトされる）
   */
  async getAttachmentsUploadDoneRaw(
    requestParameters: GetAttachmentsUploadDoneRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
      throw new runtime.RequiredError(
        'uuid',
        'Required parameter requestParameters.uuid was null or undefined when calling getAttachmentsUploadDone.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.uuid !== undefined) {
      queryParameters['uuid'] = requestParameters.uuid
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/attachments/upload_done`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * 添付ファイルAWS S3アップロード完了後に呼び出されるコールバック（AWS S3アップロードリクエストのレスポンスによりここにリダイレクトされる）
   */
  async getAttachmentsUploadDone(requestParameters: GetAttachmentsUploadDoneRequest): Promise<void> {
    await this.getAttachmentsUploadDoneRaw(requestParameters)
  }

  /**
   * 新しい添付ファイルを生成し、AWS S3アップロードに必要なパラメータを取得する
   */
  async postAttachmentsRaw(requestParameters: PostAttachmentsRequest): Promise<runtime.ApiResponse<InlineResponse201>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/attachments`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: AttachmentCreateToJSON(requestParameters.attachmentCreate),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse201FromJSON(jsonValue))
  }

  /**
   * 新しい添付ファイルを生成し、AWS S3アップロードに必要なパラメータを取得する
   */
  async postAttachments(requestParameters: PostAttachmentsRequest): Promise<InlineResponse201> {
    const response = await this.postAttachmentsRaw(requestParameters)
    return await response.value()
  }
}
