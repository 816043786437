/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  AmendsInspectionResultsUpdateInspectionResults,
  AmendsInspectionResultsUpdateInspectionResultsFromJSON,
  AmendsInspectionResultsUpdateInspectionResultsFromJSONTyped,
  AmendsInspectionResultsUpdateInspectionResultsToJSON,
  Attachment,
  AttachmentFromJSON,
  AttachmentFromJSONTyped,
  AttachmentToJSON,
} from './'

/**
 * アメンドの検査結果
 * @export
 * @interface AmendsInspectionResultsUpdate
 */
export interface AmendsInspectionResultsUpdate {
  /**
   * アメンドID
   * @type {number}
   * @memberof AmendsInspectionResultsUpdate
   */
  readonly id?: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof AmendsInspectionResultsUpdate
   */
  versionNumber: number
  /**
   * 生産者のID
   * @type {number}
   * @memberof AmendsInspectionResultsUpdate
   */
  readonly producerId?: number
  /**
   * 卸事業者のID
   * @type {number}
   * @memberof AmendsInspectionResultsUpdate
   */
  readonly wholesalerId?: number
  /**
   * 検査結果
   * @type {Array<AmendsInspectionResultsUpdateInspectionResults>}
   * @memberof AmendsInspectionResultsUpdate
   */
  inspectionResults: Array<AmendsInspectionResultsUpdateInspectionResults>
  /**
   * 検査結果を裏付ける資料（添付ファイルUUIDリスト）
   * @type {Array<string>}
   * @memberof AmendsInspectionResultsUpdate
   */
  inspectionResultsAttachmentUuids: Array<string>
  /**
   * 検査結果を裏付ける資料
   * @type {Array<Attachment>}
   * @memberof AmendsInspectionResultsUpdate
   */
  readonly inspectionResultsAttachments: Array<Attachment>
  /**
   * 検査結果メモ
   * @type {string}
   * @memberof AmendsInspectionResultsUpdate
   */
  inspectionResultsMemo: string
}

export function AmendsInspectionResultsUpdateFromJSON(json: any): AmendsInspectionResultsUpdate {
  return AmendsInspectionResultsUpdateFromJSONTyped(json, false)
}

export function AmendsInspectionResultsUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AmendsInspectionResultsUpdate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    versionNumber: json['version_number'],
    producerId: !exists(json, 'producer_id') ? undefined : json['producer_id'],
    wholesalerId: !exists(json, 'wholesaler_id') ? undefined : json['wholesaler_id'],
    inspectionResults: (json['inspection_results'] as Array<any>).map(
      AmendsInspectionResultsUpdateInspectionResultsFromJSON
    ),
    inspectionResultsAttachmentUuids: json['inspection_results_attachment_uuids'],
    inspectionResultsAttachments: (json['inspection_results_attachments'] as Array<any>).map(AttachmentFromJSON),
    inspectionResultsMemo: json['inspection_results_memo'],
  }
}

export function AmendsInspectionResultsUpdateToJSON(value?: AmendsInspectionResultsUpdate | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    version_number: value.versionNumber,
    inspection_results: (value.inspectionResults as Array<any>).map(
      AmendsInspectionResultsUpdateInspectionResultsToJSON
    ),
    inspection_results_attachment_uuids: value.inspectionResultsAttachmentUuids,
    inspection_results_memo: value.inspectionResultsMemo,
  }
}
