import { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, Text, useDisclosure } from '@chakra-ui/react'
import { BackIcon } from '../../svg/BackIcon'
import { MenuIcon } from '../../svg/MenuIcon'
import { DrawerMenu } from '../../Menu/DrawerMenu'
import { TodoIcon as TodoIconSvg } from '../../svg/TodoIcon'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { usePendingTasksContext } from '../../../PrivatePages/hooks/usePendingTasks'
import { theme } from '../../../../theme/theme'

/**
 * 戻る
 * @constructor
 */
export function Back() {
  const history = useHistory()

  return (
    <Flex
      direction="column"
      align="center"
      width="64px"
      height="54px"
      padding="10.74px 15px 8px"
      onClick={() => history.goBack()}
      cursor="pointer"
    >
      <BackIcon />
      <Text fontSize="10px" lineHeight="10px" fontWeight="bold" mt="5px">
        戻る
      </Text>
    </Flex>
  )
}

/**
 * amend専用の戻るボタン(取引案件一覧に移動する)
 * @constructor
 */
export const AmendBack = () => {
  const history = useHistory()
  return (
    <Flex
      direction="column"
      align="center"
      width="64px"
      height="54px"
      padding="10.74px 15px 8px"
      onClick={() => history.push('/deals')}
      cursor="pointer"
    >
      <BackIcon />
      <Text fontSize="10px" lineHeight="10px" fontWeight="bold" mt="5px">
        一覧へ
      </Text>
    </Flex>
  )
}

/**
 * 現物のディール詳細情報入力画面専用の戻るボタン(中身はAmendBackと同様)
 * @constructor
 */
export const SpotContractDealInputBack = () => {
  return <AmendBack />
}

/**
 * 直接リンク専用の戻るボタン(ホームに戻る)
 * @constructor
 */
export const HomeBack = () => {
  const history = useHistory()
  return (
    <Flex
      direction="column"
      align="center"
      width="64px"
      height="54px"
      padding="10.74px 15px 8px"
      onClick={() => history.push('/home')}
      cursor="pointer"
    >
      <BackIcon />
      <Text fontSize="10px" lineHeight="10px" fontWeight="bold" mt="5px">
        ホーム
      </Text>
    </Flex>
  )
}

export function OpenMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef(null)
  const { drawerSize, innerHeaderHeight } = theme.sizes.brand

  return (
    <>
      <button ref={btnRef} onClick={onOpen}>
        <Flex color="brand.text.main" align="center" direction="column" padding="10px 10px 8px 15px">
          <MenuIcon />
          <Text fontSize="10px" fontWeight="bold" marginTop="6.39px" lineHeight="10px" display="block">
            メニュー
          </Text>
        </Flex>
      </button>
      <DrawerMenu
        isOpen={isOpen}
        onClose={onClose}
        btnRef={btnRef}
        innerHeaderHeight={innerHeaderHeight}
        drawerSize={drawerSize}
      />
    </>
  )
}

export function OpenTodoList() {
  const history = useHistory()
  const isMobile = useIsMobile()

  const openPendingTasksPage = () => history.push('/pending_tasks')

  return (
    <Flex marginRight={isMobile ? '11.83px' : '12px'} height="54px">
      <button onClick={openPendingTasksPage}>
        <Flex alignItems="center" padding={isMobile ? '0 10px' : '0 12px'}>
          <TodoIcon />
        </Flex>
      </button>
    </Flex>
  )
}

// アイコンにやることあり表示を追加
export const TodoIcon = () => {
  const { hasTodo } = usePendingTasksContext()
  return <TodoIconSvg hasTodo={hasTodo} />
}
