import { Box, Flex, Text, Drawer, DrawerOverlay, DrawerContent } from '@chakra-ui/react'

import { UserInformation } from '../Menu/UserInformation'
import { UserInformationLabel } from '../InformationLabel/UserInformationLabel'
import { PrefectureLabel } from '../InformationLabel/PrefectureLabel'

import { MenuCloseIcon } from '../svg/MenuCloseIcon'
import { LogoReverse } from '../svg/LogoReverse'

import { Menu } from './Menu'

export const DrawerMenu = ({ isOpen, onClose, btnRef, innerHeaderHeight, drawerSize }: any) => {
  return (
    <Drawer size="xs" isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={btnRef as any}>
      <DrawerOverlay />
      <DrawerContent overflowX="hidden" overflowY="auto">
        <Box bg="brand.brand" width="100%" height={innerHeaderHeight} color="white">
          <Flex align="center" justifyContent="space-between">
            <Flex align="center">
              <button onClick={onClose}>
                <Flex color="brand.text.main" align="center" direction="column" padding="10px 15px 8px">
                  <MenuCloseIcon />
                  <Text fontSize="10px" fontWeight="bold" marginTop="6.39px" lineHeight="10px" display="block">
                    閉じる
                  </Text>
                </Flex>
              </button>
            </Flex>
            <UserInformationLabel />
          </Flex>
        </Box>
        <Box width={'brand.drawerSize' as any} maxWidth={drawerSize}>
          <Flex maxWidth="brand.drawerSize" height="47px">
            <Flex backgroundColor="brand.text.main" padding="9px 10px">
              <LogoReverse />
            </Flex>
            <PrefectureLabel />
          </Flex>
        </Box>
        <UserInformation />
        <Menu />
      </DrawerContent>
    </Drawer>
  )
}
