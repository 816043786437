/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * [現物契約] アメンドの検査結果
 * @export
 * @interface SpotContractAmendsInspectionResultsUpdate
 */
export interface SpotContractAmendsInspectionResultsUpdate {
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof SpotContractAmendsInspectionResultsUpdate
   */
  versionNumber: number
  /**
   * 検査結果を裏付ける資料（添付ファイルUUIDリスト）
   * @type {Array<string>}
   * @memberof SpotContractAmendsInspectionResultsUpdate
   */
  inspectionResultsAttachmentUuids: Array<string>
  /**
   * 検査結果メモ
   * @type {string}
   * @memberof SpotContractAmendsInspectionResultsUpdate
   */
  inspectionResultsMemo: string
}

export function SpotContractAmendsInspectionResultsUpdateFromJSON(
  json: any
): SpotContractAmendsInspectionResultsUpdate {
  return SpotContractAmendsInspectionResultsUpdateFromJSONTyped(json, false)
}

export function SpotContractAmendsInspectionResultsUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpotContractAmendsInspectionResultsUpdate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    versionNumber: json['version_number'],
    inspectionResultsAttachmentUuids: json['inspection_results_attachment_uuids'],
    inspectionResultsMemo: json['inspection_results_memo'],
  }
}

export function SpotContractAmendsInspectionResultsUpdateToJSON(
  value?: SpotContractAmendsInspectionResultsUpdate | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    version_number: value.versionNumber,
    inspection_results_attachment_uuids: value.inspectionResultsAttachmentUuids,
    inspection_results_memo: value.inspectionResultsMemo,
  }
}
