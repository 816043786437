/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  ProducerBankAccount,
  ProducerBankAccountFromJSON,
  ProducerBankAccountToJSON,
  ProducerBankAccountReadSeller,
  ProducerBankAccountReadSellerFromJSON,
  ProducerBankAccountReadSellerToJSON,
  ProducerBankAccountsUpdate,
  ProducerBankAccountsUpdateFromJSON,
  ProducerBankAccountsUpdateToJSON,
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
} from '../models'

export interface DeleteProducerBankAccountsIdRequest {
  id: number
  versionNumber: number
}

export interface GetProducerBankAccountsIdRequest {
  id: number
}

export interface PostProducerBankAccountsRequest {
  producerBankAccount?: ProducerBankAccount
}

export interface PutProducerBankAccountsIdRequest {
  id: number
  producerBankAccountsUpdate?: ProducerBankAccountsUpdate
}

/**
 *
 */
export class ProducerBankAccountsApi extends runtime.BaseAPI {
  /**
   * 指定IDに該当する生産者の銀行口座を削除する
   */
  async deleteProducerBankAccountsIdRaw(
    requestParameters: DeleteProducerBankAccountsIdRequest
  ): Promise<runtime.ApiResponse<ProducerBankAccountReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling deleteProducerBankAccountsId.'
      )
    }

    if (requestParameters.versionNumber === null || requestParameters.versionNumber === undefined) {
      throw new runtime.RequiredError(
        'versionNumber',
        'Required parameter requestParameters.versionNumber was null or undefined when calling deleteProducerBankAccountsId.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.versionNumber !== undefined) {
      queryParameters['version_number'] = requestParameters.versionNumber
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producer_bank_accounts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ProducerBankAccountReadSellerFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当する生産者の銀行口座を削除する
   */
  async deleteProducerBankAccountsId(
    requestParameters: DeleteProducerBankAccountsIdRequest
  ): Promise<ProducerBankAccountReadSeller> {
    const response = await this.deleteProducerBankAccountsIdRaw(requestParameters)
    return await response.value()
  }

  /**
   * 生産者の銀行口座一覧を取得する
   */
  async getProducerBankAccountsRaw(): Promise<runtime.ApiResponse<Array<ProducerBankAccountReadSeller>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producer_bank_accounts`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProducerBankAccountReadSellerFromJSON))
  }

  /**
   * 生産者の銀行口座一覧を取得する
   */
  async getProducerBankAccounts(): Promise<Array<ProducerBankAccountReadSeller>> {
    const response = await this.getProducerBankAccountsRaw()
    return await response.value()
  }

  /**
   * 指定IDに該当する生産者の銀行口座の情報を取得する
   */
  async getProducerBankAccountsIdRaw(
    requestParameters: GetProducerBankAccountsIdRequest
  ): Promise<runtime.ApiResponse<ProducerBankAccountReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getProducerBankAccountsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producer_bank_accounts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ProducerBankAccountReadSellerFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当する生産者の銀行口座の情報を取得する
   */
  async getProducerBankAccountsId(
    requestParameters: GetProducerBankAccountsIdRequest
  ): Promise<ProducerBankAccountReadSeller> {
    const response = await this.getProducerBankAccountsIdRaw(requestParameters)
    return await response.value()
  }

  /**
   * 生産者の新しい銀行口座を登録する
   */
  async postProducerBankAccountsRaw(
    requestParameters: PostProducerBankAccountsRequest
  ): Promise<runtime.ApiResponse<ProducerBankAccountReadSeller>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producer_bank_accounts`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ProducerBankAccountToJSON(requestParameters.producerBankAccount),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ProducerBankAccountReadSellerFromJSON(jsonValue))
  }

  /**
   * 生産者の新しい銀行口座を登録する
   */
  async postProducerBankAccounts(
    requestParameters: PostProducerBankAccountsRequest
  ): Promise<ProducerBankAccountReadSeller> {
    const response = await this.postProducerBankAccountsRaw(requestParameters)
    return await response.value()
  }

  /**
   * 指定IDに該当する生産者の銀行口座の情報を編集する
   */
  async putProducerBankAccountsIdRaw(
    requestParameters: PutProducerBankAccountsIdRequest
  ): Promise<runtime.ApiResponse<ProducerBankAccountReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putProducerBankAccountsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producer_bank_accounts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: ProducerBankAccountsUpdateToJSON(requestParameters.producerBankAccountsUpdate),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ProducerBankAccountReadSellerFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当する生産者の銀行口座の情報を編集する
   */
  async putProducerBankAccountsId(
    requestParameters: PutProducerBankAccountsIdRequest
  ): Promise<ProducerBankAccountReadSeller> {
    const response = await this.putProducerBankAccountsIdRaw(requestParameters)
    return await response.value()
  }
}
