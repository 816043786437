import { Annum } from './annum'

type Order = 'ASC' | 'DESC'

export const AnnumCompareService = {
  compareAnnumYear,
}

/**
 * 年産を西暦でソートするときに使用する
 * @param order
 */
export function compareAnnumYear(order: Order = 'ASC') {
  function compare(a: Annum, b: Annum) {
    const prime = a.year - b.year
    return prime !== 0 ? prime : compareId(a, b)
  }

  return function (a: Annum, b: Annum): number {
    if (order === 'ASC') {
      return compare(a, b)
    } else {
      return compare(b, a)
    }
  }
}

function compareId(a: Annum, b: Annum): number {
  return a.id - b.id
}
