/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 包装形態マスタ
 * @export
 * @interface PackagingForm1
 */
export interface PackagingForm1 {
  /**
   * ID
   * @type {number}
   * @memberof PackagingForm1
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof PackagingForm1
   */
  readonly versionNumber: number
  /**
   * ステータス（true: 有効、false: 無効）
   * @type {boolean}
   * @memberof PackagingForm1
   */
  isActive: boolean
  /**
   * 包装形態名
   * @type {string}
   * @memberof PackagingForm1
   */
  name: string
  /**
   * 包装形態の単位名
   * @type {string}
   * @memberof PackagingForm1
   */
  unitName: string
  /**
   * 包装形態の1単位あたりの重量（kg）
   * @type {number}
   * @memberof PackagingForm1
   */
  amountInKg: number
  /**
   * この包装形態を選択した時の1口あたりの取引数量（俵）
   *
   * 小数点以下の端数は0と5のみ許される：
   *
   * * OK: 100.0, 10.5, 1.0, 0.5
   * * NG: 100.1, 10.05, 1.01, 0.55
   * @type {string}
   * @memberof PackagingForm1
   */
  dealAmount: string
  /**
   * 配送手段
   * @type {string}
   * @memberof PackagingForm1
   */
  shippingMethod: PackagingForm1ShippingMethodEnum
}

/**
 * @export
 * @enum {string}
 */
export enum PackagingForm1ShippingMethodEnum {
  Truck = 'truck',
  JrContainer = 'jr_container',
}

export function PackagingForm1FromJSON(json: any): PackagingForm1 {
  return PackagingForm1FromJSONTyped(json, false)
}

export function PackagingForm1FromJSONTyped(json: any, ignoreDiscriminator: boolean): PackagingForm1 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isActive: json['is_active'],
    name: json['name'],
    unitName: json['unit_name'],
    amountInKg: json['amount_in_kg'],
    dealAmount: json['deal_amount'],
    shippingMethod: json['shipping_method'],
  }
}

export function PackagingForm1ToJSON(value?: PackagingForm1 | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    is_active: value.isActive,
    name: value.name,
    unit_name: value.unitName,
    amount_in_kg: value.amountInKg,
    deal_amount: value.dealAmount,
    shipping_method: value.shippingMethod,
  }
}
