import { TosApi } from '../../libs/api_client/apis'
import { getApiConfig } from '../../config/apiConfig'
import { useEffect, useState } from 'react'
import { FetchState } from '../types/utilTypes'
import {
  captureException,
  handle503Error,
  handleApiServerError,
  handlerNoNetworkError,
} from '../../infra/handleApiServerError'
import { Tos } from '../../domain/tos/tos'
import { useLocation } from 'react-router-dom'

type State = FetchState & {
  data: {
    tos: Tos | null
  }
}

export function useFetchTos(): State {
  const { pathname } = useLocation()

  const [state, setState] = useState<State>({ fetched: false, fetching: true, data: { tos: null } })

  useEffect(() => {
    ;(async () => {
      const tosApi = new TosApi(getApiConfig())
      try {
        const tos: Tos = await tosApi.getTos()

        setState({
          fetching: false,
          fetched: true,
          data: { tos },
        })
      } catch (err) {
        if (err instanceof Response) {
          handle503Error(err)
          handleApiServerError(err, captureException)
          handlerNoNetworkError(err)
        }
      }
    })()
  }, [pathname])

  return state
}
