/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * オファー通知条件
 * @export
 * @interface SettingOfferNotificationCondition
 */
export interface SettingOfferNotificationCondition {
  /**
   *
   * @type {string}
   * @memberof SettingOfferNotificationCondition
   */
  contractCategory: SettingOfferNotificationConditionContractCategoryEnum
  /**
   * 年産ID
   * @type {number}
   * @memberof SettingOfferNotificationCondition
   */
  annumId: number | null
  /**
   * 銘柄ID
   * @type {Set<number>}
   * @memberof SettingOfferNotificationCondition
   */
  brandIds: Set<number> | null
  /**
   * 上限金額
   * @type {number}
   * @memberof SettingOfferNotificationCondition
   */
  upperPrice: number | null
  /**
   * 下限金額
   * @type {number}
   * @memberof SettingOfferNotificationCondition
   */
  lowerPrice: number | null
}

/**
 * @export
 * @enum {string}
 */
export enum SettingOfferNotificationConditionContractCategoryEnum {
  AdvanceContract = 'advance_contract',
  SpotContract = 'spot_contract',
}

export function SettingOfferNotificationConditionFromJSON(json: any): SettingOfferNotificationCondition {
  return SettingOfferNotificationConditionFromJSONTyped(json, false)
}

export function SettingOfferNotificationConditionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SettingOfferNotificationCondition {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contractCategory: json['contract_category'],
    annumId: json['annum_id'],
    brandIds: json['brand_ids'],
    upperPrice: json['upper_price'],
    lowerPrice: json['lower_price'],
  }
}

export function SettingOfferNotificationConditionToJSON(value?: SettingOfferNotificationCondition | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contract_category: value.contractCategory,
    annum_id: value.annumId,
    brand_ids: value.brandIds,
    upper_price: value.upperPrice,
    lower_price: value.lowerPrice,
  }
}
