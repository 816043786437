/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SelfDelivery
 */
export interface SelfDelivery {
  /**
   * 運送会社名（下書きの時は空可）
   * @type {string}
   * @memberof SelfDelivery
   */
  shippingCompanyName: string
  /**
   * ドライバー名（下書きの時は空可）
   * @type {string}
   * @memberof SelfDelivery
   */
  driverName: string
  /**
   * ドライバー電話番号（下書きの時は空可）
   * @type {string}
   * @memberof SelfDelivery
   */
  driverPhoneNumber: string
  /**
   * 車番（下書きの時は空可）
   * @type {string}
   * @memberof SelfDelivery
   */
  carNumber: string
  /**
   * 引渡日（下書きの時はnull可）
   * @type {Date}
   * @memberof SelfDelivery
   */
  handoverDate: Date | null
  /**
   * 時間帯（下書きの時は空可）
   * @type {string}
   * @memberof SelfDelivery
   */
  handoverTime: string
}

export function SelfDeliveryFromJSON(json: any): SelfDelivery {
  return SelfDeliveryFromJSONTyped(json, false)
}

export function SelfDeliveryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelfDelivery {
  if (json === undefined || json === null) {
    return json
  }
  return {
    shippingCompanyName: json['shipping_company_name'],
    driverName: json['driver_name'],
    driverPhoneNumber: json['driver_phone_number'],
    carNumber: json['car_number'],
    handoverDate: json['handover_date'] === null ? null : new Date(json['handover_date']),
    handoverTime: json['handover_time'],
  }
}

export function SelfDeliveryToJSON(value?: SelfDelivery | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    shipping_company_name: value.shippingCompanyName,
    driver_name: value.driverName,
    driver_phone_number: value.driverPhoneNumber,
    car_number: value.carNumber,
    handover_date: value.handoverDate === null ? null : value.handoverDate.toISOString().substr(0, 10),
    handover_time: value.handoverTime,
  }
}
