import styled from '@emotion/styled'

export const LogoutIcon = () => (
  <Svg width="30" height="30" viewBox="0 0 30 30">
    <g transform="translate(1)">
      <path
        className="cls-1"
        d="M22.3.831V24.693a.821.821,0,0,0,.363.685l7.208,4.485a.682.682,0,0,0,.714.035.689.689,0,0,0,.369-.615V25.132H41.81a.831.831,0,0,0,.831-.831V17.4H39.515V22H30.954V6.418a1.81,1.81,0,0,0-.79-1.493l-4.971-1.8H39.515V9.3h3.127V.831A.831.831,0,0,0,41.81,0H23.131A.827.827,0,0,0,22.3.831Z"
        transform="translate(-22.3 0)"
      />
      <path
        className="cls-1"
        d="M228.412,141.226v2.834H217v3.988h11.412v2.834a.282.282,0,0,0,.164.252.268.268,0,0,0,.293-.041l4.567-5.036-4.567-5.036a.27.27,0,0,0-.293-.041.248.248,0,0,0-.164.246Z"
        transform="translate(-205.599 -132.7)"
      />
    </g>
    <rect className="cls-2" width="30" height="30" />
  </Svg>
)

const Svg = styled.svg`
  .cls-1 {
    fill: #35363a;
  }

  .cls-2 {
    fill: none;
  }
`
