/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 等級マスタ
 * @export
 * @interface GradeRead
 */
export interface GradeRead {
  /**
   * ID
   * @type {number}
   * @memberof GradeRead
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof GradeRead
   */
  readonly versionNumber: number
  /**
   * ステータス（true: 有効、false: 無効）
   * @type {boolean}
   * @memberof GradeRead
   */
  isActive: boolean
  /**
   * 等級名
   * @type {string}
   * @memberof GradeRead
   */
  name: string
  /**
   * 検査対象フラグ（true: 検査対象、false: 未検）
   * @type {boolean}
   * @memberof GradeRead
   */
  isInspected: boolean
  /**
   * 検査結果入力フラグ（true: 可、false: 不可）
   * @type {boolean}
   * @memberof GradeRead
   */
  canInputInspectionResult: boolean
}

export function GradeReadFromJSON(json: any): GradeRead {
  return GradeReadFromJSONTyped(json, false)
}

export function GradeReadFromJSONTyped(json: any, ignoreDiscriminator: boolean): GradeRead {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isActive: json['is_active'],
    name: json['name'],
    isInspected: json['is_inspected'],
    canInputInspectionResult: json['can_input_inspection_result'],
  }
}

export function GradeReadToJSON(value?: GradeRead | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    is_active: value.isActive,
    name: value.name,
    is_inspected: value.isInspected,
    can_input_inspection_result: value.canInputInspectionResult,
  }
}
