/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  OfferRequest,
  OfferRequestFromJSON,
  OfferRequestToJSON,
  OfferRequestReadSeller,
  OfferRequestReadSellerFromJSON,
  OfferRequestReadSellerToJSON,
  OfferRequestsUpdate,
  OfferRequestsUpdateFromJSON,
  OfferRequestsUpdateToJSON,
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
  ResponseSuccessBody,
  ResponseSuccessBodyFromJSON,
  ResponseSuccessBodyToJSON,
} from '../models'

export interface DeleteOfferRequestsIdRequest {
  id: number
  versionNumber: number
}

export interface GetOfferRequestsIdRequest {
  id: number
}

export interface PostOfferRequestsRequest {
  offerRequest?: OfferRequest
}

export interface PutOfferRequestsIdRequest {
  id: number
  offerRequestsUpdate?: OfferRequestsUpdate
}

/**
 *
 */
export class OfferRequestsApi extends runtime.BaseAPI {
  /**
   * 指定IDに該当する希望オファー銘柄・価格を削除する
   */
  async deleteOfferRequestsIdRaw(
    requestParameters: DeleteOfferRequestsIdRequest
  ): Promise<runtime.ApiResponse<OfferRequestReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling deleteOfferRequestsId.'
      )
    }

    if (requestParameters.versionNumber === null || requestParameters.versionNumber === undefined) {
      throw new runtime.RequiredError(
        'versionNumber',
        'Required parameter requestParameters.versionNumber was null or undefined when calling deleteOfferRequestsId.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.versionNumber !== undefined) {
      queryParameters['version_number'] = requestParameters.versionNumber
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/offer_requests/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => OfferRequestReadSellerFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当する希望オファー銘柄・価格を削除する
   */
  async deleteOfferRequestsId(requestParameters: DeleteOfferRequestsIdRequest): Promise<OfferRequestReadSeller> {
    const response = await this.deleteOfferRequestsIdRaw(requestParameters)
    return await response.value()
  }

  /**
   * 希望オファー銘柄・価格一覧を取得する
   */
  async getOfferRequestsRaw(): Promise<runtime.ApiResponse<Array<OfferRequestReadSeller>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/offer_requests`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferRequestReadSellerFromJSON))
  }

  /**
   * 希望オファー銘柄・価格一覧を取得する
   */
  async getOfferRequests(): Promise<Array<OfferRequestReadSeller>> {
    const response = await this.getOfferRequestsRaw()
    return await response.value()
  }

  /**
   * 指定IDに該当する希望オファー銘柄・価格の情報を取得する
   */
  async getOfferRequestsIdRaw(
    requestParameters: GetOfferRequestsIdRequest
  ): Promise<runtime.ApiResponse<OfferRequestReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getOfferRequestsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/offer_requests/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => OfferRequestReadSellerFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当する希望オファー銘柄・価格の情報を取得する
   */
  async getOfferRequestsId(requestParameters: GetOfferRequestsIdRequest): Promise<OfferRequestReadSeller> {
    const response = await this.getOfferRequestsIdRaw(requestParameters)
    return await response.value()
  }

  /**
   * 希望オファー銘柄・価格を登録する
   */
  async postOfferRequestsRaw(
    requestParameters: PostOfferRequestsRequest
  ): Promise<runtime.ApiResponse<ResponseSuccessBody>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/offer_requests`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: OfferRequestToJSON(requestParameters.offerRequest),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessBodyFromJSON(jsonValue))
  }

  /**
   * 希望オファー銘柄・価格を登録する
   */
  async postOfferRequests(requestParameters: PostOfferRequestsRequest): Promise<ResponseSuccessBody> {
    const response = await this.postOfferRequestsRaw(requestParameters)
    return await response.value()
  }

  /**
   * 指定IDに該当する希望オファー銘柄・価格の情報を編集する  管理者権限のアカウントのみ利用可能
   */
  async putOfferRequestsIdRaw(
    requestParameters: PutOfferRequestsIdRequest
  ): Promise<runtime.ApiResponse<OfferRequestReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putOfferRequestsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/offer_requests/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: OfferRequestsUpdateToJSON(requestParameters.offerRequestsUpdate),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => OfferRequestReadSellerFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当する希望オファー銘柄・価格の情報を編集する  管理者権限のアカウントのみ利用可能
   */
  async putOfferRequestsId(requestParameters: PutOfferRequestsIdRequest): Promise<OfferRequestReadSeller> {
    const response = await this.putOfferRequestsIdRaw(requestParameters)
    return await response.value()
  }
}
