/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * [現物契約] オファー公開期間
 * @export
 * @interface SpotContractOfferPublicationPeriodRead
 */
export interface SpotContractOfferPublicationPeriodRead {
  /**
   * ID
   * @type {number}
   * @memberof SpotContractOfferPublicationPeriodRead
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof SpotContractOfferPublicationPeriodRead
   */
  readonly versionNumber: number
  /**
   * 削除フラグ（true: 削除済、false: 未削除）
   * @type {boolean}
   * @memberof SpotContractOfferPublicationPeriodRead
   */
  readonly isDeleted: boolean
  /**
   * オファー公開期間名
   * @type {string}
   * @memberof SpotContractOfferPublicationPeriodRead
   */
  name: string
  /**
   * 公開開始日
   * @type {Date}
   * @memberof SpotContractOfferPublicationPeriodRead
   */
  publishStartDate: Date
  /**
   * 公開終了日
   * @type {Date}
   * @memberof SpotContractOfferPublicationPeriodRead
   */
  publishEndDate: Date
  /**
   * ステータス（true: 有効、false: 無効）
   * @type {boolean}
   * @memberof SpotContractOfferPublicationPeriodRead
   */
  isActive: boolean
  /**
   * オファーの作成時・登録時に選択できるかどうかのフラグ（true: 有効、false: 無効）
   * @type {boolean}
   * @memberof SpotContractOfferPublicationPeriodRead
   */
  isSelectable: boolean
}

export function SpotContractOfferPublicationPeriodReadFromJSON(json: any): SpotContractOfferPublicationPeriodRead {
  return SpotContractOfferPublicationPeriodReadFromJSONTyped(json, false)
}

export function SpotContractOfferPublicationPeriodReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpotContractOfferPublicationPeriodRead {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isDeleted: json['is_deleted'],
    name: json['name'],
    publishStartDate: new Date(json['publish_start_date']),
    publishEndDate: new Date(json['publish_end_date']),
    isActive: json['is_active'],
    isSelectable: json['is_selectable'],
  }
}

export function SpotContractOfferPublicationPeriodReadToJSON(
  value?: SpotContractOfferPublicationPeriodRead | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    publish_start_date: value.publishStartDate.toISOString().substr(0, 10),
    publish_end_date: value.publishEndDate.toISOString().substr(0, 10),
    is_active: value.isActive,
    is_selectable: value.isSelectable,
  }
}
