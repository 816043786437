/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { GradeRead, GradeReadFromJSON, GradeReadFromJSONTyped, GradeReadToJSON } from './'

/**
 *
 * @export
 * @interface PaymentReadSellerProductPayments
 */
export interface PaymentReadSellerProductPayments {
  /**
   * 等級（nullの場合は、減額なし分）
   * @type {GradeRead}
   * @memberof PaymentReadSellerProductPayments
   */
  grade: GradeRead | null
  /**
   * 売買代金, 円（税込み）
   * @type {string}
   * @memberof PaymentReadSellerProductPayments
   */
  tradingVolumeInYen: string
  /**
   * 税率, %
   * @type {string}
   * @memberof PaymentReadSellerProductPayments
   */
  taxRate: string
  /**
   * 納品物の数量
   * @type {string}
   * @memberof PaymentReadSellerProductPayments
   */
  packageCount: string
  /**
   * 俵数
   * @type {string}
   * @memberof PaymentReadSellerProductPayments
   */
  sacks: string
  /**
   * 引渡単価（円／俵）
   * @type {number}
   * @memberof PaymentReadSellerProductPayments
   */
  unitPrice: number
}

export function PaymentReadSellerProductPaymentsFromJSON(json: any): PaymentReadSellerProductPayments {
  return PaymentReadSellerProductPaymentsFromJSONTyped(json, false)
}

export function PaymentReadSellerProductPaymentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentReadSellerProductPayments {
  if (json === undefined || json === null) {
    return json
  }
  return {
    grade: GradeReadFromJSON(json['grade']),
    tradingVolumeInYen: json['trading_volume_in_yen'],
    taxRate: json['tax_rate'],
    packageCount: json['package_count'],
    sacks: json['sacks'],
    unitPrice: json['unit_price'],
  }
}

export function PaymentReadSellerProductPaymentsToJSON(value?: PaymentReadSellerProductPayments | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    grade: GradeReadToJSON(value.grade),
    trading_volume_in_yen: value.tradingVolumeInYen,
    tax_rate: value.taxRate,
    package_count: value.packageCount,
    sacks: value.sacks,
    unit_price: value.unitPrice,
  }
}
