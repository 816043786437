import { Flex, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { theme } from '../../../theme/theme'
import { useLoginInfoContext } from '../../PrivatePages/hooks/useFetchLoginInfo'
import { useIsMobile } from '../../hooks/useIsMobile'

export const PrefectureLabel = () => {
  const { data } = useLoginInfoContext()

  let prefecture = '　'
  if (data.producer) {
    prefecture = data.producer.prefecture.name
  }

  const isMobile = useIsMobile()
  const color = theme.colors.brand.text.main

  return isMobile ? (
    <Flex justify="center" align="center" flexGrow={1}>
      <Text fontWeight="bold">{prefecture}</Text>
    </Flex>
  ) : (
    <Label borderColor={color} color={color}>
      {prefecture}
    </Label>
  )
}

const Label = styled.div<{ borderColor: string; color: string }>`
  margin-left: 15px;
  padding: 10px 15px;
  background: white;
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 5px;
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
`
