export const invoiceRegistrationStatus = [
  { key: 'registered', label: '取得済' },
  { key: 'will_register', label: '取得予定' },
  { key: 'wont_register', label: '取得しない' },
  { key: 'pending', label: '未定/わからない' },
] as const

// keyのみの配列
export const invoiceRegistrationStatusKeys = invoiceRegistrationStatus.map((t) => t.key)

export type InvoiceRegistrationStatus = (typeof invoiceRegistrationStatus)[number]['key']

export type ProducerBasicProfileNormalized = {
  versionNumber: number
  // 地域ID
  regionId: number | null
  // 保管可能期間ID
  storagePeriodId: number | null
  // 有機JAS等の取り組みID
  farmingCertificationIds: Array<number>
  // 適格請求書発行事業者
  invoiceRegistrationStatus: InvoiceRegistrationStatus
  // 適格請求書発行事業者登録番号（頭の「T」を除いた13桁の数字）
  invoiceRegistrationNumber: string | null
  // 適格請求書発行事業者登録の予定時期（nullは「時期未定」）
  expectedRegistrationTimeframe: Date | null
}

export type ProducerBasicProfileCreate = ProducerBasicProfileNormalized

export type ProducerBasicProfileUpdate = ProducerBasicProfileNormalized
