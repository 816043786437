import { Box } from '@chakra-ui/react'
import type { BoxProps } from '@chakra-ui/react'

export const RedirectLink = ({
  children,
  onClick,
  id,
  ...props
}: { children: string; onClick: () => void; id?: string } & BoxProps) => {
  return (
    <Box textAlign="center" {...props}>
      <Box
        id={id}
        onClick={onClick}
        display="inline"
        textDecoration="underline"
        fontWeight="bold"
        textAlign="center"
        cursor="pointer"
      >
        {children}
      </Box>
    </Box>
  )
}
