/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * アメンドの引渡情報
 * @export
 * @interface AmendsHandoverUpdate
 */
export interface AmendsHandoverUpdate {
  /**
   * アメンドID
   * @type {number}
   * @memberof AmendsHandoverUpdate
   */
  readonly id?: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof AmendsHandoverUpdate
   */
  versionNumber: number
  /**
   * 生産者のID
   * @type {number}
   * @memberof AmendsHandoverUpdate
   */
  readonly producerId?: number
  /**
   * 卸事業者のID
   * @type {number}
   * @memberof AmendsHandoverUpdate
   */
  readonly wholesalerId?: number
  /**
   * 発送可能日
   * @type {Date}
   * @memberof AmendsHandoverUpdate
   */
  handoverPossibleDate: Date
  /**
   * 土日・祝日に引渡可能可能フラグ
   * @type {boolean}
   * @memberof AmendsHandoverUpdate
   */
  canHandoverOnHoliday: boolean
  /**
   * 引渡メモ
   * @type {string}
   * @memberof AmendsHandoverUpdate
   */
  handoverMemo: string
  /**
   * 引渡場所ID
   * @type {number}
   * @memberof AmendsHandoverUpdate
   */
  handoverAddressId: number
  /**
   * 入金口座
   * @type {number}
   * @memberof AmendsHandoverUpdate
   */
  paymentBankAccountId: number
}

export function AmendsHandoverUpdateFromJSON(json: any): AmendsHandoverUpdate {
  return AmendsHandoverUpdateFromJSONTyped(json, false)
}

export function AmendsHandoverUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AmendsHandoverUpdate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    versionNumber: json['version_number'],
    producerId: !exists(json, 'producer_id') ? undefined : json['producer_id'],
    wholesalerId: !exists(json, 'wholesaler_id') ? undefined : json['wholesaler_id'],
    handoverPossibleDate: new Date(json['handover_possible_date']),
    canHandoverOnHoliday: json['can_handover_on_holiday'],
    handoverMemo: json['handover_memo'],
    handoverAddressId: json['handover_address_id'],
    paymentBankAccountId: json['payment_bank_account_id'],
  }
}

export function AmendsHandoverUpdateToJSON(value?: AmendsHandoverUpdate | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    version_number: value.versionNumber,
    handover_possible_date: value.handoverPossibleDate.toISOString().substr(0, 10),
    can_handover_on_holiday: value.canHandoverOnHoliday,
    handover_memo: value.handoverMemo,
    handover_address_id: value.handoverAddressId,
    payment_bank_account_id: value.paymentBankAccountId,
  }
}
