/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 個別運送情報（HasProducerReviewed）
 * @export
 * @interface IndividualDeliveryHasProducerReviewed
 */
export interface IndividualDeliveryHasProducerReviewed {
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof IndividualDeliveryHasProducerReviewed
   */
  versionNumber: number
}

export function IndividualDeliveryHasProducerReviewedFromJSON(json: any): IndividualDeliveryHasProducerReviewed {
  return IndividualDeliveryHasProducerReviewedFromJSONTyped(json, false)
}

export function IndividualDeliveryHasProducerReviewedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IndividualDeliveryHasProducerReviewed {
  if (json === undefined || json === null) {
    return json
  }
  return {
    versionNumber: json['version_number'],
  }
}

export function IndividualDeliveryHasProducerReviewedToJSON(value?: IndividualDeliveryHasProducerReviewed | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    version_number: value.versionNumber,
  }
}
