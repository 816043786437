import { ArrayMaxSize, ArrayUnique, IsArray, IsDivisibleBy, IsInt, IsPositive, Max, Min } from 'class-validator'
import { InputOfferRequestCreate } from './useOfferRequestInput'

export class InputOfferRequestValidator {
  // 入力しないプロパティ
  regionId: number | null

  // 入力するプロパティ
  @IsInt()
  @IsPositive()
  brandId: number
  @IsInt()
  @IsPositive()
  gradeId: number
  @IsInt()
  @IsPositive()
  @Max(99900)
  @Min(100)
  @IsDivisibleBy(100)
  unitPrice: number
  @IsArray()
  @ArrayMaxSize(2)
  @ArrayUnique()
  specialConditionIds: number[]

  constructor(input: InputOfferRequestCreate) {
    this.regionId = input.regionId

    this.brandId = parseInt(input.brandId, 10)
    this.gradeId = parseInt(input.gradeId, 10)
    this.unitPrice = Math.floor(parseFloat(input.unitPrice) * 100) // 100~の位を入力するので
    this.specialConditionIds = this.resolveSpecialConditionIds(input)
  }

  resolveSpecialConditionIds(input: InputOfferRequestCreate) {
    return input.specialConditionIds.map((id) => parseInt(id, 10)).filter((id) => id)
  }
}
