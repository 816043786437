/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 銀行口座
 * @export
 * @interface ProducerBankAccountReadSeller
 */
export interface ProducerBankAccountReadSeller {
  /**
   * ID
   * @type {number}
   * @memberof ProducerBankAccountReadSeller
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof ProducerBankAccountReadSeller
   */
  readonly versionNumber: number
  /**
   * 削除フラグ（true: 削除済、false: 未削除）
   * @type {boolean}
   * @memberof ProducerBankAccountReadSeller
   */
  readonly isDeleted: boolean
  /**
   *
   * @type {string}
   * @memberof ProducerBankAccountReadSeller
   */
  bankCode: string
  /**
   *
   * @type {string}
   * @memberof ProducerBankAccountReadSeller
   */
  bankName: string
  /**
   *
   * @type {string}
   * @memberof ProducerBankAccountReadSeller
   */
  bankNameHiragana: string
  /**
   *
   * @type {string}
   * @memberof ProducerBankAccountReadSeller
   */
  branchCode: string
  /**
   * "futsuu": 普通、"touza": 当座、"chochiku": 貯蓄
   * @type {string}
   * @memberof ProducerBankAccountReadSeller
   */
  accountType: ProducerBankAccountReadSellerAccountTypeEnum
  /**
   *
   * @type {string}
   * @memberof ProducerBankAccountReadSeller
   */
  accountNumber: string
  /**
   *
   * @type {string}
   * @memberof ProducerBankAccountReadSeller
   */
  accountHolder: string
  /**
   *
   * @type {string}
   * @memberof ProducerBankAccountReadSeller
   */
  memo: string
}

/**
 * @export
 * @enum {string}
 */
export enum ProducerBankAccountReadSellerAccountTypeEnum {
  Futsuu = 'futsuu',
  Touza = 'touza',
  Chochiku = 'chochiku',
}

export function ProducerBankAccountReadSellerFromJSON(json: any): ProducerBankAccountReadSeller {
  return ProducerBankAccountReadSellerFromJSONTyped(json, false)
}

export function ProducerBankAccountReadSellerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProducerBankAccountReadSeller {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isDeleted: json['is_deleted'],
    bankCode: json['bank_code'],
    bankName: json['bank_name'],
    bankNameHiragana: json['bank_name_hiragana'],
    branchCode: json['branch_code'],
    accountType: json['account_type'],
    accountNumber: json['account_number'],
    accountHolder: json['account_holder'],
    memo: json['memo'],
  }
}

export function ProducerBankAccountReadSellerToJSON(value?: ProducerBankAccountReadSeller | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    bank_code: value.bankCode,
    bank_name: value.bankName,
    bank_name_hiragana: value.bankNameHiragana,
    branch_code: value.branchCode,
    account_type: value.accountType,
    account_number: value.accountNumber,
    account_holder: value.accountHolder,
    memo: value.memo,
  }
}
