import { FetchState } from '../../types/utilTypes'
import { Account } from '../../../domain/auth/account'
import { createContext, useContext, useEffect, useState } from 'react'
import { AuthApi } from '../../../libs/api_client/apis'
import { getApiConfig } from '../../../config/apiConfig'
import { useManageFetchOFMasters } from '../Concept/lib/manageFetchMasters'
import { denormalizeProducer } from '../../../domain/producer/denormalizeProducerService'
import {
  captureException,
  handle401Error,
  handle503Error,
  handleApiServerError,
  handlerNoNetworkError,
} from '../../../infra/handleApiServerError'
import { ProducerNormalized } from '../../../domain/producer/producer'
import { useLogoutAfter401Error } from '../../auth/useLogout'
import { noop } from '../../../utils/noop'
import { ExtendedProducer, extendProducerWithService } from '../../../domain/producer/producerService'
import { ExtendedLoginInfo, extendLoginInfoWithService } from '../../../domain/auth/loginInfoService'

type LoginInfoState = FetchState & {
  data: {
    producer: ExtendedProducer | null
    account: Account | null
    loginInfo: ExtendedLoginInfo | null
  }
}

type LoginInfoStateWithUpdate = LoginInfoState & {
  updateLoginInfo: () => Promise<void>
}

export const LoginInfoContext = createContext<LoginInfoStateWithUpdate>({
  fetched: false,
  fetching: true,
  data: {
    account: null,
    producer: null,
    loginInfo: null,
  },
  updateLoginInfo: noop,
})

export function useLoginInfoContext(): LoginInfoStateWithUpdate {
  return useContext(LoginInfoContext)
}

export function useFetchLoginInfo(locationPath: string): LoginInfoStateWithUpdate {
  const [state, setState] = useState<LoginInfoState>({
    fetched: false,
    fetching: true,
    data: { account: null, producer: null, loginInfo: null },
  })
  const { fetchMasters, mapPromises } = useManageFetchOFMasters()
  const logout = useLogoutAfter401Error()

  useEffect(() => {
    ;(async () => {
      fetchMasters(['prefectures'], logout)

      const prefectureMap = await mapPromises.prefectureMap
      const denormalize = denormalizeProducer({
        prefectureMap,
      })

      const authApi = new AuthApi(getApiConfig())
      try {
        type LoginInfo = {
          producer: ProducerNormalized
          producerAccount: Account
          isTosAgreementRequired: boolean
        }

        const loginInfo: LoginInfo = await authApi.getLoginInfo()

        const account = loginInfo.producerAccount
        const producer = denormalize(loginInfo.producer)
        const extendedProducer = extendProducerWithService(producer)
        const extendedLoginInfo = extendLoginInfoWithService({
          producer,
          producerAccount: account,
          isTosAgreementRequired: loginInfo.isTosAgreementRequired,
        })

        setState({
          fetched: true,
          fetching: false,
          data: { account, producer: extendedProducer, loginInfo: extendedLoginInfo },
        })
      } catch (err) {
        if (err instanceof Response) {
          handle503Error(err)
          handleApiServerError(err, captureException)
          handle401Error()(err, logout)
          handlerNoNetworkError(err)
        }
      }
    })()
  }, [locationPath])

  async function updateLoginInfo() {
    setState({ ...state, fetching: true, fetched: true }) // 情報自体はfetch済みなのでfetched=true

    fetchMasters(['prefectures'], logout)

    const prefectureMap = await mapPromises.prefectureMap
    const denormalize = denormalizeProducer({
      prefectureMap,
    })

    const authApi = new AuthApi(getApiConfig())
    try {
      type LoginInfo = {
        producer: ProducerNormalized
        producerAccount: Account
        isTosAgreementRequired: boolean
      }

      const loginInfo: LoginInfo = await authApi.getLoginInfo()

      const account = loginInfo.producerAccount
      const producer = denormalize(loginInfo.producer)
      const extendedProducer = extendProducerWithService(producer)
      const extendedLoginInfo = extendLoginInfoWithService({
        producer,
        producerAccount: account,
        isTosAgreementRequired: loginInfo.isTosAgreementRequired,
      })

      setState({
        fetched: true,
        fetching: false,
        data: { account, producer: extendedProducer, loginInfo: extendedLoginInfo },
      })
    } catch (err) {
      if (err instanceof Response) {
        handle503Error(err)
        handleApiServerError(err, captureException)
        handle401Error()(err, logout)
        handlerNoNetworkError(err)
      }
    }
  }

  return { ...state, updateLoginInfo }
}
