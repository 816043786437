/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 検査後の保管期間マスタ
 * @export
 * @interface StoragePeriodRead
 */
export interface StoragePeriodRead {
  /**
   * ID
   * @type {number}
   * @memberof StoragePeriodRead
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof StoragePeriodRead
   */
  readonly versionNumber: number
  /**
   * ステータス（true: 有効、false: 無効）
   * @type {boolean}
   * @memberof StoragePeriodRead
   */
  isActive: boolean
  /**
   * 保管期間名
   * @type {string}
   * @memberof StoragePeriodRead
   */
  name: string
  /**
   * 保管期間（日）
   * @type {number}
   * @memberof StoragePeriodRead
   */
  storagePeriodInDays: number
  /**
   * 加算される取引単価（円）
   * @type {number}
   * @memberof StoragePeriodRead
   */
  bonusInYen: number
}

export function StoragePeriodReadFromJSON(json: any): StoragePeriodRead {
  return StoragePeriodReadFromJSONTyped(json, false)
}

export function StoragePeriodReadFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoragePeriodRead {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isActive: json['is_active'],
    name: json['name'],
    storagePeriodInDays: json['storage_period_in_days'],
    bonusInYen: json['bonus_in_yen'],
  }
}

export function StoragePeriodReadToJSON(value?: StoragePeriodRead | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    is_active: value.isActive,
    name: value.name,
    storage_period_in_days: value.storagePeriodInDays,
    bonus_in_yen: value.bonusInYen,
  }
}
