import styled from '@emotion/styled'

export const ProducerIcon = () => (
  <Svg width="30" height="30" viewBox="0 0 30 30">
    <g>
      <path
        className="cls-3"
        d="M429.6,165.4h-1.3v8.039h1.3a1.594,1.594,0,0,0,1.594-1.594v-4.852A1.594,1.594,0,0,0,429.6,165.4"
        transform="translate(-403.204 -155.709)"
      />
      <path
        className="cls-3"
        d="M58,31H36.579A2.279,2.279,0,0,0,34.3,33.279V55.146a2.279,2.279,0,0,0,2.279,2.279H53.987a2.279,2.279,0,0,0,2.279-2.279V37.8h2.426a1.594,1.594,0,0,0,1.594-1.594v-2.93A2.279,2.279,0,0,0,58,31M41.853,40.17a3.586,3.586,0,0,1,7.166,0v1.02a3.586,3.586,0,0,1-7.166,0Zm3.586,11.408H38.888A5.762,5.762,0,0,1,43.974,45.6a4.588,4.588,0,0,0,2.924,0,5.758,5.758,0,0,1,5.086,5.982Z"
        transform="translate(-32.29 -29.183)"
      />
    </g>
  </Svg>
)

const Svg = styled.svg`
  .cls-1 {
    fill: none;
  }
  .cls-3 {
    fill: #35363a;
  }
`
