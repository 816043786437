/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * ハコベル依頼専用の項目（ハコベル依頼以外の時はnull）
 * @export
 * @interface IndividualDeliveryReadSellerHacobell
 */
export interface IndividualDeliveryReadSellerHacobell {
  /**
   * 運送会社名（確定までは空文字列）
   * @type {string}
   * @memberof IndividualDeliveryReadSellerHacobell
   */
  shippingCompanyName: string
  /**
   * 運送会社電話番号（確定までは空文字列）
   * @type {string}
   * @memberof IndividualDeliveryReadSellerHacobell
   */
  shippingCompanyPhoneNumber: string
  /**
   * 引渡日時（確定まではnull）
   * @type {Date}
   * @memberof IndividualDeliveryReadSellerHacobell
   */
  handoverAt: Date | null
  /**
   * ドライバー名（確定までは空文字列）
   * @type {string}
   * @memberof IndividualDeliveryReadSellerHacobell
   */
  driverName: string
  /**
   * ドライバー電話番号（確定までは空文字列）
   * @type {string}
   * @memberof IndividualDeliveryReadSellerHacobell
   */
  driverPhoneNumber: string
}

export function IndividualDeliveryReadSellerHacobellFromJSON(json: any): IndividualDeliveryReadSellerHacobell {
  return IndividualDeliveryReadSellerHacobellFromJSONTyped(json, false)
}

export function IndividualDeliveryReadSellerHacobellFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IndividualDeliveryReadSellerHacobell {
  if (json === undefined || json === null) {
    return json
  }
  return {
    shippingCompanyName: json['shipping_company_name'],
    shippingCompanyPhoneNumber: json['shipping_company_phone_number'],
    handoverAt: json['handover_at'] === null ? null : new Date(json['handover_at']),
    driverName: json['driver_name'],
    driverPhoneNumber: json['driver_phone_number'],
  }
}

export function IndividualDeliveryReadSellerHacobellToJSON(value?: IndividualDeliveryReadSellerHacobell | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    shipping_company_name: value.shippingCompanyName,
    shipping_company_phone_number: value.shippingCompanyPhoneNumber,
    handover_at: value.handoverAt === null ? null : value.handoverAt.toISOString(),
    driver_name: value.driverName,
    driver_phone_number: value.driverPhoneNumber,
  }
}
