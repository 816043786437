/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 生産者（Seller）
 * @export
 * @interface ProducersMeUpdate
 */
export interface ProducersMeUpdate {
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof ProducersMeUpdate
   */
  versionNumber: number
  /**
   * 自身での検査可否
   * @type {boolean}
   * @memberof ProducersMeUpdate
   */
  canSelfInspect: boolean
}

export function ProducersMeUpdateFromJSON(json: any): ProducersMeUpdate {
  return ProducersMeUpdateFromJSONTyped(json, false)
}

export function ProducersMeUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProducersMeUpdate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    versionNumber: json['version_number'],
    canSelfInspect: json['can_self_inspect'],
  }
}

export function ProducersMeUpdateToJSON(value?: ProducersMeUpdate | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    version_number: value.versionNumber,
    can_self_inspect: value.canSelfInspect,
  }
}
