import { Box, Flex, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { UserIcon } from '../svg/UserIcon'
import { OrganizationIcon } from '../svg/OrganizationIcon'

import { theme } from '../../../theme/theme'
import { useLoginInfoContext } from '../../PrivatePages/hooks/useFetchLoginInfo'
import { useIsMobile } from '../../hooks/useIsMobile'

export const UserInformation = () => {
  const isMobile = useIsMobile()

  const { data } = useLoginInfoContext()
  let companyName = '　'
  let accountName = '　'
  if (data.producer && data.account) {
    companyName = data.producer.companyName
    accountName = data.account.name
  }

  return (
    <Frame width={theme.sizes.brand.drawerSize} backgroundColor={theme.colors.brand.brand} isMobile={isMobile}>
      <Flex>
        <Box width="17px">
          <OrganizationIcon />
        </Box>
        <Box width="100%">
          {/* 文字列が長い場合は自動的に省略します */}
          <TextOverflow>{companyName}</TextOverflow>
        </Box>
      </Flex>
      <Flex marginTop="7px">
        <Box width="17px">
          <UserIcon />
        </Box>
        <Box width="100%">
          {/* 文字列が長い場合は自動的に省略します */}
          <TextOverflow>{accountName}</TextOverflow>
        </Box>
      </Flex>
    </Frame>
  )
}

const Frame = styled.div<{ width: string; backgroundColor: string; isMobile: boolean }>`
  width: ${(props) => props.width};
  padding: ${(props) => (props.isMobile ? '11px 32px 12px 10px' : '15px 30px 15px 15px')};
  border-radius: ${(props) => (props.isMobile ? '0' : '0 0 5px 5px')};
  background: ${(props) => props.backgroundColor};
  box-shadow: ${(props) => (props.isMobile ? '' : '0px 3px 6px #00000029')};
  ${(props) => (!props.isMobile ? 'position: absolute;' : '')}
  ${(props) => (!props.isMobile ? 'top: 0;' : '')}
  ${(props) => (!props.isMobile ? 'margin-left: 40px;' : '')}
`

const TextOverflow = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
