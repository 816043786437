import styled from '@emotion/styled'

export const MenuCloseIcon = () => (
  <Svg width="20.828" height="20.828" viewBox="0 0 20.828 20.828">
    <path className="cls-1" d="M9,9,0,18,9,9,0,0,9,9l9-9L9,9l9,9Z" transform="translate(1.414 1.414)" />
  </Svg>
)

const Svg = styled.svg`
  .cls-1 {
    fill: none;
    stroke: #35363a;
    stroke-width: 4px;
  }
`
