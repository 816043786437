import { lazy } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import { PrivateRoute } from '../auth/PrivateRoute'
import { Flex, Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { MenuContainer } from '../components/Menu/MenuContainer'
import { HeaderWithVisibilityManager } from '../components/Header/InnerHeader/VisibilityManager'
import { FooterWithVisibilityManager, useFooterDisplay } from '../components/Footer/VisibilityManager'
import { InternalContainer } from '../components/Header/InternalContainer'

import { theme } from '../../theme/theme'
import { ManageFetchOfMasterContext, mastersState } from './Concept/lib/manageFetchMasters'
import { AnnumsAndSelectedIdContext, useAnnumsAndSelectedId } from './hooks/useAnnumsAndSelectedId'
import { PendingTasksContext, usePendingTasks } from './hooks/usePendingTasks'
import { useIsMobile } from '../hooks/useIsMobile'
import { NotFound } from '../PublicPages/NotFound/NotFound'
import { TARGET_ELEMENT_ID, useScrollToTopWhenPageChanged } from './hooks/useScrollToTopWhenPageChanged'

const Home = lazy(() => import('./Home/index'))

const Offers = lazy(() => import('./Offers/index'))
const OfferRequests = lazy(() => import('./OfferRequests/index'))

const Deals = lazy(() => import('./Deals/index'))

const Producer = lazy(() => import('./Producer/index'))
const ProducerAddress = lazy(() => import('./Producer/Address/index'))
const ProducerBankAccount = lazy(() => import('./Producer/BankAccount/index'))
const Usage = lazy(() => import('./Usage/index'))
const Contact = lazy(() => import('./Contact/index'))
const Terms = lazy(() => import('./Terms/index'))

const PendingTasks = lazy(() => import('./PendingTasks/index'))
const NotificationSettings = lazy(() => import('./NotificationSettings/index'))

const TroubleShooting = lazy(() => import('./TroubleShooting/index'))

// 開発者向け
const Logout = lazy(() => import('./Logout/Logout'))

export const Private = () => {
  useScrollToTopWhenPageChanged()

  const isMobile = useIsMobile()

  const { isFooterDisplayed } = useFooterDisplay()
  const { drawerSize, innerHeaderHeight, footerHeight, outerHeaderHeight, containerMaxWidth } = theme.sizes.brand

  const annumsAndSelectedIdState = useAnnumsAndSelectedId()
  const pendingTasksState = usePendingTasks()

  return (
    <AnnumsAndSelectedIdContext.Provider value={annumsAndSelectedIdState}>
      <PendingTasksContext.Provider value={pendingTasksState}>
        <Flex justifyContent="flex-start" margin="0 auto">
          <div style={{ width: '100vh', height: '100vh' }}>
            {isMobile && <HeaderWithVisibilityManager />}
            <PrivateFrame
              isMobile={isMobile}
              drawerSize={drawerSize}
              headerHeight={
                isMobile ? innerHeaderHeight : 'calc(' + innerHeaderHeight + ' + ' + outerHeaderHeight + ')'
              }
              footerHeight={isFooterDisplayed ? footerHeight : '0'}
              maxWidth={containerMaxWidth}
            >
              <Box id={TARGET_ELEMENT_ID} className="contents" bg="brand.background">
                {!isMobile && <InternalContainer />}
                <Flex
                  maxWidth={containerMaxWidth}
                  margin="0 auto"
                  direction="row"
                  justify="center"
                  align="flex-start"
                  marginTop={0}
                >
                  {!isMobile && <MenuContainer />}
                  <Box width="100%" marginTop={0}>
                    <ManageFetchOfMasterContext.Provider value={mastersState}>
                      <Switch>
                        <Redirect path="/" to="/home" exact />
                        <PrivateRoute path="/home">
                          <Home />
                        </PrivateRoute>

                        {/* やることリスト */}
                        <PrivateRoute path="/pending_tasks">
                          <PendingTasks />
                        </PrivateRoute>

                        <PrivateRoute path="/trouble_shooting">
                          <TroubleShooting />
                        </PrivateRoute>

                        {/* ディールホーム */}
                        <PrivateRoute path="/deals">
                          <Deals />
                        </PrivateRoute>

                        {/* オファー */}
                        <PrivateRoute path="/offers">
                          <Offers />
                        </PrivateRoute>
                        <PrivateRoute path="/offer_requests">
                          <OfferRequests />
                        </PrivateRoute>

                        {/* サブページ */}
                        <PrivateRoute path="/notification_settings">
                          <NotificationSettings />
                        </PrivateRoute>
                        <PrivateRoute path="/producer" exact>
                          <Producer />
                        </PrivateRoute>
                        <PrivateRoute path="/producer/address/new">
                          <ProducerAddress />
                        </PrivateRoute>
                        <PrivateRoute path="/producer/address/edit/:address_id">
                          <ProducerAddress />
                        </PrivateRoute>
                        <PrivateRoute path="/producer/bank_account/new">
                          <ProducerBankAccount />
                        </PrivateRoute>
                        <PrivateRoute path="/producer/bank_account/edit/:bank_account_id">
                          <ProducerBankAccount />
                        </PrivateRoute>
                        <PrivateRoute path="/usage">
                          <Usage />
                        </PrivateRoute>
                        <PrivateRoute path="/contact">
                          <Contact />
                        </PrivateRoute>
                        <PrivateRoute path="/terms">
                          <Terms />
                        </PrivateRoute>

                        {/* 開発者向け */}
                        <PrivateRoute path="/logout">
                          <Logout />
                        </PrivateRoute>

                        <Route path="*">
                          <NotFound />
                        </Route>
                      </Switch>
                    </ManageFetchOfMasterContext.Provider>
                  </Box>
                </Flex>
              </Box>
            </PrivateFrame>

            <FooterWithVisibilityManager />
          </div>
        </Flex>
      </PendingTasksContext.Provider>
    </AnnumsAndSelectedIdContext.Provider>
  )
}

const PrivateFrame = styled.div<{
  isMobile: boolean
  drawerSize: string
  headerHeight: string
  footerHeight: string
  maxWidth: string
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  margin: ${(props) => (props.isMobile ? props.headerHeight + ' 0 ' + props.footerHeight : '0')};
  z-index: 1;
  .contents {
    width: 100%;
    height: 100%;
    margin-left: auto;
    overflow-x: hidden;
    overflow-y: ${(props) => (props.isMobile ? 'auto' : 'scroll')};
    -webkit-overflow-scrolling: touch;
    transform: translateZ(0);
  }
`
