/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 希望オファー銘柄・価格
 * @export
 * @interface OfferRequest
 */
export interface OfferRequest {
  /**
   * 銘柄ID
   * @type {number}
   * @memberof OfferRequest
   */
  brandId: number
  /**
   * 等級ID
   * @type {number}
   * @memberof OfferRequest
   */
  gradeId: number
  /**
   * 地域ID
   * @type {number}
   * @memberof OfferRequest
   */
  regionId: number | null
  /**
   * 単価
   * @type {number}
   * @memberof OfferRequest
   */
  unitPrice: number
  /**
   * 特別条件IDリスト（空可、2つまで）
   * @type {Array<number>}
   * @memberof OfferRequest
   */
  specialConditionIds: Array<number>
}

export function OfferRequestFromJSON(json: any): OfferRequest {
  return OfferRequestFromJSONTyped(json, false)
}

export function OfferRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brandId: json['brand_id'],
    gradeId: json['grade_id'],
    regionId: json['region_id'],
    unitPrice: json['unit_price'],
    specialConditionIds: json['special_condition_ids'],
  }
}

export function OfferRequestToJSON(value?: OfferRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_id: value.brandId,
    grade_id: value.gradeId,
    region_id: value.regionId,
    unit_price: value.unitPrice,
    special_condition_ids: value.specialConditionIds,
  }
}
