import { Configuration } from '../libs/api_client'
import { parse as parseCookie } from 'cookie'

const CSRF_TOKEN_KEY = '__Host-an-ki-s-csrf-token'

export function getApiConfig() {
  if (process.env.REACT_APP_DEPLOY_TARGET || process.env.REACT_APP_USE_REAL_API) {
    return getRealConfig()
  } else {
    return createMockConfig()
  }
}

function getRealConfig() {
  const myCookie = parseCookie(document.cookie)
  const csrfToken = myCookie[CSRF_TOKEN_KEY]

  return new Configuration({
    basePath: '/seller-api/v1',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-An-Ki-Csrf-Token': csrfToken,
    },
  })
}

function createMockConfig() {
  let headers = {}
  if (mockLoginSessionManager.isLoggedIn()) {
    headers = {
      'X-An-Ki-Csrf-Token': 'token',
    }
  }

  return new Configuration({
    basePath: 'http://localhost:3003',
    headers,
  })
}

class MockLoginSessionManager {
  isLoggedIn() {
    return window.localStorage.getItem('auth') === 'AUTHORIZED'
  }

  loggedIn() {
    window.localStorage.setItem('auth', 'AUTHORIZED')
  }

  loggedOut() {
    window.localStorage.removeItem('auth')
  }
}

export const mockLoginSessionManager = new MockLoginSessionManager()
