/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * ディール（Update、Seller）
 * @export
 * @interface DealsUpdate
 */
export interface DealsUpdate {
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof DealsUpdate
   */
  versionNumber: number
  /**
   * 生産者承諾状況（"agreement": 承諾済、"disagreement": 不承諾のみ対応）
   * @type {string}
   * @memberof DealsUpdate
   */
  producerAgreementStatus: DealsUpdateProducerAgreementStatusEnum
}

/**
 * @export
 * @enum {string}
 */
export enum DealsUpdateProducerAgreementStatusEnum {
  Agreement = 'agreement',
  Disagreement = 'disagreement',
}

export function DealsUpdateFromJSON(json: any): DealsUpdate {
  return DealsUpdateFromJSONTyped(json, false)
}

export function DealsUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealsUpdate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    versionNumber: json['version_number'],
    producerAgreementStatus: json['producer_agreement_status'],
  }
}

export function DealsUpdateToJSON(value?: DealsUpdate | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    version_number: value.versionNumber,
    producer_agreement_status: value.producerAgreementStatus,
  }
}
