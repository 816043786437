import {
  DeleteOfferRequestsIdRequest,
  OfferRequestsApi,
  PostOfferRequestsRequest,
  PutOfferRequestsIdRequest,
} from '../libs/api_client/apis/OfferRequestsApi'
import { OfferRequestCreate, EntryOfferRequestCreate } from '../domain/offerRequest/offerRequest'
import { getApiConfig } from '../config/apiConfig'
import { OfferRequestReadSeller, ResponseSuccessBody } from '../libs/api_client/models'

/**
 * POST /offers_request
 * @param offerRequest
 */
export async function postOfferRequests(
  offerRequest: OfferRequestCreate | EntryOfferRequestCreate
): Promise<ResponseSuccessBody> {
  const param: PostOfferRequestsRequest = {
    offerRequest: {
      ...offerRequest,
      // ...({} as any), // 意図的に400エラーを起こすためのコード
    },
  }

  const offerRequestApi = new OfferRequestsApi(getApiConfig())
  const offerRequestResponse: ResponseSuccessBody = await offerRequestApi.postOfferRequests(param)

  // FIXME: OfferRequest型で返したほうが良い気がするけど今必要ないので保留
  return offerRequestResponse
}

export async function putOfferRequests(offerRequest: OfferRequestCreate): Promise<OfferRequestReadSeller> {
  const param: PutOfferRequestsIdRequest = {
    id: offerRequest.id,
    offerRequestsUpdate: {
      ...offerRequest,
      // ...({} as any), // 意図的に400エラーを起こすためのコード
    },
  }

  const offerRequestApi = new OfferRequestsApi(getApiConfig())
  const offerRequestResponse: OfferRequestReadSeller = await offerRequestApi.putOfferRequestsId(param)

  // FIXME: OfferRequest型で返したほうが良い気がするけど今必要ないので保留
  return offerRequestResponse
}

export async function deleteOfferRequests(id: number, versionNumber: number): Promise<OfferRequestReadSeller> {
  const param: DeleteOfferRequestsIdRequest = {
    id,
    versionNumber,
  }

  const offerRequestApi = new OfferRequestsApi(getApiConfig())
  const offerRequestResponse: OfferRequestReadSeller = await offerRequestApi.deleteOfferRequestsId(param)

  // FIXME: OfferRequest型で返したほうが良い気がするけど今必要ないので保留
  return offerRequestResponse
}
