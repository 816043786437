import styled from '@emotion/styled'

export const QuestionIcon = ({ width, height }: { width?: number | string; height?: number | string }) => {
  const defaultSize = 19.375
  width = typeof width === 'number' || typeof width === 'string' ? width : defaultSize
  height = typeof height === 'number' || typeof height === 'string' ? height : defaultSize
  return (
    <Svg width={width} height={height} viewBox="0 0 19.375 19.375">
      <path
        className="cls-1"
        d="M11.5.813A9.687,9.687,0,1,0,21.187,10.5,9.689,9.689,0,0,0,11.5.813Zm0,17.5A7.812,7.812,0,1,1,19.312,10.5,7.808,7.808,0,0,1,11.5,18.313Zm4.189-9.969c0-1.972-2.046-3.469-3.963-3.469A4.391,4.391,0,0,0,7.8,6.992a.47.47,0,0,0,.1.639l1.087.824a.469.469,0,0,0,.651-.083C10.227,7.63,10.645,7.2,11.551,7.2c.695,0,1.554.447,1.554,1.121,0,.509-.42.771-1.106,1.155-.8.448-1.859,1.007-1.859,2.4v.338a.469.469,0,0,0,.469.469h1.783a.469.469,0,0,0,.469-.469v-.248C12.86,11,15.689,10.963,15.689,8.344Zm-2.549,6.531A1.641,1.641,0,1,0,11.5,16.516,1.642,1.642,0,0,0,13.141,14.875Z"
        transform="translate(-1.812 -0.813)"
      />
    </Svg>
  )
}

const Svg = styled.svg`
  .cls-1 {
    fill: #35363a;
  }
`
