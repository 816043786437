/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
  SpotContractAmendReadSeller,
  SpotContractAmendReadSellerFromJSON,
  SpotContractAmendReadSellerToJSON,
  SpotContractAmendsInspectionResultsUpdate,
  SpotContractAmendsInspectionResultsUpdateFromJSON,
  SpotContractAmendsInspectionResultsUpdateToJSON,
  SpotContractIndividualDeliveryReadSeller,
  SpotContractIndividualDeliveryReadSellerFromJSON,
  SpotContractIndividualDeliveryReadSellerToJSON,
  SpotContractPaymentReadSeller,
  SpotContractPaymentReadSellerFromJSON,
  SpotContractPaymentReadSellerToJSON,
} from '../models'

export interface GetSpotContractAmendsRequest {
  filters?: object
}

export interface GetSpotContractAmendsIdRequest {
  id: number
}

export interface GetSpotContractAmendsIdIndividualDeliveriesRequest {
  id: number
}

export interface GetSpotContractAmendsIdPaymentsRequest {
  id: number
}

export interface PutSpotContractAmendsIdInspectionResultsRequest {
  id: number
  spotContractAmendsInspectionResultsUpdate?: SpotContractAmendsInspectionResultsUpdate
}

/**
 *
 */
export class SpotContractAmendsApi extends runtime.BaseAPI {
  /**
   * [現物契約] 所属の生産者のアメンド一覧を取得する
   */
  async getSpotContractAmendsRaw(
    requestParameters: GetSpotContractAmendsRequest
  ): Promise<runtime.ApiResponse<Array<SpotContractAmendReadSeller>>> {
    const queryParameters: any = {}

    if (requestParameters.filters !== undefined) {
      queryParameters['_filters'] = requestParameters.filters
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/spot_contract/amends`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpotContractAmendReadSellerFromJSON))
  }

  /**
   * [現物契約] 所属の生産者のアメンド一覧を取得する
   */
  async getSpotContractAmends(
    requestParameters: GetSpotContractAmendsRequest
  ): Promise<Array<SpotContractAmendReadSeller>> {
    const response = await this.getSpotContractAmendsRaw(requestParameters)
    return await response.value()
  }

  /**
   * [現物契約] 指定IDに該当するアメンドの情報を取得する
   */
  async getSpotContractAmendsIdRaw(
    requestParameters: GetSpotContractAmendsIdRequest
  ): Promise<runtime.ApiResponse<SpotContractAmendReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getSpotContractAmendsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/spot_contract/amends/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SpotContractAmendReadSellerFromJSON(jsonValue))
  }

  /**
   * [現物契約] 指定IDに該当するアメンドの情報を取得する
   */
  async getSpotContractAmendsId(
    requestParameters: GetSpotContractAmendsIdRequest
  ): Promise<SpotContractAmendReadSeller> {
    const response = await this.getSpotContractAmendsIdRaw(requestParameters)
    return await response.value()
  }

  /**
   * [現物契約] 指定IDに該当するアメンドの個別運送情報を取得する
   */
  async getSpotContractAmendsIdIndividualDeliveriesRaw(
    requestParameters: GetSpotContractAmendsIdIndividualDeliveriesRequest
  ): Promise<runtime.ApiResponse<Array<SpotContractIndividualDeliveryReadSeller>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getSpotContractAmendsIdIndividualDeliveries.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/spot_contract/amends/{id}/individual_deliveries`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(SpotContractIndividualDeliveryReadSellerFromJSON)
    )
  }

  /**
   * [現物契約] 指定IDに該当するアメンドの個別運送情報を取得する
   */
  async getSpotContractAmendsIdIndividualDeliveries(
    requestParameters: GetSpotContractAmendsIdIndividualDeliveriesRequest
  ): Promise<Array<SpotContractIndividualDeliveryReadSeller>> {
    const response = await this.getSpotContractAmendsIdIndividualDeliveriesRaw(requestParameters)
    return await response.value()
  }

  /**
   * [現物契約] 指定IDに該当するアメンドの支払い情報を取得する
   */
  async getSpotContractAmendsIdPaymentsRaw(
    requestParameters: GetSpotContractAmendsIdPaymentsRequest
  ): Promise<runtime.ApiResponse<Array<SpotContractPaymentReadSeller>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getSpotContractAmendsIdPayments.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/spot_contract/amends/{id}/payments`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpotContractPaymentReadSellerFromJSON))
  }

  /**
   * [現物契約] 指定IDに該当するアメンドの支払い情報を取得する
   */
  async getSpotContractAmendsIdPayments(
    requestParameters: GetSpotContractAmendsIdPaymentsRequest
  ): Promise<Array<SpotContractPaymentReadSeller>> {
    const response = await this.getSpotContractAmendsIdPaymentsRaw(requestParameters)
    return await response.value()
  }

  /**
   * [現物契約] 指定IDに該当するアメンドの検査結果を編集する
   */
  async putSpotContractAmendsIdInspectionResultsRaw(
    requestParameters: PutSpotContractAmendsIdInspectionResultsRequest
  ): Promise<runtime.ApiResponse<SpotContractAmendReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putSpotContractAmendsIdInspectionResults.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/spot_contract/amends/{id}/inspection_results`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: SpotContractAmendsInspectionResultsUpdateToJSON(
        requestParameters.spotContractAmendsInspectionResultsUpdate
      ),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SpotContractAmendReadSellerFromJSON(jsonValue))
  }

  /**
   * [現物契約] 指定IDに該当するアメンドの検査結果を編集する
   */
  async putSpotContractAmendsIdInspectionResults(
    requestParameters: PutSpotContractAmendsIdInspectionResultsRequest
  ): Promise<SpotContractAmendReadSeller> {
    const response = await this.putSpotContractAmendsIdInspectionResultsRaw(requestParameters)
    return await response.value()
  }
}
