/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  Alert,
  AlertFromJSON,
  AlertToJSON,
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
} from '../models'

/**
 *
 */
export class AlertsApi extends runtime.BaseAPI {
  /**
   * 新着情報一覧を取得する
   */
  async getAlertsRaw(): Promise<runtime.ApiResponse<Array<Alert>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/alerts`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AlertFromJSON))
  }

  /**
   * 新着情報一覧を取得する
   */
  async getAlerts(): Promise<Array<Alert>> {
    const response = await this.getAlertsRaw()
    return await response.value()
  }
}
