import { cloneElement } from 'react'
import { Redirect, Route, RouteProps, RouteComponentProps } from 'react-router-dom'
import { useAuthStatus } from './useAuthStatus'
import { LoadingEntire } from '../components/Loading/Loading'

export function LoginRoute({ children, ...rest }: RouteProps & { children: any }) {
  const authStatus = useAuthStatus()

  const render = (props: RouteComponentProps) => {
    if (authStatus === 'CHECKING') {
      return <LoadingEntire />
    } else if (authStatus === 'AUTHORIZED') {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      )
    } else if (authStatus === 'UNAUTHORIZED' || authStatus === 'EXITED') {
      return cloneElement(children, props)
    } else if (authStatus === 'MAINTENANCE') {
      return <Redirect to={{ pathname: '/maintenance' }} />
    } else {
      return <LoadingEntire />
    }
  }

  return <Route {...rest} render={render} />
}
