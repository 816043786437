import styled from '@emotion/styled'

export const UserIcon = () => (
  <Svg width="17" height="17" viewBox="0 0 17 17">
    <g>
      <path
        className="cls-3"
        d="M182.727,28.934a2.517,2.517,0,1,0-2.527-2.513,2.517,2.517,0,0,0,2.527,2.513"
        transform="translate(-174.217 -23.107)"
      />
      <path
        className="cls-3"
        d="M118.883,211.3h-4.429a2.833,2.833,0,0,0-2.553,2.547v8.45a1.106,1.106,0,0,0,1.109,1.1h.774l.418,7.212a1.175,1.175,0,0,0,1.179,1.175h2.577a1.175,1.175,0,0,0,1.179-1.175l.422-7.208h.774a1.106,1.106,0,0,0,1.109-1.1v-8.457a2.836,2.836,0,0,0-2.557-2.547"
        transform="translate(-108.185 -204.284)"
      />
    </g>
  </Svg>
)

const Svg = styled.svg`
  .cls-3 {
    fill: #35363a;
  }
`
