import styled from '@emotion/styled'

export const OfferRequestIcon = ({ color = '#35363a' }: { color?: string }) => (
  <Svg width="28" height="30" viewBox="0 0 28 30" color={color}>
    <path
      className="cls-1"
      d="m26.11,10.24l-.53-.53c-.09-.09-.25-.09-.34,0l-3.83,3.84c-.09.09-.09.25,0,.34l.53.53c.09.09.25.09.34,0l3.84-3.83c.09-.09.09-.24,0-.34h0Z"
    />
    <path
      className="cls-1"
      d="m14.97,21.8c.26-.26.26-.68,0-.94-.26-.26-.68-.26-.94,0-.26.26-.26.68,0,.94.25.26.68.26.94,0Z"
    />
    <polygon className="cls-1" points="14.33 2.2 14.33 5.98 18.44 5.98 14.33 2.2" />
    <path
      className="cls-2"
      d="m11.43,25.27c.12-.12.18-.28.18-.44l.44-.44,3.57-.77c.37-.06.67-.31.81-.66,0,0,.68-1.51,1.09-2.2l-1.14-1.14-.18-.18-1.13-1.14c-.68.41-2.2,1.09-2.2,1.09-.34.14-.59.45-.66.81l-.77,3.57-.44.44c-.16,0-.32.05-.44.18-.24.24-.24.63,0,.86.23.24.62.25.86.01h0Zm2.6-4.41c.26-.26.68-.26.94,0s.26.68,0,.94-.68.26-.94,0c-.27-.26-.27-.68,0-.94Z"
    />
    <path
      className="cls-2"
      d="m19.82,18.44l-2.42-2.43-1.12,1.12c-.27.27-.63.19-.63.19l-.33.33,1.15,1.15.27.27,1.44,1.44.33-.33s-.08-.35.19-.63c.27-.27,1.12-1.12,1.12-1.12Z"
    />
    <path
      className="cls-2"
      d="m23.53,13.56h0c.3,0,.57.15.73.4l.28.43c1.03-.87,1.97-1.68,2.44-2.16,1.27-1.28,1.37-2.5.25-3.62-1.12-1.12-2.34-1.02-3.61.26-1.28,1.28-4.93,5.83-5.8,6.7l2.39,2.39,2.59-3.99c.16-.27.44-.41.73-.41h0Zm-1.59.86l-.53-.53c-.09-.09-.09-.25,0-.34l3.83-3.84c.09-.09.25-.09.34,0l.53.53c.1.11.1.25,0,.34l-3.84,3.83c-.09.09-.24.09-.34,0Z"
    />
    <rect className="cls-2" x="4.5" y="10.11" width="10.97" height="1.54" />
    <rect className="cls-2" x="4.5" y="14.51" width="8.31" height="1.54" />
    <rect className="cls-2" x="4.5" y="19.24" width="6.02" height="1.54" />
    <path
      className="cls-2"
      d="m18.62,21.2s-.04-.08-.05-.12l-.57.75v5.74s-15.83,0-15.83,0V2.17s10.42,0,10.42,0v4.37c0,.32.13.63.34.83.21.21.51.34.83.34h4.25s0,6.24,0,6.24c.24-.28,1.2-1.51,1.46-1.8.24-.28.48-.57.71-.85v-5.62S14.51,0,14.51,0H.6C.27,0,0,.27,0,.6v28.54c0,.33.27.6.6.6h18.98c.33,0,.6-.27.6-.6v-7.48h-.79c-.32,0-.62-.18-.77-.45h0ZM14.33,2.2l4.12,3.78h-4.12s0-3.78,0-3.78h0Z"
    />
    <polygon
      className="cls-2"
      points="19.4 20.79 21.65 20.79 21.65 29.74 25.4 29.74 25.4 20.79 27.66 20.79 23.53 14.43 19.4 20.79"
    />
  </Svg>
)

const Svg = styled.svg`
  .cls-1 {
    fill: none;
  }

  .cls-2 {
    fill: ${(props) => props.color};
  }
`
