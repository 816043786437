import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useQueryParams } from '../hooks/useQueryParams'

/**
 * Entryページで2ページ目を経由せず3、4ページ目を開いたり、ブラウザバック時にホームにリダイレクトする仕組み
 */
type EntryStepCycle = 'NON_ENTRY' | 'ENTRY' | 'STEP2' | 'STEP3' | 'STEP4'
export const useEntryHandler = () => {
  const [step, setStep] = useState<EntryStepCycle>('NON_ENTRY')
  const location = useLocation()
  const history = useHistory()
  const queryParams = useQueryParams<'jwt'>()

  useEffect(() => {
    if (location.pathname === '/entry' && queryParams.jwt) {
      setStep('ENTRY')
    }
    if (location.pathname === '/entry/2') {
      step === 'ENTRY' && setStep('STEP2')
    }
    if (location.pathname === '/entry/3') {
      step !== 'STEP2' && history.replace('/home')
      step === 'STEP2' && setStep('STEP3')
    }

    if (location.pathname === '/entry/4') {
      step !== 'STEP3' && history.replace('/home')
      step === 'STEP3' && setStep('STEP4')
    }
  }, [location.pathname, queryParams.jwt])
}

// hooksを動かすためのコンポーネントのため何も返さない
export const EntryHandler = () => {
  useEntryHandler()
  return null
}
