import { Button, ButtonProps as ChakraButtonProps } from '@chakra-ui/button'
import { ArrowRight } from '../svg/ArrowRight'
import styled from '@emotion/styled'

import { theme } from '../../../theme/theme'

const basicButtonStyle: ChakraButtonProps = {
  width: '150px',
  height: '50px',
  _hover: { bg: { hoverBackgroundColor: 'inherit' } },
  _active: { bg: { hoverBackgroundColor: 'inherit' } },
  fontSize: '20px',
  children: null,
}

type ButtonProps = ChakraButtonProps & {
  onClick: (ev: React.MouseEvent<HTMLButtonElement>) => void
  children: React.ReactNode
  needLeftSpace?: boolean
  disabled?: boolean
}

export function ButtonSubmit(props: ButtonProps) {
  const marginLeft = props.needLeftSpace ? '20px' : ''
  const style = {
    backgroundColor: 'brand.text.alert',
    color: 'brand.button.color',
    marginLeft,
  }

  const { needLeftSpace, ...rest } = props
  const btnProps = {
    ...basicButtonStyle,
    ...style,
    ...rest,
  }

  return <Button {...btnProps}>{props.children}</Button>
}

type ConfirmButtonProps = {
  onClick: (ev: React.MouseEvent<HTMLButtonElement>) => void
  children: React.ReactNode
  width?: string
  height?: string
  margin?: string
  padding?: string
  disabled?: boolean
}

export function ButtonConfirm(props: ConfirmButtonProps) {
  const style = {
    width: props.width ? props.width : '150px',
    height: props.height ? props.height : '50px',
    margin: props.margin ? props.margin : '0',
    padding: props.padding ? props.padding : '0 1rem',
    backgroundColor: 'brand.text.alert',
    color: 'brand.button.color',
  }

  const btnProps = {
    ...basicButtonStyle,
    ...style,
    ...props,
  }

  return <Button {...btnProps}>{props.children}</Button>
}

export function ButtonMoveToNext(props: ButtonProps & { positiveNegative?: 'positive' | 'negative' }) {
  const positiveColor = 'brand.text.alert'
  const negativeColor = 'brand.button.disagreement'
  const color = props.positiveNegative === 'negative' ? negativeColor : positiveColor
  const marginLeft = props.needLeftSpace ? '20px' : ''

  const style = {
    backgroundColor: 'brand.button.color',
    borderColor: color,
    borderWidth: '1px',
    color,
    marginLeft,
  }

  const { needLeftSpace, positiveNegative, ...rest } = props
  const btnProps = {
    ...basicButtonStyle,
    ...style,
    ...rest,
  }

  return <Button {...btnProps}>{props.children}</Button>
}

export function ButtonBack(props: ButtonProps) {
  const marginLeft = props.needLeftSpace ? '20px' : ''
  const style = {
    backgroundColor: 'brand.button.back',
    borderColor: 'brand.button.backBorder',
    borderWidth: '1px',
    color: 'brand.text.main',
    marginLeft,
  }

  const { needLeftSpace, ...rest } = props
  const btnProps = {
    ...basicButtonStyle,
    ...style,
    ...rest,
  }

  return <Button {...btnProps}>{props.children}</Button>
}

export const ButtonNext = ButtonBack

export const ButtonSelect = (props: ButtonProps) => {
  const marginLeft = props.needLeftSpace ? 'auto' : ''
  const style = {
    width: '90px',
    height: '24px',
    backgroundColor: 'brand.button.selectBackground',
    borderColor: 'brand.button.selectBorder',
    borderWidth: '1px',
    color: 'brand.text.main',
    fontSize: '14px',
    marginLeft,
  }

  const { needLeftSpace, ...rest } = props
  const buttonProps = {
    ...style,
    ...rest,
  }

  return <Button {...buttonProps}>{props.children}</Button>
}

export const ButtonEdit = (props: ButtonProps & { roundedBottomLeft?: string; roundedBottomRight?: string }) => {
  const borderRightWidth = props.roundedBottomLeft ? '1px' : ''
  const style = {
    width: '100%',
    height: '46px',
    padding: '0',
    borderColor: 'brand.button.editBorder',
    borderRadius: '0',
    borderTopWidth: '1px',
    borderRightWidth,
    fontSize: '16px',
    backgroundColor: 'brand.button.editBackground',
    roundedBottomLeft: props.roundedBottomLeft,
    roundedBottomRight: props.roundedBottomRight,
  }

  const { needLeftSpace, ...rest } = props
  const buttonProps = {
    ...style,
    ...rest,
  }

  return (
    <ButtonEditContainer>
      <Button {...buttonProps}>
        <RightArrowButtonLabel>{props.children}</RightArrowButtonLabel>
        <RightArrowButtonArrow>
          <ArrowRight />
        </RightArrowButtonArrow>
      </Button>
    </ButtonEditContainer>
  )
}

// disabledのボタンのborder-colorを通常と同じにするためのコンポーネント
// opacity: 0.4はchakra-uiのdisabledがそうなっているので
const ButtonEditContainer = styled.div`
  width: 100%;
  button:disabled {
    border-color: ${theme.colors.brand.button.editBorder};
    opacity: 1;
    & > div {
      opacity: 0.4;
    }
  }
`

const RightArrowButtonLabel = styled.div`
  position: relative;
  width: 100%;
`

const RightArrowButtonArrow = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    margin-left: auto;
    margin-right: 10px;
  }
`

export const DeleteButton = (props: ButtonProps) => {
  const style = {
    width: '320px',
    height: '40px',
    padding: '0',
    borderColor: 'brand.button.deleteBorder',
    borderRadius: '5px',
    borderWidth: '1px',
    fontSize: '16px',
    backgroundColor: 'brand.button.deleteBackground',
  }

  const { needLeftSpace, ...rest } = props
  const buttonProps = {
    ...style,
    ...rest,
  }

  return <Button {...buttonProps}>{props.children}</Button>
}

export const ButtonSubmitOnModal = styled(ButtonSubmit)`
  font-size: 16px;
  height: 40px;
  width: 100px;
`

export const ButtonCancelOnModal = styled(ButtonBack)`
  font-size: 16px;
  height: 40px;
  width: 100px;
`
