/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  LoginInfo,
  LoginInfoFromJSON,
  LoginInfoToJSON,
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
  ResponseSuccessBody,
  ResponseSuccessBodyFromJSON,
  ResponseSuccessBodyToJSON,
} from '../models'

export interface PostTokensRequest {
  jwt: number
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
}

/**
 *
 */
export class AuthApi extends runtime.BaseAPI {
  /**
   * 明示的にログアウトを行う  現在のセッションを解除し、関連のクッキーを削除する  基本的にエラーを返さない（5xxは除く）
   */
  async deleteTokensRaw(): Promise<runtime.ApiResponse<ResponseSuccessBody>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request({
      path: `/tokens`,
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessBodyFromJSON(jsonValue))
  }

  /**
   * 明示的にログアウトを行う  現在のセッションを解除し、関連のクッキーを削除する  基本的にエラーを返さない（5xxは除く）
   */
  async deleteTokens(): Promise<ResponseSuccessBody> {
    const response = await this.deleteTokensRaw()
    return await response.value()
  }

  /**
   * ログイン中の生産者アカウント情報を取得する
   */
  async getLoginInfoRaw(): Promise<runtime.ApiResponse<LoginInfo>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/login_info`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => LoginInfoFromJSON(jsonValue))
  }

  /**
   * ログイン中の生産者アカウント情報を取得する
   */
  async getLoginInfo(): Promise<LoginInfo> {
    const response = await this.getLoginInfoRaw()
    return await response.value()
  }

  /**
   * JWTとそれに該当する生産者を確認して、適切にリダイレクトを行う
   */
  async postTokensRaw(requestParameters: PostTokensRequest): Promise<runtime.ApiResponse<ResponseSuccessBody>> {
    if (requestParameters.jwt === null || requestParameters.jwt === undefined) {
      throw new runtime.RequiredError(
        'jwt',
        'Required parameter requestParameters.jwt was null or undefined when calling postTokens.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.jwt !== undefined) {
      queryParameters['jwt'] = requestParameters.jwt
    }

    if (requestParameters.utmSource !== undefined) {
      queryParameters['utm_source'] = requestParameters.utmSource
    }

    if (requestParameters.utmMedium !== undefined) {
      queryParameters['utm_medium'] = requestParameters.utmMedium
    }

    if (requestParameters.utmCampaign !== undefined) {
      queryParameters['utm_campaign'] = requestParameters.utmCampaign
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request({
      path: `/tokens`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessBodyFromJSON(jsonValue))
  }

  /**
   * JWTとそれに該当する生産者を確認して、適切にリダイレクトを行う
   */
  async postTokens(requestParameters: PostTokensRequest): Promise<ResponseSuccessBody> {
    const response = await this.postTokensRaw(requestParameters)
    return await response.value()
  }
}
