import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

export const TARGET_ELEMENT_ID = '7B549F3E-521E-4538-B071-B4A73843EB2C'

export function useScrollToTopWhenPageChanged(preventScroll?: boolean) {
  const location = useLocation()

  useEffect(() => {
    const el = document.getElementById(TARGET_ELEMENT_ID)

    if (el && !preventScroll) {
      el.scrollTop = 0
    }
  }, [location.pathname])
}
