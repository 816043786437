/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  AmendReadSeller,
  AmendReadSellerFromJSON,
  AmendReadSellerToJSON,
  AmendsExpectedHarvestTimeframeUpdate,
  AmendsExpectedHarvestTimeframeUpdateFromJSON,
  AmendsExpectedHarvestTimeframeUpdateToJSON,
  AmendsHandoverUpdate,
  AmendsHandoverUpdateFromJSON,
  AmendsHandoverUpdateToJSON,
  AmendsInspectionResultsUpdate,
  AmendsInspectionResultsUpdateFromJSON,
  AmendsInspectionResultsUpdateToJSON,
  IndividualDeliveryReadSeller,
  IndividualDeliveryReadSellerFromJSON,
  IndividualDeliveryReadSellerToJSON,
  PaymentReadSeller,
  PaymentReadSellerFromJSON,
  PaymentReadSellerToJSON,
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
} from '../models'

export interface GetAmendsRequest {
  filters?: object
}

export interface GetAmendsIdRequest {
  id: number
}

export interface GetAmendsIdIndividualDeliveriesRequest {
  id: number
}

export interface GetAmendsIdPaymentsRequest {
  id: number
}

export interface PutAmendsIdExpectedHarvestTimeframeRequest {
  id: number
  amendsExpectedHarvestTimeframeUpdate?: AmendsExpectedHarvestTimeframeUpdate
}

export interface PutAmendsIdHandoverRequest {
  id: number
  amendsHandoverUpdate?: AmendsHandoverUpdate
}

export interface PutAmendsIdInspectionResultsRequest {
  id: number
  amendsInspectionResultsUpdate?: AmendsInspectionResultsUpdate
}

/**
 *
 */
export class AmendsApi extends runtime.BaseAPI {
  /**
   * 所属の生産者のアメンド一覧を取得する
   */
  async getAmendsRaw(requestParameters: GetAmendsRequest): Promise<runtime.ApiResponse<Array<AmendReadSeller>>> {
    const queryParameters: any = {}

    if (requestParameters.filters !== undefined) {
      queryParameters['_filters'] = requestParameters.filters
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/amends`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AmendReadSellerFromJSON))
  }

  /**
   * 所属の生産者のアメンド一覧を取得する
   */
  async getAmends(requestParameters: GetAmendsRequest): Promise<Array<AmendReadSeller>> {
    const response = await this.getAmendsRaw(requestParameters)
    return await response.value()
  }

  /**
   * 指定IDに該当するアメンドの情報を取得する
   */
  async getAmendsIdRaw(requestParameters: GetAmendsIdRequest): Promise<runtime.ApiResponse<AmendReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getAmendsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/amends/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => AmendReadSellerFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当するアメンドの情報を取得する
   */
  async getAmendsId(requestParameters: GetAmendsIdRequest): Promise<AmendReadSeller> {
    const response = await this.getAmendsIdRaw(requestParameters)
    return await response.value()
  }

  /**
   * 指定IDに該当するアメンドの個別運送情報を取得する
   */
  async getAmendsIdIndividualDeliveriesRaw(
    requestParameters: GetAmendsIdIndividualDeliveriesRequest
  ): Promise<runtime.ApiResponse<Array<IndividualDeliveryReadSeller>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getAmendsIdIndividualDeliveries.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/amends/{id}/individual_deliveries`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IndividualDeliveryReadSellerFromJSON))
  }

  /**
   * 指定IDに該当するアメンドの個別運送情報を取得する
   */
  async getAmendsIdIndividualDeliveries(
    requestParameters: GetAmendsIdIndividualDeliveriesRequest
  ): Promise<Array<IndividualDeliveryReadSeller>> {
    const response = await this.getAmendsIdIndividualDeliveriesRaw(requestParameters)
    return await response.value()
  }

  /**
   * 指定IDに該当するアメンドの支払い情報を取得する
   */
  async getAmendsIdPaymentsRaw(
    requestParameters: GetAmendsIdPaymentsRequest
  ): Promise<runtime.ApiResponse<Array<PaymentReadSeller>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getAmendsIdPayments.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/amends/{id}/payments`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PaymentReadSellerFromJSON))
  }

  /**
   * 指定IDに該当するアメンドの支払い情報を取得する
   */
  async getAmendsIdPayments(requestParameters: GetAmendsIdPaymentsRequest): Promise<Array<PaymentReadSeller>> {
    const response = await this.getAmendsIdPaymentsRaw(requestParameters)
    return await response.value()
  }

  /**
   * 指定IDに該当するアメンドの収穫予定時期を登録・編集する
   */
  async putAmendsIdExpectedHarvestTimeframeRaw(
    requestParameters: PutAmendsIdExpectedHarvestTimeframeRequest
  ): Promise<runtime.ApiResponse<AmendReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putAmendsIdExpectedHarvestTimeframe.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/amends/{id}/expected_harvest_timeframe`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: AmendsExpectedHarvestTimeframeUpdateToJSON(requestParameters.amendsExpectedHarvestTimeframeUpdate),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => AmendReadSellerFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当するアメンドの収穫予定時期を登録・編集する
   */
  async putAmendsIdExpectedHarvestTimeframe(
    requestParameters: PutAmendsIdExpectedHarvestTimeframeRequest
  ): Promise<AmendReadSeller> {
    const response = await this.putAmendsIdExpectedHarvestTimeframeRaw(requestParameters)
    return await response.value()
  }

  /**
   * 指定IDに該当するアメンドの引渡情報を登録・編集する
   */
  async putAmendsIdHandoverRaw(
    requestParameters: PutAmendsIdHandoverRequest
  ): Promise<runtime.ApiResponse<AmendReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putAmendsIdHandover.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/amends/{id}/handover`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: AmendsHandoverUpdateToJSON(requestParameters.amendsHandoverUpdate),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => AmendReadSellerFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当するアメンドの引渡情報を登録・編集する
   */
  async putAmendsIdHandover(requestParameters: PutAmendsIdHandoverRequest): Promise<AmendReadSeller> {
    const response = await this.putAmendsIdHandoverRaw(requestParameters)
    return await response.value()
  }

  /**
   * 指定IDに該当するアメンドの検査結果を登録・編集する
   */
  async putAmendsIdInspectionResultsRaw(
    requestParameters: PutAmendsIdInspectionResultsRequest
  ): Promise<runtime.ApiResponse<AmendReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putAmendsIdInspectionResults.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/amends/{id}/inspection_results`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: AmendsInspectionResultsUpdateToJSON(requestParameters.amendsInspectionResultsUpdate),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => AmendReadSellerFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当するアメンドの検査結果を登録・編集する
   */
  async putAmendsIdInspectionResults(requestParameters: PutAmendsIdInspectionResultsRequest): Promise<AmendReadSeller> {
    const response = await this.putAmendsIdInspectionResultsRaw(requestParameters)
    return await response.value()
  }
}
