import styled from '@emotion/styled'

export const OrganizationIcon = () => (
  <Svg width="17" height="17" viewBox="0 0 17 17">
    <g className="cls-2">
      <path
        className="cls-3"
        d="M209.9,3.2a1.6,1.6,0,1,0-1.6-1.6,1.6,1.6,0,0,0,1.6,1.6"
        transform="translate(-201.384 0)"
      />
      <path
        className="cls-3"
        d="M169.329,119.3H166.52a1.8,1.8,0,0,0-1.62,1.62V126.3a.7.7,0,0,0,.7.7h.491l.266,4.589a.747.747,0,0,0,.75.747h1.634a.747.747,0,0,0,.747-.747l.266-4.589h.491a.7.7,0,0,0,.7-.7V120.92a1.8,1.8,0,0,0-1.62-1.62"
        transform="translate(-159.425 -115.339)"
      />
      <path
        className="cls-3"
        d="M63.4,67.7A1.4,1.4,0,1,0,62,66.3a1.4,1.4,0,0,0,1.4,1.4"
        transform="translate(-59.941 -62.745)"
      />
      <path
        className="cls-3"
        d="M368,67.7a1.4,1.4,0,1,0-1.4-1.4,1.4,1.4,0,0,0,1.4,1.4"
        transform="translate(-354.428 -62.745)"
      />
      <path
        className="cls-3"
        d="M27.955,169H25.614a1.572,1.572,0,0,0-1.414,1.414v4.7a.614.614,0,0,0,.614.614h.425l.232,4.008a.654.654,0,0,0,.654.651H27.55a.654.654,0,0,0,.654-.654l.232-4.008V175.5a1.61,1.61,0,0,1-.485-1.152Z"
        transform="translate(-23.396 -163.389)"
      />
      <path
        className="cls-3"
        d="M362.926,169h-2.341v5.349a1.61,1.61,0,0,1-.485,1.152v.226l.232,4.008a.654.654,0,0,0,.654.654h1.424a.654.654,0,0,0,.654-.654l.232-4.008h.428a.614.614,0,0,0,.614-.614v-4.7A1.572,1.572,0,0,0,362.926,169"
        transform="translate(-348.144 -163.389)"
      />
    </g>
  </Svg>
)

const Svg = styled.svg`
  .cls-3 {
    fill: #35363a;
  }
`
