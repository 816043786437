/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 生産者の住所
 * @export
 * @interface ProducerAddressReadSeller
 */
export interface ProducerAddressReadSeller {
  /**
   * ID
   * @type {number}
   * @memberof ProducerAddressReadSeller
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof ProducerAddressReadSeller
   */
  readonly versionNumber: number
  /**
   * 削除フラグ（true: 削除済、false: 未削除）
   * @type {boolean}
   * @memberof ProducerAddressReadSeller
   */
  readonly isDeleted: boolean
  /**
   * 書き込みは空文字可。
   * 読み込み時に空文字が設定されている場合、住所を整形した値を自動的に返す。
   * @type {string}
   * @memberof ProducerAddressReadSeller
   */
  name: string
  /**
   * 都道府県のID、海外は含まない
   * @type {number}
   * @memberof ProducerAddressReadSeller
   */
  prefectureId: number
  /**
   *
   * @type {string}
   * @memberof ProducerAddressReadSeller
   */
  city: string
  /**
   *
   * @type {string}
   * @memberof ProducerAddressReadSeller
   */
  address1: string
  /**
   * 空文字可
   * @type {string}
   * @memberof ProducerAddressReadSeller
   */
  address2: string
  /**
   * 空文字可
   * @type {string}
   * @memberof ProducerAddressReadSeller
   */
  memo: string
}

export function ProducerAddressReadSellerFromJSON(json: any): ProducerAddressReadSeller {
  return ProducerAddressReadSellerFromJSONTyped(json, false)
}

export function ProducerAddressReadSellerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProducerAddressReadSeller {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isDeleted: json['is_deleted'],
    name: json['name'],
    prefectureId: json['prefecture_id'],
    city: json['city'],
    address1: json['address_1'],
    address2: json['address_2'],
    memo: json['memo'],
  }
}

export function ProducerAddressReadSellerToJSON(value?: ProducerAddressReadSeller | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    prefecture_id: value.prefectureId,
    city: value.city,
    address_1: value.address1,
    address_2: value.address2,
    memo: value.memo,
  }
}
