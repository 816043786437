/**
 * Map用のpromise, resolve, rejectを作成する
 * これでresolveの引数にも型がつく
 */
export function createMapPromise<T>(): [Promise<Map<number, T>>, (map: Map<number, T>) => void, (err: any) => void] {
  let resolve: any = null
  let reject: any = null
  const promise = new Promise<Map<number, T>>((_resolve, _reject) => {
    resolve = _resolve
    reject = _reject
  })

  return [promise, resolve, reject]
}

/**
 * Array用のpromise, resolve, rejectを作成する
 * これでresolveの引数にも型がつく
 */
export function createAryPromise<T>(): [Promise<T[]>, (ary: T[]) => void, (err: any) => void] {
  let resolve: any = null
  let reject: any = null
  const promise = new Promise<T[]>((_resolve, _reject) => {
    resolve = _resolve
    reject = _reject
  })

  return [promise, resolve, reject]
}

/**
 * ArrayをidをキーにしてMap化する
 * 毎回これを書くのはしんどい
 * @param values
 */
export function convertToMap<T extends { id: number }>(values: T[]): Map<number, T> {
  const map = new Map<number, T>()
  values.forEach((v) => map.set(v.id, v))
  return map
}
