/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface Attachment
 */
export interface Attachment {
  /**
   * 添付ファイルUUID
   * @type {string}
   * @memberof Attachment
   */
  uuid: string
  /**
   * 添付ファイル名
   * @type {string}
   * @memberof Attachment
   */
  fileName: string
  /**
   * 添付ファイルサイズ
   * @type {number}
   * @memberof Attachment
   */
  fileSizeInBytes: number
}

export function AttachmentFromJSON(json: any): Attachment {
  return AttachmentFromJSONTyped(json, false)
}

export function AttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attachment {
  if (json === undefined || json === null) {
    return json
  }
  return {
    uuid: json['uuid'],
    fileName: json['file_name'],
    fileSizeInBytes: json['file_size_in_bytes'],
  }
}

export function AttachmentToJSON(value?: Attachment | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    uuid: value.uuid,
    file_name: value.fileName,
    file_size_in_bytes: value.fileSizeInBytes,
  }
}
