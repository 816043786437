import styled from '@emotion/styled'
import { Modal as ChakraModal } from '@chakra-ui/react'
import { ModalContent, ModalOverlay, Text, Box, useDisclosure } from '@chakra-ui/react'

import { QuestionIcon } from '../../svg/QuestionIcon'
import { CloseIcon } from '../../svg/CloseIcon'
import { theme } from '../../../../theme/theme'
import { ContainNewLineText } from '../../ContentBox/ContainNewLineText'

export const InfoModal = ({ title, content }: { title: string; content: string | React.ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <div onClick={onOpen} style={{ cursor: 'pointer' }}>
        <QuestionIcon />
      </div>
      <ModalBody isOpen={isOpen} onClose={onClose} title={title} content={content} />
    </>
  )
}

function ModalBody({
  isOpen,
  onClose,
  title,
  content,
}: {
  isOpen: boolean
  onClose: () => void
  title: string
  content: string | React.ReactNode
}) {
  const borderColor = theme.colors.brand.box.border

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} size="sm" isCentered={true}>
      <ModalOverlay />
      <ModalContent margin="auto 42px" borderRadius="5px">
        <Close onClick={onClose} borderColor={borderColor}>
          <CloseIcon />
        </Close>
        <Text fontSize="20px" fontWeight="bold" textAlign="center" margin="28px auto 0">
          {title}
        </Text>
        <Box fontSize="16px" margin="26px 30px 30px">
          {typeof content === 'string' && <ContainNewLineText text={content} />}
          {typeof content !== 'string' && <>{content}</>}
        </Box>
      </ModalContent>
    </ChakraModal>
  )
}

export const Close = styled.div<{ borderColor: string }>`
  width: 48px;
  height: 48px;
  background: white;
  position: absolute;
  top: -24px;
  right: -24px;
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
