import { useHistory } from 'react-router-dom'
import { useAuthManagerFromContext } from './useAuthManagerFromContext'

export function useLogout() {
  const history = useHistory()
  const authManager = useAuthManagerFromContext()

  return async () => {
    await authManager.logout()
    history.push('/')
  }
}

// エラー用で有ることを明示
export function useLogoutAfter401Error() {
  return useLogout()
}
