import styled from '@emotion/styled'

export const ArrowRight = () => (
  <Svg width="20" height="20" viewBox="0 0 20 20">
    <g transform="translate(0 20) rotate(-90)">
      <g transform="translate(5.109 7.5)">
        <path id="Path" className="cls-1" d="M0,0,5,5l5-5" />
      </g>
    </g>
  </Svg>
)

const Svg = styled.svg`
  .cls-1 {
    fill: none;
    stroke: #707070;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.667px;
  }
`
