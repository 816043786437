export function ContainNewLineText({ text }: { text: string }) {
  return (
    <p>
      {text.split('\n').flatMap((line, idx, lines) => {
        const els = [<span key={idx}>{line}</span>]
        if (idx < lines.length - 1) {
          els.push(<br key={lines.length + idx} />)
        }
        return els
      })}
    </p>
  )
}
