/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { GradeRead, GradeReadFromJSON, GradeReadFromJSONTyped, GradeReadToJSON } from './'

/**
 *
 * @export
 * @interface AmendReadSellerHandoverContents
 */
export interface AmendReadSellerHandoverContents {
  /**
   * 等級
   * @type {GradeRead}
   * @memberof AmendReadSellerHandoverContents
   */
  grade: GradeRead
  /**
   * 俵数
   * @type {string}
   * @memberof AmendReadSellerHandoverContents
   */
  sacks: string
  /**
   * 重量（kg）
   * @type {number}
   * @memberof AmendReadSellerHandoverContents
   */
  kg: number
  /**
   * 納品物の数量
   * @type {number}
   * @memberof AmendReadSellerHandoverContents
   */
  packageCount: number
  /**
   * 引渡単価（円／俵）
   * @type {number}
   * @memberof AmendReadSellerHandoverContents
   */
  unitPrice: number
}

export function AmendReadSellerHandoverContentsFromJSON(json: any): AmendReadSellerHandoverContents {
  return AmendReadSellerHandoverContentsFromJSONTyped(json, false)
}

export function AmendReadSellerHandoverContentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AmendReadSellerHandoverContents {
  if (json === undefined || json === null) {
    return json
  }
  return {
    grade: GradeReadFromJSON(json['grade']),
    sacks: json['sacks'],
    kg: json['kg'],
    packageCount: json['package_count'],
    unitPrice: json['unit_price'],
  }
}

export function AmendReadSellerHandoverContentsToJSON(value?: AmendReadSellerHandoverContents | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    grade: GradeReadToJSON(value.grade),
    sacks: value.sacks,
    kg: value.kg,
    package_count: value.packageCount,
    unit_price: value.unitPrice,
  }
}
