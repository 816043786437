import { useEffect, useState } from 'react'
import { useFetchLoginInfo } from '../../../PrivatePages/hooks/useFetchLoginInfo'
import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { ButtonSubmit } from '../../Buttons/Buttons'
import { Checkbox } from '../../Checkbox/Checkbox'
import { useFetchTos } from '../../../hooks/useFetchTos'
import { Loading } from '../../Loading/Loading'
import { useUpdateTosAgreement } from './useUpdateTosAgreement'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { captureException } from '../../../../infra/handleApiServerError'

export const TermsModal = () => {
  const isMobile = useIsMobile()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { pathname } = useLocation()
  const [readCheckbox, setReadCheckbox] = useState(false)
  const { fetched, fetching, data } = useFetchTos()
  const { fetching: updating, update } = useUpdateTosAgreement()

  const {
    fetching: loginInfoFetching,
    data: { loginInfo, account },
    updateLoginInfo,
  } = useFetchLoginInfo(pathname)

  useEffect(() => {
    if (!loginInfoFetching && loginInfo?.isTosAgreementRequire()) {
      onOpen()
    }
  }, [loginInfoFetching, loginInfo, pathname])

  const sendAgreement = async () => {
    if (data.tos === null) {
      // 基本的にここには来ない
      return
    }

    try {
      await update({
        tosVersion: data.tos.version,
        tosAgreementFlag: readCheckbox,
      })

      await updateLoginInfo()

      // モーダルを閉じる
      onClose()
    } catch (err) {
      captureException(err)
    } finally {
      // モーダルを閉じてから状態をリセットする
      setReadCheckbox(false)
    }
  }

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent background="#F3F3F3">
        {fetching && <Loading />}
        {fetched && (
          <>
            {account?.role !== 'admin' && <StaffMessage />}
            {account?.role === 'admin' && (
              <>
                <ModalBody paddingBottom="16px">
                  <Text marginBottom="8px" fontSize={isMobile ? '12px' : '16px'} color="#FF000F" textAlign="center">
                    アグリノート米市場サービス利用規約を更新しました。
                    <br />
                    引き続きご利用いただくには、最新の利用規約をお読みの上、
                    <br />
                    ご利用ください。
                  </Text>
                  <Box
                    margin="0"
                    padding="0"
                    backgroundColor="brand.box.background"
                    borderWidth="1px"
                    borderStyle="solid"
                    borderColor="brand.box.border"
                    borderRadius="5px"
                    height="300px"
                  >
                    <iframe
                      title="アグリノート米市場"
                      width="100%"
                      height="298px"
                      style={{ borderRadius: '5px', overflowX: 'hidden' }}
                      src={location.protocol + '//' + location.hostname + ':' + location.port + '/terms/index.html'}
                    ></iframe>
                  </Box>
                </ModalBody>
                <ModalFooter paddingTop="0">
                  <HStack width="100%" alignItems="center">
                    <Box width="50%">
                      <Text fontSize="12px">
                        利用規約に同意しない場合は、お使いのデバイスで画面を閉じる操作を行ってください
                      </Text>
                    </Box>
                    <Box width="50%">
                      <Checkbox
                        isChecked={readCheckbox}
                        onChange={(e) => setReadCheckbox(e.target.checked)}
                        size="lg"
                        borderColor="brand.box.border"
                      >
                        利用規約に同意する
                      </Checkbox>
                      <ButtonSubmit
                        onClick={sendAgreement}
                        isDisabled={!readCheckbox || updating}
                        width="100%"
                        height="50px"
                        marginTop="16px"
                      >
                        確認
                      </ButtonSubmit>
                    </Box>
                  </HStack>
                </ModalFooter>
              </>
            )}
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

const StaffMessage = () => {
  return (
    <>
      <ModalBody paddingY="24px">
        <VStack gap="2em" color="brand.text.alert" textAlign="center">
          <Text margin="0">※現在、スタッフアカウントでアグリノートにログインしています。</Text>
          <Text margin="0">
            管理者アカウントの方にて、
            <br />
            アグリノート米市場への利用規約同意手続きをお願いいたします。
          </Text>
          <Text margin="0">
            管理者アカウントの方による利用規約同意手続きの完了後、
            <br />
            スタッフアカウントの方も米市場を利用できるようになります。
          </Text>
        </VStack>
      </ModalBody>
      <ModalFooter paddingTop="0">
        <Text marginX="auto">お使いのデバイスで画面を閉じる操作を行ってください。</Text>
      </ModalFooter>
    </>
  )
}
