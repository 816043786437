/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * オファー通知条件
 * @export
 * @interface SettingOfferNotificationConditionRead
 */
export interface SettingOfferNotificationConditionRead {
  /**
   * ID
   * @type {number}
   * @memberof SettingOfferNotificationConditionRead
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof SettingOfferNotificationConditionRead
   */
  readonly versionNumber: number
  /**
   * 削除フラグ（true: 削除済、false: 未削除）
   * @type {boolean}
   * @memberof SettingOfferNotificationConditionRead
   */
  readonly isDeleted: boolean
  /**
   *
   * @type {string}
   * @memberof SettingOfferNotificationConditionRead
   */
  contractCategory: SettingOfferNotificationConditionReadContractCategoryEnum
  /**
   * 年産ID
   * @type {number}
   * @memberof SettingOfferNotificationConditionRead
   */
  annumId: number | null
  /**
   * 銘柄ID
   * @type {Set<number>}
   * @memberof SettingOfferNotificationConditionRead
   */
  brandIds: Set<number> | null
  /**
   * 上限金額
   * @type {number}
   * @memberof SettingOfferNotificationConditionRead
   */
  upperPrice: number | null
  /**
   * 下限金額
   * @type {number}
   * @memberof SettingOfferNotificationConditionRead
   */
  lowerPrice: number | null
}

/**
 * @export
 * @enum {string}
 */
export enum SettingOfferNotificationConditionReadContractCategoryEnum {
  AdvanceContract = 'advance_contract',
  SpotContract = 'spot_contract',
}

export function SettingOfferNotificationConditionReadFromJSON(json: any): SettingOfferNotificationConditionRead {
  return SettingOfferNotificationConditionReadFromJSONTyped(json, false)
}

export function SettingOfferNotificationConditionReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SettingOfferNotificationConditionRead {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isDeleted: json['is_deleted'],
    contractCategory: json['contract_category'],
    annumId: json['annum_id'],
    brandIds: json['brand_ids'],
    upperPrice: json['upper_price'],
    lowerPrice: json['lower_price'],
  }
}

export function SettingOfferNotificationConditionReadToJSON(value?: SettingOfferNotificationConditionRead | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contract_category: value.contractCategory,
    annum_id: value.annumId,
    brand_ids: value.brandIds,
    upper_price: value.upperPrice,
    lower_price: value.lowerPrice,
  }
}
