import { useHistory } from 'react-router-dom'
import { Box, Flex, Text, Button } from '@chakra-ui/react'

import { Logo } from '../../components/svg/Logo'
import { HomeIcon } from '../../components/svg/HomeIcon'

export function NotFound() {
  const history = useHistory()
  return (
    <Box backgroundColor="brand.background" minHeight="100vh">
      <Flex justify="center" paddingTop="40px" margin="0 auto 40px">
        <Logo width="235px" height="95px" />
      </Flex>
      <Text fontSize="26px" fontWeight="bold" textAlign="center" whiteSpace="nowrap" margin="40px 20px">
        お探しのページが
        <wbr />
        見つかりません
      </Text>
      <Box margin="40px auto 60px">
        <Text fontSize="16px" textAlign="center" margin="0 20px">
          一時的にアクセスできない状況にあるか、
        </Text>
        <Text fontSize="16px" textAlign="center" margin="0 20px">
          移動もしくは削除された
        </Text>
        <Text fontSize="16px" textAlign="center" margin="0 20px">
          可能性があります。
        </Text>
      </Box>
      <Flex justify="center" paddingBottom="80px">
        <Button
          onClick={() => history.push('/home')}
          backgroundColor="brand.brandHighlight"
          width="200px"
          height="50px"
        >
          <HomeIcon />
          <Text marginLeft="8px">ホームへ</Text>
        </Button>
      </Flex>
    </Box>
  )
}
