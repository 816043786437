/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 銘柄マスタ
 * @export
 * @interface Brand
 */
export interface Brand {
  /**
   * ID
   * @type {number}
   * @memberof Brand
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof Brand
   */
  readonly versionNumber: number
  /**
   * ステータス（true: 有効、false: 無効）
   * @type {boolean}
   * @memberof Brand
   */
  isActive: boolean
  /**
   * 銘柄名
   * @type {string}
   * @memberof Brand
   */
  name: string
  /**
   * ふりがな
   * @type {string}
   * @memberof Brand
   */
  nameKana: string
  /**
   * 分類（"uruchi": うるち米、"mochi": もち米、"sake": 酒造好適米）
   * @type {string}
   * @memberof Brand
   */
  group: BrandGroupEnum
  /**
   * 登録都道府県ID
   * @type {Array<number>}
   * @memberof Brand
   */
  prefectureIds: Array<number>
}

/**
 * @export
 * @enum {string}
 */
export enum BrandGroupEnum {
  Uruchi = 'uruchi',
  Mochi = 'mochi',
  Sake = 'sake',
}

export function BrandFromJSON(json: any): Brand {
  return BrandFromJSONTyped(json, false)
}

export function BrandFromJSONTyped(json: any, ignoreDiscriminator: boolean): Brand {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isActive: json['is_active'],
    name: json['name'],
    nameKana: json['name_kana'],
    group: json['group'],
    prefectureIds: json['prefecture_ids'],
  }
}

export function BrandToJSON(value?: Brand | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    is_active: value.isActive,
    name: value.name,
    name_kana: value.nameKana,
    group: value.group,
    prefecture_ids: value.prefectureIds,
  }
}
