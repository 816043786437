/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  Attachment,
  AttachmentFromJSON,
  AttachmentFromJSONTyped,
  AttachmentToJSON,
  PaymentReadSellerAdjustments,
  PaymentReadSellerAdjustmentsFromJSON,
  PaymentReadSellerAdjustmentsFromJSONTyped,
  PaymentReadSellerAdjustmentsToJSON,
  PaymentReadSellerProductPayments,
  PaymentReadSellerProductPaymentsFromJSON,
  PaymentReadSellerProductPaymentsFromJSONTyped,
  PaymentReadSellerProductPaymentsToJSON,
} from './'

/**
 * [現物契約] 支払い情報（Read、Seller）
 * @export
 * @interface SpotContractPaymentReadSeller
 */
export interface SpotContractPaymentReadSeller {
  /**
   * ID
   * @type {number}
   * @memberof SpotContractPaymentReadSeller
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof SpotContractPaymentReadSeller
   */
  readonly versionNumber: number
  /**
   * アメンドID
   * @type {number}
   * @memberof SpotContractPaymentReadSeller
   */
  readonly amendId: number
  /**
   * 個別運送情報ID
   * @type {number}
   * @memberof SpotContractPaymentReadSeller
   */
  readonly individualDeliveryId: number
  /**
   * 支払いの状態
   * - provisional: 仮請求
   * - waiting_for_billing: 請求待ち（該当する個別運送情報の検品が終わったら、自動的にこの状態になる）
   * - waiting_for_payment_report: 支払報告待ち
   * - waiting_for_payment_confirmation: 入金確認待ち（支払報告待ちの状態でWCIが入金確認をした場合、この状態をスキップし、自動的に振込待ちに進む）
   * - waiting_for_transfer: 振込待ち
   * - done: 完了
   * @type {string}
   * @memberof SpotContractPaymentReadSeller
   */
  readonly status: SpotContractPaymentReadSellerStatusEnum
  /**
   * 商品の売買代金リスト
   * @type {Array<PaymentReadSellerProductPayments>}
   * @memberof SpotContractPaymentReadSeller
   */
  readonly productPayments: Array<PaymentReadSellerProductPayments>
  /**
   * 調整金額リスト
   * @type {Array<PaymentReadSellerAdjustments>}
   * @memberof SpotContractPaymentReadSeller
   */
  readonly adjustments: Array<PaymentReadSellerAdjustments>
  /**
   * 支払期日（生産者に売買代金を振り込む期限）
   * @type {Date}
   * @memberof SpotContractPaymentReadSeller
   */
  readonly transferDueDate: Date | null
  /**
   * 支払い通知書（添付ファイルUUID）
   * @type {string}
   * @memberof SpotContractPaymentReadSeller
   */
  readonly transferNoticeUuid?: string | null
  /**
   *
   * @type {Attachment}
   * @memberof SpotContractPaymentReadSeller
   */
  transferNotice: Attachment
}

/**
 * @export
 * @enum {string}
 */
export enum SpotContractPaymentReadSellerStatusEnum {
  Provisional = 'provisional',
  WaitingForBilling = 'waiting_for_billing',
  WaitingForPaymentReport = 'waiting_for_payment_report',
  WaitingForPaymentConfirmation = 'waiting_for_payment_confirmation',
  WaitingForTransfer = 'waiting_for_transfer',
  Done = 'done',
}

export function SpotContractPaymentReadSellerFromJSON(json: any): SpotContractPaymentReadSeller {
  return SpotContractPaymentReadSellerFromJSONTyped(json, false)
}

export function SpotContractPaymentReadSellerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpotContractPaymentReadSeller {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    amendId: json['amend_id'],
    individualDeliveryId: json['individual_delivery_id'],
    status: json['status'],
    productPayments: (json['product_payments'] as Array<any>).map(PaymentReadSellerProductPaymentsFromJSON),
    adjustments: (json['adjustments'] as Array<any>).map(PaymentReadSellerAdjustmentsFromJSON),
    transferDueDate: json['transfer_due_date'] === null ? null : new Date(json['transfer_due_date']),
    transferNoticeUuid: !exists(json, 'transfer_notice_uuid') ? undefined : json['transfer_notice_uuid'],
    transferNotice: AttachmentFromJSON(json['transfer_notice']),
  }
}

export function SpotContractPaymentReadSellerToJSON(value?: SpotContractPaymentReadSeller | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    transfer_notice: AttachmentToJSON(value.transferNotice),
  }
}
