/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 外部検査機関
 * @export
 * @interface InspectionOrganization
 */
export interface InspectionOrganization {
  /**
   * ID
   * @type {number}
   * @memberof InspectionOrganization
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof InspectionOrganization
   */
  readonly versionNumber: number
  /**
   * ステータス（true: 有効、false: 無効）
   * @type {boolean}
   * @memberof InspectionOrganization
   */
  isActive: boolean
  /**
   * 検査場所名
   * @type {string}
   * @memberof InspectionOrganization
   */
  name: string
  /**
   * 都道府県以降の住所
   * @type {string}
   * @memberof InspectionOrganization
   */
  address: string
  /**
   * 固定電話（入力形式は自由）
   * @type {string}
   * @memberof InspectionOrganization
   */
  phoneNumber: string
  /**
   * 携帯電話（入力形式は自由）
   * @type {string}
   * @memberof InspectionOrganization
   */
  mobilePhoneNumber: string
  /**
   * FAX（記述形式は自由）
   * @type {string}
   * @memberof InspectionOrganization
   */
  faxNumber: string
  /**
   * メールアドレス（形式をチェックするが、空文字列可）
   * @type {string}
   * @memberof InspectionOrganization
   */
  email: string
  /**
   * 担当者名
   * @type {string}
   * @memberof InspectionOrganization
   */
  personInChargeName: string
  /**
   * 検査機関名
   * @type {string}
   * @memberof InspectionOrganization
   */
  organizationName: string
  /**
   * 検査機関の電話番号（入力形式は自由）
   * @type {string}
   * @memberof InspectionOrganization
   */
  organizationPhoneNumber: string
  /**
   * 検査機関の住所
   * @type {string}
   * @memberof InspectionOrganization
   */
  organizationAddress: string
  /**
   * 検査機関の担当者連絡先（入力形式は自由）
   * @type {string}
   * @memberof InspectionOrganization
   */
  organizationContact: string
  /**
   * メモ
   * @type {string}
   * @memberof InspectionOrganization
   */
  memo: string
  /**
   * 都道府県ID
   * @type {number}
   * @memberof InspectionOrganization
   */
  prefectureId: number
}

export function InspectionOrganizationFromJSON(json: any): InspectionOrganization {
  return InspectionOrganizationFromJSONTyped(json, false)
}

export function InspectionOrganizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionOrganization {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isActive: json['is_active'],
    name: json['name'],
    address: json['address'],
    phoneNumber: json['phone_number'],
    mobilePhoneNumber: json['mobile_phone_number'],
    faxNumber: json['fax_number'],
    email: json['email'],
    personInChargeName: json['person_in_charge_name'],
    organizationName: json['organization_name'],
    organizationPhoneNumber: json['organization_phone_number'],
    organizationAddress: json['organization_address'],
    organizationContact: json['organization_contact'],
    memo: json['memo'],
    prefectureId: json['prefecture_id'],
  }
}

export function InspectionOrganizationToJSON(value?: InspectionOrganization | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    is_active: value.isActive,
    name: value.name,
    address: value.address,
    phone_number: value.phoneNumber,
    mobile_phone_number: value.mobilePhoneNumber,
    fax_number: value.faxNumber,
    email: value.email,
    person_in_charge_name: value.personInChargeName,
    organization_name: value.organizationName,
    organization_phone_number: value.organizationPhoneNumber,
    organization_address: value.organizationAddress,
    organization_contact: value.organizationContact,
    memo: value.memo,
    prefecture_id: value.prefectureId,
  }
}
