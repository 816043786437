import { ReactElement } from 'react'
import { InnerHeader } from './InnerHeader'
import { useRouteMatch } from 'react-router-dom'
import { HomeIcon } from '../../svg/HomeIcon'
import { OfferIcon } from '../../svg/OfferIcon'
import { DealIcon } from '../../svg/DealIcon'
import { ProducerIcon } from '../../svg/ProducerIcon'
import { UsageIcon } from '../../svg/UsageIcon'
import { OfferRequestIcon } from '../../svg/OfferRequestIcon'
import { ContactIcon } from '../../svg/ContactIcon'
import { TermsIcon } from '../../svg/TermsIcon'
import { TroubleShootingIcon } from '../../svg/TroubleShootingIcon'

import { Back, AmendBack, SpotContractDealInputBack, HomeBack, OpenMenu, TodoIcon, OpenTodoList } from './LeftActions'
import { OpenOfferRequestInfoModal } from './RightActions'

import { useHashParams } from '../../../hooks/useHashParams'
import { BellIcon } from '../../svg/bellIcon'

type DisplayTarget = {
  title: string
  icon: ReactElement
  isMatch: boolean
  left: ReactElement | null
  right: ReactElement | null
}

export function HeaderWithVisibilityManager() {
  const DISPLAY_TARGETS: DisplayTarget[] = [
    {
      title: 'ホーム',
      icon: <HomeIcon />,
      isMatch: useRouteMatch('/home')?.isExact || false,
      left: <OpenMenu />,
      right: <OpenTodoList />,
    },
    {
      title: 'オファー',
      icon: <OfferIcon />,
      isMatch: useRouteMatch('/offers')?.isExact || false,
      left: <OpenMenu />,
      right: <OpenTodoList />,
    },
    {
      title: 'オファー',
      icon: <OfferIcon />,
      isMatch: useRouteMatch('/offers/others')?.isExact || false,
      left: <Back />,
      right: <OpenTodoList />,
    },
    {
      title: 'オファーに回答',
      icon: <OfferIcon />,
      isMatch: useRouteMatch('/offers/spot/:offer_id')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: 'オファー詳細',
      icon: <OfferIcon />,
      isMatch: useRouteMatch('/offers/spot/:offer_id/confirm_detail')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: 'オファーに回答',
      icon: <OfferIcon />,
      isMatch: useRouteMatch('/offers/spot/:offer_id/confirm')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: '引渡情報詳細',
      icon: <DealIcon />,
      isMatch: useRouteMatch('/deals/amend/spot/:amend_id')?.path.includes('/deals/amend/spot/:amend_id') || false,
      left: <AmendBack />,
      right: <OpenTodoList />,
    },
    {
      title: 'オファーに回答',
      icon: <OfferIcon />,
      isMatch: useRouteMatch('/offers/:offer_id')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: 'オファー詳細',
      icon: <OfferIcon />,
      isMatch: useRouteMatch('/offers/:offer_id/confirm_detail')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: 'オファーに回答',
      icon: <OfferIcon />,
      isMatch: useRouteMatch('/offers/:offer_id/confirm')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: '引渡情報詳細',
      icon: <DealIcon />,
      isMatch: useRouteMatch('/deals/amend/:amend_id')?.path.includes('/deals/amend/:amend_id') || false,
      left: <AmendBack />,
      right: <OpenTodoList />,
    },
    {
      title: '取引案件',
      icon: <DealIcon />,
      isMatch: useRouteMatch('/deals')?.isExact || false,
      left: <OpenMenu />,
      right: <OpenTodoList />,
    },
    {
      title: '取引案件詳細',
      icon: <DealIcon />,
      isMatch: useRouteMatch('/deals/spot/:deal_id')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: '取引案件詳細',
      icon: <DealIcon />,
      isMatch: useRouteMatch('/deals/spot/:deal_id/handover_info')?.isExact || false,
      left: <SpotContractDealInputBack />,
      right: null,
    },
    {
      title: '取引案件詳細',
      icon: <DealIcon />,
      isMatch: useRouteMatch('/deals/spot/:deal_id/inspection_results')?.isExact || false,
      left: <SpotContractDealInputBack />,
      right: null,
    },
    {
      title: '取引案件詳細',
      icon: <DealIcon />,
      isMatch: useRouteMatch('/deals/spot/:deal_id/inspection_results/confirm')?.isExact || false,
      left: <SpotContractDealInputBack />,
      right: null,
    },
    {
      title: '取引案件不承諾',
      icon: <DealIcon />,
      isMatch: useRouteMatch('/deals/spot/:deal_id/disagreement')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: '取引案件詳細',
      icon: <DealIcon />,
      isMatch: useRouteMatch('/deals/spot/:deal_id/dealt')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: '取引案件詳細',
      icon: <DealIcon />,
      isMatch: useRouteMatch('/deals/:deal_id')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: '取引案件承諾',
      icon: <DealIcon />,
      isMatch: useRouteMatch('/deals/:deal_id/agreement')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: '取引案件不承諾',
      icon: <DealIcon />,
      isMatch: useRouteMatch('/deals/:deal_id/disagreement')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: '取引案件詳細',
      icon: <DealIcon />,
      isMatch: useRouteMatch('/deals/:deal_id/dealt')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: '登録情報',
      icon: <ProducerIcon />,
      isMatch: useRouteMatch('/producer')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: '引渡場所追加',
      icon: <ProducerIcon />,
      isMatch: useRouteMatch('/producer/address/new')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: '引渡場所編集',
      icon: <ProducerIcon />,
      isMatch: useRouteMatch('/producer/address/edit/:id')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: '銀行口座追加',
      icon: <ProducerIcon />,
      isMatch: useRouteMatch('/producer/bank_account/new')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: '銀行口座編集',
      icon: <ProducerIcon />,
      isMatch: useRouteMatch('/producer/bank_account/edit/:id')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: '使い方',
      icon: <UsageIcon />,
      isMatch: useRouteMatch('/usage')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: '希望オファー入力',
      icon: <OfferRequestIcon />,
      isMatch: useRouteMatch('/offer_requests')?.isExact || false,
      left: <Back />,
      right: <OpenOfferRequestInfoModal />,
    },
    {
      title: '希望オファーの登録',
      icon: <OfferRequestIcon />,
      isMatch: useRouteMatch('/offer_requests/new')?.isExact || false,
      left: <Back />,
      right: <OpenOfferRequestInfoModal />,
    },
    {
      title: '希望オファーの更新',
      icon: <OfferRequestIcon />,
      isMatch: useRouteMatch('/offer_requests/:id/edit')?.isExact || false,
      left: <Back />,
      right: <OpenOfferRequestInfoModal />,
    },
    {
      title: '希望オファーの送信確認',
      icon: <OfferRequestIcon />,
      isMatch: useRouteMatch('/offer_requests/confirm')?.isExact || false,
      left: <Back />,
      right: <OpenOfferRequestInfoModal />,
    },
    {
      title: 'お問い合わせ',
      icon: <ContactIcon />,
      isMatch: useRouteMatch('/contact')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: 'お問い合わせ',
      icon: <ContactIcon />,
      isMatch: useRouteMatch('/contact/check')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: '利用規約',
      icon: <TermsIcon />,
      isMatch: useRouteMatch('/terms')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: 'やることリスト',
      icon: <TodoIcon />,
      isMatch: useRouteMatch('/pending_tasks')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: 'トラブル対応先',
      icon: <TroubleShootingIcon />,
      isMatch: useRouteMatch('/trouble_shooting')?.isExact || false,
      left: <Back />,
      right: null,
    },
    {
      title: '通知設定',
      icon: <BellIcon />,
      isMatch: useRouteMatch('/notification_settings')?.isExact || false,
      left: <Back />,
      right: null,
    },
  ]

  // 直接リンクで開いたときの設定(leftが<HomeBack />になっている)
  const DISPLAY_TARGET_WITH_DIRECT_ACCESS = [
    {
      title: '取引案件詳細',
      icon: <DealIcon />,
      isMatch: useRouteMatch('/deals/:deal_id')?.isExact || false,
      left: <HomeBack />,
      right: null,
    },
    {
      title: '取引案件詳細',
      icon: <DealIcon />,
      isMatch: useRouteMatch('/deals/:deal_id/dealt')?.isExact || false,
      left: <HomeBack />,
      right: null,
    },
    {
      title: '引渡情報詳細',
      icon: <DealIcon />,
      isMatch: useRouteMatch('/deals/amend/spot/:amend_id')?.path.includes('/deals/amend/spot/:amend_id') || false,
      left: <HomeBack />,
      right: <OpenTodoList />,
    },
    {
      title: '引渡情報詳細',
      icon: <DealIcon />,
      isMatch: useRouteMatch('/deals/amend/:amend_id')?.path.includes('/deals/amend/:amend_id') || false,
      left: <HomeBack />,
      right: <OpenTodoList />,
    },
    // 他の画面も予期せずアクセスされたときのために対象に入れておく
    // findは前から検索するので重複しても問題ないはず
    ...DISPLAY_TARGETS,
  ]

  const [hashParam] = useHashParams()
  const isDirect = hashParam.get('direct') === 'true' // stringで判別

  const found = isDirect
    ? DISPLAY_TARGET_WITH_DIRECT_ACCESS.find((v) => v.isMatch)
    : DISPLAY_TARGETS.find((v) => v.isMatch)

  if (found) {
    return <InnerHeader title={found.title} icon={found.icon} left={found.left} right={found.right} />
  } else {
    return <InnerHeader title="" icon={null} left={null} right={null} />
  }
}
