/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  IndividualDeliveryHasProducerReviewed,
  IndividualDeliveryHasProducerReviewedFromJSON,
  IndividualDeliveryHasProducerReviewedToJSON,
  IndividualDeliveryReadSeller,
  IndividualDeliveryReadSellerFromJSON,
  IndividualDeliveryReadSellerToJSON,
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
} from '../models'

export interface PutIndividualDeliveriesIdReviewRequest {
  id: number
  individualDeliveryHasProducerReviewed?: IndividualDeliveryHasProducerReviewed
}

/**
 *
 */
export class IndividualDeliveriesApi extends runtime.BaseAPI {
  /**
   * 指定IDに該当する個別運送情報の確認を報告する
   */
  async putIndividualDeliveriesIdReviewRaw(
    requestParameters: PutIndividualDeliveriesIdReviewRequest
  ): Promise<runtime.ApiResponse<IndividualDeliveryReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putIndividualDeliveriesIdReview.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/individual_deliveries/{id}/review`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: IndividualDeliveryHasProducerReviewedToJSON(requestParameters.individualDeliveryHasProducerReviewed),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => IndividualDeliveryReadSellerFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当する個別運送情報の確認を報告する
   */
  async putIndividualDeliveriesIdReview(
    requestParameters: PutIndividualDeliveriesIdReviewRequest
  ): Promise<IndividualDeliveryReadSeller> {
    const response = await this.putIndividualDeliveriesIdReviewRaw(requestParameters)
    return await response.value()
  }
}
