/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * オファー通知条件
 * @export
 * @interface ProducersMeSettingsOfferNotificationConditionsUpdate
 */
export interface ProducersMeSettingsOfferNotificationConditionsUpdate {
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof ProducersMeSettingsOfferNotificationConditionsUpdate
   */
  versionNumber: number
  /**
   *
   * @type {string}
   * @memberof ProducersMeSettingsOfferNotificationConditionsUpdate
   */
  contractCategory: ProducersMeSettingsOfferNotificationConditionsUpdateContractCategoryEnum
  /**
   * 年産ID
   * @type {number}
   * @memberof ProducersMeSettingsOfferNotificationConditionsUpdate
   */
  annumId: number | null
  /**
   * 銘柄ID
   * @type {Set<number>}
   * @memberof ProducersMeSettingsOfferNotificationConditionsUpdate
   */
  brandIds: Set<number> | null
  /**
   * 上限金額
   * @type {number}
   * @memberof ProducersMeSettingsOfferNotificationConditionsUpdate
   */
  upperPrice: number | null
  /**
   * 下限金額
   * @type {number}
   * @memberof ProducersMeSettingsOfferNotificationConditionsUpdate
   */
  lowerPrice: number | null
}

/**
 * @export
 * @enum {string}
 */
export enum ProducersMeSettingsOfferNotificationConditionsUpdateContractCategoryEnum {
  AdvanceContract = 'advance_contract',
  SpotContract = 'spot_contract',
}

export function ProducersMeSettingsOfferNotificationConditionsUpdateFromJSON(
  json: any
): ProducersMeSettingsOfferNotificationConditionsUpdate {
  return ProducersMeSettingsOfferNotificationConditionsUpdateFromJSONTyped(json, false)
}

export function ProducersMeSettingsOfferNotificationConditionsUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProducersMeSettingsOfferNotificationConditionsUpdate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    versionNumber: json['version_number'],
    contractCategory: json['contract_category'],
    annumId: json['annum_id'],
    brandIds: json['brand_ids'],
    upperPrice: json['upper_price'],
    lowerPrice: json['lower_price'],
  }
}

export function ProducersMeSettingsOfferNotificationConditionsUpdateToJSON(
  value?: ProducersMeSettingsOfferNotificationConditionsUpdate | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    version_number: value.versionNumber,
    contract_category: value.contractCategory,
    annum_id: value.annumId,
    brand_ids: value.brandIds,
    upper_price: value.upperPrice,
    lower_price: value.lowerPrice,
  }
}
