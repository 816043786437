import { ProducerNormalized } from './producer'
import { Producer } from './denormalizedProducer'
import { Prefecture } from '../prefecture/prefecture'

type Id = number
type RelationResolver = {
  prefectureMap: Map<Id, Prefecture>
}

export function denormalizeProducer(relationResolver: RelationResolver) {
  return function denormalize(producer: ProducerNormalized): Producer {
    const prefecture = relationResolver.prefectureMap.get(producer.prefectureId) as Prefecture

    return {
      ...producer,
      prefecture,
    }
  }
}
