/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  AmendsInspectionResultsUpdateInspectionResults,
  AmendsInspectionResultsUpdateInspectionResultsFromJSON,
  AmendsInspectionResultsUpdateInspectionResultsFromJSONTyped,
  AmendsInspectionResultsUpdateInspectionResultsToJSON,
} from './'

/**
 * [現物契約] ディール（Update、Seller）
 * @export
 * @interface SpotContractDealsUpdate
 */
export interface SpotContractDealsUpdate {
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof SpotContractDealsUpdate
   */
  versionNumber: number
  /**
   * 生産者承諾状況（"agreement": 承諾済、"disagreement": 不承諾のみ対応）
   * @type {string}
   * @memberof SpotContractDealsUpdate
   */
  producerAgreementStatus: SpotContractDealsUpdateProducerAgreementStatusEnum
  /**
   * 検査結果
   * @type {Array<AmendsInspectionResultsUpdateInspectionResults>}
   * @memberof SpotContractDealsUpdate
   */
  inspectionResults?: Array<AmendsInspectionResultsUpdateInspectionResults>
  /**
   * 検査結果を裏付ける資料（添付ファイルUUIDリスト）
   * @type {Array<string>}
   * @memberof SpotContractDealsUpdate
   */
  inspectionResultsAttachmentUuids?: Array<string>
  /**
   * 検査結果メモ
   * @type {string}
   * @memberof SpotContractDealsUpdate
   */
  inspectionResultsMemo?: string
}

/**
 * @export
 * @enum {string}
 */
export enum SpotContractDealsUpdateProducerAgreementStatusEnum {
  Agreement = 'agreement',
  Disagreement = 'disagreement',
}

export function SpotContractDealsUpdateFromJSON(json: any): SpotContractDealsUpdate {
  return SpotContractDealsUpdateFromJSONTyped(json, false)
}

export function SpotContractDealsUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpotContractDealsUpdate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    versionNumber: json['version_number'],
    producerAgreementStatus: json['producer_agreement_status'],
    inspectionResults: !exists(json, 'inspection_results')
      ? undefined
      : (json['inspection_results'] as Array<any>).map(AmendsInspectionResultsUpdateInspectionResultsFromJSON),
    inspectionResultsAttachmentUuids: !exists(json, 'inspection_results_attachment_uuids')
      ? undefined
      : json['inspection_results_attachment_uuids'],
    inspectionResultsMemo: !exists(json, 'inspection_results_memo') ? undefined : json['inspection_results_memo'],
  }
}

export function SpotContractDealsUpdateToJSON(value?: SpotContractDealsUpdate | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    version_number: value.versionNumber,
    producer_agreement_status: value.producerAgreementStatus,
    inspection_results:
      value.inspectionResults === undefined
        ? undefined
        : (value.inspectionResults as Array<any>).map(AmendsInspectionResultsUpdateInspectionResultsToJSON),
    inspection_results_attachment_uuids: value.inspectionResultsAttachmentUuids,
    inspection_results_memo: value.inspectionResultsMemo,
  }
}
