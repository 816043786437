import { ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'

import { useIsMobile } from '../../../hooks/useIsMobile'

export const Title = ({ icon, title }: { title: string; icon: ReactElement | null }) => {
  const isMobile = useIsMobile()

  return (
    <Flex justify={isMobile ? 'center' : 'flex-start'} align="center" marginLeft={isMobile ? '0' : '32px'}>
      {icon}
      <Text
        fontSize="20px"
        fontWeight="bold"
        color="brand.text.main"
        lineHeight="20px"
        marginLeft="8.86px"
        whiteSpace="nowrap"
      >
        {title}
      </Text>
    </Flex>
  )
}

export const RightButtonContainer = ({ right }: { right: ReactElement | null }) => {
  const isMobile = useIsMobile()

  return (
    <Flex
      position={isMobile ? 'absolute' : 'static'}
      top={isMobile ? '0' : 'auto'}
      right={isMobile ? '0' : 'auto'}
      justify="flex-end"
      flexGrow={1}
      flexShrink={1}
      flexBasis="20%"
      alignItems="center"
      marginRight={isMobile ? 'auto' : '20px'}
    >
      {right}
    </Flex>
  )
}
