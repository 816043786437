import { LoginInfo } from './loginInfo'
import { extendProducerWithService } from '../producer/producerService'

export type ExtendedLoginInfo = LoginInfo & LoginInfoService

interface PermissionService {
  hasPermissionOnlyAdmin: () => boolean
  hasPermissionOnlyAdminWhoCanAnswerOffer: () => boolean
}

class LoginInfoService implements PermissionService {
  get loginInfo(): LoginInfo {
    return this as any as LoginInfo
  }

  canInspect() {
    const producer = extendProducerWithService(this.loginInfo.producer)
    return producer.canInspect()
  }

  hasPermissionOnlyAdmin(): boolean {
    const { producerAccount } = this.loginInfo
    return producerAccount.role === 'admin'
  }

  hasPermissionOnlyAdminWhoCanAnswerOffer(): boolean {
    const { producerAccount, producer } = this.loginInfo
    return producer.canAnswerOffer && producerAccount.role === 'admin'
  }

  isTosAgreementRequire(): boolean {
    const { isTosAgreementRequired } = this.loginInfo
    return isTosAgreementRequired
  }
}

export function extendLoginInfoWithService(loginInfo: LoginInfo): ExtendedLoginInfo {
  return Object.assign<LoginInfoService, LoginInfo>(new LoginInfoService(), loginInfo)
}
