/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 希望オファー銘柄・価格
 * @export
 * @interface OfferRequestReadSeller
 */
export interface OfferRequestReadSeller {
  /**
   * ID
   * @type {number}
   * @memberof OfferRequestReadSeller
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof OfferRequestReadSeller
   */
  readonly versionNumber: number
  /**
   * 削除フラグ（true: 削除済、false: 未削除）
   * @type {boolean}
   * @memberof OfferRequestReadSeller
   */
  readonly isDeleted: boolean
  /**
   * 銘柄ID
   * @type {number}
   * @memberof OfferRequestReadSeller
   */
  brandId: number
  /**
   * 等級ID
   * @type {number}
   * @memberof OfferRequestReadSeller
   */
  gradeId: number
  /**
   * 地域ID
   * @type {number}
   * @memberof OfferRequestReadSeller
   */
  regionId: number | null
  /**
   * 単価
   * @type {number}
   * @memberof OfferRequestReadSeller
   */
  unitPrice: number
  /**
   * 特別条件IDリスト（空可、2つまで）
   * @type {Array<number>}
   * @memberof OfferRequestReadSeller
   */
  specialConditionIds: Array<number>
  /**
   * 更新日時
   * @type {Date}
   * @memberof OfferRequestReadSeller
   */
  readonly updatedAt: Date
}

export function OfferRequestReadSellerFromJSON(json: any): OfferRequestReadSeller {
  return OfferRequestReadSellerFromJSONTyped(json, false)
}

export function OfferRequestReadSellerFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferRequestReadSeller {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isDeleted: json['is_deleted'],
    brandId: json['brand_id'],
    gradeId: json['grade_id'],
    regionId: json['region_id'],
    unitPrice: json['unit_price'],
    specialConditionIds: json['special_condition_ids'],
    updatedAt: new Date(json['updated_at']),
  }
}

export function OfferRequestReadSellerToJSON(value?: OfferRequestReadSeller | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_id: value.brandId,
    grade_id: value.gradeId,
    region_id: value.regionId,
    unit_price: value.unitPrice,
    special_condition_ids: value.specialConditionIds,
  }
}
