import { Box, Text, Divider } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import statusJson from '../../../status.json'

import { theme } from '../../../theme/theme'

import { HomeIcon } from '../svg/HomeIcon'
import { OfferIcon } from '../svg/OfferIcon'
import { DealIcon } from '../svg/DealIcon'
import { TodoIconMenu } from '../svg/TodoIcon'
import { ProducerIcon } from '../svg/ProducerIcon'
import { UsageIcon } from '../svg/UsageIcon'
import { ContactIcon } from '../svg/ContactIcon'
import { TermsIcon } from '../svg/TermsIcon'
import { LogoutIcon } from '../svg/LogoutIcon'
import { useIsMobile } from '../../hooks/useIsMobile'

import { useLoginInfoContext } from '../../PrivatePages/hooks/useFetchLoginInfo'
import { appendAnnumQueryParamsTo } from '../../PrivatePages/hooks/useAnnumsAndSelectedId'
import { usePendingTasksContext } from '../../PrivatePages/hooks/usePendingTasks'
import { useLogout } from '../../auth/useLogout'
import { BellIcon } from '../svg/bellIcon'
import { OfferRequestIcon } from '../svg/OfferRequestIcon'

export function Menu() {
  const isMobile = useIsMobile()

  const color = theme.colors.brand.text.main
  const drawerSize = theme.sizes.brand.drawerSize

  const {
    data: { loginInfo },
  } = useLoginInfoContext()

  const { hasTodo } = usePendingTasksContext()

  const logout = useLogout()

  return (
    <Box zIndex={3} maxWidth={drawerSize} paddingTop={isMobile ? '0' : '30.5px'}>
      {!isMobile && (
        <>
          <Ul width={drawerSize} color={color}>
            <CustomLink to={appendAnnumQueryParamsTo('/home')} label="ホーム">
              <HomeIcon width={30} height={30} />
            </CustomLink>
          </Ul>
          {!isMobile && <Divider borderColor="brand.division" opacity={1} />}
          <Ul width={drawerSize} color={color}>
            <CustomLink to={appendAnnumQueryParamsTo('/offers')} label="オファー">
              <OfferIcon />
            </CustomLink>
            {!isMobile && (
              <CustomLink to="/offer_requests" label="希望オファー入力">
                <OfferRequestIcon />
              </CustomLink>
            )}
          </Ul>
          {!isMobile && <Divider borderColor="brand.division" opacity={1} />}
          <Ul width={drawerSize} color={color}>
            <CustomLink to={appendAnnumQueryParamsTo('/deals')} label="取引案件">
              <DealIcon width={30} height={30} />
            </CustomLink>
            {!isMobile && (
              <CustomLink to="/pending_tasks" label="やることリスト">
                <TodoIconMenu hasTodo={hasTodo} />
              </CustomLink>
            )}
          </Ul>
        </>
      )}

      {!isMobile && <Divider borderColor="brand.division" opacity={1} />}

      <Ul width={drawerSize} color={color}>
        {isMobile && (
          <CustomLink to="/pending_tasks" label="やることリスト">
            <TodoIconMenu hasTodo={hasTodo} />
          </CustomLink>
        )}
        {isMobile && (
          <CustomLink to="/offer_requests" label="希望オファー入力">
            <OfferRequestIcon />
          </CustomLink>
        )}
        <CustomLink to="/contact" label="お問い合わせ">
          <ContactIcon />
        </CustomLink>
        <CustomLink to="/usage" label="使い方">
          <UsageIcon />
        </CustomLink>

        <CustomLink to="/producer" label="登録情報">
          <ProducerIcon />
        </CustomLink>
        {loginInfo?.hasPermissionOnlyAdmin() && (
          <CustomLink to="/notification_settings" label="通知設定">
            <BellIcon />
          </CustomLink>
        )}
      </Ul>
      {!isMobile && <Divider borderColor="brand.division" opacity={1} />}
      <Ul width={drawerSize} color={color}>
        <Li isMobile={isMobile}>
          <CustomLink to="/terms" label="利用規約">
            <TermsIcon />
          </CustomLink>
          <Logout onClick={logout} isMobile={isMobile}>
            <LogoutIcon />
            <Text marginLeft="10px">ログアウト</Text>
          </Logout>
        </Li>
      </Ul>
      <Text fontSize="12px" margin="10px auto 25px 15px" color={color}>
        ver.{statusJson.version}
      </Text>
    </Box>
  )
}

const CustomLink = ({ children, to, label }: { children: any; to: string; label: string }) => {
  const isMobile = useIsMobile()

  return (
    <Li isMobile={isMobile}>
      <Link to={to}>
        {children}
        <Text marginLeft="10px">{label}</Text>
      </Link>
    </Li>
  )
}

const Ul = styled.ul<{ width: string; color: string }>`
  width: ${(props) => props.width};
  margin: 7.5px 0;
  list-style: none;
  color: ${(props) => props.color};
`

const Li = styled.li<{ isMobile: boolean }>`
  a {
    width: 100%;
    padding: ${(props) => (props.isMobile ? '7.5px 15px' : '7.5px 10px')};
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }
`

const Logout = styled.div<{ isMobile: boolean }>`
  width: 100%;
  padding: ${(props) => (props.isMobile ? '7.5px 15px' : '7.5px 10px')};
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
`
