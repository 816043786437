import { createContext, useContext } from 'react'
import { noop } from '../../utils/noop'

// INITIAL: まだ1度も実行されていない
// CHECKING: 認証確認中
// AUTHORIZED: 認可済み
// UNAUTHORIZED: 不認可
// EXITED: 認可取り消し済み
// MAINTENANCE: メンテナンス中
export type AuthenticationLifeCycle = 'INITIAL' | 'CHECKING' | 'AUTHORIZED' | 'UNAUTHORIZED' | 'EXITED' | 'MAINTENANCE'

export type AuthManger = {
  authState: AuthenticationLifeCycle
  /**
   * POST /producers 等の、事実上のログインを行った後に呼んで、SPAをログイン状態へ遷移する
   */
  switchToLoggedIn: () => void
  /**
   * GET /tokens を行う
   */
  login: () => void
  /**
   * DELETE /tokens を行う
   */
  logout: () => void
  /**
   * メンテナンスページへ来たとき、ログイン状態をメンテナンスで固定する
   */
  inMaintenanceMode: () => void
  /**
   * メンテナンスから通常状態に抜ける
   */
  outMaintenanceMode: () => void
}

// 初期値を定義しなければ行けないので定義した
// 実際の初期値の設定はuseInitializeAuthManager()を使って、<AuthManagerContext Provide value={useInitializeAuthManager()}/>で行うこと
const def: AuthManger = {
  authState: 'INITIAL',
  switchToLoggedIn: noop,
  login: noop,
  logout: noop,
  inMaintenanceMode: noop,
  outMaintenanceMode: noop,
}

// AuthManagerContextを使うときは、useAuthManagerFromContext()経由で取得すること
export const AuthManagerContext = createContext<AuthManger>(def)

/**
 * 実際にauthManagerを使うときは、メソッドごとに分割されたcustom hookを使うこと
 * e.g.
 *  useIsLoggedIn()
 *  useLogout()
 *  useLogin()
 */
export function useAuthManagerFromContext() {
  return useContext(AuthManagerContext)
}
