/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  InspectionOrganization,
  InspectionOrganizationFromJSON,
  InspectionOrganizationFromJSONTyped,
  InspectionOrganizationToJSON,
} from './'

/**
 * 生産者（Seller）
 * @export
 * @interface ProducerReadSeller
 */
export interface ProducerReadSeller {
  /**
   * ID
   * @type {number}
   * @memberof ProducerReadSeller
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof ProducerReadSeller
   */
  readonly versionNumber: number
  /**
   * 自身での検査可否
   * @type {boolean}
   * @memberof ProducerReadSeller
   */
  canSelfInspect: boolean
  /**
   * 生産者組織名
   * @type {string}
   * @memberof ProducerReadSeller
   */
  readonly companyName: string
  /**
   * 生産者組織名のフリガナ
   * @type {string}
   * @memberof ProducerReadSeller
   */
  readonly companyNameKana: string
  /**
   * 代表者氏名
   * @type {string}
   * @memberof ProducerReadSeller
   */
  readonly producerName: string
  /**
   * 代表者氏名のフリガナ
   * @type {string}
   * @memberof ProducerReadSeller
   */
  readonly producerNameKana: string
  /**
   * ステータス（true: 有効、false: 無効）
   * @type {boolean}
   * @memberof ProducerReadSeller
   */
  isActive: boolean
  /**
   * オファーに回答し、ディールを作成可能か（true: 可能, false: 不可能）
   * @type {boolean}
   * @memberof ProducerReadSeller
   */
  canAnswerOffer: boolean
  /**
   * 米市場へログイン可能か（true: 可能, false: 不可能）
   * @type {boolean}
   * @memberof ProducerReadSeller
   */
  canUseKomeIchiba: boolean
  /**
   * 組織の所属する都道府県ID
   * @type {number}
   * @memberof ProducerReadSeller
   */
  readonly prefectureId: number
  /**
   * 連絡先固定電話番号
   * @type {string}
   * @memberof ProducerReadSeller
   */
  readonly phone: string
  /**
   * 連絡先携帯電話番号
   * @type {string}
   * @memberof ProducerReadSeller
   */
  readonly mobilePhone: string
  /**
   * 外部検査機関
   * @type {Array<InspectionOrganization>}
   * @memberof ProducerReadSeller
   */
  readonly inspectionOrganizations: Array<InspectionOrganization>
}

export function ProducerReadSellerFromJSON(json: any): ProducerReadSeller {
  return ProducerReadSellerFromJSONTyped(json, false)
}

export function ProducerReadSellerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProducerReadSeller {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    canSelfInspect: json['can_self_inspect'],
    companyName: json['company_name'],
    companyNameKana: json['company_name_kana'],
    producerName: json['producer_name'],
    producerNameKana: json['producer_name_kana'],
    isActive: json['is_active'],
    canAnswerOffer: json['can_answer_offer'],
    canUseKomeIchiba: json['can_use_kome_ichiba'],
    prefectureId: json['prefecture_id'],
    phone: json['phone'],
    mobilePhone: json['mobile_phone'],
    inspectionOrganizations: (json['inspection_organizations'] as Array<any>).map(InspectionOrganizationFromJSON),
  }
}

export function ProducerReadSellerToJSON(value?: ProducerReadSeller | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    can_self_inspect: value.canSelfInspect,
    is_active: value.isActive,
    can_answer_offer: value.canAnswerOffer,
    can_use_kome_ichiba: value.canUseKomeIchiba,
  }
}
