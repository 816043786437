/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * IDだけを扱うスキーマ
 * @export
 * @interface IdOnly
 */
export interface IdOnly {
  /**
   *
   * @type {number}
   * @memberof IdOnly
   */
  id: number
}

export function IdOnlyFromJSON(json: any): IdOnly {
  return IdOnlyFromJSONTyped(json, false)
}

export function IdOnlyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdOnly {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
  }
}

export function IdOnlyToJSON(value?: IdOnly | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
  }
}
