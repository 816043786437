import { Box, Text, Flex } from '@chakra-ui/react'
import type { ReactNode } from 'react'

import { EntryContentBox, EntryMobileContentBox } from './EntryContentBox'
import { useIsMobile } from '../../../hooks/useIsMobile'

export const FormField = ({
  titleLabel,
  isRequire,
  note,
  formElement,
  formNote,
  errorMessage,
}: {
  titleLabel: string
  isRequire?: boolean
  note?: ReactNode
  formElement: ReactNode
  formNote?: string
  errorMessage?: ReactNode
}) => {
  const isMobile = useIsMobile()

  const element = (
    <Flex alignItems="center" margin={isMobile ? '13px 0' : '30px'} flexWrap="wrap">
      <Box width={isMobile ? '100%' : '50%'} marginBottom={isMobile ? '7px' : '0'}>
        <Box fontSize="18px" fontWeight="bold" whiteSpace="nowrap">
          {titleLabel}
          {isRequire && (
            <Text display="inline" color="brand.text.alert">
              *
            </Text>
          )}
        </Box>
        {note && <Text fontSize="12px">{note}</Text>}
      </Box>
      <Box marginLeft="auto" width={isMobile ? '100%' : '50%'}>
        <Box whiteSpace="nowrap">{formElement}</Box>
        <Text whiteSpace="nowrap" fontSize="12px">
          {formNote ? formNote : 'この選択は、後から変更できます。'}
        </Text>
        {errorMessage}
      </Box>
    </Flex>
  )

  if (isMobile) {
    return <EntryMobileContentBox>{element}</EntryMobileContentBox>
  } else {
    return <EntryContentBox margin="10px auto">{element}</EntryContentBox>
  }
}
