/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 生産者の住所
 * @export
 * @interface ProducerAddressesUpdate
 */
export interface ProducerAddressesUpdate {
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof ProducerAddressesUpdate
   */
  versionNumber: number
  /**
   * 書き込みは空文字可。
   * 読み込み時に空文字が設定されている場合、住所を整形した値を自動的に返す。
   * @type {string}
   * @memberof ProducerAddressesUpdate
   */
  name: string
  /**
   * 都道府県のID、海外は含まない
   * @type {number}
   * @memberof ProducerAddressesUpdate
   */
  prefectureId: number
  /**
   *
   * @type {string}
   * @memberof ProducerAddressesUpdate
   */
  city: string
  /**
   *
   * @type {string}
   * @memberof ProducerAddressesUpdate
   */
  address1: string
  /**
   * 空文字可
   * @type {string}
   * @memberof ProducerAddressesUpdate
   */
  address2: string
  /**
   * 空文字可
   * @type {string}
   * @memberof ProducerAddressesUpdate
   */
  memo: string
}

export function ProducerAddressesUpdateFromJSON(json: any): ProducerAddressesUpdate {
  return ProducerAddressesUpdateFromJSONTyped(json, false)
}

export function ProducerAddressesUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProducerAddressesUpdate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    versionNumber: json['version_number'],
    name: json['name'],
    prefectureId: json['prefecture_id'],
    city: json['city'],
    address1: json['address_1'],
    address2: json['address_2'],
    memo: json['memo'],
  }
}

export function ProducerAddressesUpdateToJSON(value?: ProducerAddressesUpdate | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    version_number: value.versionNumber,
    name: value.name,
    prefecture_id: value.prefectureId,
    city: value.city,
    address_1: value.address1,
    address_2: value.address2,
    memo: value.memo,
  }
}
