import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { theme } from '../../../theme/theme'
import { useLoginInfoContext } from '../../PrivatePages/hooks/useFetchLoginInfo'
import { useIsMobile } from '../../hooks/useIsMobile'

export const UserInformationLabel = () => {
  const isMobile = useIsMobile()
  const color = theme.colors.brand.text.main

  const { data } = useLoginInfoContext()

  let role = '　'
  let canAnswerOfferDisplay = '　'
  if (data.producer && data.account) {
    role = data.account.role === 'admin' ? '管理者' : data.account.role === 'staff' ? 'スタッフ' : '不明'
    canAnswerOfferDisplay = data.producer.canAnswerOffer ? '有料' : '無料' // 仕様的に、表示状は、オファーに回答できる > 有料, できない > 無料
  }

  return isMobile ? (
    <Flex margin="auto 14px auto auto">
      <MobileLabel borderColor={color} color={color}>
        {canAnswerOfferDisplay}
      </MobileLabel>
      <MobileLabel borderColor={color} color={color}>
        {role}
      </MobileLabel>
    </Flex>
  ) : (
    <Flex color="white">
      <Label borderColor={color} color={color}>
        {canAnswerOfferDisplay}
      </Label>
      <Label borderColor={color} color={color}>
        {role}
      </Label>
    </Flex>
  )
}

const MobileLabel = styled.div<{ borderColor: string; color: string }>`
  margin-left: 3px;
  padding: 5px 14px 3px;
  background: white;
  border: 1px solid ${(props) => props.borderColor};
  color: ${(props) => props.color};
  font-size: 12px;
  font-weight: bold;
`

const Label = styled.div<{ borderColor: string; color: string }>`
  margin-left: 10px;
  padding: 10px 12px;
  background: white;
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 5px;
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
`
