import { Suspense, lazy } from 'react'
import { HashRouter, Switch, Route, useLocation } from 'react-router-dom'
import { useAuthManager } from './auth/useAuthManager'
import { PrivateRoute } from './auth/PrivateRoute'
import { AuthManagerContext } from './auth/useAuthManagerFromContext'
import { LoginRoute } from './auth/LoginRoute'
import { Private } from './PrivatePages/Private'

import { LoadingEntire } from './components/Loading/Loading'
import { DeveloperHome } from './forDev/DeveloperHome'
import { GoogleAnalytics4 } from './components/GoogleAnalytics4/GoogleAnalytics4'

import { LoginInfoContext, useFetchLoginInfo } from './PrivatePages/hooks/useFetchLoginInfo'
import { EntryHandler } from './EntryPages/EntryHandler'
import { PublicEntryRouter, PrivateEntryRouter } from './EntryPages/EntryRouter'
import { TermsModal } from './components/Modal/TermsModal/TermsModal'

const LoginErrorPage = lazy(() => import('./PublicPages/LoginErrorPage/index'))
const Maintenance = lazy(() => import('./PublicPages/Maintenance/index'))

export function App() {
  // 認証の初期化をここでやってContextで配る
  const authManager = useAuthManager()

  return (
    <AuthManagerContext.Provider value={authManager}>
      <HashRouter>
        <GoogleAnalytics4 />
        <EntryHandler />
        <Suspense fallback={<LoadingEntire />}>
          <Switch>
            {/* 開発用 */}
            {process.env.NODE_ENV === 'development' && (
              <Route path="/div">
                <DeveloperHome />
              </Route>
            )}

            <Route path="/maintenance">
              <Maintenance />
            </Route>

            <LoginRoute path="/login_error">
              <LoginErrorPage />
            </LoginRoute>
            <Route path={['/entry', '/entry/2']} exact>
              <PublicEntryRouter />
            </Route>

            <PrivateRoute path="/">
              <PrivateRoutes />
            </PrivateRoute>
          </Switch>
        </Suspense>
      </HashRouter>
    </AuthManagerContext.Provider>
  )
}

export const PrivateRoutes = () => {
  const { pathname } = useLocation()
  const loginInfoState = useFetchLoginInfo(pathname)

  return (
    <LoginInfoContext.Provider value={loginInfoState}>
      <Switch>
        <Route path="/entry/*">
          <PrivateEntryRouter />
        </Route>
        <PrivateRoute path="/">
          <Private />
        </PrivateRoute>
      </Switch>
      <TermsModal />
    </LoginInfoContext.Provider>
  )
}
