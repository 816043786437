import { lazy } from 'react'
import { Switch } from 'react-router-dom'
import { PrivateRoute } from '../auth/PrivateRoute'
import { LoginRoute } from '../auth/LoginRoute'

import { PrivateEntryContainer } from './PrivateEntryContainer'

const EntryContainer = lazy(() => import('./PublicEntryContainer'))

export const PublicEntryRouter = () => {
  return (
    <Switch>
      <LoginRoute path="/entry" exact>
        <EntryContainer />
      </LoginRoute>
      <LoginRoute path="/entry/2" exact>
        <EntryContainer />
      </LoginRoute>
    </Switch>
  )
}

export const PrivateEntryRouter = () => {
  return (
    <Switch>
      <PrivateRoute path="/entry/3" exact>
        <PrivateEntryContainer />
      </PrivateRoute>
      <PrivateRoute path="/entry/4" exact>
        <PrivateEntryContainer />
      </PrivateRoute>
    </Switch>
  )
}
