/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 地域マスタ
 * @export
 * @interface RegionRead
 */
export interface RegionRead {
  /**
   * ID
   * @type {number}
   * @memberof RegionRead
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof RegionRead
   */
  readonly versionNumber: number
  /**
   * ステータス（true: 有効、false: 無効）
   * @type {boolean}
   * @memberof RegionRead
   */
  isActive: boolean
  /**
   * 都道府県ID
   * @type {number}
   * @memberof RegionRead
   */
  prefectureId: number
  /**
   * 地域名
   * @type {string}
   * @memberof RegionRead
   */
  name: string
}

export function RegionReadFromJSON(json: any): RegionRead {
  return RegionReadFromJSONTyped(json, false)
}

export function RegionReadFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegionRead {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isActive: json['is_active'],
    prefectureId: json['prefecture_id'],
    name: json['name'],
  }
}

export function RegionReadToJSON(value?: RegionRead | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    is_active: value.isActive,
    prefecture_id: value.prefectureId,
    name: value.name,
  }
}
