import { createContext, useContext } from 'react'
import { convertToMap, createAryPromise, createMapPromise } from './utils'
import { BrandNormalized } from '../../../../domain/brand/brand'
import { Grade } from '../../../../domain/grade/grade'
import { Annum } from '../../../../domain/annum/annum'
import { OfferPublicationPeriod } from '../../../../domain/offerPublicationPeriod/advanceContract/offerPublicationPeriod'
import { PackagingForm } from '../../../../domain/packagingForm/packagingForm'
import { Prefecture } from '../../../../domain/prefecture/prefecture'
import { RegionNormalized } from '../../../../domain/region/region'
import { SpecialCondition } from '../../../../domain/specialCondition/specialCondition'
import { StoragePeriod } from '../../../../domain/storagePeriod/storagePeriod'
import { FarmingCertification } from '../../../../domain/farmingCertification/farmingCertification'
import { SpotContractAnnum } from '../../../../domain/spotContractAnnum/spotContractAnnum'
import { SpotContractOfferPublicationPeriod } from '../../../../domain/offerPublicationPeriod/spotContract/spotContractOfferPublicationPeriod'
import { SpotContractSpecialCondition } from '../../../../domain/spotContractSpecialCondition/spotContractSpecialCondition'
import { SpotContractStoragePeriod } from '../../../../domain/spotContractStoragePeriod/spotContractStoragePeriod'
import { CommonMasterApi, SpotContractCommonMasterApi } from '../../../../libs/api_client/apis'
import { getApiConfig } from '../../../../config/apiConfig'
import {
  captureException,
  handle401Error,
  handle503Error,
  handleApiServerError,
  handlerNoNetworkError,
} from '../../../../infra/handleApiServerError'
import { isActive } from '../../../../domain/utils'

export type MasterName =
  | 'annums'
  | 'brands'
  | 'farmingCertifications'
  | 'grades'
  | 'offerPublicationPeriods'
  | 'packagingForms'
  | 'prefectures'
  | 'regions'
  | 'specialConditions'
  | 'storagePeriods'
  | 'spotContractAnnums'
  | 'spotContractOfferPublicationPeriods'
  | 'spotContractSpecialConditions'
  | 'spotContractStoragePeriods'

export type MasterList = {
  annums: Annum[]
  brands: BrandNormalized[]
  farmingCertifications: FarmingCertification[]
  grades: Grade[]
  offerPublicationPeriods: OfferPublicationPeriod[]
  packagingForms: PackagingForm[]
  prefectures: Prefecture[]
  regions: RegionNormalized[]
  specialConditions: SpecialCondition[]
  storagePeriods: StoragePeriod[]
  spotContractAnnums: SpotContractAnnum[]
  spotContractOfferPublicationPeriods: SpotContractOfferPublicationPeriod[]
  spotContractSpecialConditions: SpotContractSpecialCondition[]
  spotContractStoragePeriods: SpotContractStoragePeriod[]
}

type ListPromises = {
  annums: Promise<Annum[]>
  brands: Promise<BrandNormalized[]>
  farmingCertifications: Promise<FarmingCertification[]>
  grades: Promise<Grade[]>
  offerPublicationPeriods: Promise<OfferPublicationPeriod[]>
  packagingForms: Promise<PackagingForm[]>
  prefectures: Promise<Prefecture[]>
  regions: Promise<RegionNormalized[]>
  specialConditions: Promise<SpecialCondition[]>
  storagePeriods: Promise<StoragePeriod[]>
  spotContractAnnums: Promise<SpotContractAnnum[]>
  spotContractOfferPublicationPeriods: Promise<SpotContractOfferPublicationPeriod[]>
  spotContractSpecialConditions: Promise<SpotContractSpecialCondition[]>
  spotContractStoragePeriods: Promise<SpotContractStoragePeriod[]>
}

export type ManageMasterContextType = {
  fetchMasters: (targetMasterNames: MasterName[], handleAfter401Error: () => void) => void
  listPromises: ListPromises
  mapPromises: {
    annumMap: Promise<Map<number, Annum>>
    brandMap: Promise<Map<number, BrandNormalized>>
    farmingCertificationMap: Promise<Map<number, FarmingCertification>>
    gradeMap: Promise<Map<number, Grade>>
    offerPublicationPeriodMap: Promise<Map<number, OfferPublicationPeriod>>
    packagingFormMap: Promise<Map<number, PackagingForm>>
    prefectureMap: Promise<Map<number, Prefecture>>
    regionMap: Promise<Map<number, RegionNormalized>>
    specialConditionMap: Promise<Map<number, SpecialCondition>>
    storagePeriodMap: Promise<Map<number, StoragePeriod>>
    spotContractAnnumMap: Promise<Map<number, SpotContractAnnum>>
    spotContractOfferPublicationPeriodMap: Promise<Map<number, SpotContractOfferPublicationPeriod>>
    spotContractSpecialConditionMap: Promise<Map<number, SpotContractSpecialCondition>>
    spotContractStoragePeriodMap: Promise<Map<number, SpotContractStoragePeriod>>
  }
  listPromisesWithoutInactive: ListPromises
}

export const mastersState = manageFetchOfMasters()

export const ManageFetchOfMasterContext = createContext<ManageMasterContextType>(mastersState)

export function useManageFetchOFMasters() {
  return useContext<ManageMasterContextType>(ManageFetchOfMasterContext)
}

function manageFetchOfMasters(): ManageMasterContextType {
  // map
  const [annumMapPromise, resolveAnnumMap] = createMapPromise<Annum>()
  const [brandMapPromise, resolveBrandMap] = createMapPromise<BrandNormalized>()
  const [farmingCertificationMapPromise, resolveFarmingCertificationMap] = createMapPromise<FarmingCertification>()
  const [gradeMapPromise, resolveGradeMap] = createMapPromise<Grade>()
  const [offerPublicationPeriodMapPromise, resolveOfferPublicationPeriodMap] =
    createMapPromise<OfferPublicationPeriod>()
  const [packagingFormMapPromise, resolvePackagingFormMap] = createMapPromise<PackagingForm>()
  const [prefectureMapPromise, resolvePrefectureMap] = createMapPromise<Prefecture>()
  const [regionMapPromise, resolveRegionMap] = createMapPromise<RegionNormalized>()
  const [specialConditionMapPromise, resolveSpecialConditionMap] = createMapPromise<SpecialCondition>()
  const [storagePeriodMapPromise, resolveStoragePeriodMap] = createMapPromise<StoragePeriod>()
  const [spotContractAnnumMapPromise, resolveSpotContractAnnumMap] = createMapPromise<SpotContractAnnum>()
  const [spotContractOfferPublicationPeriodMapPromise, resolveSpotContractOfferPublicationPeriodMap] =
    createMapPromise<SpotContractOfferPublicationPeriod>()
  const [spotContractSpecialConditionMapPromise, resolveSpotContractSpecialConditionMap] =
    createMapPromise<SpotContractSpecialCondition>()
  const [spotContractStoragePeriodMapPromise, resolveSpotContractStoragePeriodMap] =
    createMapPromise<SpotContractStoragePeriod>()

  // ary
  const [annumsPromise, resolveAnnums] = createAryPromise<Annum>()
  const [brandsPromise, resolveBrands] = createAryPromise<BrandNormalized>()
  const [farmingCertificationsPromise, resolveFarmingCertifications] = createAryPromise<FarmingCertification>()
  const [gradesPromise, resolveGrades] = createAryPromise<Grade>()
  const [offerPublicationPeriodsPromise, resolveOfferPublicationPeriods] = createAryPromise<OfferPublicationPeriod>()
  const [packagingFormsPromise, resolvePackagingForms] = createAryPromise<PackagingForm>()
  const [prefecturesPromise, resolvePrefectures] = createAryPromise<Prefecture>()
  const [regionsPromise, resolveRegions] = createAryPromise<RegionNormalized>()
  const [specialConditionsPromise, resolveSpecialConditions] = createAryPromise<SpecialCondition>()
  const [storagePeriodsPromise, resolveStoragePeriods] = createAryPromise<StoragePeriod>()
  const [spotContractAnnumsPromise, resolveSpotContractAnnums] = createAryPromise<SpotContractAnnum>()
  const [spotContractOfferPublicationPeriodsPromise, resolveSpotContractOfferPublicationPeriods] =
    createAryPromise<SpotContractOfferPublicationPeriod>()
  const [spotContractSpecialConditionsPromise, resolveSpotContractSpecialConditions] =
    createAryPromise<SpotContractSpecialCondition>()
  const [spotContractStoragePeriodsPromise, resolveSpotContractStoragePeriods] =
    createAryPromise<SpotContractStoragePeriod>()

  // ary without inactive
  const [annumsWithoutInactivePromise, resolveAnnumsWithoutInactive] = createAryPromise<Annum>()
  const [brandsWithoutInactivePromise, resolveBrandsWithoutInactive] = createAryPromise<BrandNormalized>()
  const [farmingCertificationsWithoutInactivePromise, resolveFarmingCertifcationsWithoutInactive] =
    createAryPromise<FarmingCertification>()
  const [gradesWithoutInactivePromise, resolveGradesWithoutInactive] = createAryPromise<Grade>()
  const [offerPublicationPeriodsWithoutInactivePromise, resolveOfferPublicationPeriodsWithoutInactive] =
    createAryPromise<OfferPublicationPeriod>()
  const [packagingFormsWithoutInactivePromise, resolvePackagingFormsWithoutInactive] = createAryPromise<PackagingForm>()
  const [prefecturesWithoutInactivePromise, resolvePrefecturesWithoutInactive] = createAryPromise<Prefecture>()
  const [regionsWithoutInactivePromise, resolveRegionsWithoutInactive] = createAryPromise<RegionNormalized>()
  const [specialConditionsWithoutInactivePromise, resolveSpecialConditionsWithoutInactive] =
    createAryPromise<SpecialCondition>()
  const [storagePeriodsWithoutInactivePromise, resolveStoragePeriodsWithoutInactive] = createAryPromise<StoragePeriod>()
  const [spotContractAnnumsWithoutInactivePromise, resolveSpotContractAnnumsWithoutInactive] =
    createAryPromise<SpotContractAnnum>()
  const [
    spotContractOfferPublicationPeriodsWithoutInactivePromise,
    resolveSpotContractOfferPublicationPeriodsWithoutInactive,
  ] = createAryPromise<SpotContractOfferPublicationPeriod>()
  const [spotContractSpecialConditionsWithoutInactivePromise, resolveSpotContractSpecialConditionsWithoutInactive] =
    createAryPromise<SpotContractSpecialCondition>()
  const [spotContractStoragePeriodsWithoutInactivePromise, resolveSpotContractStoragePeriodsWithoutInactive] =
    createAryPromise<SpotContractStoragePeriod>()

  const fetchedState = {
    annums: false,
    brands: false,
    farmingCertifications: false,
    grades: false,
    offerPublicationPeriods: false,
    packagingForms: false,
    prefectures: false,
    regions: false,
    specialConditions: false,
    storagePeriods: false,
    spotContractAnnums: false,
    spotContractOfferPublicationPeriods: false,
    spotContractSpecialConditions: false,
    spotContractStoragePeriods: false,
  }

  /**
   * mastersのfetchを行う
   * @param targetMasterNames fetchしたいmasterの名前を配列で渡す。渡したmaster以外はfetchされない
   * @param handleAfter401Error
   */
  async function fetchMasters(targetMasterNames: MasterName[], handleAfter401Error: () => void) {
    const promises: [
      Promise<Annum[]>,
      Promise<BrandNormalized[]>,
      Promise<FarmingCertification[]>,
      Promise<Grade[]>,
      Promise<OfferPublicationPeriod[]>,
      Promise<PackagingForm[]>,
      Promise<Prefecture[]>,
      Promise<RegionNormalized[]>,
      Promise<SpecialCondition[]>,
      Promise<StoragePeriod[]>,
      Promise<SpotContractAnnum[]>,
      Promise<SpotContractOfferPublicationPeriod[]>,
      Promise<SpotContractSpecialCondition[]>,
      Promise<SpotContractStoragePeriod[]>
    ] = [] as any

    /**
     * シチュエーションに応じて、リアルフェッチかフェッチ済かダミーフェッチを行うかを管理する
     * @param masterName
     * @param promise
     * @param fetch
     */
    function manageFetchBefore<T>(masterName: MasterName, promise: Promise<T[]>, fetch: () => Promise<T[]>) {
      if (targetMasterNames.includes(masterName)) {
        if (fetchedState[masterName]) {
          promises.push(promise as any)
        } else {
          promises.push(fetch() as any)
          fetchedState[masterName] = true
        }
      } else {
        promises.push(Promise.resolve([]))
      }
    }

    /**
     * 取得した値をarrayとmapとしてresolveする
     * targetsに含まれていなければ、resolveされないので、resolveしたいものは必ずtargetsとして渡す必要がある
     * @param masterName
     * @param values
     * @param resolve
     * @param resolveMap
     * @param resolveWithoutInactive
     */
    function manageFetchAfter<T extends { id: number; isActive: boolean }>(
      masterName: MasterName,
      values: T[],
      resolve: (values: T[]) => void,
      resolveMap: (map: Map<number, T>) => void,
      resolveWithoutInactive: (values: T[]) => void
    ) {
      if (targetMasterNames.includes(masterName)) {
        const valuesMap = convertToMap<T>(values)
        resolve(values)
        resolveMap(valuesMap)
        resolveWithoutInactive(values.filter(isActive))
      }
    }

    const commonMasterApi = new CommonMasterApi(getApiConfig())
    const spotContractCommonMasterApi = new SpotContractCommonMasterApi(getApiConfig())

    const getAnnums: () => Promise<Annum[]> = () => commonMasterApi.getAnnums()
    manageFetchBefore<Annum>('annums', annumsPromise, getAnnums)

    const getBrands: () => Promise<BrandNormalized[]> = () => commonMasterApi.getBrands()
    manageFetchBefore<BrandNormalized>('brands', brandsPromise, getBrands)

    const getFarmingCertifications: () => Promise<FarmingCertification[]> = () =>
      commonMasterApi.getFarmingCertifications()
    manageFetchBefore<FarmingCertification>(
      'farmingCertifications',
      farmingCertificationsPromise,
      getFarmingCertifications
    )

    const getGrades: () => Promise<Grade[]> = () => commonMasterApi.getGrades()
    manageFetchBefore<Grade>('grades', gradesPromise, getGrades)

    const getOfferPublicationPeriods: () => Promise<OfferPublicationPeriod[]> = () =>
      commonMasterApi.getOfferPublicationPeriods()
    manageFetchBefore<OfferPublicationPeriod>(
      'offerPublicationPeriods',
      offerPublicationPeriodsPromise,
      getOfferPublicationPeriods
    )

    const getPackagingForms: () => Promise<PackagingForm[]> = () => commonMasterApi.getPackagingForms()
    manageFetchBefore<PackagingForm>('packagingForms', packagingFormsPromise, getPackagingForms)

    const getPrefectures: () => Promise<Prefecture[]> = () => commonMasterApi.getPrefectures()
    manageFetchBefore<Prefecture>('prefectures', prefecturesPromise, getPrefectures)

    const getRegions: () => Promise<RegionNormalized[]> = () => commonMasterApi.getRegions()
    manageFetchBefore<RegionNormalized>('regions', regionsPromise, getRegions)

    const getSpecialConditions: () => Promise<SpecialCondition[]> = () => commonMasterApi.getSpecialConditions()
    manageFetchBefore<SpecialCondition>('specialConditions', specialConditionsPromise, getSpecialConditions)

    const getStoragePeriods: () => Promise<StoragePeriod[]> = () => commonMasterApi.getStoragePeriods()
    manageFetchBefore<StoragePeriod>('storagePeriods', storagePeriodsPromise, getStoragePeriods)

    const getSpotContractAnnums: () => Promise<SpotContractAnnum[]> = () =>
      spotContractCommonMasterApi.getSpotContractAnnums()
    manageFetchBefore<SpotContractAnnum>('spotContractAnnums', spotContractAnnumsPromise, getSpotContractAnnums)

    const getSpotContractOfferPublicationPeriods: () => Promise<SpotContractOfferPublicationPeriod[]> = () =>
      spotContractCommonMasterApi.getSpotContractOfferPublicationPeriods()
    manageFetchBefore<SpotContractOfferPublicationPeriod>(
      'spotContractOfferPublicationPeriods',
      spotContractOfferPublicationPeriodsPromise,
      getSpotContractOfferPublicationPeriods
    )

    const getSpotContractSpecialConditions: () => Promise<SpotContractSpecialCondition[]> = () =>
      spotContractCommonMasterApi.getSpotContractSpecialConditions()
    manageFetchBefore<SpotContractSpecialCondition>(
      'spotContractSpecialConditions',
      spotContractSpecialConditionsPromise,
      getSpotContractSpecialConditions
    )

    const getSpotContractStoragePeriods: () => Promise<SpotContractStoragePeriod[]> = () =>
      spotContractCommonMasterApi.getSpotContractStoragePeriods()
    manageFetchBefore<SpotContractStoragePeriod>(
      'spotContractStoragePeriods',
      spotContractStoragePeriodsPromise,
      getSpotContractStoragePeriods
    )

    try {
      // 型合わせのための冗長な代入
      const resp = await Promise.all(promises)
      const [
        annums,
        brands,
        farmingCertifications,
        grades,
        offerPublicationPeriods,
        packagingForms,
        prefectures,
        regions,
        specialConditions,
        storagePeriods,
        spotContractAnnums,
        spotContractOfferPublicationPeriods,
        spotContractSpecialConditions,
        spotContractStoragePeriods,
      ] = resp as [
        Annum[],
        BrandNormalized[],
        FarmingCertification[],
        Grade[],
        OfferPublicationPeriod[],
        PackagingForm[],
        Prefecture[],
        RegionNormalized[],
        SpecialCondition[],
        StoragePeriod[],
        SpotContractAnnum[],
        SpotContractOfferPublicationPeriod[],
        SpotContractSpecialCondition[],
        SpotContractStoragePeriod[]
      ]

      manageFetchAfter<Annum>('annums', annums, resolveAnnums, resolveAnnumMap, resolveAnnumsWithoutInactive)
      manageFetchAfter<BrandNormalized>('brands', brands, resolveBrands, resolveBrandMap, resolveBrandsWithoutInactive)
      manageFetchAfter<FarmingCertification>(
        'farmingCertifications',
        farmingCertifications,
        resolveFarmingCertifications,
        resolveFarmingCertificationMap,
        resolveFarmingCertifcationsWithoutInactive
      )
      manageFetchAfter<Grade>('grades', grades, resolveGrades, resolveGradeMap, resolveGradesWithoutInactive)
      manageFetchAfter<OfferPublicationPeriod>(
        'offerPublicationPeriods',
        offerPublicationPeriods,
        resolveOfferPublicationPeriods,
        resolveOfferPublicationPeriodMap,
        resolveOfferPublicationPeriodsWithoutInactive
      )
      manageFetchAfter<PackagingForm>(
        'packagingForms',
        packagingForms,
        resolvePackagingForms,
        resolvePackagingFormMap,
        resolvePackagingFormsWithoutInactive
      )
      manageFetchAfter<Prefecture>(
        'prefectures',
        prefectures,
        resolvePrefectures,
        resolvePrefectureMap,
        resolvePrefecturesWithoutInactive
      )
      manageFetchAfter<RegionNormalized>(
        'regions',
        regions,
        resolveRegions,
        resolveRegionMap,
        resolveRegionsWithoutInactive
      )
      manageFetchAfter<SpecialCondition>(
        'specialConditions',
        specialConditions,
        resolveSpecialConditions,
        resolveSpecialConditionMap,
        resolveSpecialConditionsWithoutInactive
      )
      manageFetchAfter<StoragePeriod>(
        'storagePeriods',
        storagePeriods,
        resolveStoragePeriods,
        resolveStoragePeriodMap,
        resolveStoragePeriodsWithoutInactive
      )
      manageFetchAfter<SpotContractAnnum>(
        'spotContractAnnums',
        spotContractAnnums,
        resolveSpotContractAnnums,
        resolveSpotContractAnnumMap,
        resolveSpotContractAnnumsWithoutInactive
      )

      manageFetchAfter<SpotContractOfferPublicationPeriod>(
        'spotContractOfferPublicationPeriods',
        spotContractOfferPublicationPeriods,
        resolveSpotContractOfferPublicationPeriods,
        resolveSpotContractOfferPublicationPeriodMap,
        resolveSpotContractOfferPublicationPeriodsWithoutInactive
      )

      manageFetchAfter<SpotContractSpecialCondition>(
        'spotContractSpecialConditions',
        spotContractSpecialConditions,
        resolveSpotContractSpecialConditions,
        resolveSpotContractSpecialConditionMap,
        resolveSpotContractSpecialConditionsWithoutInactive
      )

      manageFetchAfter<SpotContractStoragePeriod>(
        'spotContractStoragePeriods',
        spotContractStoragePeriods,
        resolveSpotContractStoragePeriods,
        resolveSpotContractStoragePeriodMap,
        resolveSpotContractStoragePeriodsWithoutInactive
      )
    } catch (err) {
      if (err instanceof Response) {
        handle503Error(err)
        handleApiServerError(err, captureException)
        handle401Error()(err, handleAfter401Error)
        handlerNoNetworkError(err)
      }
    }
  }

  return {
    fetchMasters,
    listPromises: {
      annums: annumsPromise,
      brands: brandsPromise,
      farmingCertifications: farmingCertificationsPromise,
      grades: gradesPromise,
      offerPublicationPeriods: offerPublicationPeriodsPromise,
      packagingForms: packagingFormsPromise,
      prefectures: prefecturesPromise,
      regions: regionsPromise,
      specialConditions: specialConditionsPromise,
      storagePeriods: storagePeriodsPromise,
      spotContractAnnums: spotContractAnnumsPromise,
      spotContractOfferPublicationPeriods: spotContractOfferPublicationPeriodsPromise,
      spotContractSpecialConditions: spotContractSpecialConditionsPromise,
      spotContractStoragePeriods: spotContractStoragePeriodsPromise,
    },
    mapPromises: {
      annumMap: annumMapPromise,
      brandMap: brandMapPromise,
      farmingCertificationMap: farmingCertificationMapPromise,
      gradeMap: gradeMapPromise,
      offerPublicationPeriodMap: offerPublicationPeriodMapPromise,
      packagingFormMap: packagingFormMapPromise,
      prefectureMap: prefectureMapPromise,
      regionMap: regionMapPromise,
      specialConditionMap: specialConditionMapPromise,
      storagePeriodMap: storagePeriodMapPromise,
      spotContractAnnumMap: spotContractAnnumMapPromise,
      spotContractOfferPublicationPeriodMap: spotContractOfferPublicationPeriodMapPromise,
      spotContractSpecialConditionMap: spotContractSpecialConditionMapPromise,
      spotContractStoragePeriodMap: spotContractStoragePeriodMapPromise,
    },
    listPromisesWithoutInactive: {
      annums: annumsWithoutInactivePromise,
      brands: brandsWithoutInactivePromise,
      farmingCertifications: farmingCertificationsWithoutInactivePromise,
      grades: gradesWithoutInactivePromise,
      offerPublicationPeriods: offerPublicationPeriodsWithoutInactivePromise,
      packagingForms: packagingFormsWithoutInactivePromise,
      prefectures: prefecturesWithoutInactivePromise,
      regions: regionsWithoutInactivePromise,
      specialConditions: specialConditionsWithoutInactivePromise,
      storagePeriods: storagePeriodsWithoutInactivePromise,
      spotContractAnnums: spotContractAnnumsWithoutInactivePromise,
      spotContractOfferPublicationPeriods: spotContractOfferPublicationPeriodsWithoutInactivePromise,
      spotContractSpecialConditions: spotContractSpecialConditionsWithoutInactivePromise,
      spotContractStoragePeriods: spotContractStoragePeriodsWithoutInactivePromise,
    },
  }
}
