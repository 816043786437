/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 生産者の基本情報
 * @export
 * @interface ProducerBasicProfile
 */
export interface ProducerBasicProfile {
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof ProducerBasicProfile
   */
  versionNumber: number
  /**
   * 地域ID
   * @type {number}
   * @memberof ProducerBasicProfile
   */
  regionId: number | null
  /**
   * 保管可能期間ID
   * @type {number}
   * @memberof ProducerBasicProfile
   */
  storagePeriodId: number | null
  /**
   * 有機JAS等の取り組みIDリスト（空可）
   * @type {Array<number>}
   * @memberof ProducerBasicProfile
   */
  farmingCertificationIds: Array<number>
  /**
   * 適格請求書発行事業者登録状況（"registered": 取得済、"will_register": 取得予定, "wont_register": 取得しない、"pending": 未定/わからない）
   * @type {string}
   * @memberof ProducerBasicProfile
   */
  invoiceRegistrationStatus: ProducerBasicProfileInvoiceRegistrationStatusEnum
  /**
   * 適格請求書発行事業者登録番号（頭の「T」を除いた13桁の数字）
   * @type {string}
   * @memberof ProducerBasicProfile
   */
  invoiceRegistrationNumber: string | null
  /**
   * 適格請求書発行事業者登録の予定時期（nullは「時期未定」）
   * @type {Date}
   * @memberof ProducerBasicProfile
   */
  expectedRegistrationTimeframe: Date | null
}

/**
 * @export
 * @enum {string}
 */
export enum ProducerBasicProfileInvoiceRegistrationStatusEnum {
  Registered = 'registered',
  WillRegister = 'will_register',
  WontRegister = 'wont_register',
  Pending = 'pending',
}

export function ProducerBasicProfileFromJSON(json: any): ProducerBasicProfile {
  return ProducerBasicProfileFromJSONTyped(json, false)
}

export function ProducerBasicProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProducerBasicProfile {
  if (json === undefined || json === null) {
    return json
  }
  return {
    versionNumber: json['version_number'],
    regionId: json['region_id'],
    storagePeriodId: json['storage_period_id'],
    farmingCertificationIds: json['farming_certification_ids'],
    invoiceRegistrationStatus: json['invoice_registration_status'],
    invoiceRegistrationNumber: json['invoice_registration_number'],
    expectedRegistrationTimeframe:
      json['expected_registration_timeframe'] === null ? null : new Date(json['expected_registration_timeframe']),
  }
}

export function ProducerBasicProfileToJSON(value?: ProducerBasicProfile | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    version_number: value.versionNumber,
    region_id: value.regionId,
    storage_period_id: value.storagePeriodId,
    farming_certification_ids: value.farmingCertificationIds,
    invoice_registration_status: value.invoiceRegistrationStatus,
    invoice_registration_number: value.invoiceRegistrationNumber,
    expected_registration_timeframe:
      value.expectedRegistrationTimeframe === null
        ? null
        : value.expectedRegistrationTimeframe.toISOString().substr(0, 10),
  }
}
