/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * [現物契約] 年産
 * @export
 * @interface SpotContractAnnum
 */
export interface SpotContractAnnum {
  /**
   * ID
   * @type {number}
   * @memberof SpotContractAnnum
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof SpotContractAnnum
   */
  readonly versionNumber: number
  /**
   * ステータス（true: 有効、false: 無効）
   * @type {boolean}
   * @memberof SpotContractAnnum
   */
  isActive: boolean
  /**
   * 年産名
   * @type {string}
   * @memberof SpotContractAnnum
   */
  name: string
  /**
   * 有効期間（開始）
   * @type {Date}
   * @memberof SpotContractAnnum
   */
  activePeriodStart: Date
  /**
   * 有効期間（終了）
   * @type {Date}
   * @memberof SpotContractAnnum
   */
  activePeriodEnd: Date
  /**
   * 西暦
   * @type {number}
   * @memberof SpotContractAnnum
   */
  year: number
}

export function SpotContractAnnumFromJSON(json: any): SpotContractAnnum {
  return SpotContractAnnumFromJSONTyped(json, false)
}

export function SpotContractAnnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpotContractAnnum {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isActive: json['is_active'],
    name: json['name'],
    activePeriodStart: new Date(json['active_period_start']),
    activePeriodEnd: new Date(json['active_period_end']),
    year: json['year'],
  }
}

export function SpotContractAnnumToJSON(value?: SpotContractAnnum | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    is_active: value.isActive,
    name: value.name,
    active_period_start: value.activePeriodStart.toISOString().substr(0, 10),
    active_period_end: value.activePeriodEnd.toISOString().substr(0, 10),
    year: value.year,
  }
}
