/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  Deal,
  DealFromJSON,
  DealToJSON,
  DealRead,
  DealReadFromJSON,
  DealReadToJSON,
  DealsUpdate,
  DealsUpdateFromJSON,
  DealsUpdateToJSON,
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
} from '../models'

export interface GetDealsRequest {
  filters?: object
}

export interface GetDealsIdRequest {
  id: number
}

export interface PostOffersIdDealsRequest {
  id: number
  deal?: Deal
}

export interface PutDealsIdRequest {
  id: number
  dealsUpdate?: DealsUpdate
}

/**
 *
 */
export class DealsApi extends runtime.BaseAPI {
  /**
   * ディール一覧を取得する
   */
  async getDealsRaw(requestParameters: GetDealsRequest): Promise<runtime.ApiResponse<Array<DealRead>>> {
    const queryParameters: any = {}

    if (requestParameters.filters !== undefined) {
      queryParameters['_filters'] = requestParameters.filters
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/deals`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DealReadFromJSON))
  }

  /**
   * ディール一覧を取得する
   */
  async getDeals(requestParameters: GetDealsRequest): Promise<Array<DealRead>> {
    const response = await this.getDealsRaw(requestParameters)
    return await response.value()
  }

  /**
   * 指定IDに該当するディールの情報を取得する
   */
  async getDealsIdRaw(requestParameters: GetDealsIdRequest): Promise<runtime.ApiResponse<DealRead>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getDealsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/deals/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => DealReadFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当するディールの情報を取得する
   */
  async getDealsId(requestParameters: GetDealsIdRequest): Promise<DealRead> {
    const response = await this.getDealsIdRaw(requestParameters)
    return await response.value()
  }

  /**
   * 指定IDに該当するオファーのディールに回答する
   */
  async postOffersIdDealsRaw(requestParameters: PostOffersIdDealsRequest): Promise<runtime.ApiResponse<DealRead>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling postOffersIdDeals.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/offers/{id}/deals`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: DealToJSON(requestParameters.deal),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => DealReadFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当するオファーのディールに回答する
   */
  async postOffersIdDeals(requestParameters: PostOffersIdDealsRequest): Promise<DealRead> {
    const response = await this.postOffersIdDealsRaw(requestParameters)
    return await response.value()
  }

  /**
   * 指定IDに該当するディールの情報を編集する  producer_agreement_statusのみ指定できる
   */
  async putDealsIdRaw(requestParameters: PutDealsIdRequest): Promise<runtime.ApiResponse<DealRead>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putDealsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/deals/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: DealsUpdateToJSON(requestParameters.dealsUpdate),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => DealReadFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当するディールの情報を編集する  producer_agreement_statusのみ指定できる
   */
  async putDealsId(requestParameters: PutDealsIdRequest): Promise<DealRead> {
    const response = await this.putDealsIdRaw(requestParameters)
    return await response.value()
  }
}
