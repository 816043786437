import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

export function useScrollToTopWhenPageChanged() {
  const location = useLocation()

  useEffect(() => {
    const el = document.querySelector('html')

    if (el) {
      el.scrollTop = 0
    }
  }, [location.pathname])
}
