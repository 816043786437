import styled from '@emotion/styled'

import { OuterHeader } from './OuterHeader'
import { HeaderWithVisibilityManager } from './InnerHeader/VisibilityManager'

export const InternalContainer = () => {
  return (
    <Header>
      <OuterHeader />
      <HeaderWithVisibilityManager />
    </Header>
  )
}

const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
`
