import styled from '@emotion/styled'

import { Menu } from './Menu'

import { theme } from '../../../theme/theme'

export const MenuContainer = () => {
  const { innerHeaderHeight, outerHeaderHeight } = theme.sizes.brand
  return (
    <MenuBox innerHeaderHeight={innerHeaderHeight} outerHeaderHeight={outerHeaderHeight}>
      <Menu />
    </MenuBox>
  )
}

const MenuBox = styled.div<{ innerHeaderHeight: string; outerHeaderHeight: string }>`
  position: sticky;
  top: calc(${(props) => props.innerHeaderHeight + ' + ' + props.outerHeaderHeight});
  margin-left: 40px;
`
