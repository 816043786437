import { RegionNormalized } from './region'

export const RegionFilterService = {
  inPrefecture,
}
/**
 * 地域が対象の都道府県のものかどうかを返す
 * @param prefectureId
 */
export function inPrefecture(prefectureId: number) {
  return function (region: RegionNormalized): boolean {
    return region.prefectureId === prefectureId
  }
}
