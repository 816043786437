import { PutProducersMeTosAgreementRequest, ProducersApi } from '../libs/api_client/apis'
import { getApiConfig } from '../config/apiConfig'
import { ProducerTosAgreementEdit } from '../domain/producerTosAgreement/producerTosAgreement'

/**
 * PUT /producers/me/tos_agreement
 * @param validated
 */
export const putProducerTosAgreement = async (validated: ProducerTosAgreementEdit): Promise<void> => {
  const param: PutProducersMeTosAgreementRequest = {
    producerTosAgreementUpdateSeller: validated,
  }

  const producersApi = new ProducersApi(getApiConfig())
  await producersApi.putProducersMeTosAgreement(param)

  return
}
