import styled from '@emotion/styled'

export const UsageIcon = () => (
  <Svg width="30.001" height="30.001" viewBox="0 0 30.001 30.001">
    <g>
      <path
        className="cls-3"
        d="M79.2,27.494,72.7,21.2a2.654,2.654,0,0,0-4.5,1.91V36.822a3.984,3.984,0,0,0,1.213,2.859l7.945,7.688a2.654,2.654,0,0,0,3.691,0L89,39.688a3.984,3.984,0,0,0,1.213-2.859V23.111a2.654,2.654,0,0,0-4.5-1.91Zm7.254,8.865a1.324,1.324,0,0,1-.4.955L79.2,43.941V32.7l1.3-1.26,4.828-4.67a.662.662,0,0,1,1.125.475Z"
        transform="translate(-64.204 -19.255)"
      />
    </g>
  </Svg>
)

const Svg = styled.svg`
  .cls-3 {
    fill: #35363a;
  }
`
