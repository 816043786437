/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  AnnumRead,
  AnnumReadFromJSON,
  AnnumReadToJSON,
  BrandRead,
  BrandReadFromJSON,
  BrandReadToJSON,
  FarmingCertificationRead,
  FarmingCertificationReadFromJSON,
  FarmingCertificationReadToJSON,
  GradeRead,
  GradeReadFromJSON,
  GradeReadToJSON,
  OfferPublicationPeriodRead,
  OfferPublicationPeriodReadFromJSON,
  OfferPublicationPeriodReadToJSON,
  PackagingFormRead,
  PackagingFormReadFromJSON,
  PackagingFormReadToJSON,
  PrefectureRead,
  PrefectureReadFromJSON,
  PrefectureReadToJSON,
  RegionRead,
  RegionReadFromJSON,
  RegionReadToJSON,
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
  SpecialConditionRead,
  SpecialConditionReadFromJSON,
  SpecialConditionReadToJSON,
  StoragePeriodRead,
  StoragePeriodReadFromJSON,
  StoragePeriodReadToJSON,
  TosReadSeller,
  TosReadSellerFromJSON,
  TosReadSellerToJSON,
} from '../models'

/**
 *
 */
export class CommonMasterApi extends runtime.BaseAPI {
  /**
   * 年産一覧を取得する
   */
  async getAnnumsRaw(): Promise<runtime.ApiResponse<Array<AnnumRead>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/annums`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AnnumReadFromJSON))
  }

  /**
   * 年産一覧を取得する
   */
  async getAnnums(): Promise<Array<AnnumRead>> {
    const response = await this.getAnnumsRaw()
    return await response.value()
  }

  /**
   * 銘柄一覧を取得する
   */
  async getBrandsRaw(): Promise<runtime.ApiResponse<Array<BrandRead>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/brands`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BrandReadFromJSON))
  }

  /**
   * 銘柄一覧を取得する
   */
  async getBrands(): Promise<Array<BrandRead>> {
    const response = await this.getBrandsRaw()
    return await response.value()
  }

  /**
   * 有機JAS等の取り組み一覧を取得する
   */
  async getFarmingCertificationsRaw(): Promise<runtime.ApiResponse<Array<FarmingCertificationRead>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/farming_certifications`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FarmingCertificationReadFromJSON))
  }

  /**
   * 有機JAS等の取り組み一覧を取得する
   */
  async getFarmingCertifications(): Promise<Array<FarmingCertificationRead>> {
    const response = await this.getFarmingCertificationsRaw()
    return await response.value()
  }

  /**
   * 等級一覧を取得する
   */
  async getGradesRaw(): Promise<runtime.ApiResponse<Array<GradeRead>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/grades`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GradeReadFromJSON))
  }

  /**
   * 等級一覧を取得する
   */
  async getGrades(): Promise<Array<GradeRead>> {
    const response = await this.getGradesRaw()
    return await response.value()
  }

  /**
   * オファー公開期間一覧を取得する
   */
  async getOfferPublicationPeriodsRaw(): Promise<runtime.ApiResponse<Array<OfferPublicationPeriodRead>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/offer_publication_periods`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferPublicationPeriodReadFromJSON))
  }

  /**
   * オファー公開期間一覧を取得する
   */
  async getOfferPublicationPeriods(): Promise<Array<OfferPublicationPeriodRead>> {
    const response = await this.getOfferPublicationPeriodsRaw()
    return await response.value()
  }

  /**
   * 包装形態一覧を取得する
   */
  async getPackagingFormsRaw(): Promise<runtime.ApiResponse<Array<PackagingFormRead>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/packaging_forms`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PackagingFormReadFromJSON))
  }

  /**
   * 包装形態一覧を取得する
   */
  async getPackagingForms(): Promise<Array<PackagingFormRead>> {
    const response = await this.getPackagingFormsRaw()
    return await response.value()
  }

  /**
   * 都道府県一覧を取得する（新規登録時に必要なため、認証なしでも取得できる）
   */
  async getPrefecturesRaw(): Promise<runtime.ApiResponse<Array<PrefectureRead>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request({
      path: `/prefectures`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PrefectureReadFromJSON))
  }

  /**
   * 都道府県一覧を取得する（新規登録時に必要なため、認証なしでも取得できる）
   */
  async getPrefectures(): Promise<Array<PrefectureRead>> {
    const response = await this.getPrefecturesRaw()
    return await response.value()
  }

  /**
   * 地域一覧を取得する
   */
  async getRegionsRaw(): Promise<runtime.ApiResponse<Array<RegionRead>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/regions`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RegionReadFromJSON))
  }

  /**
   * 地域一覧を取得する
   */
  async getRegions(): Promise<Array<RegionRead>> {
    const response = await this.getRegionsRaw()
    return await response.value()
  }

  /**
   * 特別条件一覧を取得する
   */
  async getSpecialConditionsRaw(): Promise<runtime.ApiResponse<Array<SpecialConditionRead>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/special_conditions`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpecialConditionReadFromJSON))
  }

  /**
   * 特別条件一覧を取得する
   */
  async getSpecialConditions(): Promise<Array<SpecialConditionRead>> {
    const response = await this.getSpecialConditionsRaw()
    return await response.value()
  }

  /**
   * 保管期間一覧を取得する
   */
  async getStoragePeriodsRaw(): Promise<runtime.ApiResponse<Array<StoragePeriodRead>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/storage_periods`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StoragePeriodReadFromJSON))
  }

  /**
   * 保管期間一覧を取得する
   */
  async getStoragePeriods(): Promise<Array<StoragePeriodRead>> {
    const response = await this.getStoragePeriodsRaw()
    return await response.value()
  }

  /**
   * 最新の利用規約の情報を取得する
   */
  async getTosRaw(): Promise<runtime.ApiResponse<TosReadSeller>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request({
      path: `/tos`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => TosReadSellerFromJSON(jsonValue))
  }

  /**
   * 最新の利用規約の情報を取得する
   */
  async getTos(): Promise<TosReadSeller> {
    const response = await this.getTosRaw()
    return await response.value()
  }
}
