/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PaymentReadSellerAdjustments
 */
export interface PaymentReadSellerAdjustments {
  /**
   * 調整金額内容（一覧に表示する項目名的な）
   * @type {string}
   * @memberof PaymentReadSellerAdjustments
   */
  title: string
  /**
   * 調整金額, 円（税込み）
   * @type {string}
   * @memberof PaymentReadSellerAdjustments
   */
  adjustmentInYen: string
  /**
   * 税率, %
   * @type {string}
   * @memberof PaymentReadSellerAdjustments
   */
  taxRate: string
  /**
   * 売買代金の調整金額かどうか表すフラグ（trueの調整金額は生産者にも見える）
   * @type {boolean}
   * @memberof PaymentReadSellerAdjustments
   */
  isProductAdjustment: boolean
  /**
   * メモ
   * @type {string}
   * @memberof PaymentReadSellerAdjustments
   */
  memo: string
}

export function PaymentReadSellerAdjustmentsFromJSON(json: any): PaymentReadSellerAdjustments {
  return PaymentReadSellerAdjustmentsFromJSONTyped(json, false)
}

export function PaymentReadSellerAdjustmentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentReadSellerAdjustments {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: json['title'],
    adjustmentInYen: json['adjustment_in_yen'],
    taxRate: json['tax_rate'],
    isProductAdjustment: json['is_product_adjustment'],
    memo: json['memo'],
  }
}

export function PaymentReadSellerAdjustmentsToJSON(value?: PaymentReadSellerAdjustments | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: value.title,
    adjustment_in_yen: value.adjustmentInYen,
    tax_rate: value.taxRate,
    is_product_adjustment: value.isProductAdjustment,
    memo: value.memo,
  }
}
