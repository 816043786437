/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ProducerAccountRead,
  ProducerAccountReadFromJSON,
  ProducerAccountReadFromJSONTyped,
  ProducerAccountReadToJSON,
  ProducerReadSeller,
  ProducerReadSellerFromJSON,
  ProducerReadSellerFromJSONTyped,
  ProducerReadSellerToJSON,
} from './'

/**
 * ログイン中の生産者アカウント情報
 * @export
 * @interface LoginInfo
 */
export interface LoginInfo {
  /**
   *
   * @type {ProducerReadSeller}
   * @memberof LoginInfo
   */
  producer: ProducerReadSeller
  /**
   *
   * @type {ProducerAccountRead}
   * @memberof LoginInfo
   */
  producerAccount: ProducerAccountRead
  /**
   * 利用規約に同意が必要かどうか
   *
   * 最新の利用規約にまだ同意していない場合はtrue
   * @type {boolean}
   * @memberof LoginInfo
   */
  isTosAgreementRequired: boolean
}

export function LoginInfoFromJSON(json: any): LoginInfo {
  return LoginInfoFromJSONTyped(json, false)
}

export function LoginInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    producer: ProducerReadSellerFromJSON(json['producer']),
    producerAccount: ProducerAccountReadFromJSON(json['producer_account']),
    isTosAgreementRequired: json['is_tos_agreement_required'],
  }
}

export function LoginInfoToJSON(value?: LoginInfo | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    producer: ProducerReadSellerToJSON(value.producer),
    producer_account: ProducerAccountReadToJSON(value.producerAccount),
    is_tos_agreement_required: value.isTosAgreementRequired,
  }
}
