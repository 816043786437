/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * [現物契約] ディール
 * @export
 * @interface SpotContractDeal
 */
export interface SpotContractDeal {
  /**
   * 納品物の包装形態ID
   * @type {number}
   * @memberof SpotContractDeal
   */
  packagingFormId: number
  /**
   * 取引口数
   * @type {number}
   * @memberof SpotContractDeal
   */
  tradingUnits: number
  /**
   * 増額される金額（円）
   * @type {number}
   * @memberof SpotContractDeal
   */
  unitPriceAdjustmentInYen: number
  /**
   * [現物契約] 成約後保管可能期間ID
   * @type {number}
   * @memberof SpotContractDeal
   */
  storagePeriodId: number
}

export function SpotContractDealFromJSON(json: any): SpotContractDeal {
  return SpotContractDealFromJSONTyped(json, false)
}

export function SpotContractDealFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpotContractDeal {
  if (json === undefined || json === null) {
    return json
  }
  return {
    packagingFormId: json['packaging_form_id'],
    tradingUnits: json['trading_units'],
    unitPriceAdjustmentInYen: json['unit_price_adjustment_in_yen'],
    storagePeriodId: json['storage_period_id'],
  }
}

export function SpotContractDealToJSON(value?: SpotContractDeal | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    packaging_form_id: value.packagingFormId,
    trading_units: value.tradingUnits,
    unit_price_adjustment_in_yen: value.unitPriceAdjustmentInYen,
    storage_period_id: value.storagePeriodId,
  }
}
