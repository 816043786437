import styled from '@emotion/styled'

export const OfferIcon = ({ height }: { height?: number }) => {
  const defaultWidth = 28
  const defaultHeight = 30
  const width = typeof height === 'number' ? defaultWidth * (height / defaultHeight) : defaultWidth
  height = typeof height === 'number' ? height : defaultHeight
  return (
    <Svg width={width} height={height} viewBox="0 0 28 30">
      <rect className="cls-3" x="4.85" y="8.34" width="10.62" height="1.2" />
      <rect className="cls-3" x="4.85" y="13.88" width="10.62" height="1.2" />
      <rect className="cls-3" x="4.85" y="17.02" width="10.62" height="1.2" />
      <rect className="cls-3" x="4.85" y="20.2" width="10.62" height="1.2" />
      <rect className="cls-3" x="4.85" y="24.01" width="8.69" height="1.2" />
      <rect className="cls-3" x="4.85" y="10.86" width="5.39" height="1.2" />
      <path className="cls-3" d="m20.1,4.52c-.04-.05-.09-.11-.15-.15.05.04.1.09.15.15Z" />
      <path className="cls-3" d="m.59,29.85c-.05-.02-.11-.05-.16-.09.05.04.11.06.16.09Z" />
      <path
        className="cls-3"
        d="m27.93.77h0c-.01-.06-.03-.12-.06-.18h0s-.02-.06-.04-.08c-.03-.05-.06-.11-.11-.15-.18-.22-.44-.35-.75-.35H8.6c-.53,0-.97.43-.97.97v1.02h-2.87c-.53,0-.97.43-.97.97v1.19H.97C.44,4.15,0,4.58,0,5.12v23.84C0,29.23.11,29.47.29,29.64c.04.04.09.08.15.12s.11.06.16.09c.03.01.06.02.09.04.09.03.19.04.29.04h19.34v-2.59l-1.57-2.42v3.43s-17.16,0-17.16,0V5.72h17.16v16.12h1.33v-6.99c0-.25.08-.49.23-.68V5.12c0-.1-.02-.2-.04-.29-.01-.03-.02-.06-.04-.09-.02-.06-.05-.11-.09-.16-.05-.08-.12-.15-.19-.2-.17-.14-.38-.22-.61-.22H5.36v-.59h17.16v10.17h1.57V2.95c0-.06,0-.13-.02-.19-.01-.06-.03-.12-.06-.18-.01-.03-.02-.06-.04-.08-.03-.05-.06-.11-.11-.15-.18-.22-.44-.35-.75-.35h-13.92v-.43h17.16v20.27s1.47.02,1.57.05V.96c0-.06,0-.13-.02-.19h0Z"
      />
      <polygon
        className="cls-3"
        points="25.21 14.84 21.19 14.84 21.19 22.94 18.77 22.94 23.2 29.74 27.62 22.94 25.21 22.94 25.21 14.84"
      />
      <path
        className="cls-2"
        d="m17.09,26.85h-.52c-.26,0-.26.02-.52.02s-.26.05-.52.05-.26-.06-.52-.06c-.26.06-.52-.09-.58-.35-.02-.08-.02-.16,0-.24,0-.23-.01-.23-.01-.47s-.05-.23-.05-.47.05-.23.05-.47-.05-.23-.05-.47.04-.23.04-.47c0-.16.08-.31.2-.42.11-.12.26-.2.42-.2.26,0,.26,0,.52,0s.26.02.52.02.26-.04.52-.04.26,0,.52,0c.33.06.58.32.64.64,0,.23-.01.23-.01.47s-.03.23-.03.47.05.23.05.47-.01.23-.01.47-.05.23-.05.47c-.01.32-.27.57-.59.58"
      />
      <path
        className="cls-1"
        d="m16.61,24.57c-.06,0-.11-.05-.11-.11s.05-.11.11-.11c.06,0,.11.05.11.11h0c0,.06-.05.11-.11.11m-1.11.01c-.06,0-.11-.05-.11-.11s.05-.11.11-.11c.06,0,.11.05.11.11h0c0,.06-.05.11-.11.11m1.39.22s-.06-.04-.09-.05c.16-.11.21-.33.11-.49s-.33-.21-.49-.11c-.15.1-.2.3-.12.46-.08,0-.16-.01-.24-.01-.08,0-.16,0-.23.01.02-.05.04-.1.04-.15,0-.2-.16-.36-.36-.36s-.36.16-.36.36c0,.12.05.22.15.29-.02.01-.05.03-.08.05-.19.11-.3.32-.3.54v.05h0c.75.05,1.5.05,2.25,0h0v-.05c.01-.23-.11-.44-.29-.55m-1.95.75c.05.14.14.26.27.34.53.26,1.15.26,1.67,0,.13-.08.22-.2.27-.34-.73.13-1.47.13-2.21,0"
      />
    </Svg>
  )
}

const Svg = styled.svg`
  .cls-1 {
    fill: #fff;
  }

  .cls-2 {
    fill: #c30e23;
  }

  .cls-3 {
    fill: #35363a;
  }
`
