/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  IdOnly,
  IdOnlyFromJSON,
  IdOnlyToJSON,
  ProducerAddressReadSeller,
  ProducerAddressReadSellerFromJSON,
  ProducerAddressReadSellerToJSON,
  ProducerBankAccountReadSeller,
  ProducerBankAccountReadSellerFromJSON,
  ProducerBankAccountReadSellerToJSON,
  ProducerBasicProfile,
  ProducerBasicProfileFromJSON,
  ProducerBasicProfileToJSON,
  ProducerCreateSeller,
  ProducerCreateSellerFromJSON,
  ProducerCreateSellerToJSON,
  ProducerReadSeller,
  ProducerReadSellerFromJSON,
  ProducerReadSellerToJSON,
  ProducerTosAgreementUpdateSeller,
  ProducerTosAgreementUpdateSellerFromJSON,
  ProducerTosAgreementUpdateSellerToJSON,
  ProducersMeUpdate,
  ProducersMeUpdateFromJSON,
  ProducersMeUpdateToJSON,
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
  ResponseSuccessBody,
  ResponseSuccessBodyFromJSON,
  ResponseSuccessBodyToJSON,
} from '../models'

export interface PostProducersRequest {
  producerCreateSeller?: ProducerCreateSeller
}

export interface PutProducersMeRequest {
  producersMeUpdate?: ProducersMeUpdate
}

export interface PutProducersMeBasicProfileRequest {
  producerBasicProfile?: ProducerBasicProfile
}

export interface PutProducersMeDefaultBankAccountRequest {
  idOnly?: IdOnly
}

export interface PutProducersMeDefaultProducerAddressRequest {
  idOnly?: IdOnly
}

export interface PutProducersMeTosAgreementRequest {
  producerTosAgreementUpdateSeller?: ProducerTosAgreementUpdateSeller
}

/**
 *
 */
export class ProducersApi extends runtime.BaseAPI {
  /**
   * 自分が所属している生産者の情報を取得する（現状、内容が/login_infoと被るが、念のため定義する）
   */
  async getProducersMeRaw(): Promise<runtime.ApiResponse<ProducerReadSeller>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producers/me`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ProducerReadSellerFromJSON(jsonValue))
  }

  /**
   * 自分が所属している生産者の情報を取得する（現状、内容が/login_infoと被るが、念のため定義する）
   */
  async getProducersMe(): Promise<ProducerReadSeller> {
    const response = await this.getProducersMeRaw()
    return await response.value()
  }

  /**
   * 生産者が自身の組織の基本情報を取得する
   */
  async getProducersMeBasicProfileRaw(): Promise<runtime.ApiResponse<ProducerBasicProfile>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producers/me/basic_profile`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ProducerBasicProfileFromJSON(jsonValue))
  }

  /**
   * 生産者が自身の組織の基本情報を取得する
   */
  async getProducersMeBasicProfile(): Promise<ProducerBasicProfile> {
    const response = await this.getProducersMeBasicProfileRaw()
    return await response.value()
  }

  /**
   * 生産者が自身の組織のデフォルトの銀行口座を取得する
   */
  async getProducersMeDefaultBankAccountRaw(): Promise<runtime.ApiResponse<ProducerBankAccountReadSeller>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producers/me/default_bank_account`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ProducerBankAccountReadSellerFromJSON(jsonValue))
  }

  /**
   * 生産者が自身の組織のデフォルトの銀行口座を取得する
   */
  async getProducersMeDefaultBankAccount(): Promise<ProducerBankAccountReadSeller> {
    const response = await this.getProducersMeDefaultBankAccountRaw()
    return await response.value()
  }

  /**
   * 生産者が自身の組織のデフォルトの住所を取得する
   */
  async getProducersMeDefaultProducerAddressRaw(): Promise<runtime.ApiResponse<ProducerAddressReadSeller>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producers/me/default_producer_address`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ProducerAddressReadSellerFromJSON(jsonValue))
  }

  /**
   * 生産者が自身の組織のデフォルトの住所を取得する
   */
  async getProducersMeDefaultProducerAddress(): Promise<ProducerAddressReadSeller> {
    const response = await this.getProducersMeDefaultProducerAddressRaw()
    return await response.value()
  }

  /**
   * 新しい生産者を登録する
   */
  async postProducersRaw(requestParameters: PostProducersRequest): Promise<runtime.ApiResponse<ResponseSuccessBody>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request({
      path: `/producers`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ProducerCreateSellerToJSON(requestParameters.producerCreateSeller),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessBodyFromJSON(jsonValue))
  }

  /**
   * 新しい生産者を登録する
   */
  async postProducers(requestParameters: PostProducersRequest): Promise<ResponseSuccessBody> {
    const response = await this.postProducersRaw(requestParameters)
    return await response.value()
  }

  /**
   * 自分が所属している生産者情報を編集する
   */
  async putProducersMeRaw(requestParameters: PutProducersMeRequest): Promise<runtime.ApiResponse<ProducerReadSeller>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producers/me`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: ProducersMeUpdateToJSON(requestParameters.producersMeUpdate),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ProducerReadSellerFromJSON(jsonValue))
  }

  /**
   * 自分が所属している生産者情報を編集する
   */
  async putProducersMe(requestParameters: PutProducersMeRequest): Promise<ProducerReadSeller> {
    const response = await this.putProducersMeRaw(requestParameters)
    return await response.value()
  }

  /**
   * 生産者が自身の組織の基本情報を更新する
   */
  async putProducersMeBasicProfileRaw(
    requestParameters: PutProducersMeBasicProfileRequest
  ): Promise<runtime.ApiResponse<ProducerBasicProfile>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producers/me/basic_profile`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: ProducerBasicProfileToJSON(requestParameters.producerBasicProfile),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ProducerBasicProfileFromJSON(jsonValue))
  }

  /**
   * 生産者が自身の組織の基本情報を更新する
   */
  async putProducersMeBasicProfile(
    requestParameters: PutProducersMeBasicProfileRequest
  ): Promise<ProducerBasicProfile> {
    const response = await this.putProducersMeBasicProfileRaw(requestParameters)
    return await response.value()
  }

  /**
   * 生産者が自身の組織のデフォルトの銀行口座を更新する
   */
  async putProducersMeDefaultBankAccountRaw(
    requestParameters: PutProducersMeDefaultBankAccountRequest
  ): Promise<runtime.ApiResponse<IdOnly>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producers/me/default_bank_account`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: IdOnlyToJSON(requestParameters.idOnly),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => IdOnlyFromJSON(jsonValue))
  }

  /**
   * 生産者が自身の組織のデフォルトの銀行口座を更新する
   */
  async putProducersMeDefaultBankAccount(requestParameters: PutProducersMeDefaultBankAccountRequest): Promise<IdOnly> {
    const response = await this.putProducersMeDefaultBankAccountRaw(requestParameters)
    return await response.value()
  }

  /**
   * 生産者が自身の組織のデフォルトの住所を更新する
   */
  async putProducersMeDefaultProducerAddressRaw(
    requestParameters: PutProducersMeDefaultProducerAddressRequest
  ): Promise<runtime.ApiResponse<IdOnly>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producers/me/default_producer_address`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: IdOnlyToJSON(requestParameters.idOnly),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => IdOnlyFromJSON(jsonValue))
  }

  /**
   * 生産者が自身の組織のデフォルトの住所を更新する
   */
  async putProducersMeDefaultProducerAddress(
    requestParameters: PutProducersMeDefaultProducerAddressRequest
  ): Promise<IdOnly> {
    const response = await this.putProducersMeDefaultProducerAddressRaw(requestParameters)
    return await response.value()
  }

  /**
   * 自分が所属している生産者の利用規約同意状況を更新する
   */
  async putProducersMeTosAgreementRaw(
    requestParameters: PutProducersMeTosAgreementRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producers/me/tos_agreement`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: ProducerTosAgreementUpdateSellerToJSON(requestParameters.producerTosAgreementUpdateSeller),
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * 自分が所属している生産者の利用規約同意状況を更新する
   */
  async putProducersMeTosAgreement(requestParameters: PutProducersMeTosAgreementRequest): Promise<void> {
    await this.putProducersMeTosAgreementRaw(requestParameters)
  }
}
