import { useHistory } from 'react-router'
import { Box, Text } from '@chakra-ui/react'
import type { BoxProps } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'

import { buildPageTitle } from '../../utils/buildPageTitle'
import { EntryLayout } from './components/Layout/EntryLayout'
import { ButtonSubmit } from '../components/Buttons/Buttons'
import { RedirectLink } from './components/Navigation/RedirectLink'
import { NeedSelect } from '../components/Select/NeedSelect'
import { Select } from '../components/Select/Select'
import { MasterList } from '../PrivatePages/Concept/lib/manageFetchMasters'
import {
  InvoiceRegistrationStatus,
  invoiceRegistrationStatus,
} from '../../domain/producerBasicProfile/producerBasicProfile'
import type { RegionNormalized } from '../../domain/region/region'
import type { StoragePeriod } from '../../domain/storagePeriod/storagePeriod'
import type { FarmingCertification } from '../../domain/farmingCertification/farmingCertification'
import { useBasicProfileInput } from './hooks/entry3/useBasicProfileInput'
import { MultiSelect } from '../components/Select/MultiSelect'
import { useUpdateBasicProfile } from './hooks/entry3/useUpdateBasicProfile'
import { RegionFilterService } from '../../domain/region/regionFilterService'
import { FormField } from './components/Layout/FormField'

export const Entry3 = ({
  masters,
  prefectureId,
  basicProfileRefresh,
}: {
  masters: Pick<MasterList, 'regions' | 'storagePeriods' | 'farmingCertifications'>
  prefectureId: number
  basicProfileRefresh: () => Promise<void>
}) => {
  const history = useHistory()
  const nextPage = () => history.push('/entry/4')

  const {
    state: { input, validated },
    setValue,
  } = useBasicProfileInput()
  const { save, fetching } = useUpdateBasicProfile(validated)

  const saveBasicProfile = async () => {
    await save()
    await basicProfileRefresh()
    nextPage()
  }

  const filteredRegions = masters.regions.filter(RegionFilterService.inPrefecture(prefectureId))

  return (
    <>
      <Helmet>
        <title>{buildPageTitle('新規利用開始 基本情報の登録')}</title>
      </Helmet>

      <EntryLayout step={3}>
        <Box marginY="20px" textAlign="center">
          <Text>
            基本情報を登録いただくことで
            <br />
            あなたにあったオファーが届きやすくなります。
            <br />
            （この登録は省略することができます。）
          </Text>
          <NextPageLink id="gtm-km1baApp_entry03_Omit01" onClick={nextPage} />
          <Text marginY="20px" fontWeight="bold">
            基本情報の内容は登録完了後に米市場の登録情報画面から修正できます。
          </Text>
        </Box>

        <SelectRegionForm
          regionsMaster={filteredRegions}
          selectedValue={input.regionId}
          onChange={(value) => setValue('regionId', value)}
        />
        <SelectStoragePeriodForm
          storagePeriodsMaster={masters.storagePeriods}
          selectedValue={input.storagePeriodId}
          onChange={(value) => setValue('storagePeriodId', value)}
        />
        <SelectFarmingCertificationsForm
          farmingCertificationsMaster={masters.farmingCertifications}
          selectedValues={input.farmingCertificationIds}
          onChange={(values) => setValue('farmingCertificationIds', values)}
        />
        <SelectInvoiceRegistrationForm
          selectedValue={input.invoiceRegistrationStatus}
          onChange={(value) => setValue('invoiceRegistrationStatus', value)}
        />

        <Box marginTop="30px" marginBottom="20px" textAlign="center">
          <ButtonSubmit
            id="gtm-km1baApp_entry03_NextBtn"
            width="280px"
            onClick={saveBasicProfile}
            isDisabled={fetching}
          >
            この内容で登録して、次へ
          </ButtonSubmit>
        </Box>
        <NextPageLink id="gtm-km1baApp_entry03_Omit02" onClick={nextPage} paddingBottom="80px" />
      </EntryLayout>
    </>
  )
}

const SelectRegionForm = ({
  regionsMaster,
  selectedValue,
  onChange,
}: {
  regionsMaster: RegionNormalized[]
  selectedValue: string
  onChange: (value: string) => void
}) => {
  if (regionsMaster.length === 0) {
    return null
  }
  return (
    <FormField
      titleLabel="地域"
      formElement={
        <Select value={selectedValue} onChange={(e) => onChange(e.target.value)}>
          <NeedSelect />
          {regionsMaster.map((region) => {
            return (
              <option key={region.id} value={region.id}>
                {region.name}
              </option>
            )
          })}
        </Select>
      }
    />
  )
}

const SelectStoragePeriodForm = ({
  storagePeriodsMaster,
  selectedValue,
  onChange,
}: {
  storagePeriodsMaster: StoragePeriod[]
  selectedValue: string
  onChange: (value: string) => void
}) => {
  return (
    <FormField
      titleLabel="検査後に自ら保管できる期間"
      formElement={
        <Select value={selectedValue} onChange={(e) => onChange(e.target.value)}>
          <NeedSelect />
          {storagePeriodsMaster.map((sp) => {
            return (
              <option key={sp.id} value={sp.id}>
                {sp.name}
              </option>
            )
          })}
        </Select>
      }
    />
  )
}

const SelectFarmingCertificationsForm = ({
  farmingCertificationsMaster,
  selectedValues,
  onChange,
}: {
  farmingCertificationsMaster: FarmingCertification[]
  selectedValues: number[]
  onChange: (values: number[]) => void
}) => {
  const options = farmingCertificationsMaster.map((fc) => ({ label: fc.displayText, value: fc.id }))
  const values = options.filter((o) => selectedValues.includes(o.value))
  return (
    <FormField
      titleLabel="有機JAS・GAP等の取り組み"
      formElement={<MultiSelect<number> options={options} values={values} onChange={onChange} />}
    />
  )
}

const SelectInvoiceRegistrationForm = ({
  selectedValue,
  onChange,
}: {
  selectedValue: InvoiceRegistrationStatus
  onChange: (value: string) => void
}) => {
  return (
    <FormField
      titleLabel="適格請求書発行事業者ですか？"
      formElement={
        <Select value={selectedValue} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange(e.target.value)}>
          {invoiceRegistrationStatus.map((status) => {
            return (
              <option key={status.key} value={status.key}>
                {status.label}
              </option>
            )
          })}
        </Select>
      }
    />
  )
}

const NextPageLink = ({ id, onClick, ...props }: { id: string; onClick: () => void } & BoxProps) => {
  return (
    <RedirectLink id={id} onClick={onClick} {...props}>
      省略して、次へ
    </RedirectLink>
  )
}
