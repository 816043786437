import { useState } from 'react'
import type { EntryOfferRequestCreate } from '../../../../domain/offerRequest/offerRequest'
import { postOfferRequests } from '../../../../infra/offerRequestSend'
import { useLogoutAfter401Error } from '../../../auth/useLogout'
import {
  captureException,
  handle401Error,
  handle4xxWithout401Error,
  handle503Error,
  handleApiServerError,
  handlerNoNetworkError,
} from '../../../../infra/handleApiServerError'
import { noop } from '../../../../utils/noop'

export const useCreateOfferRequest = (validated: EntryOfferRequestCreate) => {
  const [fetching, setFetching] = useState(false)
  const logout = useLogoutAfter401Error()

  const save = async () => {
    try {
      setFetching(true)
      await postOfferRequests(validated)
      setFetching(false)
    } catch (err) {
      if (err instanceof Response) {
        handle503Error(err)
        handleApiServerError(err)
        handle401Error()(err, logout)
        handle4xxWithout401Error('予期せぬエラーが発生しました。時間を置いて再度お試しください。')(err, noop)
        handlerNoNetworkError(err)
        captureException(err)
      }

      throw err
    }
  }
  return { fetching, save }
}
