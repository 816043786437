import styled from '@emotion/styled'

export const TroubleShootingIcon = ({ width, height }: { width?: number | string; height?: number | string }) => {
  const defaultWidth = 25
  const defaultHeight = 22
  width = typeof width === 'number' || typeof width === 'string' ? width : defaultWidth
  height = typeof height === 'number' || typeof height === 'string' ? height : defaultHeight
  return (
    <Svg width={width} height={height} viewBox="0 0 25 22">
      <g transform="translate(-111 -16)">
        <g transform="translate(111 -11.362)">
          <path
            className="cls-1"
            d="M227.146,357.113a1.549,1.549,0,1,0,1.549,1.549A1.55,1.55,0,0,0,227.146,357.113Z"
            transform="translate(-214.645 -314.227)"
          />
          <path
            className="cls-1"
            d="M24.663,45.632,14.686,28.606a2.543,2.543,0,0,0-4.371,0L.337,45.632a2.463,2.463,0,0,0,0,2.487,2.538,2.538,0,0,0,2.186,1.243H22.477a2.538,2.538,0,0,0,2.186-1.243A2.461,2.461,0,0,0,24.663,45.632Zm-1.984,1.217a.813.813,0,0,1-.7.4H3.021a.811.811,0,0,1-.7-.4.784.784,0,0,1,0-.8L11.8,29.875a.815.815,0,0,1,1.4,0l9.479,16.176A.789.789,0,0,1,22.679,46.849Z"
            transform="translate(0)"
          />
          <path
            className="cls-1"
            d="M227.146,153.719a1.55,1.55,0,0,0-1.55,1.549l.6,6.274a.954.954,0,0,0,1.909,0l.594-6.274A1.549,1.549,0,0,0,227.146,153.719Z"
            transform="translate(-214.645 -120.719)"
          />
        </g>
      </g>
    </Svg>
  )
}

const Svg = styled.svg`
  .cls-1 {
    fill: #4b4b4b;
  }
`
