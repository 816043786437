/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  OfferReadSeller,
  OfferReadSellerFromJSON,
  OfferReadSellerToJSON,
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
} from '../models'

export interface GetOffersRequest {
  filters?: object
}

export interface GetOffersIdRequest {
  id: number
}

/**
 *
 */
export class OffersApi extends runtime.BaseAPI {
  /**
   * オファー一覧を取得する
   */
  async getOffersRaw(requestParameters: GetOffersRequest): Promise<runtime.ApiResponse<Array<OfferReadSeller>>> {
    const queryParameters: any = {}

    if (requestParameters.filters !== undefined) {
      queryParameters['_filters'] = requestParameters.filters
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/offers`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferReadSellerFromJSON))
  }

  /**
   * オファー一覧を取得する
   */
  async getOffers(requestParameters: GetOffersRequest): Promise<Array<OfferReadSeller>> {
    const response = await this.getOffersRaw(requestParameters)
    return await response.value()
  }

  /**
   * 指定IDに該当するオファーの情報を取得する
   */
  async getOffersIdRaw(requestParameters: GetOffersIdRequest): Promise<runtime.ApiResponse<OfferReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getOffersId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/offers/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => OfferReadSellerFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当するオファーの情報を取得する
   */
  async getOffersId(requestParameters: GetOffersIdRequest): Promise<OfferReadSeller> {
    const response = await this.getOffersIdRaw(requestParameters)
    return await response.value()
  }
}
