/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * AWS S3アップロードに必要なパラメータ
 * @export
 * @interface InlineResponse201AwsS3PresignedPost
 */
export interface InlineResponse201AwsS3PresignedPost {
  /**
   * ファイルのPOST先URL
   * @type {string}
   * @memberof InlineResponse201AwsS3PresignedPost
   */
  url: string
  /**
   * ファイルと共にPOSTする値
   * @type {{ [key: string]: string; }}
   * @memberof InlineResponse201AwsS3PresignedPost
   */
  fields: { [key: string]: string }
}

export function InlineResponse201AwsS3PresignedPostFromJSON(json: any): InlineResponse201AwsS3PresignedPost {
  return InlineResponse201AwsS3PresignedPostFromJSONTyped(json, false)
}

export function InlineResponse201AwsS3PresignedPostFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InlineResponse201AwsS3PresignedPost {
  if (json === undefined || json === null) {
    return json
  }
  return {
    url: json['url'],
    fields: json['fields'],
  }
}

export function InlineResponse201AwsS3PresignedPostToJSON(value?: InlineResponse201AwsS3PresignedPost | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    url: value.url,
    fields: value.fields,
  }
}
