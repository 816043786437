import type { ReactNode } from 'react'
import { Box } from '@chakra-ui/react'
import { ContentBox } from '../../../components/ContentBox/ContentBox'

export const EntryContentBox = ({
  children,
  margin = '0 auto',
  padding,
}: {
  children: ReactNode
  margin?: string
  padding?: string
}) => {
  return (
    <Box maxWidth="614px" margin={margin}>
      <ContentBox padding={padding} marginBottom="0">
        {children}
      </ContentBox>
    </Box>
  )
}

export const EntryMobileContentBox = ({ children }: { children: ReactNode }) => {
  return (
    <Box maxWidth="614px" margin="0 auto">
      <Box paddingX="12px">{children}</Box>
    </Box>
  )
}
