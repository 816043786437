/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { GradeRead, GradeReadFromJSON, GradeReadFromJSONTyped, GradeReadToJSON } from './'

/**
 *
 * @export
 * @interface IndividualDeliveryReadSellerDeliveryContents
 */
export interface IndividualDeliveryReadSellerDeliveryContents {
  /**
   * 等級ID（grade_penaltiesにあるgradeのIDのみ指定可能；nullの場合は、減額なし分）
   * @type {number}
   * @memberof IndividualDeliveryReadSellerDeliveryContents
   */
  gradeId: number | null
  /**
   * 等級
   * @type {GradeRead}
   * @memberof IndividualDeliveryReadSellerDeliveryContents
   */
  grade: GradeRead
  /**
   * 納品物の数量
   * @type {number}
   * @memberof IndividualDeliveryReadSellerDeliveryContents
   */
  packageCount: number
}

export function IndividualDeliveryReadSellerDeliveryContentsFromJSON(
  json: any
): IndividualDeliveryReadSellerDeliveryContents {
  return IndividualDeliveryReadSellerDeliveryContentsFromJSONTyped(json, false)
}

export function IndividualDeliveryReadSellerDeliveryContentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IndividualDeliveryReadSellerDeliveryContents {
  if (json === undefined || json === null) {
    return json
  }
  return {
    gradeId: json['grade_id'],
    grade: GradeReadFromJSON(json['grade']),
    packageCount: json['package_count'],
  }
}

export function IndividualDeliveryReadSellerDeliveryContentsToJSON(
  value?: IndividualDeliveryReadSellerDeliveryContents | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    grade_id: value.gradeId,
    grade: GradeReadToJSON(value.grade),
    package_count: value.packageCount,
  }
}
