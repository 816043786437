import { Footer } from './Footer'
import { useRouteMatch } from 'react-router-dom'

const DISPLAY_TARGETS = ['/home', '/offers', '/offers/others', '/deals']

export function FooterWithVisibilityManager() {
  const { isFooterDisplayed } = useFooterDisplay()

  return isFooterDisplayed ? <Footer /> : null
}

export function useFooterDisplay(): { isFooterDisplayed: boolean } {
  const isFooterDisplayed = DISPLAY_TARGETS.map(useRouteMatch)
    .map((match) => match && match.isExact) // 完全一致での判定
    .includes(true)

  return { isFooterDisplayed }
}
