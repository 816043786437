import styled from '@emotion/styled'

import { CircularSteps } from './CircularSteps'

import { useIsMobile } from '../../../hooks/useIsMobile'

import { theme } from '../../../../theme/theme'
import { STEP_LIST } from './stepList'

export const EntrySteps = ({ step }: { step: 1 | 2 | 3 | 4 }) => {
  const activeColor = theme.colors.brand.text.alert
  const color = theme.colors.brand.text.main
  const background = theme.colors.brand.box.background
  const borderColor = theme.colors.brand.button.backBorder
  const isMobile = useIsMobile()
  if (isMobile) {
    return <CircularSteps step={step} />
  }
  return (
    <StepBox>
      <StepFrame activeColor={activeColor} color={color} background={background} borderColor={borderColor}>
        <ol className="step">
          {STEP_LIST.map((item, index) => {
            return (
              <li key={item} className={step === index + 1 ? 'active' : ''}>
                {item}
              </li>
            )
          })}
        </ol>
      </StepFrame>
    </StepBox>
  )
}

const StepBox = styled.div`
  padding: 21px 0 0 0;
  margin: 0 46.62px 20px 47px;
`

const StepFrame = styled.div<{
  activeColor: string
  color: string
  background: string
  borderColor: string
}>`
  .step {
    list-style-type: none;
    display: table;
    width: 100%;
    min-width: 281px;
    max-width: 614px;
    padding: 0;
    margin: 0 auto;
    overflow: hidden;
  }
  .step li {
    width: calc(25% - (54px / 3));
    display: table-cell;
    position: relative;
    background: ${(props) => props.background};
    border: 1px solid ${(props) => props.borderColor};
    color: ${(props) => props.color};
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }
  .step li:first-of-type {
    padding: 17px 7px 18px 17px;
    border-right: none;
    border-radius: 5px 0 0 5px;
    text-align: center;
  }
  .step li:nth-child(2) {
    padding: 17px 5px 18px 20px;
  }
  .step li:nth-child(3) {
    padding: 17px 5px 18px 20px;
  }
  .step li:last-child {
    padding: 17px 15px 18px 20px;
    border-radius: 0 5px 5px 0;
    text-align: center;
  }
  .step li:last-child:before,
  .step li:last-child:after {
    display: none;
  }
  .step li:before,
  .step li:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin: auto;
  }
  .step li:before {
    top: -0px;
    right: -18.5px;
    border-style: solid;
    border-color: transparent transparent transparent ${(props) => props.borderColor};
    border-width: 26.5px 0 26.5px 18px;
    z-index: 10;
  }
  .step li:after {
    top: -0px;
    right: -17px;
    border-style: solid;
    border-color: transparent transparent transparent ${(props) => props.background};
    border-width: 26.5px 0 26.5px 18px;
    z-index: 10;
  }
  .step li.active {
    background: ${(props) => props.activeColor};
    color: #fff;
    font-weight: bold;
  }
  .step li.active:after {
    border-color: transparent transparent transparent ${(props) => props.activeColor};
  }
`
