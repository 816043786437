/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  AmendReadSellerGradePenalties,
  AmendReadSellerGradePenaltiesFromJSON,
  AmendReadSellerGradePenaltiesFromJSONTyped,
  AmendReadSellerGradePenaltiesToJSON,
  AmendReadSellerHandoverContents,
  AmendReadSellerHandoverContentsFromJSON,
  AmendReadSellerHandoverContentsFromJSONTyped,
  AmendReadSellerHandoverContentsToJSON,
  AmendReadSellerInspectionResults,
  AmendReadSellerInspectionResultsFromJSON,
  AmendReadSellerInspectionResultsFromJSONTyped,
  AmendReadSellerInspectionResultsToJSON,
  AmendReadSellerProducer,
  AmendReadSellerProducerFromJSON,
  AmendReadSellerProducerFromJSONTyped,
  AmendReadSellerProducerToJSON,
  AmendReadSellerWholesaler,
  AmendReadSellerWholesalerFromJSON,
  AmendReadSellerWholesalerFromJSONTyped,
  AmendReadSellerWholesalerToJSON,
  Attachment,
  AttachmentFromJSON,
  AttachmentFromJSONTyped,
  AttachmentToJSON,
  Brand,
  BrandFromJSON,
  BrandFromJSONTyped,
  BrandToJSON,
  Grade,
  GradeFromJSON,
  GradeFromJSONTyped,
  GradeToJSON,
  PackagingForm,
  PackagingFormFromJSON,
  PackagingFormFromJSONTyped,
  PackagingFormToJSON,
  Prefecture,
  PrefectureFromJSON,
  PrefectureFromJSONTyped,
  PrefectureToJSON,
  Region,
  RegionFromJSON,
  RegionFromJSONTyped,
  RegionToJSON,
  SpotContractAnnum,
  SpotContractAnnumFromJSON,
  SpotContractAnnumFromJSONTyped,
  SpotContractAnnumToJSON,
  SpotContractProducerAddressReadAmend,
  SpotContractProducerAddressReadAmendFromJSON,
  SpotContractProducerAddressReadAmendFromJSONTyped,
  SpotContractProducerAddressReadAmendToJSON,
  SpotContractProducerBankAccountReadAmend,
  SpotContractProducerBankAccountReadAmendFromJSON,
  SpotContractProducerBankAccountReadAmendFromJSONTyped,
  SpotContractProducerBankAccountReadAmendToJSON,
  SpotContractSpecialCondition,
  SpotContractSpecialConditionFromJSON,
  SpotContractSpecialConditionFromJSONTyped,
  SpotContractSpecialConditionToJSON,
  SpotContractStoragePeriod,
  SpotContractStoragePeriodFromJSON,
  SpotContractStoragePeriodFromJSONTyped,
  SpotContractStoragePeriodToJSON,
} from './'

/**
 * [現物契約] アメンド
 * @export
 * @interface SpotContractAmendReadSeller
 */
export interface SpotContractAmendReadSeller {
  /**
   * ID
   * @type {number}
   * @memberof SpotContractAmendReadSeller
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof SpotContractAmendReadSeller
   */
  readonly versionNumber: number
  /**
   * 例外フローフラグ
   * @type {boolean}
   * @memberof SpotContractAmendReadSeller
   */
  readonly isExceptionFlow: boolean
  /**
   * 現在のステップ（売り手）
   * @type {string}
   * @memberof SpotContractAmendReadSeller
   */
  readonly currentStepSeller: SpotContractAmendReadSellerCurrentStepSellerEnum
  /**
   * 現在のステップ（買い手）
   * @type {string}
   * @memberof SpotContractAmendReadSeller
   */
  readonly currentStepBuyer: SpotContractAmendReadSellerCurrentStepBuyerEnum
  /**
   * 現在のステップ（支払い）
   * @type {string}
   * @memberof SpotContractAmendReadSeller
   */
  readonly currentStepPayment: SpotContractAmendReadSellerCurrentStepPaymentEnum
  /**
   * ディールID
   * @type {number}
   * @memberof SpotContractAmendReadSeller
   */
  readonly dealId: number
  /**
   *
   * @type {Brand}
   * @memberof SpotContractAmendReadSeller
   */
  brand: Brand
  /**
   *
   * @type {Grade}
   * @memberof SpotContractAmendReadSeller
   */
  grade: Grade
  /**
   *
   * @type {SpotContractAnnum}
   * @memberof SpotContractAmendReadSeller
   */
  annum: SpotContractAnnum
  /**
   *
   * @type {Prefecture}
   * @memberof SpotContractAmendReadSeller
   */
  prefecture: Prefecture
  /**
   *
   * @type {Region}
   * @memberof SpotContractAmendReadSeller
   */
  region: Region | null
  /**
   *
   * @type {PackagingForm}
   * @memberof SpotContractAmendReadSeller
   */
  packagingForm: PackagingForm
  /**
   * 納品物の数量
   * @type {number}
   * @memberof SpotContractAmendReadSeller
   */
  readonly packageCount: number
  /**
   * オファー単価（円／俵）
   * @type {number}
   * @memberof SpotContractAmendReadSeller
   */
  readonly offerUnitPrice: number
  /**
   * 増額される金額（円）
   * @type {number}
   * @memberof SpotContractAmendReadSeller
   */
  readonly unitPriceAdjustmentInYen: number
  /**
   * 等級格差による減額（空可）
   * @type {Array<AmendReadSellerGradePenalties>}
   * @memberof SpotContractAmendReadSeller
   */
  readonly gradePenalties: Array<AmendReadSellerGradePenalties>
  /**
   * [現物契約] 特別条件リスト（空可）
   * @type {Array<SpotContractSpecialCondition>}
   * @memberof SpotContractAmendReadSeller
   */
  readonly specialConditions: Array<SpotContractSpecialCondition>
  /**
   * 特別条件メモ
   * @type {string}
   * @memberof SpotContractAmendReadSeller
   */
  readonly specialConditionsMemo: string
  /**
   *
   * @type {SpotContractStoragePeriod}
   * @memberof SpotContractAmendReadSeller
   */
  storagePeriod: SpotContractStoragePeriod
  /**
   * 引渡内容
   * @type {Array<AmendReadSellerHandoverContents>}
   * @memberof SpotContractAmendReadSeller
   */
  readonly handoverContents: Array<AmendReadSellerHandoverContents>
  /**
   * 引渡合計取引金額
   * @type {string}
   * @memberof SpotContractAmendReadSeller
   */
  readonly handoverVolumeInYen: string
  /**
   * 引渡可能日
   * @type {Date}
   * @memberof SpotContractAmendReadSeller
   */
  readonly handoverPossibleDate: Date | null
  /**
   * 土日・祝日に引渡可能フラグ
   * @type {boolean}
   * @memberof SpotContractAmendReadSeller
   */
  readonly canHandoverOnHoliday: boolean | null
  /**
   * 運送手配時の注意点
   * @type {string}
   * @memberof SpotContractAmendReadSeller
   */
  readonly handoverMemo: string | null
  /**
   *
   * @type {SpotContractProducerAddressReadAmend}
   * @memberof SpotContractAmendReadSeller
   */
  handoverAddress: SpotContractProducerAddressReadAmend | null
  /**
   * 検査結果
   * @type {Array<AmendReadSellerInspectionResults>}
   * @memberof SpotContractAmendReadSeller
   */
  readonly inspectionResults: Array<AmendReadSellerInspectionResults> | null
  /**
   * 検査結果を裏付ける資料（添付ファイルUUIDリスト）
   * @type {Array<string>}
   * @memberof SpotContractAmendReadSeller
   */
  inspectionResultsAttachmentUuids?: Array<string>
  /**
   * 検査結果を裏付ける資料
   * @type {Array<Attachment>}
   * @memberof SpotContractAmendReadSeller
   */
  readonly inspectionResultsAttachments: Array<Attachment> | null
  /**
   * 検査結果メモ
   * @type {string}
   * @memberof SpotContractAmendReadSeller
   */
  readonly inspectionResultsMemo: string | null
  /**
   *
   * @type {SpotContractProducerBankAccountReadAmend}
   * @memberof SpotContractAmendReadSeller
   */
  paymentBankAccount: SpotContractProducerBankAccountReadAmend | null
  /**
   *
   * @type {AmendReadSellerWholesaler}
   * @memberof SpotContractAmendReadSeller
   */
  wholesaler: AmendReadSellerWholesaler
  /**
   *
   * @type {AmendReadSellerProducer}
   * @memberof SpotContractAmendReadSeller
   */
  producer: AmendReadSellerProducer
}

/**
 * @export
 * @enum {string}
 */
export enum SpotContractAmendReadSellerCurrentStepSellerEnum {
  Done = 'done',
}
/**
 * @export
 * @enum {string}
 */
export enum SpotContractAmendReadSellerCurrentStepBuyerEnum {
  WaitingForDeliveryCreation = 'waiting_for_delivery_creation',
  WaitingForAfterDeliveryInspection = 'waiting_for_after_delivery_inspection',
  Done = 'done',
}
/**
 * @export
 * @enum {string}
 */
export enum SpotContractAmendReadSellerCurrentStepPaymentEnum {
  WaitingForDelivery = 'waiting_for_delivery',
  WaitingForPayment = 'waiting_for_payment',
  Done = 'done',
}

export function SpotContractAmendReadSellerFromJSON(json: any): SpotContractAmendReadSeller {
  return SpotContractAmendReadSellerFromJSONTyped(json, false)
}

export function SpotContractAmendReadSellerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpotContractAmendReadSeller {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isExceptionFlow: json['is_exception_flow'],
    currentStepSeller: json['current_step_seller'],
    currentStepBuyer: json['current_step_buyer'],
    currentStepPayment: json['current_step_payment'],
    dealId: json['deal_id'],
    brand: BrandFromJSON(json['brand']),
    grade: GradeFromJSON(json['grade']),
    annum: SpotContractAnnumFromJSON(json['annum']),
    prefecture: PrefectureFromJSON(json['prefecture']),
    region: RegionFromJSON(json['region']),
    packagingForm: PackagingFormFromJSON(json['packaging_form']),
    packageCount: json['package_count'],
    offerUnitPrice: json['offer_unit_price'],
    unitPriceAdjustmentInYen: json['unit_price_adjustment_in_yen'],
    gradePenalties: (json['grade_penalties'] as Array<any>).map(AmendReadSellerGradePenaltiesFromJSON),
    specialConditions: (json['special_conditions'] as Array<any>).map(SpotContractSpecialConditionFromJSON),
    specialConditionsMemo: json['special_conditions_memo'],
    storagePeriod: SpotContractStoragePeriodFromJSON(json['storage_period']),
    handoverContents: (json['handover_contents'] as Array<any>).map(AmendReadSellerHandoverContentsFromJSON),
    handoverVolumeInYen: json['handover_volume_in_yen'],
    handoverPossibleDate: json['handover_possible_date'] === null ? null : new Date(json['handover_possible_date']),
    canHandoverOnHoliday: json['can_handover_on_holiday'],
    handoverMemo: json['handover_memo'],
    handoverAddress: SpotContractProducerAddressReadAmendFromJSON(json['handover_address']),
    inspectionResults:
      json['inspection_results'] === null
        ? null
        : (json['inspection_results'] as Array<any>).map(AmendReadSellerInspectionResultsFromJSON),
    inspectionResultsAttachmentUuids: !exists(json, 'inspection_results_attachment_uuids')
      ? undefined
      : json['inspection_results_attachment_uuids'],
    inspectionResultsAttachments:
      json['inspection_results_attachments'] === null
        ? null
        : (json['inspection_results_attachments'] as Array<any>).map(AttachmentFromJSON),
    inspectionResultsMemo: json['inspection_results_memo'],
    paymentBankAccount: SpotContractProducerBankAccountReadAmendFromJSON(json['payment_bank_account']),
    wholesaler: AmendReadSellerWholesalerFromJSON(json['wholesaler']),
    producer: AmendReadSellerProducerFromJSON(json['producer']),
  }
}

export function SpotContractAmendReadSellerToJSON(value?: SpotContractAmendReadSeller | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand: BrandToJSON(value.brand),
    grade: GradeToJSON(value.grade),
    annum: SpotContractAnnumToJSON(value.annum),
    prefecture: PrefectureToJSON(value.prefecture),
    region: RegionToJSON(value.region),
    packaging_form: PackagingFormToJSON(value.packagingForm),
    storage_period: SpotContractStoragePeriodToJSON(value.storagePeriod),
    handover_address: SpotContractProducerAddressReadAmendToJSON(value.handoverAddress),
    inspection_results_attachment_uuids: value.inspectionResultsAttachmentUuids,
    payment_bank_account: SpotContractProducerBankAccountReadAmendToJSON(value.paymentBankAccount),
    wholesaler: AmendReadSellerWholesalerToJSON(value.wholesaler),
    producer: AmendReadSellerProducerToJSON(value.producer),
  }
}
