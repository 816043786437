/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 都道府県マスタ
 * @export
 * @interface Prefecture
 */
export interface Prefecture {
  /**
   * ID
   * @type {number}
   * @memberof Prefecture
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof Prefecture
   */
  readonly versionNumber: number
  /**
   * ステータス（true: 有効、false: 無効）
   * @type {boolean}
   * @memberof Prefecture
   */
  isActive: boolean
  /**
   * 都道府県名
   * @type {string}
   * @memberof Prefecture
   */
  name: string
}

export function PrefectureFromJSON(json: any): Prefecture {
  return PrefectureFromJSONTyped(json, false)
}

export function PrefectureFromJSONTyped(json: any, ignoreDiscriminator: boolean): Prefecture {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isActive: json['is_active'],
    name: json['name'],
  }
}

export function PrefectureToJSON(value?: Prefecture | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    is_active: value.isActive,
    name: value.name,
  }
}
