import { ReactElement } from 'react'
import { useLoginInfoContext } from '../../../PrivatePages/hooks/useFetchLoginInfo'
import { MobileHeader } from './MobileHeader'
import { PcHeader } from './PcHeader'
import { useIsMobile } from '../../../hooks/useIsMobile'

export function InnerHeader(props: {
  title: string
  icon: ReactElement | null
  left: ReactElement | null
  right: ReactElement | null
}) {
  const isMobile = useIsMobile()
  useLoginInfoContext()

  return (
    <>
      {isMobile && <MobileHeader {...props} />}
      {!isMobile && <PcHeader {...props} />}
    </>
  )
}
