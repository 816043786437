import { ReactElement } from 'react'
import { theme } from '../../../../theme/theme'
import { Flex } from '@chakra-ui/react'
import { RightButtonContainer, Title } from './atom'
import { UserInformation } from '../../Menu/UserInformation'
import styled from '@emotion/styled'

export function PcHeader({
  title,
  icon,
  right,
}: {
  title: string
  icon: ReactElement | null
  left: ReactElement | null
  right: ReactElement | null
}) {
  const { drawerSize, innerHeaderHeight, containerMaxWidth } = theme.sizes.brand

  return (
    <RelativeHeader innerHeaderHeight={innerHeaderHeight} backgroundColor={theme.colors.brand.brand}>
      <Frame pcMaxWidth={containerMaxWidth} innerHeaderHeight={innerHeaderHeight} drawerSize={drawerSize}>
        <Flex
          backgroundColor="brand.brand"
          width={'calc(' + containerMaxWidth + ' - 40px)'}
          height={innerHeaderHeight}
          align="center"
          margin="0 40px"
          justifyContent="flex-start"
          paddingLeft={drawerSize}
        >
          <Title icon={icon} title={title} />
          <RightButtonContainer right={right} />
        </Flex>
        <UserInformation />
      </Frame>
    </RelativeHeader>
  )
}

const RelativeHeader = styled.header<{ innerHeaderHeight: string; backgroundColor: string }>`
  position: relative;
  width: 100%;
  height: ${(props) => props.innerHeaderHeight};
  background: ${(props) => props.backgroundColor};
  z-index: 2;
`

const Frame = styled.div<{ pcMaxWidth: string; innerHeaderHeight: string; drawerSize: string }>`
  max-width: ${(props) => props.pcMaxWidth};
  height: ${(props) => props.innerHeaderHeight};
  margin: 0 auto;
`
