import styled from '@emotion/styled'

export const HomeIcon = ({ width, height }: { width?: number | string; height?: number | string }) => {
  const defaultWidth = 23.384
  const defaultHeight = 20.812
  width = typeof width === 'number' || typeof width === 'string' ? width : defaultWidth
  height = typeof height === 'number' || typeof height === 'string' ? height : defaultHeight
  return (
    <Svg width={width} height={height} viewBox="0 0 23.384 20.812">
      <g transform="translate(11.692 10.406)">
        <g transform="translate(-11.692 -10.406)">
          <path
            className="cls-1"
            d="M111.565,174.475v8.24h-5.293v-7.091h-4.779v7.091H96.2v-8.24l7.683-6.375Z"
            transform="translate(-92.19 -161.903)"
          />
          <path
            className="cls-1"
            d="M12.8,68.208,24.492,58.9l11.692,9.308-1.361,1.966L24.492,61.953,14.161,70.174Z"
            transform="translate(-12.8 -57.953)"
          />
          <path className="cls-1" d="M373.394,39.2v5.952l-2.894-2.3V39.2Z" transform="translate(-353.303 -39.2)" />
        </g>
      </g>
    </Svg>
  )
}

const Svg = styled.svg`
  .cls-1 {
    fill: #35363a;
  }
`
