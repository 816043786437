/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * [現物契約] 生産者の住所（Read、Amend）
 * @export
 * @interface SpotContractProducerAddressReadAmend
 */
export interface SpotContractProducerAddressReadAmend {
  /**
   * ID
   * @type {number}
   * @memberof SpotContractProducerAddressReadAmend
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof SpotContractProducerAddressReadAmend
   */
  readonly versionNumber: number
  /**
   * 都道府県のID、海外は含まない
   * @type {number}
   * @memberof SpotContractProducerAddressReadAmend
   */
  readonly prefectureId: number
  /**
   *
   * @type {string}
   * @memberof SpotContractProducerAddressReadAmend
   */
  readonly city: string
  /**
   *
   * @type {string}
   * @memberof SpotContractProducerAddressReadAmend
   */
  readonly address1: string
  /**
   * 空文字可
   * @type {string}
   * @memberof SpotContractProducerAddressReadAmend
   */
  readonly address2: string
}

export function SpotContractProducerAddressReadAmendFromJSON(json: any): SpotContractProducerAddressReadAmend {
  return SpotContractProducerAddressReadAmendFromJSONTyped(json, false)
}

export function SpotContractProducerAddressReadAmendFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpotContractProducerAddressReadAmend {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    prefectureId: json['prefecture_id'],
    city: json['city'],
    address1: json['address_1'],
    address2: json['address_2'],
  }
}

export function SpotContractProducerAddressReadAmendToJSON(value?: SpotContractProducerAddressReadAmend | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {}
}
