/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * ディールの引渡情報
 * @export
 * @interface SpotContractDealsHandoverCreate
 */
export interface SpotContractDealsHandoverCreate {
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof SpotContractDealsHandoverCreate
   */
  versionNumber: number
  /**
   * 発送可能日
   * @type {Date}
   * @memberof SpotContractDealsHandoverCreate
   */
  handoverPossibleDate: Date
  /**
   * 土日・祝日に引渡可能可能フラグ
   * @type {boolean}
   * @memberof SpotContractDealsHandoverCreate
   */
  canHandoverOnHoliday: boolean
  /**
   * 引渡メモ
   * @type {string}
   * @memberof SpotContractDealsHandoverCreate
   */
  handoverMemo: string
  /**
   * 引渡場所ID
   * @type {number}
   * @memberof SpotContractDealsHandoverCreate
   */
  handoverAddressId: number
  /**
   * 入金口座
   * @type {number}
   * @memberof SpotContractDealsHandoverCreate
   */
  paymentBankAccountId: number
}

export function SpotContractDealsHandoverCreateFromJSON(json: any): SpotContractDealsHandoverCreate {
  return SpotContractDealsHandoverCreateFromJSONTyped(json, false)
}

export function SpotContractDealsHandoverCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpotContractDealsHandoverCreate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    versionNumber: json['version_number'],
    handoverPossibleDate: new Date(json['handover_possible_date']),
    canHandoverOnHoliday: json['can_handover_on_holiday'],
    handoverMemo: json['handover_memo'],
    handoverAddressId: json['handover_address_id'],
    paymentBankAccountId: json['payment_bank_account_id'],
  }
}

export function SpotContractDealsHandoverCreateToJSON(value?: SpotContractDealsHandoverCreate | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    version_number: value.versionNumber,
    handover_possible_date: value.handoverPossibleDate.toISOString().substr(0, 10),
    can_handover_on_holiday: value.canHandoverOnHoliday,
    handover_memo: value.handoverMemo,
    handover_address_id: value.handoverAddressId,
    payment_bank_account_id: value.paymentBankAccountId,
  }
}
