/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface AmendsInspectionResultsUpdateInspectionResults
 */
export interface AmendsInspectionResultsUpdateInspectionResults {
  /**
   * 等級ID（grade_penaltiesにあるgradeのIDのみ指定可能；nullの場合は、減額なし分）
   * @type {number}
   * @memberof AmendsInspectionResultsUpdateInspectionResults
   */
  gradeId: number | null
  /**
   * 納品物の数量
   * @type {number}
   * @memberof AmendsInspectionResultsUpdateInspectionResults
   */
  packageCount: number
}

export function AmendsInspectionResultsUpdateInspectionResultsFromJSON(
  json: any
): AmendsInspectionResultsUpdateInspectionResults {
  return AmendsInspectionResultsUpdateInspectionResultsFromJSONTyped(json, false)
}

export function AmendsInspectionResultsUpdateInspectionResultsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AmendsInspectionResultsUpdateInspectionResults {
  if (json === undefined || json === null) {
    return json
  }
  return {
    gradeId: json['grade_id'],
    packageCount: json['package_count'],
  }
}

export function AmendsInspectionResultsUpdateInspectionResultsToJSON(
  value?: AmendsInspectionResultsUpdateInspectionResults | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    grade_id: value.gradeId,
    package_count: value.packageCount,
  }
}
