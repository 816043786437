import { ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { RightButtonContainer, Title } from './atom'

import { useIsMobile } from '../../../hooks/useIsMobile'

import { theme } from '../../../../theme/theme'

export function MobileHeader({
  title,
  icon,
  left,
  right,
}: {
  title: string
  icon: ReactElement | null
  left: ReactElement | null
  right: ReactElement | null
}) {
  const { innerHeaderHeight } = theme.sizes.brand

  return (
    <FixedHeader innerHeaderHeight={innerHeaderHeight} backgroundColor={theme.colors.brand.brand}>
      <Flex
        backgroundColor="brand.brand"
        width="100%"
        position="fixed"
        top="0"
        height={innerHeaderHeight}
        align="center"
        justifyContent="center"
        borderBottom="1px"
        borderStyle="solid"
        borderColor="brand.box.border"
      >
        <LeftButtonContainer left={left} />
        <Title icon={icon} title={title} />
        <RightButtonContainer right={right} />
      </Flex>
    </FixedHeader>
  )
}

const FixedHeader = styled.header<{ innerHeaderHeight: string; backgroundColor: string }>`
  position: fixed;
  width: 100%;
  height: ${(props) => props.innerHeaderHeight};
  background: ${(props) => props.backgroundColor};
  z-index: 2;
`

const LeftButtonContainer = ({ left }: { left: ReactElement | null }) => {
  const isMobile = useIsMobile()

  return (
    <Flex justify="flex-start" position="fixed" top={isMobile ? '0' : theme.sizes.brand.outerHeaderHeight} left="0">
      {left}
    </Flex>
  )
}
