import type { ReactNode } from 'react'
import { Box } from '@chakra-ui/react'
import { EntrySteps } from '../EntrySteps/EntrySteps'

export const EntryLayout = ({ children, step }: { children: ReactNode; step: 1 | 2 | 3 | 4 }) => {
  return (
    <Box width="100%" minHeight="100vh" color="brand.text.main" backgroundColor="brand.background">
      <EntrySteps step={step} />
      <Box width="100%" paddingX="8px">
        {children}
      </Box>
    </Box>
  )
}
