/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OfferReadSellerGradePenalties,
  OfferReadSellerGradePenaltiesFromJSON,
  OfferReadSellerGradePenaltiesFromJSONTyped,
  OfferReadSellerGradePenaltiesToJSON,
} from './'

/**
 * [現物契約] オファー（Read）
 * @export
 * @interface SpotContractOfferReadSeller
 */
export interface SpotContractOfferReadSeller {
  /**
   * ID
   * @type {number}
   * @memberof SpotContractOfferReadSeller
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof SpotContractOfferReadSeller
   */
  readonly versionNumber: number
  /**
   * 削除フラグ（true: 削除済、false: 未削除）
   * @type {boolean}
   * @memberof SpotContractOfferReadSeller
   */
  readonly isDeleted: boolean
  /**
   * [現物契約] オファー公開期間ID
   * @type {number}
   * @memberof SpotContractOfferReadSeller
   */
  offerPublicationPeriodId: number
  /**
   * 公開開始日
   * @type {Date}
   * @memberof SpotContractOfferReadSeller
   */
  readonly publishStartDate: Date
  /**
   * 公開終了日
   * @type {Date}
   * @memberof SpotContractOfferReadSeller
   */
  readonly publishEndDate: Date
  /**
   * 銘柄ID
   * @type {number}
   * @memberof SpotContractOfferReadSeller
   */
  brandId: number
  /**
   * 等級ID
   * @type {number}
   * @memberof SpotContractOfferReadSeller
   */
  gradeId: number
  /**
   * [現物契約] 年産ID
   * @type {number}
   * @memberof SpotContractOfferReadSeller
   */
  annumId: number
  /**
   * 都道府県IDリスト（空不可）
   * @type {Array<number>}
   * @memberof SpotContractOfferReadSeller
   */
  prefectureIds: Array<number>
  /**
   * 地域ID（都道府県IDリストの要素数が2以上の場合指定不可）
   * @type {number}
   * @memberof SpotContractOfferReadSeller
   */
  regionId: number | null
  /**
   * オファー単価（円／俵）
   * @type {number}
   * @memberof SpotContractOfferReadSeller
   */
  unitPrice: number
  /**
   * 最低取引口数
   * @type {number}
   * @memberof SpotContractOfferReadSeller
   */
  lowestTradingUnits: number
  /**
   * 最大取引口数（未指定の場合は募集口数と同じ値に設定される）
   * @type {number}
   * @memberof SpotContractOfferReadSeller
   */
  highestTradingUnits: number
  /**
   * 受入可能な包装形態IDリスト（いずれか1つは必須）
   * @type {Array<number>}
   * @memberof SpotContractOfferReadSeller
   */
  packagingFormIds: Array<number>
  /**
   * 単価の増額提案可否
   * @type {boolean}
   * @memberof SpotContractOfferReadSeller
   */
  canAdjustUnitPrice: boolean
  /**
   * [現物契約] 成約後保管可能期間IDリスト（最低、「標準(成約後14日以内に引き取り)」は必須）
   * @type {Array<number>}
   * @memberof SpotContractOfferReadSeller
   */
  storagePeriodIds: Array<number>
  /**
   * 等級格差による減額（空可）
   * @type {Array<OfferReadSellerGradePenalties>}
   * @memberof SpotContractOfferReadSeller
   */
  gradePenalties: Array<OfferReadSellerGradePenalties>
  /**
   * [現物契約] 特別条件IDリスト（空可）
   * @type {Array<number>}
   * @memberof SpotContractOfferReadSeller
   */
  specialConditionIds: Array<number>
  /**
   * 特別条件メモ
   * @type {string}
   * @memberof SpotContractOfferReadSeller
   */
  specialConditionsMemo: string
  /**
   * 残口数
   * @type {number}
   * @memberof SpotContractOfferReadSeller
   */
  readonly tradingUnitsLeft: number
  /**
   * オファーステータス（"can_answer": 未回答・回答可能、"answered": 回答済・回答不可能、"cannot_answer": 未回答・回答不可能）
   * @type {string}
   * @memberof SpotContractOfferReadSeller
   */
  readonly answerStatus: SpotContractOfferReadSellerAnswerStatusEnum
  /**
   * オファー生成日時
   * @type {Date}
   * @memberof SpotContractOfferReadSeller
   */
  readonly createdAt: Date
}

/**
 * @export
 * @enum {string}
 */
export enum SpotContractOfferReadSellerAnswerStatusEnum {
  CanAnswer = 'can_answer',
  Answered = 'answered',
  CannotAnswer = 'cannot_answer',
}

export function SpotContractOfferReadSellerFromJSON(json: any): SpotContractOfferReadSeller {
  return SpotContractOfferReadSellerFromJSONTyped(json, false)
}

export function SpotContractOfferReadSellerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpotContractOfferReadSeller {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isDeleted: json['is_deleted'],
    offerPublicationPeriodId: json['offer_publication_period_id'],
    publishStartDate: new Date(json['publish_start_date']),
    publishEndDate: new Date(json['publish_end_date']),
    brandId: json['brand_id'],
    gradeId: json['grade_id'],
    annumId: json['annum_id'],
    prefectureIds: json['prefecture_ids'],
    regionId: json['region_id'],
    unitPrice: json['unit_price'],
    lowestTradingUnits: json['lowest_trading_units'],
    highestTradingUnits: json['highest_trading_units'],
    packagingFormIds: json['packaging_form_ids'],
    canAdjustUnitPrice: json['can_adjust_unit_price'],
    storagePeriodIds: json['storage_period_ids'],
    gradePenalties: (json['grade_penalties'] as Array<any>).map(OfferReadSellerGradePenaltiesFromJSON),
    specialConditionIds: json['special_condition_ids'],
    specialConditionsMemo: json['special_conditions_memo'],
    tradingUnitsLeft: json['trading_units_left'],
    answerStatus: json['answer_status'],
    createdAt: new Date(json['created_at']),
  }
}

export function SpotContractOfferReadSellerToJSON(value?: SpotContractOfferReadSeller | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    offer_publication_period_id: value.offerPublicationPeriodId,
    brand_id: value.brandId,
    grade_id: value.gradeId,
    annum_id: value.annumId,
    prefecture_ids: value.prefectureIds,
    region_id: value.regionId,
    unit_price: value.unitPrice,
    lowest_trading_units: value.lowestTradingUnits,
    highest_trading_units: value.highestTradingUnits,
    packaging_form_ids: value.packagingFormIds,
    can_adjust_unit_price: value.canAdjustUnitPrice,
    storage_period_ids: value.storagePeriodIds,
    grade_penalties: (value.gradePenalties as Array<any>).map(OfferReadSellerGradePenaltiesToJSON),
    special_condition_ids: value.specialConditionIds,
    special_conditions_memo: value.specialConditionsMemo,
  }
}
