/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { GradeRead, GradeReadFromJSON, GradeReadFromJSONTyped, GradeReadToJSON } from './'

/**
 *
 * @export
 * @interface AmendReadSellerGradePenalties
 */
export interface AmendReadSellerGradePenalties {
  /**
   * 等級ID
   * @type {number}
   * @memberof AmendReadSellerGradePenalties
   */
  gradeId: number
  /**
   * 減額する金額（円）（nullは「受け取らない」の意味）
   * @type {number}
   * @memberof AmendReadSellerGradePenalties
   */
  penaltyInYen: number | null
  /**
   * 等級
   * @type {GradeRead}
   * @memberof AmendReadSellerGradePenalties
   */
  grade: GradeRead
}

export function AmendReadSellerGradePenaltiesFromJSON(json: any): AmendReadSellerGradePenalties {
  return AmendReadSellerGradePenaltiesFromJSONTyped(json, false)
}

export function AmendReadSellerGradePenaltiesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AmendReadSellerGradePenalties {
  if (json === undefined || json === null) {
    return json
  }
  return {
    gradeId: json['grade_id'],
    penaltyInYen: json['penalty_in_yen'],
    grade: GradeReadFromJSON(json['grade']),
  }
}

export function AmendReadSellerGradePenaltiesToJSON(value?: AmendReadSellerGradePenalties | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    grade_id: value.gradeId,
    penalty_in_yen: value.penaltyInYen,
    grade: GradeReadToJSON(value.grade),
  }
}
