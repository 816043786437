/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * メール通知設定
 * @export
 * @interface SettingMailNotification
 */
export interface SettingMailNotification {
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof SettingMailNotification
   */
  versionNumber: number
  /**
   * やることが追加された時の通知の有効・無効
   * @type {boolean}
   * @memberof SettingMailNotification
   */
  whenAddedPendingTask: boolean
  /**
   * 取引に進捗があった時の通知の有効・無効
   * @type {boolean}
   * @memberof SettingMailNotification
   */
  whenProcessedDeal: boolean
  /**
   * 組織情報の更新があった時の通知の有効・無効
   * @type {boolean}
   * @memberof SettingMailNotification
   */
  whenChangedProfile: boolean
}

export function SettingMailNotificationFromJSON(json: any): SettingMailNotification {
  return SettingMailNotificationFromJSONTyped(json, false)
}

export function SettingMailNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingMailNotification {
  if (json === undefined || json === null) {
    return json
  }
  return {
    versionNumber: json['version_number'],
    whenAddedPendingTask: json['when_added_pending_task'],
    whenProcessedDeal: json['when_processed_deal'],
    whenChangedProfile: json['when_changed_profile'],
  }
}

export function SettingMailNotificationToJSON(value?: SettingMailNotification | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    version_number: value.versionNumber,
    when_added_pending_task: value.whenAddedPendingTask,
    when_processed_deal: value.whenProcessedDeal,
    when_changed_profile: value.whenChangedProfile,
  }
}
