import { Box, Text, Divider as ChakraDivider, List } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useIsMobile } from '../../hooks/useIsMobile'

export function ContentBox({
  children,
  marginTop = '0',
  marginBottom = '10px',
  padding = '0',
}: {
  children: React.ReactNode
  marginTop?: string
  marginBottom?: string
  padding?: string
}) {
  return (
    <Box
      marginTop={marginTop}
      marginBottom={marginBottom}
      padding={padding}
      backgroundColor="brand.box.background"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="brand.box.border"
      borderRadius="5px"
    >
      {children}
    </Box>
  )
}

export const BoxLeftRight = ({ title, content }: { title: string; content: string | React.ReactNode }) => (
  <LabelContentCombination title={title} content={content} titleTextAlign="left" contentTextAlign="right" />
)

export const BoxLeftLeft = ({ title, content }: { title: string; content: string | React.ReactNode }) => (
  <LabelContentCombination title={title} content={content} titleTextAlign="left" contentTextAlign="left" />
)

export const BoxLeftLeftSmall = ({ title, content }: { title: string; content: string | React.ReactNode }) => (
  <LabelContentCombination
    title={title}
    content={content}
    titleTextAlign="left"
    contentTextAlign="left"
    titleFontSize="16px"
    contentFontSize="16px"
  />
)

export const BoxLeftRightSmall = ({ title, content }: { title: string; content: string | React.ReactNode }) => (
  <LabelContentCombination
    title={title}
    content={content}
    titleTextAlign="left"
    contentTextAlign="right"
    titleFontSize="16px"
    contentFontSize="16px"
  />
)

export const BoxLeftNode = ({ title, content }: { title: string; content: string | React.ReactNode }) => (
  <LabelContentCombination title={title} content={content} titleTextAlign="left" />
)

export const BoxCenterNodeSmall = ({ title, content }: { title: string; content: string | React.ReactNode }) => (
  <LabelContentCombination title={title} content={content} titleTextAlign="center" titleFontSize="16px" />
)

export const BoxCenterLeftSmall = ({ title, content }: { title: string; content: string | React.ReactNode }) => (
  <LabelContentCombination
    title={title}
    content={content}
    titleTextAlign="center"
    titleFontSize="16px"
    contentTextAlign="left"
    contentFontSize="16px"
  />
)

function LabelContentCombination({
  title,
  content,
  titleTextAlign,
  titleFontSize = '20px',
  contentTextAlign = 'right',
  contentFontSize = '20px',
}: {
  title: string
  content: string | React.ReactNode
  titleTextAlign: 'center' | 'left' | 'right' | 'start'
  titleFontSize?: string // px指定
  contentTextAlign?: 'center' | 'left' | 'right'
  contentFontSize?: string // px指定
}) {
  return (
    <Box margin="20px">
      <Text fontSize={titleFontSize} fontWeight="bold" textAlign={titleTextAlign}>
        {title}
      </Text>
      <Divider />
      {typeof content === 'string' && (
        <Text fontSize={contentFontSize} textAlign={contentTextAlign}>
          {content}
        </Text>
      )}
      {typeof content !== 'string' && content}
    </Box>
  )
}

export const Divider = () => (
  <ChakraDivider margin="5px 0" borderStyle="dashed" borderColor="brand.box.border" opacity={1} />
)

export const IndentList = styled(List)`
  li {
    padding-left: 1em;
    text-indent: -1em;
    font-size: 16px;
  }
`

// solid dividerで分割する
export const DataDisplay = ({
  label,
  contents,
  blankText = '未確定',
}: {
  label: string
  contents: string | Date | React.ReactNode | null
  blankText?: string
}) => {
  const isMobile = useIsMobile()
  return (
    <>
      <Text fontSize="16px" fontWeight="bold">
        {label}
      </Text>
      <ChakraDivider borderColor="brand.contentsDivider" margin={isMobile ? '5px 0' : '6px 0'} opacity={1} />
      {!contents && (
        <Text fontSize="16px" textAlign="right" color="brand.text.disabled">
          {blankText}
        </Text>
      )}
      {contents && (
        <Text fontSize="16px" textAlign="right" wordBreak="break-all">
          {contents}
        </Text>
      )}
    </>
  )
}
