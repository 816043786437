import { useHistory } from 'react-router'
import { Box, Text } from '@chakra-ui/react'
import type { BoxProps } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'

import { buildPageTitle } from '../../utils/buildPageTitle'
import { EntryLayout } from './components/Layout/EntryLayout'
import { ButtonSubmit } from '../components/Buttons/Buttons'
import { RedirectLink } from './components/Navigation/RedirectLink'
import { NeedSelect } from '../components/Select/NeedSelect'
import { Select } from '../components/Select/Select'
import { Input } from '../components/Input/Input'
import { MasterList } from '../PrivatePages/Concept/lib/manageFetchMasters'
import { BrandNormalized } from '../../domain/brand/brand'
import { Grade } from '../../domain/grade/grade'
import { SpecialCondition } from '../../domain/specialCondition/specialCondition'
import { useOfferRequestInput } from './hooks/entry4/useOfferRequestInput'
import { ValidationErrorText } from '../components/Validation/ValidationErrorText'
import { ValidationError } from 'class-validator'
import { useCreateOfferRequest } from './hooks/entry4/useCreateOfferRequest'
import { BrandFilterService } from '../../domain/brand/brandFilterService'
import { FormField } from './components/Layout/FormField'
import { BrandCompareService } from '../../domain/brand/brandCompareService'
import { Checkbox } from '../components/Checkbox/Checkbox'

export const Entry4 = ({
  masters,
  prefectureId,
  regionId,
}: {
  masters: Pick<MasterList, 'brands' | 'grades' | 'specialConditions'>
  prefectureId: number
  regionId: number | null
}) => {
  const history = useHistory()
  const redirectToHome = () => history.push('/home')

  const {
    state: { options, input, changed, validated, validationErrors },
    setValue,
    toChanged,
    toggleBrandFilter,
  } = useOfferRequestInput(regionId, masters.brands)
  const { save, fetching } = useCreateOfferRequest(validated)

  const saveOfferRequest = async () => {
    await save()
    redirectToHome()
  }

  return (
    <>
      <Helmet>
        <title>{buildPageTitle('新規利用開始 希望オファー入力')}</title>
      </Helmet>

      <EntryLayout step={4}>
        <Box marginY="20px" textAlign="center">
          <Text>
            希望オファーを登録いただくことで
            <br />
            あなたにあったオファーが届きやすくなります。
            <br />
            （この登録は省略することができます。）
          </Text>
          <RedirectToHomeLink id="gtm-km1baApp_entry04_Omit01" onClick={redirectToHome} />
          <Text marginTop="20px" fontWeight="bold">
            希望オファーの内容は登録完了後に
            <br />
            米市場ホーム画面の「希望オファー入力」から修正出来ます。
          </Text>
          <Text fontWeight="bold">また、別の希望オファーがある場合は最大5件まで追加登録することができます。</Text>
        </Box>

        <SelectBrandForm
          prefectureId={prefectureId}
          isBrandFilterActive={options.isBrandFilterActive}
          toggleBrandFilter={toggleBrandFilter}
          brandsMaster={masters.brands.sort(BrandCompareService.compareBrandNameKana('ASC'))}
          selectedValue={input.brandId}
          onChange={(value) => {
            setValue('brandId', value)
            toChanged('brandId')
          }}
          isChanged={changed.brandId}
          validationError={validationErrors.find((err) => err.property === 'brandId') || null}
        />
        <SelectGradeForm
          gradesMaster={masters.grades}
          selectedValue={input.gradeId}
          onChange={(value) => {
            setValue('gradeId', value)
            toChanged('gradeId')
          }}
          isChanged={changed.gradeId}
          validationError={validationErrors.find((err) => err.property === 'gradeId') || null}
        />
        <InputUnitPriceForm
          inputValue={input.unitPrice}
          onChange={(value) => setValue('unitPrice', value)}
          onBlur={() => toChanged('unitPrice')}
          isChanged={changed.unitPrice}
          validationError={validationErrors.find((err) => err.property === 'unitPrice') || null}
        />
        <SelectSpecialConditionForm
          labelNumber={0}
          specialConditionsMaster={masters.specialConditions}
          selectedValue={input.specialConditionIds[0]}
          onChange={(value) => {
            setValue('specialConditionIds', [value, input.specialConditionIds[1]])
            toChanged('specialConditionIds')
          }}
          isChanged={changed.specialConditionIds}
          validationError={validationErrors.find((err) => err.property === 'specialConditionIds') || null}
        />
        <SelectSpecialConditionForm
          labelNumber={1}
          specialConditionsMaster={masters.specialConditions}
          selectedValue={input.specialConditionIds[1]}
          onChange={(value) => {
            setValue('specialConditionIds', [input.specialConditionIds[0], value])
            toChanged('specialConditionIds')
          }}
          isChanged={changed.specialConditionIds}
          validationError={validationErrors.find((err) => err.property === 'specialConditionIds') || null}
        />

        <Box marginTop="30px" marginBottom="20px" textAlign="center">
          <ButtonSubmit
            id="gtm-km1baApp_entry04_RegistBtn"
            width="280px"
            onClick={saveOfferRequest}
            isDisabled={validationErrors.length !== 0 || fetching}
          >
            この内容で登録、利用開始
          </ButtonSubmit>
        </Box>
        <RedirectToHomeLink id="gtm-km1baApp_entry04_Omit02" onClick={redirectToHome} paddingBottom="80px" />
      </EntryLayout>
    </>
  )
}

const SelectBrandForm = ({
  prefectureId,
  isBrandFilterActive,
  toggleBrandFilter,
  brandsMaster,
  selectedValue,
  onChange,
  isChanged,
  validationError,
}: {
  prefectureId: number
  isBrandFilterActive: boolean
  toggleBrandFilter: () => void
  brandsMaster: BrandNormalized[]
  selectedValue: string
  onChange: (value: string) => void
  isChanged: boolean
  validationError: ValidationError | null
}) => {
  const brandList = isBrandFilterActive
    ? brandsMaster.filter(BrandFilterService.inPrefecture(prefectureId))
    : brandsMaster
  return (
    <FormField
      titleLabel="銘柄"
      isRequire={true}
      formElement={
        <>
          <Box marginBottom="2px">
            <Checkbox isChecked={isBrandFilterActive} onChange={toggleBrandFilter}>
              <Text fontSize="14px">自身の県産に登録のある銘柄に絞り込む</Text>
            </Checkbox>
          </Box>
          <Select value={selectedValue} onChange={(e) => onChange(e.target.value)}>
            <NeedSelect />
            {brandList.map((brand) => (
              <option key={brand.id} value={brand.id}>
                {brand.name}
              </option>
            ))}
          </Select>
        </>
      }
      errorMessage={isChanged && !!validationError && <ValidationErrorText text="選択してください" />}
    />
  )
}

const SelectGradeForm = ({
  gradesMaster,
  selectedValue,
  onChange,
  isChanged,
  validationError,
}: {
  gradesMaster: Grade[]
  selectedValue: string
  onChange: (value: string) => void
  isChanged: boolean
  validationError: ValidationError | null
}) => {
  return (
    <FormField
      titleLabel="等級"
      isRequire={true}
      formElement={
        <Select value={selectedValue} onChange={(e) => onChange(e.target.value)}>
          <NeedSelect />
          {gradesMaster.map((grades) => (
            <option key={grades.id} value={grades.id}>
              {grades.name}
            </option>
          ))}
        </Select>
      }
      errorMessage={isChanged && !!validationError && <ValidationErrorText text="選択してください" />}
    />
  )
}

const InputUnitPriceForm = ({
  inputValue,
  onChange,
  onBlur,
  isChanged,
  validationError,
}: {
  inputValue: string
  onChange: (value: string) => void
  onBlur: () => void
  isChanged: boolean
  validationError: ValidationError | null
}) => {
  return (
    <FormField
      titleLabel="１俵(60kg)あたり単価"
      isRequire={true}
      formElement={
        <Box
          borderWidth="1px"
          marginTop="10px"
          borderColor="brand.box.border"
          backgroundColor="white"
          borderRadius="5px"
        >
          <Input
            display="inline-block"
            margin="4px 4px 4px 20px"
            padding="0 5px"
            width="64px"
            height="30px"
            borderColor="brand.box.border"
            borderRadius="0"
            textAlign="right"
            placeholder="0"
            type="number"
            pattern="\d*"
            backgroundColor="white"
            value={inputValue}
            onChange={(e) => onChange(e.target.value)}
            onBlur={onBlur}
          />
          <Text display="inline">00円以上</Text>
        </Box>
      }
      formNote="この値は、後から変更できます。"
      errorMessage={
        isChanged && !!validationError && <ValidationErrorText text="1〜999の範囲で整数を入力してください" />
      }
    />
  )
}

const SelectSpecialConditionForm = ({
  labelNumber,
  specialConditionsMaster,
  selectedValue,
  onChange,
  isChanged,
  validationError,
}: {
  labelNumber: 0 | 1
  specialConditionsMaster: SpecialCondition[]
  selectedValue: string
  onChange: (value: string) => void
  isChanged: boolean
  validationError: ValidationError | null
}) => {
  return (
    <FormField
      titleLabel={`特別条件${labelNumber === 0 ? '①' : '②'}`}
      note={
        <Text wordBreak="keep-all">
          有機JASなどの条件付きで販売したい場合は
          <wbr />
          選択してください
        </Text>
      }
      formElement={
        <Select value={selectedValue} onChange={(e) => onChange(e.target.value)}>
          <NeedSelect />
          {specialConditionsMaster.map((specialCondition) => (
            <option key={specialCondition.id} value={specialCondition.id}>
              {specialCondition.displayText}
            </option>
          ))}
        </Select>
      }
      errorMessage={
        labelNumber === 1 &&
        isChanged &&
        !!validationError && <ValidationErrorText text="1つめの特別条件とは別のものを選択してください" />
      }
    />
  )
}

const RedirectToHomeLink = ({ id, onClick, ...props }: { id: string; onClick: () => void } & BoxProps) => {
  return (
    <RedirectLink id={id} onClick={onClick} {...props}>
      省略して、利用開始
    </RedirectLink>
  )
}
