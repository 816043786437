/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
  SpotContractIndividualDeliveryHasProducerReviewed,
  SpotContractIndividualDeliveryHasProducerReviewedFromJSON,
  SpotContractIndividualDeliveryHasProducerReviewedToJSON,
  SpotContractIndividualDeliveryReadSeller,
  SpotContractIndividualDeliveryReadSellerFromJSON,
  SpotContractIndividualDeliveryReadSellerToJSON,
} from '../models'

export interface PutSpotContractIndividualDeliveriesIdReviewRequest {
  id: number
  spotContractIndividualDeliveryHasProducerReviewed?: SpotContractIndividualDeliveryHasProducerReviewed
}

/**
 *
 */
export class SpotContractIndividualDeliveriesApi extends runtime.BaseAPI {
  /**
   * [現物契約] 指定IDに該当する個別運送情報の確認を報告する
   */
  async putSpotContractIndividualDeliveriesIdReviewRaw(
    requestParameters: PutSpotContractIndividualDeliveriesIdReviewRequest
  ): Promise<runtime.ApiResponse<SpotContractIndividualDeliveryReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putSpotContractIndividualDeliveriesIdReview.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/spot_contract/individual_deliveries/{id}/review`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: SpotContractIndividualDeliveryHasProducerReviewedToJSON(
        requestParameters.spotContractIndividualDeliveryHasProducerReviewed
      ),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SpotContractIndividualDeliveryReadSellerFromJSON(jsonValue)
    )
  }

  /**
   * [現物契約] 指定IDに該当する個別運送情報の確認を報告する
   */
  async putSpotContractIndividualDeliveriesIdReview(
    requestParameters: PutSpotContractIndividualDeliveriesIdReviewRequest
  ): Promise<SpotContractIndividualDeliveryReadSeller> {
    const response = await this.putSpotContractIndividualDeliveriesIdReviewRaw(requestParameters)
    return await response.value()
  }
}
