import { Tabs, Tab, TabList, Text, Flex } from '@chakra-ui/react'
import { Link, useRouteMatch } from 'react-router-dom'

import { theme } from '../../../theme/theme'

import { HomeIcon } from '../svg/HomeIcon'
import { OfferIcon } from '../svg/OfferIcon'
import { DealIcon } from '../svg/DealIcon'
import { useIsMobile } from '../../hooks/useIsMobile'
import { appendAnnumQueryParamsTo } from '../../PrivatePages/hooks/useAnnumsAndSelectedId'

const selectedStyle = {
  color: 'brand.text.main',
  bg: 'brand.brandHighlight',
  boxShadow: '0px 0px 8px #0000005C',
  ':focus': { boxShadow: '0px 0px 8px #0000005C' },
}

const linkStyle = {
  padding: '12px 0 11px',
  border: '0',
  display: 'block',
  width: '100%',
  height: '100%',
}

export function Footer() {
  const tabIndex = ['/home', '/offers', '/deals']
    .map(useRouteMatch)
    .map((match) => !!match) // 全く異なるURLなので、先頭マッチで見つかったもので良い
    .findIndex((v) => v)

  const isMobile = useIsMobile()
  const { footerHeight } = theme.sizes.brand

  const currentUrl = location.hash.replace('#', '')

  return isMobile ? (
    <footer>
      <Tabs
        position="fixed"
        height={footerHeight}
        bottom={0}
        isFitted
        variant="enclosed"
        width="100%"
        zIndex={3}
        index={tabIndex}
      >
        <TabList bg="brand.brand" borderRadius={isMobile ? '5px 5px 0 0' : '0'} boxShadow="3px 0px 6px #00000029">
          <Tab padding={0} key={0} _selected={selectedStyle}>
            <Link to={tabIndex === 0 ? currentUrl : appendAnnumQueryParamsTo('/home')} style={linkStyle}>
              <Flex align="center" justify="center">
                <HomeIcon />
                <Text fontSize="16px" fontWeight="bold" marginLeft="8px">
                  ホーム
                </Text>
              </Flex>
            </Link>
          </Tab>
          <Tab padding={0} key={1} _selected={selectedStyle}>
            <Link to={tabIndex === 1 ? currentUrl : appendAnnumQueryParamsTo('/offers')} style={linkStyle}>
              <Flex align="center" justify="center">
                <OfferIcon height={23} />
                <Text fontSize="16px" fontWeight="bold" marginLeft="8px">
                  オファー
                </Text>
              </Flex>
            </Link>
          </Tab>
          <Tab padding={0} key={2} _selected={selectedStyle}>
            <Link to={tabIndex === 2 ? currentUrl : appendAnnumQueryParamsTo('/deals')} style={linkStyle}>
              <Flex align="center" justify="center">
                <DealIcon />
                <Text fontSize="16px" fontWeight="bold" marginLeft="8px">
                  取引案件
                </Text>
              </Flex>
            </Link>
          </Tab>
        </TabList>
      </Tabs>
    </footer>
  ) : null
}
