import * as Sentry from '@sentry/react'
import { noop } from '../utils/noop'
import logout from '../presentation/PrivatePages/Logout/Logout'

export function handleApiServerError(err: Response, captureException: (err: Response) => void = noop) {
  if (err.status !== 503 && (err.status < 400 || err.status > 499)) {
    alert('予期せぬエラーが発生しました。時間を置いて再度お試しください。')
    captureException(err)
  }
  console.error(err.status)
  console.error(err)
}

export function handle503Error(err: Response) {
  if (err.status === 503) {
    location.href = '/#/maintenance'
  }
}

export function handle401Error(
  message: string = 'ログイン情報が確認できませんでした。再度アグリノートにログインしてからご利用ください。'
) {
  return function (err: Response, handleAfter401Error: () => void) {
    if (err.status === 401) {
      alert(message)
      handleAfter401Error && handleAfter401Error()
    }
    console.error(err.status)
    console.error(err)
  }
}

export function handle409Error(message: string = '情報が更新されました。ブラウザをリロードしてください。') {
  return function (err: Response, handleAfter409Error: () => void) {
    if (err.status === 409) {
      alert(message)
      handleAfter409Error && handleAfter409Error()
    }
    console.error(err.status)
    console.error(err)
  }
}

export function captureException(err: unknown) {
  Sentry.captureException(err)
}

export function handle4xxWithout401Error(message: string) {
  return function (err: Response, handleAfterError: () => void) {
    if (err.status >= 400 && err.status < 500 && err.status !== 401) {
      alert(message)
      handleAfterError()
    }
    console.error(err.status)
    console.error(err)
  }
}

export function handlerNoNetworkError(err: Response) {
  if (!navigator.onLine && err.status === undefined) {
    alert('オフラインのため送信できませんでした')
  }
  console.error(err.status)
  console.error(err)
}

export function handleErrorOnSave(err: any) {
  if (err instanceof Response) {
    handle503Error(err)
    handleApiServerError(err)
    handle401Error()(err, logout)
    handle409Error()(err, noop)
    handle4xxWithout401Error('予期せぬエラーが発生しました。時間を置いて再度お試しください。')(err, noop)
    handlerNoNetworkError(err)
    captureException(err)
  }
}
