/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 生産者の利用規約同意状況（Update、Seller）
 * @export
 * @interface ProducerTosAgreementUpdateSeller
 */
export interface ProducerTosAgreementUpdateSeller {
  /**
   * 対象の利用規約バージョンの識別子
   *
   * 最新のバージョンと異なる場合、409エラーが返ってくる
   * @type {string}
   * @memberof ProducerTosAgreementUpdateSeller
   */
  tosVersion: string
  /**
   * 利用規約同意フラグ
   *
   * デフォルトではfalse
   *
   * ユーザの操作によりtrueに切り替える
   *
   * falseの場合、403エラーが返ってくる
   * @type {boolean}
   * @memberof ProducerTosAgreementUpdateSeller
   */
  tosAgreementFlag: boolean
}

export function ProducerTosAgreementUpdateSellerFromJSON(json: any): ProducerTosAgreementUpdateSeller {
  return ProducerTosAgreementUpdateSellerFromJSONTyped(json, false)
}

export function ProducerTosAgreementUpdateSellerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProducerTosAgreementUpdateSeller {
  if (json === undefined || json === null) {
    return json
  }
  return {
    tosVersion: json['tos_version'],
    tosAgreementFlag: json['tos_agreement_flag'],
  }
}

export function ProducerTosAgreementUpdateSellerToJSON(value?: ProducerTosAgreementUpdateSeller | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    tos_version: value.tosVersion,
    tos_agreement_flag: value.tosAgreementFlag,
  }
}
