/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 最新の利用規約の情報（Read、Seller）
 * @export
 * @interface TosReadSeller
 */
export interface TosReadSeller {
  /**
   * 最新の利用規約バージョンの識別子
   * @type {string}
   * @memberof TosReadSeller
   */
  version: string
}

export function TosReadSellerFromJSON(json: any): TosReadSeller {
  return TosReadSellerFromJSONTyped(json, false)
}

export function TosReadSellerFromJSONTyped(json: any, ignoreDiscriminator: boolean): TosReadSeller {
  if (json === undefined || json === null) {
    return json
  }
  return {
    version: json['version'],
  }
}

export function TosReadSellerToJSON(value?: TosReadSeller | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    version: value.version,
  }
}
