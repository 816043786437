import { useState } from 'react'
import { useEffectOnce } from 'react-use'

import { useManageFetchOFMasters } from '../Concept/lib/manageFetchMasters'
import { useLogoutAfter401Error } from '../../auth/useLogout'
import {
  captureException,
  handle401Error,
  handle503Error,
  handleApiServerError,
  handlerNoNetworkError,
} from '../../../infra/handleApiServerError'

import type { FetchState } from '../../types/utilTypes'
import type { MasterName, MasterList } from '../Concept/lib/manageFetchMasters'

type MasterListPartial = Partial<Record<MasterName, MasterList>>
type ReturnMasters<MasterKeys extends MasterName> = Pick<MasterList, MasterKeys>

type State<ReturnMasters extends MasterListPartial> = FetchState & { data: { masters: ReturnMasters } }
const initState = <ReturnMasters extends MasterListPartial>(masterKeysArray: MasterName[]): State<ReturnMasters> => {
  const masterBlankEntries = masterKeysArray.map((key) => [key, []])
  return {
    fetched: false,
    fetching: true,
    data: {
      masters: Object.fromEntries(masterBlankEntries),
    },
  }
}

export const useFetchMasters = <MasterKeys extends MasterName = MasterName>(
  masterKeysArray: MasterName[]
): State<ReturnMasters<MasterKeys>> => {
  const [state, setState] = useState<State<ReturnMasters<MasterKeys>>>(initState(masterKeysArray))
  const { fetchMasters, listPromisesWithoutInactive } = useManageFetchOFMasters()
  const logout = useLogoutAfter401Error()

  useEffectOnce(() => {
    ;(async () => {
      try {
        fetchMasters(masterKeysArray, logout)

        const masters = await masterKeysArray.reduce(async (acc, current) => {
          return { ...(await acc), [current]: await listPromisesWithoutInactive[current] }
        }, Promise.resolve<ReturnMasters<MasterKeys>>({} as ReturnMasters<MasterKeys>))

        setState({
          fetching: false,
          fetched: true,
          data: { masters },
        })
      } catch (err) {
        if (err instanceof Response) {
          handle503Error(err)
          handleApiServerError(err, captureException)
          handle401Error()(err, logout)
          handlerNoNetworkError(err)
        }
      }
    })()
  })

  return state
}
