import styled from '@emotion/styled'

export const ContactIcon = () => (
  <Svg width="30" height="30" viewBox="0 0 30 30">
    <g>
      <path
        className="cls-3"
        d="M254.371,229.579a5.809,5.809,0,0,0,1.1-3.363,5.98,5.98,0,0,0-1.646-4.043,8.524,8.524,0,0,0-4.037-2.373l-.422,1.559a6.835,6.835,0,0,1,3.275,1.91,4.38,4.38,0,0,1,1.225,2.953,4.167,4.167,0,0,1-.8,2.432,6.11,6.11,0,0,1-2.209,1.863l-.68.352.885,1.975a35.417,35.417,0,0,1-6.422-1.828l-.023-.012h-.023a6.243,6.243,0,0,1-3.668-2.795l-1.418.779a7.818,7.818,0,0,0,4.553,3.539h0a34.913,34.913,0,0,0,8.25,2.133l1.4.158-1.459-3.258a7.513,7.513,0,0,0,2.133-1.98"
        transform="translate(-225.467 -206.921)"
      />
      <path
        className="cls-3"
        d="M21.041,50.789a8.12,8.12,0,0,0,2.1-5.367,7.8,7.8,0,0,0-.943-3.7,10.125,10.125,0,0,0-4.23-4.078,13.589,13.589,0,0,0-6.4-1.547,13.11,13.11,0,0,0-8.115,2.672,9.536,9.536,0,0,0-2.52,2.959A7.8,7.8,0,0,0,0,45.422a7.926,7.926,0,0,0,1.5,4.594,10.228,10.228,0,0,0,3.193,2.9L2.748,57.264l1.4-.158a49.532,49.532,0,0,0,11.689-3.018,11.534,11.534,0,0,0,5.2-3.3m-5.76,1.781-.023.012a50.351,50.351,0,0,1-9.867,2.725L6.779,52.2,6.1,51.85a9.017,9.017,0,0,1-3.281-2.766,6.279,6.279,0,0,1-1.2-3.662,6.182,6.182,0,0,1,.75-2.936,8.537,8.537,0,0,1,3.568-3.416,11.979,11.979,0,0,1,5.637-1.359,11.49,11.49,0,0,1,7.107,2.32,7.888,7.888,0,0,1,2.1,2.455,6.187,6.187,0,0,1,.75,2.936,6.491,6.491,0,0,1-1.705,4.3A9.962,9.962,0,0,1,15.3,52.565Z"
        transform="translate(0 -33.985)"
      />
      <path
        className="cls-3"
        d="M99.454,177.7a1.254,1.254,0,1,0,.889.369,1.254,1.254,0,0,0-.889-.369"
        transform="translate(-92.446 -167.194)"
      />
      <path
        className="cls-3"
        d="M182.154,177.7a1.254,1.254,0,1,0,.889.369,1.254,1.254,0,0,0-.889-.369"
        transform="translate(-170.301 -167.288)"
      />
      <path
        className="cls-3"
        d="M264.854,177.7a1.254,1.254,0,1,0,.889.369,1.254,1.254,0,0,0-.889-.369"
        transform="translate(-248.155 -167.288)"
      />
    </g>
  </Svg>
)

const Svg = styled.svg`
  .cls-3 {
    fill: #35363a;
  }
`
