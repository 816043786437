import { useState } from 'react'
import { useEffectOnce } from 'react-use'

import { FetchState } from '../../../types/utilTypes'
import { ProducerBasicProfileNormalized } from '../../../../domain/producerBasicProfile/producerBasicProfile'
import { ProducerBasicProfile } from '../../../../domain/producerBasicProfile/denormalizedProducerBasicProfle'

import { useLogoutAfter401Error } from '../../../auth/useLogout'
import { ProducersApi } from '../../../../libs/api_client/apis'
import { getApiConfig } from '../../../../config/apiConfig'

import {
  captureException,
  handle401Error,
  handle503Error,
  handleApiServerError,
  handlerNoNetworkError,
} from '../../../../infra/handleApiServerError'
import { useManageFetchOFMasters } from '../../Concept/lib/manageFetchMasters'
import { denormalizeProducerBasicProfile } from '../../../../domain/producerBasicProfile/denormalizeProducerBasicProfileService'
import { StoragePeriod } from '../../../../domain/storagePeriod/storagePeriod'
import { FarmingCertification } from '../../../../domain/farmingCertification/farmingCertification'
import { RegionNormalized } from '../../../../domain/region/region'

export type Masters = {
  regions: RegionNormalized[]
  storagePeriods: StoragePeriod[]
  farmingCertifications: FarmingCertification[]
}

type State = FetchState & {
  data: {
    masters: Masters
    producerBasicProfile: ProducerBasicProfile | null
  }
}

const initState = {
  fetched: false,
  fetching: true,
  data: { masters: { regions: [], storagePeriods: [], farmingCertifications: [] }, producerBasicProfile: null },
}

export const useProducerBasicProfile = () => {
  const [state, setState] = useState<State>(initState)
  const { fetchMasters, listPromisesWithoutInactive, mapPromises } = useManageFetchOFMasters()
  const logout = useLogoutAfter401Error()

  useEffectOnce(() => {
    ;(async () => {
      await fetchProducerBasicProfile()
    })()
  })

  const fetchProducerBasicProfile = async () => {
    fetchMasters(['regions', 'storagePeriods', 'farmingCertifications'], logout)
    const regions = await listPromisesWithoutInactive.regions
    const storagePeriods = await listPromisesWithoutInactive.storagePeriods
    const farmingCertifications = await listPromisesWithoutInactive.farmingCertifications
    const masters = { regions, storagePeriods, farmingCertifications }

    const regionMap = await mapPromises.regionMap
    const storagePeriodMap = await mapPromises.storagePeriodMap
    const farmingCertificationMap = await mapPromises.farmingCertificationMap

    const denormalize = denormalizeProducerBasicProfile({
      regionMap,
      storagePeriodMap,
      farmingCertificationMap,
    })

    const producersApi = new ProducersApi(getApiConfig())
    try {
      const producerBasicProfile: ProducerBasicProfileNormalized = await producersApi.getProducersMeBasicProfile()
      const denormalizedProducerBasicProfile = denormalize(producerBasicProfile)

      setState({
        fetching: false,
        fetched: true,
        data: {
          masters,
          producerBasicProfile: denormalizedProducerBasicProfile,
        },
      })
    } catch (err) {
      if (err instanceof Response) {
        handle503Error(err)
        handleApiServerError(err, captureException)
        handle401Error()(err, logout)
        handlerNoNetworkError(err)
      }
    }
  }

  return { ...state, refresh: fetchProducerBasicProfile }
}
