import { BrandNormalized } from './brand'

type Order = 'ASC' | 'DESC'

export const BrandCompareService = {
  compareBrandNameKana,
}

/**
 * 銘柄をかなでソートするときに使用する
 * @param order
 */
export function compareBrandNameKana(order: Order = 'ASC') {
  function compare(a: BrandNormalized, b: BrandNormalized) {
    const prime = a.nameKana.localeCompare(b.nameKana)
    return prime !== 0 ? prime : compareId(a, b)
  }

  return function (a: BrandNormalized, b: BrandNormalized): number {
    if (order === 'ASC') {
      return compare(a, b)
    } else {
      return compare(b, a)
    }
  }
}

function compareId(a: BrandNormalized, b: BrandNormalized): number {
  return a.id - b.id
}
