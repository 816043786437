/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  ProducersMeSettingsOfferNotificationConditionsUpdate,
  ProducersMeSettingsOfferNotificationConditionsUpdateFromJSON,
  ProducersMeSettingsOfferNotificationConditionsUpdateToJSON,
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
  SettingMailNotification,
  SettingMailNotificationFromJSON,
  SettingMailNotificationToJSON,
  SettingOfferNotification,
  SettingOfferNotificationFromJSON,
  SettingOfferNotificationToJSON,
  SettingOfferNotificationCondition,
  SettingOfferNotificationConditionFromJSON,
  SettingOfferNotificationConditionToJSON,
  SettingOfferNotificationConditionRead,
  SettingOfferNotificationConditionReadFromJSON,
  SettingOfferNotificationConditionReadToJSON,
} from '../models'

export interface DeleteProducerAccountsMeSettingsOfferNotificationConditionsIdRequest {
  id: number
  versionNumber: number
}

export interface PostSettingsOfferNotificationConditionsRequest {
  settingOfferNotificationCondition?: SettingOfferNotificationCondition
}

export interface PutProducersMeSettingsOfferNotificationRequest {
  settingOfferNotification?: SettingOfferNotification
}

export interface PutSettingsMailNotificationRequest {
  settingMailNotification?: SettingMailNotification
}

export interface PutSettingsOfferNotificationConditionsIdRequest {
  id: number
  producersMeSettingsOfferNotificationConditionsUpdate?: ProducersMeSettingsOfferNotificationConditionsUpdate
}

/**
 *
 */
export class NotificationsApi extends runtime.BaseAPI {
  /**
   * 組織のオファー通知条件を削除する
   */
  async deleteProducerAccountsMeSettingsOfferNotificationConditionsIdRaw(
    requestParameters: DeleteProducerAccountsMeSettingsOfferNotificationConditionsIdRequest
  ): Promise<runtime.ApiResponse<SettingOfferNotificationConditionRead>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling deleteProducerAccountsMeSettingsOfferNotificationConditionsId.'
      )
    }

    if (requestParameters.versionNumber === null || requestParameters.versionNumber === undefined) {
      throw new runtime.RequiredError(
        'versionNumber',
        'Required parameter requestParameters.versionNumber was null or undefined when calling deleteProducerAccountsMeSettingsOfferNotificationConditionsId.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.versionNumber !== undefined) {
      queryParameters['version_number'] = requestParameters.versionNumber
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producers/me/settings/offer_notification_conditions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SettingOfferNotificationConditionReadFromJSON(jsonValue)
    )
  }

  /**
   * 組織のオファー通知条件を削除する
   */
  async deleteProducerAccountsMeSettingsOfferNotificationConditionsId(
    requestParameters: DeleteProducerAccountsMeSettingsOfferNotificationConditionsIdRequest
  ): Promise<SettingOfferNotificationConditionRead> {
    const response = await this.deleteProducerAccountsMeSettingsOfferNotificationConditionsIdRaw(requestParameters)
    return await response.value()
  }

  /**
   * 自身のメール通知の設定情報を取得する
   */
  async getSettingsMailNotificationsRaw(): Promise<runtime.ApiResponse<SettingMailNotification>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producer_accounts/me/settings/mail_notification`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SettingMailNotificationFromJSON(jsonValue))
  }

  /**
   * 自身のメール通知の設定情報を取得する
   */
  async getSettingsMailNotifications(): Promise<SettingMailNotification> {
    const response = await this.getSettingsMailNotificationsRaw()
    return await response.value()
  }

  /**
   * 組織のオファー通知の設定を取得する
   */
  async getSettingsOfferNotificationRaw(): Promise<runtime.ApiResponse<SettingOfferNotification>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producers/me/settings/offer_notification`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SettingOfferNotificationFromJSON(jsonValue))
  }

  /**
   * 組織のオファー通知の設定を取得する
   */
  async getSettingsOfferNotification(): Promise<SettingOfferNotification> {
    const response = await this.getSettingsOfferNotificationRaw()
    return await response.value()
  }

  /**
   * 組織のオファー通知条件一覧を取得する
   */
  async getSettingsOfferNotificationConditionsRaw(): Promise<
    runtime.ApiResponse<Array<SettingOfferNotificationConditionRead>>
  > {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producers/me/settings/offer_notification_conditions`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(SettingOfferNotificationConditionReadFromJSON)
    )
  }

  /**
   * 組織のオファー通知条件一覧を取得する
   */
  async getSettingsOfferNotificationConditions(): Promise<Array<SettingOfferNotificationConditionRead>> {
    const response = await this.getSettingsOfferNotificationConditionsRaw()
    return await response.value()
  }

  /**
   * 組織のオファー通知条件を登録する
   */
  async postSettingsOfferNotificationConditionsRaw(
    requestParameters: PostSettingsOfferNotificationConditionsRequest
  ): Promise<runtime.ApiResponse<SettingOfferNotificationConditionRead>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producers/me/settings/offer_notification_conditions`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: SettingOfferNotificationConditionToJSON(requestParameters.settingOfferNotificationCondition),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SettingOfferNotificationConditionReadFromJSON(jsonValue)
    )
  }

  /**
   * 組織のオファー通知条件を登録する
   */
  async postSettingsOfferNotificationConditions(
    requestParameters: PostSettingsOfferNotificationConditionsRequest
  ): Promise<SettingOfferNotificationConditionRead> {
    const response = await this.postSettingsOfferNotificationConditionsRaw(requestParameters)
    return await response.value()
  }

  /**
   * 組織のオファー通知の設定を更新する
   */
  async putProducersMeSettingsOfferNotificationRaw(
    requestParameters: PutProducersMeSettingsOfferNotificationRequest
  ): Promise<runtime.ApiResponse<SettingOfferNotification>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producers/me/settings/offer_notification`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: SettingOfferNotificationToJSON(requestParameters.settingOfferNotification),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SettingOfferNotificationFromJSON(jsonValue))
  }

  /**
   * 組織のオファー通知の設定を更新する
   */
  async putProducersMeSettingsOfferNotification(
    requestParameters: PutProducersMeSettingsOfferNotificationRequest
  ): Promise<SettingOfferNotification> {
    const response = await this.putProducersMeSettingsOfferNotificationRaw(requestParameters)
    return await response.value()
  }

  /**
   * 自身のメール通知の設定情報を更新する
   */
  async putSettingsMailNotificationRaw(
    requestParameters: PutSettingsMailNotificationRequest
  ): Promise<runtime.ApiResponse<SettingMailNotification>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producer_accounts/me/settings/mail_notification`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: SettingMailNotificationToJSON(requestParameters.settingMailNotification),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SettingMailNotificationFromJSON(jsonValue))
  }

  /**
   * 自身のメール通知の設定情報を更新する
   */
  async putSettingsMailNotification(
    requestParameters: PutSettingsMailNotificationRequest
  ): Promise<SettingMailNotification> {
    const response = await this.putSettingsMailNotificationRaw(requestParameters)
    return await response.value()
  }

  /**
   * 組織のオファー通知条件を更新する
   */
  async putSettingsOfferNotificationConditionsIdRaw(
    requestParameters: PutSettingsOfferNotificationConditionsIdRequest
  ): Promise<runtime.ApiResponse<SettingOfferNotificationConditionRead>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putSettingsOfferNotificationConditionsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producers/me/settings/offer_notification_conditions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: ProducersMeSettingsOfferNotificationConditionsUpdateToJSON(
        requestParameters.producersMeSettingsOfferNotificationConditionsUpdate
      ),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SettingOfferNotificationConditionReadFromJSON(jsonValue)
    )
  }

  /**
   * 組織のオファー通知条件を更新する
   */
  async putSettingsOfferNotificationConditionsId(
    requestParameters: PutSettingsOfferNotificationConditionsIdRequest
  ): Promise<SettingOfferNotificationConditionRead> {
    const response = await this.putSettingsOfferNotificationConditionsIdRaw(requestParameters)
    return await response.value()
  }
}
