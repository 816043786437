import { ProducerCreate, ProducerNormalized, ProducerUpdate } from '../domain/producer/producer'
import {
  PostProducersRequest,
  ProducersApi,
  PutProducersMeBasicProfileRequest,
  PutProducersMeRequest,
} from '../libs/api_client/apis'
import { getApiConfig } from '../config/apiConfig'
import {
  InvoiceRegistrationStatus,
  ProducerBasicProfileUpdate,
} from '../domain/producerBasicProfile/producerBasicProfile'
import { ProducerBasicProfile, ProducerBasicProfileInvoiceRegistrationStatusEnum } from '../libs/api_client'

export async function postProducer(validated: ProducerCreate): Promise<{ result: 'success' }> {
  const param: PostProducersRequest = {
    producerCreateSeller: validated,
  }

  const producerApi = new ProducersApi(getApiConfig())
  const res = await producerApi.postProducers(param)

  return res
}

/**
 * PUT /producers/me
 * @param producer
 */
export async function putProducersMe(producer: ProducerUpdate): Promise<ProducerNormalized> {
  const param: PutProducersMeRequest = {
    producersMeUpdate: {
      ...producer,
      // ...({} as any), // 意図的に400エラーを起こすためのコード
    },
  }

  const producersApi = new ProducersApi(getApiConfig())
  const producerRequestResponse: ProducerNormalized = await producersApi.putProducersMe(param)

  // FIXME: OfferRequest型で返したほうが良い気がするけど今必要ないので保留
  return producerRequestResponse
}

export const putProducersMeBasicProfile = async (
  validated: ProducerBasicProfileUpdate
): Promise<ProducerBasicProfile> => {
  const { invoiceRegistrationStatus } = validated
  const changeToEnum = (
    invoiceRegistrationStatus: InvoiceRegistrationStatus
  ): ProducerBasicProfileInvoiceRegistrationStatusEnum => {
    switch (invoiceRegistrationStatus) {
      case 'registered':
        return ProducerBasicProfileInvoiceRegistrationStatusEnum.Registered
      case 'will_register':
        return ProducerBasicProfileInvoiceRegistrationStatusEnum.WillRegister
      case 'wont_register':
        return ProducerBasicProfileInvoiceRegistrationStatusEnum.WontRegister
      case 'pending':
        return ProducerBasicProfileInvoiceRegistrationStatusEnum.Pending
    }
  }

  const param: PutProducersMeBasicProfileRequest = {
    producerBasicProfile: { ...validated, invoiceRegistrationStatus: changeToEnum(invoiceRegistrationStatus) },
  }
  const producersApi = new ProducersApi(getApiConfig())

  return await producersApi.putProducersMeBasicProfile(param)
}
