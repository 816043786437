import { Flex } from '@chakra-ui/react'
import { theme } from '../../../../theme/theme'
import { OfferRequestModal } from '../../Modal/InfoModal/OfferRequestModal'

export function OpenOfferRequestInfoModal() {
  return (
    <Flex height={theme.sizes.brand.innerHeaderHeight} justify="center" align="center" padding="0 26.81px 0 0">
      <OfferRequestModal />
    </Flex>
  )
}
