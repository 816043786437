/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
  SpotContractOfferReadSeller,
  SpotContractOfferReadSellerFromJSON,
  SpotContractOfferReadSellerToJSON,
} from '../models'

export interface GetSpotContractOffersRequest {
  filters?: object
}

export interface GetSpotContractOffersIdRequest {
  id: number
}

/**
 *
 */
export class SpotContractOffersApi extends runtime.BaseAPI {
  /**
   * [現物契約] オファー一覧を取得する
   */
  async getSpotContractOffersRaw(
    requestParameters: GetSpotContractOffersRequest
  ): Promise<runtime.ApiResponse<Array<SpotContractOfferReadSeller>>> {
    const queryParameters: any = {}

    if (requestParameters.filters !== undefined) {
      queryParameters['_filters'] = requestParameters.filters
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/spot_contract/offers`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpotContractOfferReadSellerFromJSON))
  }

  /**
   * [現物契約] オファー一覧を取得する
   */
  async getSpotContractOffers(
    requestParameters: GetSpotContractOffersRequest
  ): Promise<Array<SpotContractOfferReadSeller>> {
    const response = await this.getSpotContractOffersRaw(requestParameters)
    return await response.value()
  }

  /**
   * [現物契約] 指定IDに該当するオファーの情報を取得する
   */
  async getSpotContractOffersIdRaw(
    requestParameters: GetSpotContractOffersIdRequest
  ): Promise<runtime.ApiResponse<SpotContractOfferReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getSpotContractOffersId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/spot_contract/offers/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SpotContractOfferReadSellerFromJSON(jsonValue))
  }

  /**
   * [現物契約] 指定IDに該当するオファーの情報を取得する
   */
  async getSpotContractOffersId(
    requestParameters: GetSpotContractOffersIdRequest
  ): Promise<SpotContractOfferReadSeller> {
    const response = await this.getSpotContractOffersIdRaw(requestParameters)
    return await response.value()
  }
}
