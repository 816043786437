import { ArrayUnique, IsArray, IsIn, IsInt, IsOptional, IsPositive, IsString } from 'class-validator'
import type { InputBasicProfileCreate } from './useBasicProfileInput'
import {
  InvoiceRegistrationStatus,
  invoiceRegistrationStatusKeys,
} from '../../../../domain/producerBasicProfile/producerBasicProfile'

export class InputBasicProfileValidator {
  versionNumber: 0 // 固定値

  @IsInt()
  @IsPositive()
  @IsOptional()
  regionId: number | null
  @IsInt()
  @IsPositive()
  @IsOptional()
  storagePeriodId: number | null
  @IsArray()
  @ArrayUnique()
  farmingCertificationIds: number[]
  @IsString()
  @IsIn(invoiceRegistrationStatusKeys)
  invoiceRegistrationStatus: InvoiceRegistrationStatus

  // 適格請求書発行事業者登録番号（頭の「T」を除いた13桁の数字）
  invoiceRegistrationNumber: string | null

  // 適格請求書発行事業者登録の予定時期（nullは「時期未定」）
  expectedRegistrationTimeframe: Date | null

  constructor(input: InputBasicProfileCreate) {
    this.versionNumber = input.versionNumber
    this.regionId = input.regionId === '' ? null : parseInt(input.regionId, 10)
    this.storagePeriodId = input.storagePeriodId === '' ? null : parseInt(input.storagePeriodId, 10)
    this.farmingCertificationIds = input.farmingCertificationIds
    this.invoiceRegistrationStatus = input.invoiceRegistrationStatus
    this.invoiceRegistrationNumber = null
    this.expectedRegistrationTimeframe = null
  }
}
