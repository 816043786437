import { Select as ChakraSelect } from '@chakra-ui/react'
import type { SelectProps } from '@chakra-ui/react'

export const Select = (props: SelectProps) => {
  return (
    <ChakraSelect
      borderColor="brand.box.border"
      _hover={{ borderColor: 'brand.box.border' }}
      backgroundColor="white"
      {...props}
    />
  )
}
