import { CONTRACT_TYPE_LABEL } from '../domain/contractType/contractType'
import { ExtendedOffer } from '../domain/offer/advanceContract/offerService'
import { ExtendedSpotContractOffer } from '../domain/offer/spotContract/spotContractOfferService'
import { ExtendedProducer } from '../domain/producer/producerService'
import { Mode as OfferPageMode } from '../presentation/PrivatePages/Offers/Detail/OfferDetailContainer'
import { Mode as SpotContractOfferPageMode } from '../presentation/PrivatePages/Offers/SpotContractDetail/OfferSpotDetailContainer'

import { ExtendedDeal } from '../domain/deal/advanceContract/dealService'
import { ExtendedSpotContractDeal } from '../domain/deal/spotContract/spotContractDealService'
import { Mode as DealPageMode } from '../presentation/PrivatePages/Deals/Detail/DealsDetailContainer'
import { Mode as DealSpotPageMode } from '../presentation/PrivatePages/Deals/SpotDetail/DealSpotDetailContainer'
import { Mode as AmendDetailPageMode } from '../presentation/PrivatePages/Deals/Amend/AmendDetailContainer'
import { Mode as SpotAmendPageMode } from '../presentation/PrivatePages/Deals/SpotAmend/SpotAmendContainer'

// ページタイトルを作成する関数。
export const buildPageTitle = (value?: string): string => {
  if (value) {
    return `${value} - アグリノート米市場`
  }
  return 'アグリノート米市場'
}

export const buildOfferDetailPageTitle = (
  contractOffer: ExtendedOffer | ExtendedSpotContractOffer,
  producer: ExtendedProducer,
  mode: OfferPageMode | SpotContractOfferPageMode
): string => {
  const contractType = CONTRACT_TYPE_LABEL[contractOffer.contractType]
  const annum = contractOffer.annum.name
  const prefecture = producer.prefecture.name
  const region = contractOffer.region?.name ?? ''
  const brand = contractOffer.brand.name
  const offerID = contractOffer.id
  const unitPrice = contractOffer.unitPrice.toLocaleString()
  const partialTitle = `${contractType} ${annum}年産 ${prefecture}${region}産 ${brand}_${offerID} ${unitPrice}円`
  if (mode === 'confirmDetail') {
    return `${partialTitle} のオファー - アグリノート米市場`
  } else if (mode === 'inputAnswer') {
    return `${partialTitle} のオファーへの回答 - アグリノート米市場`
  } else if (mode === 'confirmAnswer') {
    return `${partialTitle} のオファーへの回答確認 - アグリノート米市場`
  }
  return 'アグリノート米市場'
}

export const buildDealDetailPageTitle = (
  contractDeal: ExtendedDeal | ExtendedSpotContractDeal,
  producer: ExtendedProducer,
  mode: DealPageMode | DealSpotPageMode | AmendDetailPageMode | SpotAmendPageMode
): string => {
  const contractType = CONTRACT_TYPE_LABEL[contractDeal.contractType]
  const annum = contractDeal.annum.name
  const prefecture = producer?.prefecture.name
  const region = contractDeal.region?.name ?? ''
  const brand = contractDeal.brand.name
  const offerID = contractDeal.id
  const unitPrice = contractDeal.unitPrice.toLocaleString()
  const partialTitle = `${contractType} ${annum}年産 ${prefecture}${region}産 ${brand}_${offerID} ${unitPrice}円`

  switch (mode) {
    case 'detail': // 事前契約・現物契約 詳細ページ
    case 'dealt': // 事前契約・現物契約 詳細ページ
      return `${partialTitle} の取引案件詳細 - アグリノート米市場`
    case 'handoverExpectedHarvestTimeframe': // 事前契約 amend
    case 'handoverInfo': // 事前契約 amend
    case 'handoverInspectionResults': // 事前契約 amend
    case 'handoverInspectionResultsConfirm': // 事前契約 amend
    case 'delivery': // 事前契約 amend
    case 'PAYMENTS': // 事前契約・現物契約 amend
    case 'HANDOVER_INFO': // 現物契約 amend
    case 'HANDOVER_INSPECTION_RESULTS': // 現物契約 amend
    case 'HANDOVER_INSPECTION_RESULTS_CONFIRM': // 現物契約 amend
    case 'DELIVERY': // 現物契約 amend
      return `${partialTitle} の引渡情報詳細 - アグリノート米市場`
    case 'agreement': // 事前契約 詳細ページ
    case 'handover_info': // 現物契約 詳細ページ
    case 'inspection_results': // 現物契約 詳細ページ
      return `${partialTitle} の取引案件承諾申請 - アグリノート米市場`
    case 'disagreement': // 事前契約・現物契約 詳細ページ
      return `${partialTitle} の取引案件不承諾申請 - アグリノート米市場`
    default:
      return 'アグリノート米市場'
  }
}
