/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface OfferReadSellerGradePenalties
 */
export interface OfferReadSellerGradePenalties {
  /**
   * 等級ID
   * @type {number}
   * @memberof OfferReadSellerGradePenalties
   */
  gradeId: number
  /**
   * 減額する金額（円）（nullは「受け取らない」の意味）
   * @type {number}
   * @memberof OfferReadSellerGradePenalties
   */
  penaltyInYen: number | null
}

export function OfferReadSellerGradePenaltiesFromJSON(json: any): OfferReadSellerGradePenalties {
  return OfferReadSellerGradePenaltiesFromJSONTyped(json, false)
}

export function OfferReadSellerGradePenaltiesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OfferReadSellerGradePenalties {
  if (json === undefined || json === null) {
    return json
  }
  return {
    gradeId: json['grade_id'],
    penaltyInYen: json['penalty_in_yen'],
  }
}

export function OfferReadSellerGradePenaltiesToJSON(value?: OfferReadSellerGradePenalties | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    grade_id: value.gradeId,
    penalty_in_yen: value.penaltyInYen,
  }
}
