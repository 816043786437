/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 生産者アカウント
 * @export
 * @interface ProducerAccountRead
 */
export interface ProducerAccountRead {
  /**
   * ANアカウントID
   * @type {number}
   * @memberof ProducerAccountRead
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof ProducerAccountRead
   */
  readonly versionNumber: number
  /**
   * 削除フラグ（true: 削除済、false: 未削除）
   * @type {boolean}
   * @memberof ProducerAccountRead
   */
  readonly isDeleted: boolean
  /**
   * 氏名
   * @type {string}
   * @memberof ProducerAccountRead
   */
  readonly name: string
  /**
   * ステータス（true: 有効、false: 無効）
   * @type {boolean}
   * @memberof ProducerAccountRead
   */
  isActive: boolean
  /**
   * 権限（"admin": 管理者、"staff": スタッフ）
   * @type {string}
   * @memberof ProducerAccountRead
   */
  readonly role: ProducerAccountReadRoleEnum
  /**
   * email
   * @type {string}
   * @memberof ProducerAccountRead
   */
  readonly email: string
}

/**
 * @export
 * @enum {string}
 */
export enum ProducerAccountReadRoleEnum {
  Admin = 'admin',
  Staff = 'staff',
}

export function ProducerAccountReadFromJSON(json: any): ProducerAccountRead {
  return ProducerAccountReadFromJSONTyped(json, false)
}

export function ProducerAccountReadFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProducerAccountRead {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isDeleted: json['is_deleted'],
    name: json['name'],
    isActive: json['is_active'],
    role: json['role'],
    email: json['email'],
  }
}

export function ProducerAccountReadToJSON(value?: ProducerAccountRead | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    is_active: value.isActive,
  }
}
