import styled from '@emotion/styled'

export const BackIcon = () => (
  <Svg width="14.138" height="20" viewBox="0 0 14.138 20">
    <path
      className="cls-1"
      d="M1.4-6.806a1.1,1.1,0,0,0,0,1.554l10.9,8.9a1.1,1.1,0,0,0,1.554,0l1.038-1.038a1.1,1.1,0,0,0,0-1.553L5.837-6.029l9.054-7.087a1.1,1.1,0,0,0,0-1.553l-1.038-1.038a1.1,1.1,0,0,0-1.554,0Z"
      transform="translate(-1.074 16.029)"
    />
  </Svg>
)

const Svg = styled.svg`
  .cls-1 {
    fill: #35363a;
  }
`
