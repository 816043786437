import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { theme } from '../../../theme/theme'
import { useIsMobile } from '../../hooks/useIsMobile'
import { Blank, BlankFooter, BlankInnerHeader, BlankOuterHeader } from '../BlankParts/BlankParts'

export const LoadingEntire = () => {
  const isMobile = useIsMobile()

  const { outerHeaderHeight, innerHeaderHeight, footerHeight } = theme.sizes.brand
  const mainBackground = theme.colors.brand.text.main
  const brandBackground = theme.colors.brand.brand
  const background = theme.colors.brand.background

  return isMobile ? (
    <>
      <BlankInnerHeader background={brandBackground} height={innerHeaderHeight} />
      <Blank background={background} height={`calc(100vh - ${innerHeaderHeight} - ${footerHeight})`}>
        <Loader />
      </Blank>
      <BlankFooter background={brandBackground} height={footerHeight} />
    </>
  ) : (
    <>
      <BlankOuterHeader background={mainBackground} height={outerHeaderHeight} />
      <BlankInnerHeader background={brandBackground} height={innerHeaderHeight} />
      <Blank background={background} height={`calc(100vh - ${outerHeaderHeight} - ${innerHeaderHeight})`}>
        <Loader />
      </Blank>
    </>
  )
}

export const Loading = () => (
  <Flex width="100%" margin="80px 0" align="center" justify="center">
    <Loader />
  </Flex>
)

export const Loader = () => (
  <LoaderComponent brandColor={theme.colors.brand.brand}>
    <div className="loader" />
  </LoaderComponent>
)

const LoaderComponent = styled.div<{ brandColor: string }>`
  .loader,
  .loader:before,
  .loader:after {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    animation-fill-mode: both;
    animation: load-animation 1.8s infinite ease-in-out;
  }
  .loader {
    position: relative;
    top: -25px;
    font-size: 10px;
    color: ${(props) => props.brandColor};
    transform: translateZ(0);
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -35px;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 35px;
  }
  @keyframes load-animation {
    0%,
    80%,
    100% {
      box-shadow: 0 25px 0 -13px;
    }
    40% {
      box-shadow: 0 25px 0 0;
    }
  }
`
