import Select from 'react-select'

import { theme } from '../../../theme/theme'

const customStyles = (isDisabled: boolean) => {
  return {
    control: (provided: any) => {
      const style = {
        ...provided,
        backgroundColor: isDisabled ? theme.colors.brand.selector.disabled : provided.backgroundColor,
        borderColor: isDisabled ? theme.colors.brand.box.disabled : theme.colors.brand.box.border,
        cursor: isDisabled ? 'not-allowed' : 'default',
        minHeight: '40px',
        borderRadius: '0.375rem',
        pointerEvents: isDisabled ? 'auto' : undefined,
      }
      return style
    },
    dropdownIndicator: (provided: any) => {
      return {
        ...provided,
        color: isDisabled ? theme.colors.brand.selector.disabledColor : theme.colors.brand.box.border,
      }
    },
    menu: (provided: any) => ({
      ...provided,
      zIndex: 999,
    }),
    multiValue: (provided: any) => ({
      ...provided,
      color: isDisabled ? theme.colors.brand.selector.disabledColor : provided.color,
      pointerEvents: isDisabled ? 'none' : undefined,
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: isDisabled ? theme.colors.brand.selector.disabledColor : provided.color,
    }),
    option: (provided: any) => ({
      ...provided,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: isDisabled ? theme.colors.brand.selector.disabledColor : theme.colors.brand.text.main,
    }),
  }
}

type Option<T> = { label: string; value: T }

export const MultiSelect = <T extends string | number>({
  options,
  values,
  onChange,
  isDisabled,
}: {
  options: Option<T>[]
  values: Option<T>[]
  onChange: (values: T[]) => void
  isDisabled?: boolean
}) => {
  return (
    <Select
      styles={customStyles(!!isDisabled)}
      options={options}
      value={values}
      onChange={(options) => onChange(options.map((option) => option.value))}
      isMulti={true}
      placeholder="選択してください"
      isDisabled={isDisabled}
      isSearchable={false}
    />
  )
}
