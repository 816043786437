import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { theme } from '../../../theme/theme'

import { PrefectureLabel } from '../InformationLabel/PrefectureLabel'
import { UserInformationLabel } from '../InformationLabel/UserInformationLabel'

import { LogoReverseLarge } from '../svg/LogoReverseLarge'
import { useIsMobile } from '../../hooks/useIsMobile'

export const OuterHeader = () => {
  const isMobile = useIsMobile()
  const { outerHeaderHeight, containerMaxWidth } = theme.sizes.brand
  const backgroundColor = theme.colors.brand.text.main
  return (
    <Header isMobile={isMobile} height={outerHeaderHeight} backgroundColor={backgroundColor}>
      <Box margin="0 auto" height="100%">
        <Flex
          margin="0 auto"
          padding="0 40px"
          align="center"
          maxWidth={containerMaxWidth}
          height="100%"
          justify="space-between"
        >
          <Flex align="center">
            <LogoReverseLarge />
            <PrefectureLabel />
          </Flex>
          <UserInformationLabel />
        </Flex>
      </Box>
    </Header>
  )
}

const Header = styled.div<{ isMobile: boolean; height: string; backgroundColor: string }>`
  width: 100%;
  height: ${(props) => (props.isMobile ? 'auto' : props.height)};
  background: ${(props) => props.backgroundColor};
`
