/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  IndividualDeliveryReadSellerDeliveryContents,
  IndividualDeliveryReadSellerDeliveryContentsFromJSON,
  IndividualDeliveryReadSellerDeliveryContentsFromJSONTyped,
  IndividualDeliveryReadSellerDeliveryContentsToJSON,
  IndividualDeliveryReadSellerHacobell,
  IndividualDeliveryReadSellerHacobellFromJSON,
  IndividualDeliveryReadSellerHacobellFromJSONTyped,
  IndividualDeliveryReadSellerHacobellToJSON,
  SelfDelivery,
  SelfDeliveryFromJSON,
  SelfDeliveryFromJSONTyped,
  SelfDeliveryToJSON,
} from './'

/**
 * [現物契約] 個別運送情報（Read、Seller）
 * @export
 * @interface SpotContractIndividualDeliveryReadSeller
 */
export interface SpotContractIndividualDeliveryReadSeller {
  /**
   * ID
   * @type {number}
   * @memberof SpotContractIndividualDeliveryReadSeller
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof SpotContractIndividualDeliveryReadSeller
   */
  readonly versionNumber: number
  /**
   * アメンドID
   * @type {number}
   * @memberof SpotContractIndividualDeliveryReadSeller
   */
  amendId: number
  /**
   * デリバリー種類（self_delivery: 自社運送、hacobell: ハコベルに依頼）
   * @type {string}
   * @memberof SpotContractIndividualDeliveryReadSeller
   */
  deliveryType: SpotContractIndividualDeliveryReadSellerDeliveryTypeEnum
  /**
   * デリバリーの状態
   *
   * draft: 下書き
   * waiting_for_arrangement: 手配待ち（ハコベル等、外部のサービスによる手配が必要な時）
   * waiting_for_inspection: 検品待ち
   * done: デリバリー完了
   * cancelled: キャンセル済み（ユーザの操作によりキャンセルされた場合）
   * cancelled_by_deal: 取消済み（ディール取消に伴い取消された場合）
   * @type {string}
   * @memberof SpotContractIndividualDeliveryReadSeller
   */
  status: SpotContractIndividualDeliveryReadSellerStatusEnum
  /**
   * 生産者確認済みフラグ
   * @type {boolean}
   * @memberof SpotContractIndividualDeliveryReadSeller
   */
  hasProducerReviewed: boolean
  /**
   * 運送内容
   * @type {Array<IndividualDeliveryReadSellerDeliveryContents>}
   * @memberof SpotContractIndividualDeliveryReadSeller
   */
  deliveryContents: Array<IndividualDeliveryReadSellerDeliveryContents> | null
  /**
   *
   * @type {SelfDelivery}
   * @memberof SpotContractIndividualDeliveryReadSeller
   */
  selfDelivery: SelfDelivery
  /**
   *
   * @type {IndividualDeliveryReadSellerHacobell}
   * @memberof SpotContractIndividualDeliveryReadSeller
   */
  hacobell: IndividualDeliveryReadSellerHacobell | null
}

/**
 * @export
 * @enum {string}
 */
export enum SpotContractIndividualDeliveryReadSellerDeliveryTypeEnum {
  SelfDelivery = 'self_delivery',
  Hacobell = 'hacobell',
}
/**
 * @export
 * @enum {string}
 */
export enum SpotContractIndividualDeliveryReadSellerStatusEnum {
  Draft = 'draft',
  WaitingForArrangement = 'waiting_for_arrangement',
  WaitingForInspection = 'waiting_for_inspection',
  Done = 'done',
  Cancelled = 'cancelled',
  CancelledByDeal = 'cancelled_by_deal',
}

export function SpotContractIndividualDeliveryReadSellerFromJSON(json: any): SpotContractIndividualDeliveryReadSeller {
  return SpotContractIndividualDeliveryReadSellerFromJSONTyped(json, false)
}

export function SpotContractIndividualDeliveryReadSellerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpotContractIndividualDeliveryReadSeller {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    amendId: json['amend_id'],
    deliveryType: json['delivery_type'],
    status: json['status'],
    hasProducerReviewed: json['has_producer_reviewed'],
    deliveryContents:
      json['delivery_contents'] === null
        ? null
        : (json['delivery_contents'] as Array<any>).map(IndividualDeliveryReadSellerDeliveryContentsFromJSON),
    selfDelivery: SelfDeliveryFromJSON(json['self_delivery']),
    hacobell: IndividualDeliveryReadSellerHacobellFromJSON(json['hacobell']),
  }
}

export function SpotContractIndividualDeliveryReadSellerToJSON(
  value?: SpotContractIndividualDeliveryReadSeller | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    amend_id: value.amendId,
    delivery_type: value.deliveryType,
    status: value.status,
    has_producer_reviewed: value.hasProducerReviewed,
    delivery_contents:
      value.deliveryContents === null
        ? null
        : (value.deliveryContents as Array<any>).map(IndividualDeliveryReadSellerDeliveryContentsToJSON),
    self_delivery: SelfDeliveryToJSON(value.selfDelivery),
    hacobell: IndividualDeliveryReadSellerHacobellToJSON(value.hacobell),
  }
}
