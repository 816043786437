/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * アメンドの収穫予定時期
 * @export
 * @interface AmendsExpectedHarvestTimeframeUpdate
 */
export interface AmendsExpectedHarvestTimeframeUpdate {
  /**
   * アメンドID
   * @type {number}
   * @memberof AmendsExpectedHarvestTimeframeUpdate
   */
  readonly id?: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof AmendsExpectedHarvestTimeframeUpdate
   */
  versionNumber: number
  /**
   * 生産者のID
   * @type {number}
   * @memberof AmendsExpectedHarvestTimeframeUpdate
   */
  readonly producerId?: number
  /**
   * 卸事業者のID
   * @type {number}
   * @memberof AmendsExpectedHarvestTimeframeUpdate
   */
  readonly wholesalerId?: number
  /**
   * 収穫予定時期（画面では「x月y旬」として扱われる、上旬: 日付が1、中旬: 日付が11、下旬: 日付が21、null/「未定・不明」は不可）
   * @type {Date}
   * @memberof AmendsExpectedHarvestTimeframeUpdate
   */
  expectedHarvestTimeframe: Date
}

export function AmendsExpectedHarvestTimeframeUpdateFromJSON(json: any): AmendsExpectedHarvestTimeframeUpdate {
  return AmendsExpectedHarvestTimeframeUpdateFromJSONTyped(json, false)
}

export function AmendsExpectedHarvestTimeframeUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AmendsExpectedHarvestTimeframeUpdate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    versionNumber: json['version_number'],
    producerId: !exists(json, 'producer_id') ? undefined : json['producer_id'],
    wholesalerId: !exists(json, 'wholesaler_id') ? undefined : json['wholesaler_id'],
    expectedHarvestTimeframe: new Date(json['expected_harvest_timeframe']),
  }
}

export function AmendsExpectedHarvestTimeframeUpdateToJSON(value?: AmendsExpectedHarvestTimeframeUpdate | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    version_number: value.versionNumber,
    expected_harvest_timeframe: value.expectedHarvestTimeframe.toISOString().substr(0, 10),
  }
}
