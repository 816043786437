import { RegionNormalized } from '../region/region'
import { StoragePeriod } from '../storagePeriod/storagePeriod'
import { ProducerBasicProfileNormalized } from './producerBasicProfile'
import { ProducerBasicProfile } from './denormalizedProducerBasicProfle'
import { FarmingCertification } from '../farmingCertification/farmingCertification'

type Id = number
type RelationResolver = {
  regionMap: Map<Id, RegionNormalized>
  storagePeriodMap: Map<Id, StoragePeriod>
  farmingCertificationMap: Map<Id, FarmingCertification>
}

/**
 * producerBasicProfileのid refになっている箇所を解決して非正規化したproducerBasicProfileを返す
 * @param relationResolver
 */
export function denormalizeProducerBasicProfile(relationResolver: RelationResolver) {
  return function denormalize(producerBasicProfile: ProducerBasicProfileNormalized): ProducerBasicProfile {
    const region =
      producerBasicProfile.regionId !== null
        ? (relationResolver.regionMap.get(producerBasicProfile.regionId) as RegionNormalized)
        : null
    const storagePeriod =
      producerBasicProfile.storagePeriodId !== null
        ? (relationResolver.storagePeriodMap.get(producerBasicProfile.storagePeriodId) as StoragePeriod)
        : null
    const farmingCertifications =
      producerBasicProfile.farmingCertificationIds.length > 0
        ? producerBasicProfile.farmingCertificationIds.map(
            (id) => relationResolver.farmingCertificationMap.get(id) as FarmingCertification
          )
        : []

    return {
      ...producerBasicProfile,
      region,
      storagePeriod,
      farmingCertifications,
    }
  }
}
