/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  ProducerAddress,
  ProducerAddressFromJSON,
  ProducerAddressToJSON,
  ProducerAddressReadSeller,
  ProducerAddressReadSellerFromJSON,
  ProducerAddressReadSellerToJSON,
  ProducerAddressesUpdate,
  ProducerAddressesUpdateFromJSON,
  ProducerAddressesUpdateToJSON,
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
} from '../models'

export interface DeleteProducerAddressesIdRequest {
  id: number
  versionNumber: number
}

export interface GetProducerAddressesIdRequest {
  id: number
}

export interface PostProducerAddressesRequest {
  producerAddress?: ProducerAddress
}

export interface PutProducerAddressesIdRequest {
  id: number
  producerAddressesUpdate?: ProducerAddressesUpdate
}

/**
 *
 */
export class ProducerAddressesApi extends runtime.BaseAPI {
  /**
   * 指定IDに該当する生産者自身の住所を削除する
   */
  async deleteProducerAddressesIdRaw(
    requestParameters: DeleteProducerAddressesIdRequest
  ): Promise<runtime.ApiResponse<ProducerAddressReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling deleteProducerAddressesId.'
      )
    }

    if (requestParameters.versionNumber === null || requestParameters.versionNumber === undefined) {
      throw new runtime.RequiredError(
        'versionNumber',
        'Required parameter requestParameters.versionNumber was null or undefined when calling deleteProducerAddressesId.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.versionNumber !== undefined) {
      queryParameters['version_number'] = requestParameters.versionNumber
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producer_addresses/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ProducerAddressReadSellerFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当する生産者自身の住所を削除する
   */
  async deleteProducerAddressesId(
    requestParameters: DeleteProducerAddressesIdRequest
  ): Promise<ProducerAddressReadSeller> {
    const response = await this.deleteProducerAddressesIdRaw(requestParameters)
    return await response.value()
  }

  /**
   * 生産者自身の住所一覧を取得する
   */
  async getProducerAddressesRaw(): Promise<runtime.ApiResponse<Array<ProducerAddressReadSeller>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producer_addresses`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProducerAddressReadSellerFromJSON))
  }

  /**
   * 生産者自身の住所一覧を取得する
   */
  async getProducerAddresses(): Promise<Array<ProducerAddressReadSeller>> {
    const response = await this.getProducerAddressesRaw()
    return await response.value()
  }

  /**
   * 指定IDに該当する生産者自身の住所の情報を取得する
   */
  async getProducerAddressesIdRaw(
    requestParameters: GetProducerAddressesIdRequest
  ): Promise<runtime.ApiResponse<ProducerAddressReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getProducerAddressesId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producer_addresses/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ProducerAddressReadSellerFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当する生産者自身の住所の情報を取得する
   */
  async getProducerAddressesId(requestParameters: GetProducerAddressesIdRequest): Promise<ProducerAddressReadSeller> {
    const response = await this.getProducerAddressesIdRaw(requestParameters)
    return await response.value()
  }

  /**
   * 生産者自身の新しい住所を登録する
   */
  async postProducerAddressesRaw(
    requestParameters: PostProducerAddressesRequest
  ): Promise<runtime.ApiResponse<ProducerAddressReadSeller>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producer_addresses`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ProducerAddressToJSON(requestParameters.producerAddress),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ProducerAddressReadSellerFromJSON(jsonValue))
  }

  /**
   * 生産者自身の新しい住所を登録する
   */
  async postProducerAddresses(requestParameters: PostProducerAddressesRequest): Promise<ProducerAddressReadSeller> {
    const response = await this.postProducerAddressesRaw(requestParameters)
    return await response.value()
  }

  /**
   * 指定IDに該当する生産者自身の住所の情報を編集する
   */
  async putProducerAddressesIdRaw(
    requestParameters: PutProducerAddressesIdRequest
  ): Promise<runtime.ApiResponse<ProducerAddressReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putProducerAddressesId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/producer_addresses/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: ProducerAddressesUpdateToJSON(requestParameters.producerAddressesUpdate),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ProducerAddressReadSellerFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当する生産者自身の住所の情報を編集する
   */
  async putProducerAddressesId(requestParameters: PutProducerAddressesIdRequest): Promise<ProducerAddressReadSeller> {
    const response = await this.putProducerAddressesIdRaw(requestParameters)
    return await response.value()
  }
}
