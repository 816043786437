import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

function getQueryString(): string {
  return location.hash.split('?')[1] ?? ''
}

function getHashPrefix(): string {
  return location.hash.split('?')[0].replace(/^#/, '')
}

/**
 * location.hashのquery parametersを取得・設定するhook
 * ```
 * // e.g.
 * const ALLOWED_VALUES = ['value-a', 'value-b'] as const
 * type AllowedValue = typeof ALLOWED_VALUES[number]
 * const defaultValue = ALLOWED_VALUES[0]
 *
 * function yourCustomHook() {
 *   const [params, update] = useHashParams('replace')
 *
 *   const valueFromParams: any = params.get('value_key')
 *   const value: AllowedValue = ALLOWED_VALUES.includes(valueFromParams) ? valueFromParams : defaultValue
 *
 *   function updateValue(value: string) {
 *     update('value_key', value)
 *   }
 *
 *   return [value, updateValue]
 * }
 * ```
 *
 * @param mode location.hashのquery stringを書き換えたとき、historyを積むか積まないかを選択
 */
export function useHashParams(
  mode: 'push' | 'replace' = 'replace'
): [URLSearchParams, (key: string, value: any) => void] {
  const history = useHistory()
  const initialQueryString = getQueryString()
  const [queryString, setQueryString] = useState<string>(initialQueryString)

  useEffect(() => {
    const handler = () => {
      const nextQueryString = getQueryString()
      setQueryString(nextQueryString)
    }

    window.addEventListener('popstate', handler)
    window.addEventListener('pushstate', handler)
    window.addEventListener('replacestate', handler)

    return () => {
      window.removeEventListener('popstate', handler)
      window.removeEventListener('pushstate', handler)
      window.removeEventListener('replacestate', handler)
    }
  }, [])

  function updateHashParam(key: string, value: any) {
    const query = new URLSearchParams(queryString)
    query.set(key, value)

    const prefix = getHashPrefix()
    const hash = `${prefix}?${query.toString()}`

    if (mode === 'replace') {
      history.replace(hash)
    } else {
      history.push(hash)
    }
  }

  const hashParams = new URLSearchParams(queryString)
  return [hashParams, updateHashParam]
}

export function getHashParams() {
  return new URLSearchParams(getQueryString())
}

export function buildHashParams(keyFromHashPrams: string[], keyValues: { [key: string]: string } = {}) {
  const hashParams = getHashParams()
  const params = new URLSearchParams()

  // hashParamsから該当のキーの値を抽出
  hashParams.forEach((value, key) => {
    if (keyFromHashPrams.includes(key)) {
      params.set(key, value)
    }
  })

  // 任意の追加したいもの
  Object.entries(keyValues).forEach(([key, value]) => {
    params.set(key, value)
  })

  return params.toString()
}
