import { useState, createContext, useContext } from 'react'
import { useEffectOnce } from 'react-use'

import { FetchState } from '../../types/utilTypes'
import { noop } from '../../../utils/noop'
import { useLogoutAfter401Error } from '../../auth/useLogout'
import {
  captureException,
  handle401Error,
  handle503Error,
  handleApiServerError,
  handlerNoNetworkError,
} from '../../../infra/handleApiServerError'
import { PendingTasksApi } from '../../../libs/api_client/apis'
import { getApiConfig } from '../../../config/apiConfig'
import { PendingTask, PendingTasks } from '../../../domain/pendingTasks/pendingTasks'
import { PendingTasksService } from '../../../domain/pendingTasks/pendingTasksService'

type PendingTasksFetchingState = FetchState & {
  data: {
    pendingTasks: Array<PendingTask>
  }
}

type State = PendingTasksFetchingState & {
  hasTodo: boolean
  updatePendingTasks: () => Promise<void>
}

export const PendingTasksContext = createContext<State>({
  fetching: true,
  fetched: false,
  data: { pendingTasks: [] },
  hasTodo: false,
  updatePendingTasks: noop,
})

export const usePendingTasksContext = (): State => {
  return useContext(PendingTasksContext)
}

export const usePendingTasks = (): State => {
  const [pendingTasksState, setPendingTasksState] = useState<PendingTasksFetchingState>({
    fetched: false,
    fetching: true,
    data: { pendingTasks: [] },
  })
  const logout = useLogoutAfter401Error()

  useEffectOnce(() => {
    ;(async () => {
      await updatePendingTasks()
    })()
  })

  const updatePendingTasks = async () => {
    const pendingTasksApi = new PendingTasksApi(getApiConfig())
    try {
      const pendingTasks: PendingTasks = await pendingTasksApi.getPendingTasks({})
      const sortedPendingTasks = pendingTasks.sort(PendingTasksService.compareDeadline())
      setPendingTasksState({ fetched: true, fetching: false, data: { pendingTasks: sortedPendingTasks } })
    } catch (err) {
      if (err instanceof Response) {
        handle503Error(err)
        handleApiServerError(err, captureException)
        handle401Error()(err, logout)
        handlerNoNetworkError(err)
      }
    }
  }

  return { ...pendingTasksState, hasTodo: pendingTasksState.data.pendingTasks.length !== 0, updatePendingTasks }
}
