import { Producer } from './denormalizedProducer'
import { InspectionOrganizationNormalized } from '../inspectionOrganization/inspectionOrganizations'

export type ExtendedProducer = Producer & ProducerService

class ProducerService {
  get producer(): Producer {
    return this as any as Producer
  }

  /**
   * 有効な外部検査場所のみを返す
   */
  get activeInspectionOrganizations(): InspectionOrganizationNormalized[] {
    const { producer } = this
    return producer.inspectionOrganizations.filter((io) => io.isActive)
  }

  /**
   * 生産者として検査可能かどうかを返す
   */
  canInspect(): boolean {
    const { producer } = this
    return producer.canSelfInspect || this.canInspectByInspectionOrganizations()
  }

  /**
   * 外部検査場所の登録状況の観点からのみ検査可能かどうかを返す
   */
  canInspectByInspectionOrganizations(): boolean {
    return this.activeInspectionOrganizations.length > 0
  }
}

export function extendProducerWithService(producer: Producer): ExtendedProducer {
  return Object.assign<ProducerService, Producer>(new ProducerService(), producer)
}
