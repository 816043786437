/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 有機JAS等の取り組み
 * @export
 * @interface FarmingCertificationRead
 */
export interface FarmingCertificationRead {
  /**
   * ID
   * @type {number}
   * @memberof FarmingCertificationRead
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof FarmingCertificationRead
   */
  readonly versionNumber: number
  /**
   * ステータス（true: 有効、false: 無効）
   * @type {boolean}
   * @memberof FarmingCertificationRead
   */
  isActive: boolean
  /**
   * 取り組み名
   * @type {string}
   * @memberof FarmingCertificationRead
   */
  name: string
  /**
   * 売るぞう表示テキスト
   * @type {string}
   * @memberof FarmingCertificationRead
   */
  displayText: string
}

export function FarmingCertificationReadFromJSON(json: any): FarmingCertificationRead {
  return FarmingCertificationReadFromJSONTyped(json, false)
}

export function FarmingCertificationReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FarmingCertificationRead {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isActive: json['is_active'],
    name: json['name'],
    displayText: json['display_text'],
  }
}

export function FarmingCertificationReadToJSON(value?: FarmingCertificationRead | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    is_active: value.isActive,
    name: value.name,
    display_text: value.displayText,
  }
}
