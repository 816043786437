import { useState } from 'react'
import { useLogoutAfter401Error } from '../../../auth/useLogout'
import { putProducerTosAgreement } from '../../../../infra/producerTosAgreement'
import { ProducerTosAgreementEdit } from '../../../../domain/producerTosAgreement/producerTosAgreement'
import {
  captureException,
  handle401Error,
  handle4xxWithout401Error,
  handle503Error,
  handleApiServerError,
  handlerNoNetworkError,
} from '../../../../infra/handleApiServerError'
import { noop } from '../../../../utils/noop'
import { useToast } from '@chakra-ui/react'

export const useUpdateTosAgreement = () => {
  const toast = useToast()
  const [fetching, setFetching] = useState<boolean>(false)
  const logout = useLogoutAfter401Error()

  const update = async (validated: ProducerTosAgreementEdit) => {
    try {
      setFetching(true)
      await putProducerTosAgreement(validated)
      setFetching(false)
    } catch (err) {
      if (err instanceof Response) {
        if (err.status === 409) {
          toast({
            status: 'error',
            title: '利用規約に更新がありました',
            description: '3秒後にページを再読み込みします',
            duration: 3000,
          })
          // 3秒後に再読み込み
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        } else {
          handle503Error(err)
          handleApiServerError(err)
          handle401Error()(err, logout)
          handle4xxWithout401Error('予期せぬエラーが発生しました。時間を置いて再度お試しください。')(err, noop)
          handlerNoNetworkError(err)
          captureException(err)
        }
      }

      throw err
    }
  }

  return { fetching, update }
}
