import * as Sentry from '@sentry/react'
import status from './status.json'

if (process.env.REACT_APP_DEPLOY_TARGET) {
  let dsn = 'https://72a877c3d3eb44a0886b46d95ec07fe3@o60106.ingest.sentry.io/5401411'
  if (process.env.REACT_APP_DEPLOY_TARGET === 'production') {
    dsn = 'https://e617530a3c39474d80f2e6c221549185@o60106.ingest.sentry.io/5401408'
  }
  Sentry.init({
    dsn,
    release: status.release,
  })
}

type Props = {
  children: React.ReactElement
}

export const SentryWrapper = (props: Props): React.ReactElement => {
  if (!process.env.REACT_APP_DEPLOY_TARGET) {
    return props.children
  }
  return (
    <Sentry.ErrorBoundary fallback="エラーが発生しました。画面を再読み込みしてください。">
      {props.children}
    </Sentry.ErrorBoundary>
  )
}
