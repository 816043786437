import { Text } from '@chakra-ui/react'

import { InfoModal } from './InfoModal'

export const OfferRequestModal = () => (
  <InfoModal
    title="希望オファー入力"
    content={
      <>
        <Text>希望オファーを登録いただくことで、あなたにあったオファーが届きやすくなります。</Text>
        <Text>また、希望オファーは最大5件まで登録することができます。</Text>
        <Text>リクエストは統計的に処理され、組織名等が卸業者や外部の第三者に公開されることはありません。</Text>
      </>
    }
  />
)
