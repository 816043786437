import styled from '@emotion/styled'

export const CloseIcon = () => (
  <Svg width="25.817" height="25.817" viewBox="0 0 25.817 25.817">
    <g transform="translate(-509.091 -852.377)">
      <path className="cls-1" d="M0,0H29.511" transform="translate(511.566 875.719) rotate(-45)" />
      <path className="cls-1" d="M0,0H29.511" transform="translate(532.434 875.719) rotate(-135)" />
    </g>
  </Svg>
)

const Svg = styled.svg`
  .cls-1 {
    fill: none;
    stroke: #707070;
    stroke-width: 7px;
  }
`
