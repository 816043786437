import { extendTheme } from '@chakra-ui/react'

const brandColor =
  process.env.REACT_APP_DEPLOY_TARGET === 'production' || process.env.REACT_APP_DEPLOY_COLOR ? '#FFD500' : '#4CBD9B'
const DRAWER_SIZE = '260px'

export const theme = extendTheme({
  sizes: {
    brand: {
      breakPoint: 1024,
      tabletBreakPoint: 768,
      drawerSize: DRAWER_SIZE,
      containerMaxWidth: '1024px',
      outerHeaderHeight: '66px',
      innerHeaderHeight: '54px',
      footerHeight: '47px',
    },
    xs: DRAWER_SIZE,
  },
  colors: {
    brand: {
      text: {
        main: '#35363A',
        disabled: '#989898',
        tabDisabled: '#9d9d9d',
        alert: '#F4511E',
        requireLabelBackground: '#CB461E',
        quiet: '#656565',
        highlightBackground: '#dddddd',
        conspicuous: '#b71616',
      },
      button: {
        disagreement: '#174FBA',
        ok: '#E4E4E4',
        okBorder: '#D5D5D5',
        okHoverBackground: 'rgb(255,133,95)',
        ngHoverBackground: '#6583BC',
        cancelBorder: '#3C4043',
        cancelBackground: '#FFFFFF',
        selectBorder: '#707070',
        selectBackground: '#FFFFFF',
        editBorder: '#707070',
        editBackground: '#F7F7F7',
        deleteBorder: '#B2B2B2',
        deleteBackground: '#FFFFFF',
        back: '#FFFFFF',
        backBorder: '#B2B2B2',
        color: '#FFFFFF',
        lightHighlight: '#E6E6E6',
      },
      link: {
        color: '#1492E6',
      },
      logo: {
        main: '#221815',
        stamp: '#C30E23',
      },
      box: {
        background: '#FFFFFF',
        border: '#707070',
        highlight: '#FFF9DD',
        divider: '#9d9d9d',
        disabled: '#c3c3c3',
      },
      card: {
        standard: '#FFD500',
        cannotOperate: '#707070',
        provisionalContract: '#8EB73F',
        answered: '#818181',
        unanswered: '#FF6200',
        closed: '#00A7FF',
        done: '#16B798',
        answeredBackground: '#EAEAEA',
        specialConditionInfo: '#B71616',
        standardBackground: '#FFFFFF',
        closedBackground: '#7070704D',
        gradeBorder: '#4B4B4B',
        cannotOperateBackground: '#7070704D',
        detailDivider: '#9d9d9d',
        labelBorder: '#9d9d9d',
        contractDarkBackground: '#483B23',
      },
      selector: {
        activeBackground: '#605151',
        activeColor: '#fff',
        background: '#fff',
        borderColor: '#707070',
        disabled: '#f9f9f9',
        disabledColor: '#aaaaaa',
      },
      icon: {
        todoAlert: '#F4511E',
        active: '#707070',
        disabled: '#989898',
      },
      input: {
        border: '#707070',
        disabledBackground: '#e6e6e6',
      },
      payment: {
        progressLabelBackground: '#18C769',
        doneLabelBackground: '#FF9300',
      },
      background: '#F5F5F5',
      brand: brandColor,
      brandHighlight: '#FDDE3E',
      division: 'rgb(191,191,191)',
      contentsDivider: '#707070',
      linkDivider: '#BFBFBF',
    },
  },
})
