import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const TodoIcon = ({ hasTodo = false }: { hasTodo?: boolean }) => (
  <Box position="relative">
    <Svg width="28.174px" height="32.79px" viewBox="0 0 28.174 32.79">
      <g transform="translate(-63 -89.188)">
        <g transform="translate(63 89.188)">
          <path
            className="cls-1"
            d="M85.669,56.585H82.735l-.069,2.157a3.137,3.137,0,0,1-.069.652l3-.04a.663.663,0,0,1,.665.661l.069,21.125a.663.663,0,0,1-.665.661H64.149a.663.663,0,0,1-.665-.661l-.069-21.125a.663.663,0,0,1,.665-.661l3,.04a3.136,3.136,0,0,1-.069-.652l.069-2.157H64.149a3.317,3.317,0,0,0-3.327,3.306V81.8a3.317,3.317,0,0,0,3.327,3.306h21.52A3.317,3.317,0,0,0,89,81.8V59.891A3.317,3.317,0,0,0,85.669,56.585Z"
            transform="translate(-60.822 -52.317)"
          />
          <path
            className="cls-1"
            d="M165.922,8.308h9.732a2.086,2.086,0,0,0,2.087-2.087V4.131a2.086,2.086,0,0,0-2.087-2.087h-2.637a2.237,2.237,0,0,0-4.458,0h-2.637a2.086,2.086,0,0,0-2.087,2.087v2.09A2.086,2.086,0,0,0,165.922,8.308Zm4.866-7.081a1.022,1.022,0,1,1-1.023,1.022A1.022,1.022,0,0,1,170.787,1.227Z"
            transform="translate(-156.7)"
          />
          <path
            className="cls-1"
            d="M152.832,244.091l-5.57,5.13-3.543-3.266-2.026,1.866,5.569,5.132,2.026-1.866,5.569-5.132Z"
            transform="translate(-134.187 -233.428)"
          />
          <path
            className="cls-1"
            d="M150.48,244.091l-4.394,4.047-2.8-2.576-1.6,1.472,4.393,4.048,1.6-1.472,4.393-4.048Z"
            transform="translate(-132.874 -224.443)"
          />
        </g>
      </g>
    </Svg>
    {hasTodo && <TodoAlert />}
  </Box>
)

const Svg = styled.svg`
  .cls-1 {
    fill: #35363a;
  }
`

const TodoAlert = () => (
  <Box
    position="absolute"
    width="15px"
    height="15px"
    borderRadius="7.5px"
    backgroundColor="brand.icon.todoAlert"
    top="19.629px"
    left="17px"
  ></Box>
)

export const TodoIconMenu = ({ hasTodo = false }: { hasTodo: boolean }) => {
  return (
    <SvgMenu width="28" height="30.629" viewBox="0 0 28 30.629">
      <g>
        <path
          d="M82.141,56.585H79.624l-.06,1.83a2.632,2.632,0,0,1-.06.553l2.577-.034a.566.566,0,0,1,.571.561l.06,17.919a.566.566,0,0,1-.571.561H63.677a.566.566,0,0,1-.571-.561l-.06-17.919a.566.566,0,0,1,.571-.561l2.577.034a2.631,2.631,0,0,1-.06-.553l.06-1.83H63.677a2.83,2.83,0,0,0-2.855,2.8V77.974a2.83,2.83,0,0,0,2.855,2.8H82.141A2.83,2.83,0,0,0,85,77.974V59.389A2.83,2.83,0,0,0,82.141,56.585Z"
          transform="translate(-60.822 -52.317)"
          fill="#35363a"
        />
        <path
          d="M165.922,8.308h9.732a2.086,2.086,0,0,0,2.087-2.087V4.131a2.086,2.086,0,0,0-2.087-2.087h-2.637a2.237,2.237,0,0,0-4.458,0h-2.637a2.086,2.086,0,0,0-2.087,2.087v2.09A2.086,2.086,0,0,0,165.922,8.308Zm4.866-7.081a1.022,1.022,0,1,1-1.023,1.022A1.022,1.022,0,0,1,170.787,1.227Z"
          transform="translate(-158.7)"
          fill="#35363a"
        />
        <path
          d="M152.832,244.091l-5.57,5.13-3.543-3.266-2.026,1.866,5.569,5.132,2.026-1.866,5.569-5.132Z"
          transform="translate(-136.187 -235.357)"
          fill="#35363a"
        />
        <path
          d="M150.48,244.091l-4.394,4.047-2.8-2.576-1.6,1.472,4.393,4.048,1.6-1.472,4.393-4.048Z"
          transform="translate(-134.857 -227.739)"
          fill="#35363a"
        />
      </g>
      {hasTodo && <circle cx="7.5" cy="7.5" r="7.5" transform="translate(13 15.629)" fill="#f4511e" />}
    </SvgMenu>
  )
}

const SvgMenu = styled.svg`
  margin-left: 2px;
`
