import { DateTime } from 'luxon'
import { PendingTask, PendingTasks } from './pendingTasks'

export const PendingTasksService = {
  compareDeadline,
  getFirstTaskIdListByDate,
}

function compareDeadline() {
  function compare(a: PendingTask, b: PendingTask) {
    const prime = b.deadline.getTime() - a.deadline.getTime()
    return prime !== 0 ? prime : a.id - b.id
  }

  return function (a: PendingTask, b: PendingTask) {
    return compare(b, a)
  }
}

// 同じ日付で最初のタスクをピックアップする
function getFirstTaskIdListByDate(
  pendingTasks: PendingTasks,
  today: DateTime = DateTime.local().setZone('Asia/Tokyo').startOf('day')
) {
  const getDate = (date: Date) => DateTime.fromJSDate(date).setZone('Asia/Tokyo').startOf('day')
  return (
    pendingTasks
      // 締め切りの日付のみの形式にしたプロパティを追加
      .map((task) => ({ ...task, deadlineString: getDate(task.deadline) }))
      // タスクの日付と一致する最初のデータのみをピックアップ
      .filter((task, number, tasks) => {
        const terminate = DateTime.fromJSDate(task.deadline).startOf('day')
        if (terminate.toMillis() < today.toMillis()) {
          // 期限切れの場合は配列の最初の要素のみ有効
          if (number === 0) {
            return true
          }
        } else {
          const sameDateTaskId = tasks.find((t) => t.deadlineString.toMillis() === getDate(task.deadline).toMillis())
          return sameDateTaskId?.id === task.id ? true : false
        }
      })
      // idだけの配列にする
      .map((task) => task.id)
  )
}
