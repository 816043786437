import type { BrandNormalized } from './brand'
import { BrandFilterService } from './brandFilterService'

export const BrandService = {
  isBrandBelongsToPrefecture,
}

/**
 * ブランドが都道府県に所属しているかを返す
 * @param brandMasters 銘柄のマスタ
 * @param targetBrandId 対象の銘柄ID
 * @param prefectureId 所属する都道府県ID
 */
export function isBrandBelongsToPrefecture(
  brandMasters: BrandNormalized[],
  targetBrandId: number,
  prefectureId?: number
) {
  const brandsBelongPrefecture = prefectureId ? brandMasters.filter(BrandFilterService.inPrefecture(prefectureId)) : []
  return brandsBelongPrefecture.map((b) => b.id).includes(targetBrandId)
}
