import { useState } from 'react'
import { ValidationError } from 'class-validator/types/validation/ValidationError'

import { InvoiceRegistrationStatus } from '../../../../domain/producerBasicProfile/producerBasicProfile'
import { ProducerBasicProfileCreate } from '../../../../domain/producerBasicProfile/producerBasicProfile'
import { InputBasicProfileValidator } from './basicProfileCreateValidator'
import { validateSync } from 'class-validator'

export type InputBasicProfileCreate = {
  versionNumber: 0 // 固定値
  regionId: string
  storagePeriodId: string
  farmingCertificationIds: number[]
  invoiceRegistrationStatus: InvoiceRegistrationStatus
}

export type BasicProfileInputState = {
  input: InputBasicProfileCreate // HTMLからの生入力
  validated: ProducerBasicProfileCreate // 生入力を期待する型にフィットしたもの（validationErrorsが存在するとき正しくない）
  validationErrors: ValidationError[] // class-validatorのバリデーションエラー
}

const getDefaultInput = (): InputBasicProfileCreate => ({
  versionNumber: 0,
  regionId: '',
  storagePeriodId: '',
  farmingCertificationIds: [],
  invoiceRegistrationStatus: 'pending',
})

export const useBasicProfileInput = () => {
  const defaultInput = getDefaultInput()
  const validated = new InputBasicProfileValidator(defaultInput)
  const validationErrors = validateSync(validated)

  const [state, setState] = useState<BasicProfileInputState>({
    input: defaultInput,
    validated,
    validationErrors,
  })

  const setValue = (key: keyof InputBasicProfileCreate, value: string | number[]) => {
    const newInput: InputBasicProfileCreate = {
      ...state.input,
      [key]: value,
    }

    const validated = new InputBasicProfileValidator(newInput)
    const validationErrors = validateSync(validated)

    setState({
      ...state,
      input: newInput,
      validated,
      validationErrors,
    })
  }

  return { state, setValue }
}
