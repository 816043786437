import styled from '@emotion/styled'

export const MenuIcon = () => (
  <Svg width="22" height="19.627" viewBox="0 0 22 19.627">
    <g transform="translate(-23.5 -91)">
      <line className="cls-1" x2="22" transform="translate(23.5 109.127)" />
      <line className="cls-1" x2="22" transform="translate(23.5 100.813)" />
      <line className="cls-1" x2="22" transform="translate(23.5 92.5)" />
    </g>
  </Svg>
)

const Svg = styled.svg`
  .cls-1 {
    fill: none;
    stroke: #35363a;
    stroke-width: 3px;
  }
`
