import { Entry3 } from './Entry3'
import { Entry4 } from './Entry4'
import { useRouteMatch } from 'react-router-dom'
import { useScrollToTopWhenPageChanged } from '../PublicPages/hooks/useScrollToTopWhenPageChanged'
import { Loading } from '../components/Loading/Loading'
import { useFetchMasters } from '../PrivatePages/hooks/useFetchMasters'
import { useProducerBasicProfile } from '../PrivatePages/Producer/hooks/useProducerBasicProfile'
import { useLoginInfoContext } from '../PrivatePages/hooks/useFetchLoginInfo'

type Mode =
  | 'entry3' // 基本情報登録
  | 'entry4' // 希望オファー入力

function useWhichPage(): Mode {
  const target = [useRouteMatch('/entry/3')?.isExact && 'entry3', useRouteMatch('/entry/4')?.isExact && 'entry4']

  return target.find((v) => !!v) as Mode
}

export function PrivateEntryContainer() {
  useScrollToTopWhenPageChanged()

  const mode = useWhichPage()
  const {
    data: { loginInfo },
  } = useLoginInfoContext()

  const mastersFetchState = useFetchMasters<
    'brands' | 'grades' | 'regions' | 'storagePeriods' | 'farmingCertifications' | 'specialConditions'
  >(['brands', 'grades', 'regions', 'storagePeriods', 'farmingCertifications', 'specialConditions'])

  const producerBasicProfileState = useProducerBasicProfile()

  if (
    !loginInfo ||
    !mastersFetchState.fetched ||
    !producerBasicProfileState.fetched ||
    !producerBasicProfileState.data.producerBasicProfile
  ) {
    return <Loading />
  }

  return (
    <>
      {mode === 'entry3' && (
        <Entry3
          masters={mastersFetchState.data.masters}
          prefectureId={loginInfo.producer.prefectureId}
          basicProfileRefresh={producerBasicProfileState.refresh}
        />
      )}
      {mode === 'entry4' && (
        <Entry4
          masters={mastersFetchState.data.masters}
          prefectureId={loginInfo.producer.prefectureId}
          regionId={producerBasicProfileState.data.producerBasicProfile.regionId}
        />
      )}
    </>
  )
}
