/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  InlineResponse201AwsS3PresignedPost,
  InlineResponse201AwsS3PresignedPostFromJSON,
  InlineResponse201AwsS3PresignedPostFromJSONTyped,
  InlineResponse201AwsS3PresignedPostToJSON,
} from './'

/**
 *
 * @export
 * @interface InlineResponse201
 */
export interface InlineResponse201 {
  /**
   * 添付ファイルUUID
   * @type {string}
   * @memberof InlineResponse201
   */
  uuid: string
  /**
   *
   * @type {InlineResponse201AwsS3PresignedPost}
   * @memberof InlineResponse201
   */
  awsS3PresignedPost: InlineResponse201AwsS3PresignedPost
}

export function InlineResponse201FromJSON(json: any): InlineResponse201 {
  return InlineResponse201FromJSONTyped(json, false)
}

export function InlineResponse201FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse201 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    uuid: json['uuid'],
    awsS3PresignedPost: InlineResponse201AwsS3PresignedPostFromJSON(json['aws_s3_presigned_post']),
  }
}

export function InlineResponse201ToJSON(value?: InlineResponse201 | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    uuid: value.uuid,
    aws_s3_presigned_post: InlineResponse201AwsS3PresignedPostToJSON(value.awsS3PresignedPost),
  }
}
