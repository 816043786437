/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * やること
 * @export
 * @interface PendingTaskReadSeller
 */
export interface PendingTaskReadSeller {
  /**
   * id
   * @type {number}
   * @memberof PendingTaskReadSeller
   */
  readonly id: number
  /**
   * DBに保存してあるやることを一意に特定するキー
   * @type {string}
   * @memberof PendingTaskReadSeller
   */
  readonly taskKey: string
  /**
   * 締め切り
   * @type {Date}
   * @memberof PendingTaskReadSeller
   */
  readonly deadline: Date
  /**
   * やることを1文で完全に表現したもの
   * @type {string}
   * @memberof PendingTaskReadSeller
   */
  readonly description: string
  /**
   * オファーid
   * @type {number}
   * @memberof PendingTaskReadSeller
   */
  readonly offerId: number | null
  /**
   * 取引案件id
   * @type {number}
   * @memberof PendingTaskReadSeller
   */
  readonly dealId: number | null
  /**
   * アメンドid
   * @type {number}
   * @memberof PendingTaskReadSeller
   */
  readonly amendId: number | null
  /**
   * デリバリーid
   * @type {number}
   * @memberof PendingTaskReadSeller
   */
  readonly deliveryId: number | null
  /**
   * 現物取引案件id
   * @type {number}
   * @memberof PendingTaskReadSeller
   */
  readonly spotContractDealId: number | null
  /**
   * 現物アメンドid
   * @type {number}
   * @memberof PendingTaskReadSeller
   */
  readonly spotContractAmendId: number | null
  /**
   * やることが作成された日時
   * @type {Date}
   * @memberof PendingTaskReadSeller
   */
  readonly createdAt: Date
  /**
   * 取引相手
   * @type {string}
   * @memberof PendingTaskReadSeller
   */
  readonly tradingPartner: string
}

export function PendingTaskReadSellerFromJSON(json: any): PendingTaskReadSeller {
  return PendingTaskReadSellerFromJSONTyped(json, false)
}

export function PendingTaskReadSellerFromJSONTyped(json: any, ignoreDiscriminator: boolean): PendingTaskReadSeller {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    taskKey: json['task_key'],
    deadline: new Date(json['deadline']),
    description: json['description'],
    offerId: json['offer_id'],
    dealId: json['deal_id'],
    amendId: json['amend_id'],
    deliveryId: json['delivery_id'],
    spotContractDealId: json['spot_contract_deal_id'],
    spotContractAmendId: json['spot_contract_amend_id'],
    createdAt: new Date(json['created_at']),
    tradingPartner: json['trading_partner'],
  }
}

export function PendingTaskReadSellerToJSON(value?: PendingTaskReadSeller | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {}
}
