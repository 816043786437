import { useLogout } from '../../auth/useLogout'

export const Logout = () => {
  const logout = useLogout()

  return (
    <div>
      <div>
        <p>
          <button type="button" onClick={logout}>
            ログアウト
          </button>
        </p>
      </div>
    </div>
  )
}

export default Logout
