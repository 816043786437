import { useEffect, useState } from 'react'
import { AuthenticationLifeCycle, AuthManger } from './useAuthManagerFromContext'
import { AuthApi } from '../../libs/api_client'
import { getApiConfig, mockLoginSessionManager } from '../../config/apiConfig'
import {
  captureException,
  handle503Error,
  handleApiServerError,
  handlerNoNetworkError,
} from '../../infra/handleApiServerError'

/**
 * AuthManagerContextにわたす値を初期化する
 */
export function useAuthManager(): AuthManger {
  const [state, setState] = useState<AuthenticationLifeCycle>('INITIAL')

  useEffect(() => {
    ;(async () => {
      setState('CHECKING')
      const auth = await Server.check()
      setState(auth)
    })()
  }, [])

  return {
    authState: state,
    switchToLoggedIn: () => {
      setState('AUTHORIZED')
    },
    login: async () => {
      await Server.login()
      setState('AUTHORIZED')
    },
    logout: async () => {
      await Server.logout()
      setState('EXITED')
    },
    inMaintenanceMode: () => {
      setState('MAINTENANCE')
    },
    outMaintenanceMode: async () => {
      setState('CHECKING')
      // すでに利用中の場合 > ログイン状態が維持されていれば回復可能
      // 新規連携画面 > jwtが無いので回復不能
      // 以上のパターンがあるので、メンテナンスから復帰した先はhomeとする
      location.href = '/#/deals'
      const auth = await Server.check()
      setState(auth)
    },
  }
}

const Server = {
  login: async () => {
    // モックAPIへのダミーログイン
    const authApi = new AuthApi(getApiConfig())
    try {
      await authApi.postTokens({ jwt: 1 })
      mockLoginSessionManager.loggedIn()
    } catch (err) {
      if (err instanceof Response) {
        handle503Error(err)
        handleApiServerError(err, captureException)
        handlerNoNetworkError(err)
      }
    }
  },
  logout: async () => {
    const authApi = new AuthApi(getApiConfig())
    try {
      await authApi.deleteTokens()
      mockLoginSessionManager.loggedOut()
    } catch (err) {
      if (err instanceof Response) {
        handle503Error(err)
        handleApiServerError(err, captureException)
        handlerNoNetworkError(err)
      }
    }
  },
  check: async () => {
    const authApi = new AuthApi(getApiConfig())
    try {
      await authApi.getLoginInfo()

      return 'AUTHORIZED'
    } catch (err) {
      if (err instanceof Response) {
        handle503Error(err)
        handleApiServerError(err, captureException)
        handlerNoNetworkError(err)

        if (err.status === 503) {
          return 'MAINTENANCE'
        } else {
          return 'EXITED'
        }
      }

      return 'EXITED'
    }
  },
}
