/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
  SpotContractDeal,
  SpotContractDealFromJSON,
  SpotContractDealToJSON,
  SpotContractDealReadSeller,
  SpotContractDealReadSellerFromJSON,
  SpotContractDealReadSellerToJSON,
  SpotContractDealsHandoverCreate,
  SpotContractDealsHandoverCreateFromJSON,
  SpotContractDealsHandoverCreateToJSON,
  SpotContractDealsUpdate,
  SpotContractDealsUpdateFromJSON,
  SpotContractDealsUpdateToJSON,
} from '../models'

export interface GetSpotContractDealsRequest {
  filters?: object
}

export interface GetSpotContractDealsIdRequest {
  id: number
}

export interface PostSpotContractDealsIdHandoverRequest {
  id: number
  spotContractDealsHandoverCreate?: SpotContractDealsHandoverCreate
}

export interface PostSpotContractOffersIdDealsRequest {
  id: number
  spotContractDeal?: SpotContractDeal
}

export interface PutSpotContractDealsIdRequest {
  id: number
  spotContractDealsUpdate?: SpotContractDealsUpdate
}

/**
 *
 */
export class SpotContractDealsApi extends runtime.BaseAPI {
  /**
   * [現物契約] ディール一覧を取得する
   */
  async getSpotContractDealsRaw(
    requestParameters: GetSpotContractDealsRequest
  ): Promise<runtime.ApiResponse<Array<SpotContractDealReadSeller>>> {
    const queryParameters: any = {}

    if (requestParameters.filters !== undefined) {
      queryParameters['_filters'] = requestParameters.filters
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/spot_contract/deals`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpotContractDealReadSellerFromJSON))
  }

  /**
   * [現物契約] ディール一覧を取得する
   */
  async getSpotContractDeals(
    requestParameters: GetSpotContractDealsRequest
  ): Promise<Array<SpotContractDealReadSeller>> {
    const response = await this.getSpotContractDealsRaw(requestParameters)
    return await response.value()
  }

  /**
   * [現物契約] 指定IDに該当するディールの情報を取得する
   */
  async getSpotContractDealsIdRaw(
    requestParameters: GetSpotContractDealsIdRequest
  ): Promise<runtime.ApiResponse<SpotContractDealReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getSpotContractDealsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/spot_contract/deals/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SpotContractDealReadSellerFromJSON(jsonValue))
  }

  /**
   * [現物契約] 指定IDに該当するディールの情報を取得する
   */
  async getSpotContractDealsId(requestParameters: GetSpotContractDealsIdRequest): Promise<SpotContractDealReadSeller> {
    const response = await this.getSpotContractDealsIdRaw(requestParameters)
    return await response.value()
  }

  /**
   * 指定IDに該当するディールの引渡情報を登録する（なんらかの理由ですでに登録済みの引渡情報があれば、上書きされる）
   */
  async postSpotContractDealsIdHandoverRaw(
    requestParameters: PostSpotContractDealsIdHandoverRequest
  ): Promise<runtime.ApiResponse<SpotContractDealReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling postSpotContractDealsIdHandover.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/spot_contract/deals/{id}/handover`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: SpotContractDealsHandoverCreateToJSON(requestParameters.spotContractDealsHandoverCreate),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SpotContractDealReadSellerFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当するディールの引渡情報を登録する（なんらかの理由ですでに登録済みの引渡情報があれば、上書きされる）
   */
  async postSpotContractDealsIdHandover(
    requestParameters: PostSpotContractDealsIdHandoverRequest
  ): Promise<SpotContractDealReadSeller> {
    const response = await this.postSpotContractDealsIdHandoverRaw(requestParameters)
    return await response.value()
  }

  /**
   * [現物契約] 指定IDに該当するオファーのディールに回答する
   */
  async postSpotContractOffersIdDealsRaw(
    requestParameters: PostSpotContractOffersIdDealsRequest
  ): Promise<runtime.ApiResponse<SpotContractDealReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling postSpotContractOffersIdDeals.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/spot_contract/offers/{id}/deals`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: SpotContractDealToJSON(requestParameters.spotContractDeal),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SpotContractDealReadSellerFromJSON(jsonValue))
  }

  /**
   * [現物契約] 指定IDに該当するオファーのディールに回答する
   */
  async postSpotContractOffersIdDeals(
    requestParameters: PostSpotContractOffersIdDealsRequest
  ): Promise<SpotContractDealReadSeller> {
    const response = await this.postSpotContractOffersIdDealsRaw(requestParameters)
    return await response.value()
  }

  /**
   * [現物契約] 指定IDに該当するディールの情報を編集する  producer_agreement_status、検査結果が指定できる  producer_agreement_statusがagreementの場合、検査結果の指定が必須
   */
  async putSpotContractDealsIdRaw(
    requestParameters: PutSpotContractDealsIdRequest
  ): Promise<runtime.ApiResponse<SpotContractDealReadSeller>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putSpotContractDealsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/spot_contract/deals/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: SpotContractDealsUpdateToJSON(requestParameters.spotContractDealsUpdate),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SpotContractDealReadSellerFromJSON(jsonValue))
  }

  /**
   * [現物契約] 指定IDに該当するディールの情報を編集する  producer_agreement_status、検査結果が指定できる  producer_agreement_statusがagreementの場合、検査結果の指定が必須
   */
  async putSpotContractDealsId(requestParameters: PutSpotContractDealsIdRequest): Promise<SpotContractDealReadSeller> {
    const response = await this.putSpotContractDealsIdRaw(requestParameters)
    return await response.value()
  }
}
