import { BrandNormalized } from './brand'

export const BrandFilterService = {
  inPrefecture,
}

/**
 * オファーが対象の都道府県のものかどうかを返す
 * @param prefectureId
 */
export function inPrefecture(prefectureId: number) {
  return function (brand: BrandNormalized): boolean {
    return brand.prefectureIds.includes(prefectureId)
  }
}
