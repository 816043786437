/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  PendingTaskReadSeller,
  PendingTaskReadSellerFromJSON,
  PendingTaskReadSellerToJSON,
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
} from '../models'

export interface GetPendingTasksRequest {
  page?: number
  perPage?: number
}

/**
 *
 */
export class PendingTasksApi extends runtime.BaseAPI {
  /**
   * sellerのやることを取得する
   */
  async getPendingTasksRaw(
    requestParameters: GetPendingTasksRequest
  ): Promise<runtime.ApiResponse<Array<PendingTaskReadSeller>>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['_page'] = requestParameters.page
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters['_perPage'] = requestParameters.perPage
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/pending_tasks`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PendingTaskReadSellerFromJSON))
  }

  /**
   * sellerのやることを取得する
   */
  async getPendingTasks(requestParameters: GetPendingTasksRequest): Promise<Array<PendingTaskReadSeller>> {
    const response = await this.getPendingTasksRaw(requestParameters)
    return await response.value()
  }
}
