/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import { TosReadSeller, TosReadSellerFromJSON, TosReadSellerToJSON } from '../models'

/**
 *
 */
export class TosApi extends runtime.BaseAPI {
  /**
   * 最新の利用規約の情報を取得する
   */
  async getTosRaw(): Promise<runtime.ApiResponse<TosReadSeller>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request({
      path: `/tos`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => TosReadSellerFromJSON(jsonValue))
  }

  /**
   * 最新の利用規約の情報を取得する
   */
  async getTos(): Promise<TosReadSeller> {
    const response = await this.getTosRaw()
    return await response.value()
  }
}
