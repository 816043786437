/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
  SpotContractAnnumRead,
  SpotContractAnnumReadFromJSON,
  SpotContractAnnumReadToJSON,
  SpotContractOfferPublicationPeriodRead,
  SpotContractOfferPublicationPeriodReadFromJSON,
  SpotContractOfferPublicationPeriodReadToJSON,
  SpotContractSpecialConditionRead,
  SpotContractSpecialConditionReadFromJSON,
  SpotContractSpecialConditionReadToJSON,
  SpotContractStoragePeriodRead,
  SpotContractStoragePeriodReadFromJSON,
  SpotContractStoragePeriodReadToJSON,
} from '../models'

/**
 *
 */
export class SpotContractCommonMasterApi extends runtime.BaseAPI {
  /**
   * [現物契約] 年産一覧を取得する
   */
  async getSpotContractAnnumsRaw(): Promise<runtime.ApiResponse<Array<SpotContractAnnumRead>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/spot_contract/annums`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpotContractAnnumReadFromJSON))
  }

  /**
   * [現物契約] 年産一覧を取得する
   */
  async getSpotContractAnnums(): Promise<Array<SpotContractAnnumRead>> {
    const response = await this.getSpotContractAnnumsRaw()
    return await response.value()
  }

  /**
   * [現物契約] オファー公開期間一覧を取得する
   */
  async getSpotContractOfferPublicationPeriodsRaw(): Promise<
    runtime.ApiResponse<Array<SpotContractOfferPublicationPeriodRead>>
  > {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/spot_contract/offer_publication_periods`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(SpotContractOfferPublicationPeriodReadFromJSON)
    )
  }

  /**
   * [現物契約] オファー公開期間一覧を取得する
   */
  async getSpotContractOfferPublicationPeriods(): Promise<Array<SpotContractOfferPublicationPeriodRead>> {
    const response = await this.getSpotContractOfferPublicationPeriodsRaw()
    return await response.value()
  }

  /**
   * [現物契約] 特別条件一覧を取得する
   */
  async getSpotContractSpecialConditionsRaw(): Promise<runtime.ApiResponse<Array<SpotContractSpecialConditionRead>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/spot_contract/special_conditions`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpotContractSpecialConditionReadFromJSON))
  }

  /**
   * [現物契約] 特別条件一覧を取得する
   */
  async getSpotContractSpecialConditions(): Promise<Array<SpotContractSpecialConditionRead>> {
    const response = await this.getSpotContractSpecialConditionsRaw()
    return await response.value()
  }

  /**
   * [現物契約] 保管期間一覧を取得する
   */
  async getSpotContractStoragePeriodsRaw(): Promise<runtime.ApiResponse<Array<SpotContractStoragePeriodRead>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/spot_contract/storage_periods`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpotContractStoragePeriodReadFromJSON))
  }

  /**
   * [現物契約] 保管期間一覧を取得する
   */
  async getSpotContractStoragePeriods(): Promise<Array<SpotContractStoragePeriodRead>> {
    const response = await this.getSpotContractStoragePeriodsRaw()
    return await response.value()
  }
}
