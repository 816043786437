/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 新着情報（暫定）
 * @export
 * @interface Alert
 */
export interface Alert {
  /**
   * 新着情報ID
   * @type {number}
   * @memberof Alert
   */
  readonly id: number
  /**
   * 新着情報カテゴリ
   *
   * ＜事前契約＞
   *
   * "offer_new_published": 新しいオファーが届きました
   * "offer_publication_ending": 現在のオファーの公開終了３日前です
   * "offer_special_arrived": 特別オファーが届きました
   * "deal_inspection_adjustment_ok": 外部検査の調整ができました
   * "deal_inspection_adjustment_ng": 外部検査の調整ができませんでした
   * "deal_closed": 仮成約が成約になりました
   * "deal_not_closed": 仮成約が不成約になりました
   *
   * ＜現物契約＞
   *
   * "spot_contract_offer_new_published": 新しいオファーが届きました
   * "spot_contract_offer_publication_ending": 現在のオファーの公開終了３日前です
   * "spot_contract_offer_special_arrived": 特別オファーが届きました
   * "spot_contract_deal_inspection_adjustment_ok": 外部検査の調整ができました
   * "spot_contract_deal_inspection_adjustment_ng": 外部検査の調整ができませんでした
   * "spot_contract_deal_closed": 仮成約が成約になりました
   * "spot_contract_deal_not_closed": 仮成約が不成約になりました
   * @type {string}
   * @memberof Alert
   */
  readonly category: AlertCategoryEnum
  /**
   * 新着情報の日付
   * @type {Date}
   * @memberof Alert
   */
  readonly date: Date
  /**
   * 新着情報のタイトル
   * @type {string}
   * @memberof Alert
   */
  readonly title: string
}

/**
 * @export
 * @enum {string}
 */
export enum AlertCategoryEnum {
  OfferNewPublished = 'offer_new_published',
  OfferPublicationEnding = 'offer_publication_ending',
  OfferSpecialArrived = 'offer_special_arrived',
  DealInspectionAdjustmentOk = 'deal_inspection_adjustment_ok',
  DealInspectionAdjustmentNg = 'deal_inspection_adjustment_ng',
  DealClosed = 'deal_closed',
  DealNotClosed = 'deal_not_closed',
  SpotContractOfferNewPublished = 'spot_contract_offer_new_published',
  SpotContractOfferPublicationEnding = 'spot_contract_offer_publication_ending',
  SpotContractOfferSpecialArrived = 'spot_contract_offer_special_arrived',
  SpotContractDealInspectionAdjustmentOk = 'spot_contract_deal_inspection_adjustment_ok',
  SpotContractDealInspectionAdjustmentNg = 'spot_contract_deal_inspection_adjustment_ng',
  SpotContractDealClosed = 'spot_contract_deal_closed',
  SpotContractDealNotClosed = 'spot_contract_deal_not_closed',
}

export function AlertFromJSON(json: any): Alert {
  return AlertFromJSONTyped(json, false)
}

export function AlertFromJSONTyped(json: any, ignoreDiscriminator: boolean): Alert {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    category: json['category'],
    date: new Date(json['date']),
    title: json['title'],
  }
}

export function AlertToJSON(value?: Alert | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {}
}
