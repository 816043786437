import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'
import * as serviceWorker from './serviceWorker'
import { App } from './presentation/Router'
import { SentryWrapper } from './sentry'

import { theme } from './theme/theme'
import { ChakraProvider } from '@chakra-ui/react'

import { HelmetProvider } from 'react-helmet-async'

const DefaultStyle = styled.div`
  color: ${theme.colors.brand.text.main};
`

ReactDOM.render(
  <StrictMode>
    <SentryWrapper>
      <ChakraProvider theme={theme}>
        <HelmetProvider>
          <DefaultStyle>
            <App />
          </DefaultStyle>
        </HelmetProvider>
      </ChakraProvider>
    </SentryWrapper>
  </StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
