import { useState } from 'react'
import { validateSync } from 'class-validator'
import { ValidationError } from 'class-validator/types/validation/ValidationError'
import { EntryOfferRequestCreate } from '../../../../domain/offerRequest/offerRequest'
import { InputOfferRequestValidator } from './offerRequestCreateValidator'
import { useLoginInfoContext } from '../../../PrivatePages/hooks/useFetchLoginInfo'
import { BrandNormalized } from '../../../../domain/brand/brand'
import { BrandService } from '../../../../domain/brand/brandService'

export type InputOfferRequestCreate = {
  // 銘柄
  brandId: string
  // 等級
  gradeId: string
  // 地域(入力しない)
  regionId: number | null
  // 1俵(60kg)あたり単価（税込）
  unitPrice: string
  // 特別条件リスト
  specialConditionIds: string[]
}

export type InputOfferRequestCreateChanged = { [key in keyof InputOfferRequestCreate]: boolean }

export type OfferRequestInputState = {
  edited: boolean
  options: { isBrandFilterActive: boolean }
  input: InputOfferRequestCreate
  changed: InputOfferRequestCreateChanged
  validated: EntryOfferRequestCreate
  validationErrors: ValidationError[]
}

const getInitialInput = (regionId: number | null): InputOfferRequestCreate => ({
  brandId: '',
  gradeId: '',
  regionId,
  unitPrice: '',
  specialConditionIds: ['', ''],
})

export const useOfferRequestInput = (regionId: number | null, brandsMaster: BrandNormalized[]) => {
  const {
    data: { producer },
  } = useLoginInfoContext()

  const defaultInput = getInitialInput(regionId)
  const defaultChanged: InputOfferRequestCreateChanged = {
    brandId: false,
    gradeId: false,
    regionId: false,
    unitPrice: false,
    specialConditionIds: false,
  }

  const validated = new InputOfferRequestValidator(defaultInput)
  const validationErrors = validateSync(validated)

  const [state, setState] = useState<OfferRequestInputState>({
    edited: false,
    options: { isBrandFilterActive: true },
    input: defaultInput,
    changed: defaultChanged,
    validated,
    validationErrors,
  })

  const setValue = (key: keyof InputOfferRequestCreate, value: string | string[]) => {
    const newInput: InputOfferRequestCreate = {
      ...state.input,
      [key]: value,
    }

    const validated = new InputOfferRequestValidator(newInput)
    const validationErrors = validateSync(validated)

    setState((state) => ({
      ...state,
      edited: true,
      input: newInput,
      validated,
      validationErrors,
    }))
  }

  const toChanged = (key: keyof InputOfferRequestCreate) => {
    const newChanged: InputOfferRequestCreateChanged = {
      ...state.changed,
      [key]: true,
    }
    setState((state) => ({
      ...state,
      edited: true,
      changed: newChanged,
    }))
  }

  const toggleBrandFilter = () => {
    const newOptions = {
      ...state.options,
      isBrandFilterActive: !state.options.isBrandFilterActive,
    }

    if (newOptions.isBrandFilterActive) {
      if (!BrandService.isBrandBelongsToPrefecture(brandsMaster, state.validated.brandId, producer?.prefectureId)) {
        // 銘柄の都道府県フィルタがonになったとき、表示対象外の銘柄を選択していた場合、未選択に戻す
        const newInput = {
          ...state.input,
          brandId: '',
        }

        const validated = new InputOfferRequestValidator(newInput)
        const validationErrors = validateSync(validated)

        setState((state) => ({
          ...state,
          input: newInput,
          validated,
          validationErrors,
        }))
      }
    }

    setState((state) => ({
      ...state,
      options: newOptions,
    }))
  }

  return {
    state,
    setValue,
    toChanged,
    toggleBrandFilter,
  }
}
