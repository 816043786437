import { Flex, Box, CircularProgress, CircularProgressLabel } from '@chakra-ui/react'

import { STEP_LIST } from './stepList'

export const CircularSteps = ({ step }: { step: 1 | 2 | 3 | 4 }) => {
  return (
    <Flex justifyContent="center" alignItems="center" paddingY="20px" backgroundColor="brand.brand">
      <CircularProgress value={step * 25} color="brand.text.alert" trackColor="brand.background" size="50px">
        <CircularProgressLabel>{step} / 4</CircularProgressLabel>
      </CircularProgress>
      <Box marginLeft="20px" fontSize="18px" fontWeight="bold">
        {STEP_LIST[step - 1]}
      </Box>
    </Flex>
  )
}
