/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 返すデータが特にないときに返すレスポンス
 * @export
 * @interface ResponseSuccessBody
 */
export interface ResponseSuccessBody {
  /**
   * 結果（"success"で固定）
   * @type {string}
   * @memberof ResponseSuccessBody
   */
  readonly result: ResponseSuccessBodyResultEnum
}

/**
 * @export
 * @enum {string}
 */
export enum ResponseSuccessBodyResultEnum {
  Success = 'success',
}

export function ResponseSuccessBodyFromJSON(json: any): ResponseSuccessBody {
  return ResponseSuccessBodyFromJSONTyped(json, false)
}

export function ResponseSuccessBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseSuccessBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    result: json['result'],
  }
}

export function ResponseSuccessBodyToJSON(value?: ResponseSuccessBody | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {}
}
