/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * 年産
 * @export
 * @interface AnnumRead
 */
export interface AnnumRead {
  /**
   * ID
   * @type {number}
   * @memberof AnnumRead
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof AnnumRead
   */
  readonly versionNumber: number
  /**
   * ステータス（true: 有効、false: 無効）
   * @type {boolean}
   * @memberof AnnumRead
   */
  isActive: boolean
  /**
   * 年産名
   * @type {string}
   * @memberof AnnumRead
   */
  name: string
  /**
   * 西暦
   * @type {number}
   * @memberof AnnumRead
   */
  year: number
}

export function AnnumReadFromJSON(json: any): AnnumRead {
  return AnnumReadFromJSONTyped(json, false)
}

export function AnnumReadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnumRead {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isActive: json['is_active'],
    name: json['name'],
    year: json['year'],
  }
}

export function AnnumReadToJSON(value?: AnnumRead | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    is_active: value.isActive,
    name: value.name,
    year: value.year,
  }
}
