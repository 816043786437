/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * オファー通知設定
 * @export
 * @interface SettingOfferNotification
 */
export interface SettingOfferNotification {
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof SettingOfferNotification
   */
  versionNumber: number
  /**
   * 自身のプロフィール情報とマッチした時の通知の有効・無効
   * @type {boolean}
   * @memberof SettingOfferNotification
   */
  whenMatchedProfile: boolean
  /**
   * 指定した条件にマッチした時の通知の有効・無効
   * @type {boolean}
   * @memberof SettingOfferNotification
   */
  whenMatchedConditions: boolean
}

export function SettingOfferNotificationFromJSON(json: any): SettingOfferNotification {
  return SettingOfferNotificationFromJSONTyped(json, false)
}

export function SettingOfferNotificationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SettingOfferNotification {
  if (json === undefined || json === null) {
    return json
  }
  return {
    versionNumber: json['version_number'],
    whenMatchedProfile: json['when_matched_profile'],
    whenMatchedConditions: json['when_matched_conditions'],
  }
}

export function SettingOfferNotificationToJSON(value?: SettingOfferNotification | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    version_number: value.versionNumber,
    when_matched_profile: value.whenMatchedProfile,
    when_matched_conditions: value.whenMatchedConditions,
  }
}
